import React from 'react';
import { Email } from '@styled-icons/material';
import { Mobile } from '@styled-icons/fa-solid';
import { Theme } from '../../assets';
import PropTypes from 'prop-types';

const ProfileInfoItem = ({ email, phone }) => {
  return (
    <div className={'align-col-middle'} style={styles.container}>
      <div className={'align-col-start'} style={styles.itemView}>
        <div className={'align-middle'}>
          <Email color={Theme.colors.text} size={18} />
          <div style={{ marginLeft: 4, ...styles.itemTxt }}>{email}</div>
        </div>
        <div className={'align-middle'} style={{ marginTop: 13 }}>
          <Mobile color={Theme.colors.text} size={18} />
          <div style={{ marginLeft: 7, ...styles.itemTxt }}>{phone}</div>
        </div>
      </div>
      <div style={styles.divider} />
    </div>
  );
};

const styles = {
  container: { width: '100%', marginTop: 35 },
  itemView: {
    width: '100%',
    alignItems: 'flex-start',
    padding: 15,
    marginBottom: 20,
    backgroundColor: Theme.colors.gray8,
    borderRadius: 15
  },
  itemTxt: { flex: 1, fontSize: 16, fontFamily: Theme.fonts.medium, color: Theme.colors.text },
  divider: { height: 1, width: '100%', backgroundColor: Theme.colors.gray9, marginBottom: 20 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.email === nextProps.email && prevProps.phone === nextProps.phone;
}

ProfileInfoItem.propTypes = {
  id: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string
};
export default React.memo(ProfileInfoItem, arePropsEqual);
