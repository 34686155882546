import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import PullToRefresh from 'react-simple-pull-to-refresh';
import InfiniteScroll from 'react-infinite-scroller';
import {
  VSort_Title,
  VSort_Closest,
  VSort_FastDelivery,
  VSort_HighRating,
  VSort_Low2HighPrice,
  VSort_PopularFirst
} from '../../constants/config';
import Theme from '../../assets/theme';
import { ROUTES_NAMES } from '../../constants';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../constants/config';
import { SearchInput } from '../../components/Inputs';
import { setHomeVendorFilter, setHomeVendorSort } from '../../store/actions/app';
import { setVendorCart } from '../../store/actions/shop';
import VendorItem from '../../components/Vendor/VendorItem';
import FeatureList from '../../components/Home/FeatureList';
import CategItem from '../../components/Home/CategItem';
import HomeHeader from '../../components/Home/HomeHeader';
import BlockSpinner from '../../components/BlockSpinner';
import NoRestaurants from '../../components/Empty/NoRestaurants';
import { RoundIconBtn } from '../../components/Buttons';
import './index.css';
import { CommonTabs } from '../../components/TabSelector';
import { Search } from '@styled-icons/evil';
import { Filter } from '@styled-icons/bootstrap';
import PropTypes from 'prop-types';
import { getFeaturedBlocks, getFoodCategories, getVendors } from '../../store/actions/vendors';
import { CircularProgress } from '@mui/material';
import { isEmpty } from '../../utils/common';

const expectedBlocks = [
  { key: 'suggested', icon: 'top' },
  { key: 'new', icon: 'new' },
  { key: 'exclusive', icon: 'collision' }
  // { key: 'free_delivery', icon: null },
  // { key: 'all', icon: null },
];
const vertPerPage = 10;

const HomePage = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const catsLoaded = useRef(false);
  const featureLoaded = useRef(false);
  const vendorsLoaded = useRef(false);

  const foodCategID = useRef(null);
  const scroller = useRef(null);

  const [curFoodCatID, setCurFoodCatID] = useState(null);

  const [foodCategories, setFoodCats] = useState([]);
  const [featuredBlocks, setFeaturedBlocks] = useState([]);
  const [allvendors, setAllVendors] = useState([]);

  const [dataLoading, setDataLoading] = useState(null);
  const [showCateg, setShowCateg] = useState(false);

  const [vertLoading, setVertLoading] = useState(false);

  const [isRefreshing, setRefreshing] = useState(false);

  const [vertPage, setVertPage] = useState(1);
  const [vertTotalPages, setVertTotalPages] = useState(1);

  useEffect(() => {
    loadData(true);
    foodCategID.current = null;
    setCurFoodCatID(null);
  }, [
    props.home_vendor_filter.vendor_type,
    props.home_vendor_filter.order_type,
    props.coordinates.latitude,
    props.coordinates.longitude
  ]);

  useEffect(() => {
    if (dataLoading == false) {
      loadVendors(1, vertPerPage, true);
    }
  }, [
    props.home_vendor_filter.is_meal,
    props.home_vendor_filter.is_dietary,
    props.home_vendor_filter.ongoing_offer,
    props.home_vendor_filter.open_now,
    props.home_vendor_filter.online_payment,
    props.home_vendor_filter.searchTerm,
    props.home_vendor_filter.low_fee,
    props.home_vendor_filter.high_fee,
    props.home_vendor_sort
  ]);

  useEffect(() => {
    if (dataLoading == false) {
      onFavChange(props.vendorData);
    }
  }, [props.vendorData.isFav]);

  const getFilers = (flag) => {
    const {
      vendor_type,
      order_type,
      is_meal,
      is_dietary,
      ongoing_offer,
      open_now,
      online_payment,
      low_fee,
      high_fee,
      searchTerm
    } = props.home_vendor_filter;
    let filters = [];
    if (vendor_type === 'vendors') {
      filters.push('vendor_type=Restaurant');
    } else {
      filters.push('vendor_type=Grocery');
    }
    filters.push('order_method=' + order_type);

    if (flag === 1) {
      return filters;
    }

    if (foodCategID.current != null) {
      filters.push(`food_category_ids[]=${foodCategID.current}`);
    }
    if (flag === 2) {
      return filters;
    }
    if (is_meal) {
      filters.push('is_meal=1');
    }
    if (is_dietary) {
      filters.push('is_dietary=1');
    }
    if (ongoing_offer) {
      filters.push('promotions=1');
    }
    if (open_now) {
      filters.push('open_now=1');
    }
    if (online_payment) {
      filters.push('online_payment=1');
    }
    if (low_fee != null) {
      filters.push(`low_fee=${low_fee}`);
    }
    if (high_fee != null) {
      filters.push(`high_fee=${high_fee}`);
    }
    if (searchTerm !== '') {
      filters.push('name=' + searchTerm);
    }
    return filters;
  };

  const getSortDir = (sort_type) => {
    if (
      sort_type === VSort_Title ||
      sort_type === VSort_FastDelivery ||
      sort_type === VSort_Closest ||
      sort_type === VSort_Low2HighPrice
    ) {
      return 1;
    } else if (sort_type === VSort_HighRating || sort_type === VSort_PopularFirst) {
      return -1;
    }
    return 1;
  };

  const loadData = async (includeCatLoad = false, isHomeRefresh = false) => {
    if (isHomeRefresh) {
      setRefreshing(true);
    } else {
      setDataLoading(true);
    }

    setShowCateg(includeCatLoad === false);
    if (includeCatLoad) {
      loadCategories();
    }
    loadVendors(1, vertPerPage, true);
    loadFeaturedBlocks();
  };

  const refresh = async () => {
    setRefreshing(true);
    await loadCategories();
    await loadVendors(1, vertPerPage, true);
    await loadFeaturedBlocks();
  };

  const loadCategories = async () => {
    try {
      catsLoaded.current = false;
      let formattedFoodCategories = [];
      let categs_filterKeys = getFilers(1);
      let categs_response = await getFoodCategories(categs_filterKeys);
      if (
        !!categs_response &&
        !!categs_response.food_categories &&
        !!categs_response.food_categories.length >= 0
      ) {
        formattedFoodCategories = categs_response.food_categories.map(
          ({ icon, id, title_en, title_sq, image }) => ({
            id,
            icon,
            title_en,
            title_sq,
            image,
            selected: false
          })
        );
      }

      catsLoaded.current = true;
      setFoodCats(formattedFoodCategories);
      checkDataLoading();
    } catch (error) {
      catsLoaded.current = true;
      checkDataLoading();
    }
  };

  const loadVendors = async (page, perPage, forceLoading = false) => {
    try {
      if (!vertLoading || forceLoading) {
        vendorsLoaded.current = false;
        if (forceLoading) {
          setVertLoading(true);
        }
        let { latitude, longitude } = props.coordinates;
        let filterKeys = getFilers();
        let order_dir = getSortDir(props.home_vendor_sort);

        let vendorsData = await getVendors(
          page,
          latitude,
          longitude,
          props.home_vendor_sort,
          order_dir,
          perPage,
          filterKeys
        );

        if (page > 1) {
          const currentVendorIds = allvendors.map((x) => x.id);
          const newVendors = vendorsData.data.filter((x) => currentVendorIds.indexOf(x.id) === -1);
          setVertPage(vendorsData['current_page']);
          setVertTotalPages(vendorsData['last_page']);
          setAllVendors([...allvendors, ...newVendors]);
        } else {
          setVertPage(vendorsData['current_page']);
          setVertTotalPages(vendorsData['last_page']);
          setAllVendors(vendorsData.data);
        }
        vendorsLoaded.current = true;
        setVertLoading(false);
        checkDataLoading();
      }
    } catch (error) {
      vendorsLoaded.current = true;
      setVertLoading(false);
      checkDataLoading();
    }
  };

  const loadFeaturedBlocks = async () => {
    try {
      featureLoaded.current = false;
      let { latitude, longitude } = props.coordinates;
      let filterKeys = getFilers(2);
      // eslint-disable-next-line react/prop-types
      let _featuredBlocks = await getFeaturedBlocks(latitude, longitude, filterKeys);
      setFeaturedBlocks(_featuredBlocks);
      featureLoaded.current = true;
      checkDataLoading();
    } catch (error) {
      featureLoaded.current = true;
      checkDataLoading();
    }
  };

  const isFiltered = () => {
    const {
      is_meal,
      is_dietary,
      ongoing_offer,
      open_now,
      online_payment,
      low_fee,
      high_fee,
      searchTerm
    } = props.home_vendor_filter;

    if (
      is_meal ||
      is_dietary ||
      ongoing_offer ||
      open_now ||
      online_payment ||
      low_fee != null ||
      high_fee != null ||
      isEmpty(searchTerm) === false
    ) {
      return true;
    }
    return false;
  };

  const isEmptyData = () => {
    let featured_cnt = 0;
    for (let i = 0; i < expectedBlocks.length; i++) {
      let key = expectedBlocks[i].key;
      if (featuredBlocks[key] && featuredBlocks[key].vendors) {
        featured_cnt =
          featured_cnt + featuredBlocks[key].vendors.length
            ? featuredBlocks[key].vendors.length
            : 0;
      }
    }
    return featured_cnt === 0 && allvendors
      ? isFiltered() === false && allvendors.length === 0
      : false;
  };

  const checkDataLoading = () => {
    if (
      catsLoaded.current === true &&
      featureLoaded.current === true &&
      vendorsLoaded.current === true
    ) {
      setDataLoading(false);
      setRefreshing(false);
    }
  };

  const onFavChange = (data) => {
    let found_all_index = allvendors.findIndex((i) => i.id === data.id);
    if (found_all_index !== -1) {
      let tmp = allvendors.slice(0, allvendors.length);
      tmp[found_all_index].isFav = data.isFav;
      setAllVendors(tmp);
    }

    let _featuredBlocks = Object.assign({}, featuredBlocks);

    expectedBlocks.map(({ key }) => {
      if (
        _featuredBlocks[key] != null &&
        _featuredBlocks[key].vendors != null &&
        _featuredBlocks[key].vendors.length > 0
      ) {
        let found_index = _featuredBlocks[key].vendors.findIndex((i) => i.id === data.id);
        if (found_index !== -1) {
          _featuredBlocks[key].vendors[found_index].isFav = data.isFav;
        }
      }
    });
    setFeaturedBlocks(_featuredBlocks);
  };

  const goToRestaurantDetails = (vendor) => {
    props.setVendorCart(vendor);
    navigate(`/vendor/${vendor.id}/${vendor.order_method}`);
  };

  const _renderCategories = () => {
    return (
      <div className={'align-col-start categ-hlist'}>
        <div className={'align-row-start categ-list'}>
          {foodCategories.map((cat) => (
            <CategItem
              key={cat.id}
              category={cat}
              cat_id={cat.id}
              isSelected={curFoodCatID === cat.id}
              onSelect={(categ) => {
                if (foodCategID.current === categ.id) {
                  foodCategID.current = null;
                  setCurFoodCatID(null);
                } else {
                  foodCategID.current = categ.id;
                  setCurFoodCatID(categ.id);
                }
                loadData(false);
              }}
            />
          ))}
        </div>
        <div className={'scrollview_hider'} />
      </div>
    );
  };

  const _renderFeatureBlocks = () => {
    return (
      <div style={{ width: '100%', marginTop: 8 }}>
        {expectedBlocks.map(({ key }) => {
          if (
            featuredBlocks[key] &&
            featuredBlocks[key].block &&
            featuredBlocks[key].block['is_active']
          ) {
            const restaurants = featuredBlocks[key].vendors;
            if (!restaurants || restaurants.length === 0) {
              return null;
            }
            return (
              <FeatureList
                key={key}
                label={featuredBlocks[key].block.title_sq}
                items={restaurants}
                onFavChange={onFavChange}
                goVendorDetail={(vendor) => {
                  goToRestaurantDetails(vendor);
                }}
              />
            );
          }
        })}
      </div>
    );
  };

  const _renderSearchView = () => {
    if (dataLoading !== false) {
      return null;
    }
    return (
      <div className="row_center" style={{ width: '100%', marginBottom: 20 }}>
        <SearchInput
          placeholder={
            props.home_vendor_filter.vendor_type === 'vendors'
              ? t('search.search_vendors')
              : t('search.search_grocery')
          }
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45, marginRight: 12 }}
          value={props.home_vendor_filter.searchTerm}
          onChange={(searchTerm) => {
            props.setHomeVendorFilter({
              searchTerm: searchTerm
            });
          }}
        />
        <RoundIconBtn
          style={{ width: 45, height: 45 }}
          icon={<Filter size={26} color={Theme.colors.cyan2} />}
          onClick={() => {
            navigate(ROUTES_NAMES.homeFilters);
          }}
        />
      </div>
    );
  };

  const _renderVertVendors = () => {
    if (dataLoading === false && allvendors ? allvendors.length === 0 : false) {
      if (
        props.home_vendor_filter.searchTerm == null ||
        props.home_vendor_filter.searchTerm === ''
      ) {
        return (
          <NoRestaurants
            desc={
              props.home_vendor_filter.vendor_type === 'vendors'
                ? t('no_restaurant_filter')
                : t('no_grocery_filter')
            }
            style={{ marginTop: 60, marginBottom: 20 }}
          />
        );
      }
      return (
        <NoRestaurants
          desc={
            props.home_vendor_filter.vendor_type === 'vendors'
              ? t('no_restaurant_search')
              : t('no_grocery_search')
          }
          style={{ marginTop: 60, marginBottom: 20 }}
        />
      );
    }

    return (
      <div className="col_center" style={{ width: '100%', alignItems: 'flex-start' }}>
        <div className={'subject-title'}>
          {props.home_vendor_filter.vendor_type === 'vendors' ? t('all_vendors') : t('all_grocery')}
        </div>
        <div style={{ width: '100%', marginTop: 16 }}>
          {allvendors
            ? allvendors.map((vendor) => (
                <div key={vendor.id} className="col_center" style={{ width: '100%' }}>
                  <VendorItem
                    data={vendor}
                    vendor_id={vendor.id}
                    isFav={vendor.isFav}
                    is_open={vendor.is_open}
                    // style={{ width: '100%', marginBottom: 16, marginRight: 0 }}
                    onFavChange={onFavChange}
                    onClick={() => {
                      goToRestaurantDetails(vendor);
                    }}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
    );
  };

  return (
    <div className={'w100 home-screen'}>
      <div className={'ph-20 w100'}>
        <HomeHeader
          curTab={props.home_vendor_filter.vendor_type}
          cashback_amount={props.user.cashback_amount}
          photo={props.user.photo}
          isLoggedIn={props.isLoggedIn}
          coordinates={props.coordinates}
          onLocationSetup={(location) => {
            navigate(
              `/setup-location?from_home=true&lat=${location.latitude}&lng=${location.longitude}`
            );
          }}
          onGoWallet={() => {
            navigate(ROUTES_NAMES.cashback);
          }}
          onGoProfile={() => {
            navigate(ROUTES_NAMES.profile);
          }}
          onTabChange={(item) => {
            props.setHomeVendorFilter({
              order_type: OrderType_Delivery
            });
            setTimeout(() => {
              props.setHomeVendorFilter({
                vendor_type: item
              });
            }, 200);
          }}
        />
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={
              props.home_vendor_filter.vendor_type === 'vendors'
                ? [OrderType_Delivery, OrderType_Pickup, OrderType_Reserve]
                : [OrderType_Delivery, OrderType_Pickup]
            }
            item={props.home_vendor_filter.order_type}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => {
              setTimeout(() => {
                props.setHomeVendorFilter({
                  order_type: item
                });
              }, 200);
            }}
          />
        </div>
      </div>
      {dataLoading === false && isEmptyData() === true ? (
        <div className={'scrollview'}>
          {_renderCategories()}
          <div style={{ height: '50px' }}></div>
          <NoRestaurants
            desc={
              props.home_vendor_filter.vendor_type === 'vendors'
                ? t('no_restaurant_filter')
                : t('no_grocery_filter')
            }
            style={{ marginTop: 60, marginBottom: 20 }}
          />
        </div>
      ) : (
        <div className={'scrollview'} id={'home-scroller'}>
          <InfiniteScroll
            ref={scroller}
            pageStart={0}
            loadMore={() => {
              if (vertPage < vertTotalPages) {
                loadVendors(vertPage + 1, vertPerPage);
              }
            }}
            hasMore={vertPage < vertTotalPages}
            loader={
              vertLoading ? null : (
                <div className="align-col-middle mt12 mb20" key={0}>
                  <CircularProgress size={20} color="primary" />
                </div>
              )
            }
            useWindow={false}>
            <PullToRefresh onRefresh={refresh} className={'pull-to-refresh'}>
              {_renderCategories()}
              {_renderFeatureBlocks()}
              {_renderSearchView()}
              {!isRefreshing && vertLoading ? (
                <BlockSpinner style={{ paddingBottom: 60, height: 270 }} />
              ) : (
                _renderVertVendors()
              )}
            </PullToRefresh>
          </InfiniteScroll>
        </div>
      )}
      {dataLoading !== false && (
        <div
          className={'align-col-middle'}
          style={{
            height: window.innerHeight - 135 - (showCateg ? 126 : 0) - 76,
            width: '100%',
            backgroundColor: Theme.colors.white,
            position: 'fixed',
            left: 0,
            top: showCateg ? 261 : 135,
            zIndex: 1,
            paddingBottom: 60
          }}>
          <BlockSpinner />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,
  address: app.address || {},
  language: app.language,
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort,
  vendorData: shop.vendorData
});

HomePage.propTypes = {
  home_vendor_filter: PropTypes.shape({
    vendor_type: PropTypes.string,
    order_type: PropTypes.string,
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number,
    searchTerm: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  user: PropTypes.shape({
    photo: PropTypes.string,
    cashback_amount: PropTypes.number
  }),
  isLoggedIn: PropTypes.bool,
  home_vendor_sort: PropTypes.string,
  setVendorCart: PropTypes.func,
  setHomeVendorFilter: PropTypes.func
};

export default connect(mapStateToProps, {
  setHomeVendorFilter,
  setHomeVendorSort,
  setVendorCart
})(HomePage);
