import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import UserItem from '../../../components/Chats/UserItem';
import NoFriendList from '../../../components/Empty/NoFriendList';
import './index.css';
import { Theme } from '../../../assets';
import Header from '../../../components/Header';
import { getFriends } from '../../../store/actions/app';

const MyFriends = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: null,
    friends: []
  });
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllFriends();
  }, []);

  const getAllFriends = (searchTerm) => {
    setState({ ...state, loading: true });

    getFriends('accepted', searchTerm)
      .then((data) => {
        setState({ ...state, loading: false, friends: data });
      })
      .catch(() => {
        setState({ ...state, loading: false });
      });
  };

  return (
    <div
      className={'align-col-start flex_1 myfriends-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      <Header title={t('social.my_friends')} />
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('social.search.friends')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45 }}
          value={searchTerm}
          onChange={(text) => {
            setSearchTerm(text);
            getAllFriends(text);
          }}
        />
      </div>
      <div className={'scrollview mt20'}>
        {state.loading === false && state.friends.length === 0 ? (
          <NoFriendList title={t('social.no_friends')} desc={t('social.no_friends_desc')} />
        ) : (
          state.friends.map((item) => (
            <UserItem
              key={item.id}
              item={item}
              id={item.id}
              onClick={() => {
                navigate(`/snapfooder/${item.id}`);
              }}
            />
          ))
        )}
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {})(MyFriends);
