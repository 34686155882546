import React, { useState } from 'react';
import { Dialog, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import { MainBtn } from '../../Buttons';
import './index.css';
import { CloseOutline } from '@styled-icons/evaicons-outline';
import { CheckCircle, CheckCircleFill } from '@styled-icons/bootstrap';

const SearchFilterModal = ({ isOpen, items, onClose, onFilterChange, onApplyFilter }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [filters, setFilters] = useState([]);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  React.useEffect(() => {
    setFilters(items);
  }, [items]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal search-filter-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div className={'align-middle'}>
          <IconButton style={{ padding: 0 }} onClick={onClose}>
            <CloseOutline size={25} color={Theme.colors.text} />
          </IconButton>
          <div style={styles.modalTitle}>{t('search.filter')}</div>
          <div style={{ width: 30 }} />
        </div>
        <div className={'align-col-middle w100'} style={{ marginTop: 20, marginBottom: 20 }}>
          {filters.map((filter, index) => (
            <div
              key={filter.title}
              onClick={() => {
                let arr = filters.splice(0, filters.length);
                arr[index].check = !arr[index].check;
                setFilters(arr);
                onFilterChange(arr);
              }}
              className={'flex_between'}
              style={{ width: '100%', height: 50, cursor: 'pointer' }}>
              <div style={styles.modalBtnTxt}>{filter.title}</div>
              {filter.check == true ? (
                <CheckCircleFill size={30} color={Theme.colors.cyan2} />
              ) : (
                <CheckCircle size={30} color={Theme.colors.gray5} />
              )}
            </div>
          ))}
        </div>
        <MainBtn title={t('search.applyFilters')} onClick={onApplyFilter} />
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 12
  },
  modalBtnTxt: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  }
};

SearchFilterModal.propTypes = {
  isOpen: PropTypes.bool,
  items: PropTypes.array,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
  onApplyFilter: PropTypes.func
};
export default SearchFilterModal;
