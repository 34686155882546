import React from 'react';
import { Theme } from '../../../assets';
import AppText from '../../AppText';
import './index.css';
import PropTypes from 'prop-types';

const PopularItem = ({ title, onPress }) => {
  return (
    <div className={'popular-item'} onClick={onPress}>
      <AppText
        style={{ fontSize: 14, fontFamily: Theme.fonts.semiBold, color: Theme.colors.cyan2 }}>
        {title}
      </AppText>
    </div>
  );
};
PopularItem.propTypes = {
  title: PropTypes.string,
  onPress: PropTypes.func
};
export default PopularItem;
