import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';

const VendorClosedModal = ({ showModal, title, goHome, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal vendor-closed-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div style={styles.modalTitle}>{title}</div>
        <div
          onClick={onClose}
          className={'align-middle'}
          style={{ width: '100%', height: 40, cursor: 'pointer' }}>
          <div style={styles.seeBtnTxt}>{t('vendor_profile.see_menu')}</div>
        </div>
        <div
          onClick={goHome}
          className={'align-middle'}
          style={{ width: '100%', height: 40, cursor: 'pointer' }}>
          <div style={styles.homeBtnTxt}>{t('vendor_profile.gobackhome')}</div>
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 30,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.text,
    marginBottom: 20
  },
  seeBtnTxt: {
    fontSize: 16,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.cyan2
  },
  homeBtnTxt: {
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal && prevProps.title === nextProps.title;
}

VendorClosedModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  goHome: PropTypes.func
};

export default React.memo(VendorClosedModal, arePropsEqual);
