import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Users } from '@styled-icons/fa-solid';

import { Theme } from '../../../assets';
import './index.css';
import Svg_newchat from '../../../assets/svgs/chats/ic_new_chat.svg';

const NewConvModal = ({ isOpen, onClose, onSingleChat, onGroupChat }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal new-conv-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div style={styles.modalTitle}>{t('social.start_new')}</div>
        <div
          onClick={onSingleChat}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <img src={Svg_newchat} />
          <div style={styles.modalBtnTxt}>{t('social.conversation')}</div>
        </div>
        <div style={styles.divider} />
        <div
          onClick={onGroupChat}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <Users size={22} color={Theme.colors.text} />
          <div style={styles.modalBtnTxt}>{t('social.group_chat')}</div>
        </div>
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 12
  },
  modalBtnTxt: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
NewConvModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSingleChat: PropTypes.func,
  onGroupChat: PropTypes.func
};
export default React.memo(NewConvModal, arePropsEqual);
