import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import OnboardingScreen from '../../views/tour/on-boarding';
import PropTypes from 'prop-types';

const OnboardRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route exact path={'/'} element={<OnboardingScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </React.Fragment>
  );
};

OnboardRoutes.propTypes = {
  isAppLoaded: PropTypes.bool
};
export default OnboardRoutes;
