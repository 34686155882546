import moment from 'moment';
import React from 'react';
import { Config } from '../../../constants';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.css';

const PromotionItem = ({ data, onSelect, style }) => {
  const { t } = useTranslation();

  const getDateLimit = () => {
    return moment(data.end_time, 'YYYY-MM-DD  hh:mm:ss').diff(moment(new Date()), 'days');
  };

  return (
    <div onClick={() => onSelect()} className={'promotion-item'} style={style}>
      <div className={'align-row-start w100'} style={{ width: '100%', marginBottom: 4 }}>
        <div className={'title flex_1'}>{data.title}</div>
        <div style={{ minWidth: 68, alignItems: 'flex-end' }}>
          {getDateLimit() > 0 && (
            <div className={'date_limit'}>
              {getDateLimit()} {t('promotions.days_left')}
            </div>
          )}
        </div>
      </div>
      <div className={'align-middle w100'}>
        <div className={'align-col-start flex_1'} style={{ alignItems: 'flex-start' }}>
          <div className={'sub_title'}>
            {data.type.charAt(0).toUpperCase() + data.type.slice(1)}
          </div>
          <div className={'description'}>{data.description}</div>
        </div>
        {data.type === 'discount' && (
          <div className={'align-col-middle food-img'}>
            <img src={Config.IMG_BASE_URL + data.promotion_details.product_image_thumbnail_path} />
          </div>
        )}
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.id === nextProps.id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.onFavChange === nextProps.onFavChange
  );
}

PromotionItem.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    end_time: PropTypes.string,
    promotion_details: PropTypes.shape({
      product_image_thumbnail_path: PropTypes.string
    })
  }),
  style: PropTypes.object,
  onSelect: PropTypes.func
};
export default React.memo(PromotionItem, arePropsEqual);
