import React, { useState, useRef, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { updateProfileDetails } from '../../../store/actions/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Edit2Outline } from '@styled-icons/evaicons-outline';
import DatePicker from 'react-mobile-datepicker';
import ImageCapture from 'react-image-data-capture';
import {
  getImageFullURL,
  isEmpty,
  // validateEmailAddress,
  validatePhoneNumber
} from '../../../utils/common';
import { Theme } from '../../../assets';
import { AuthInput } from '../../../components/Inputs';
import Header from '../../../components/Header';
import ImgPickOptionModal from '../../../components/Modals/ImgPickOptionModal';
import LoadingModal from '../../../components/Modals/LoadingModal';
import moment from 'moment';
import PropTypes from 'prop-types';
import Img_user from '../../../assets/images/user-default.png';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { updateChannelUserInfo } from '../../../services/chat';

const ProfileEditScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const file_input = useRef(null);

  const config = useMemo(() => ({ video: true }), []);
  const [isCameraOn, setIsCameraOn] = useState(false);

  const [isLoading, ShowLoading] = useState(false);
  const [isDateModal, ShowDateModal] = useState(false);
  const [showPickerModal, setShowPickerModal] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [full_name, setFullName] = useState(props.user.full_name);
  const [phone, setPhone] = useState(props.user.phone);
  const [email, setEmail] = useState(props.user.email);
  const [birthday, setBirthday] = useState(
    props.user.birthdate == null ? null : moment(props.user.birthdate).toDate()
  );

  const onSave = async () => {
    let errorMsg = null;
    if (isEmpty(full_name) || isEmpty(phone)) {
      errorMsg = 'account_details.required_fields';
    }
    // else if (!isEmpty(email) && validateEmailAddress(email) === false) {
    //   errorMsg = 'alerts.wrong_email_format';
    // }
    else if (validatePhoneNumber(phone.replace(/\s/g, '')) === false) {
      errorMsg = 'alerts.wrong_phone_format';
    }

    if (errorMsg != null) {
      return confirmAlert({
        title: t('attention'),
        message: t(errorMsg),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    try {
      ShowLoading(true);
      const updated_user = await props.updateProfileDetails({
        full_name,
        email,
        phone,
        birthday: birthday == null ? null : moment(birthday).format('YYYY-MM-DD'),
        photo: photoData == null ? null : photoData.split(',')[1]
      });

      await updateChannelUserInfo(updated_user);
      ShowLoading(false);
      confirmAlert({
        title: '',
        message: t('account_details.profile_update_success'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {
              navigate(-1);
            }
          }
        ]
      });
    } catch (error) {
      console.info(error);
      ShowLoading(false);
      confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onCapture = (imageData) => {
    if (imageData == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageData.blob);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };

    setIsCameraOn(false);
  };

  const onError = useCallback(() => {
    confirmAlert({
      title: '',
      message: t('account_details.no_camera'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {
            setIsCameraOn(false);
          }
        }
      ]
    });
  }, []);

  const onHandleGalleryImg = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };
  };

  return (
    <div className={'edit-profile-screen'}>
      <Header onRight={onSave} right={<div className={'save-btn'}>{t('save')}</div>} />
      <div className={'scrollview'}>
        <div className={'align-col-middle'}>
          <div className={'align-col-middle avatar-view'}>
            <div className={'align-col-middle photo-view'}>
              <img
                src={
                  photoData
                    ? photoData
                    : isEmpty(props.user.photo) || props.user.photo === 'x'
                    ? Img_user
                    : getImageFullURL(props.user.photo)
                }
              />
            </div>
            <div
              onClick={() => {
                setShowPickerModal(true);
              }}
              className={'align-col-middle photo-edit'}>
              <Edit2Outline size={14} color={Theme.colors.white} />
            </div>
          </div>
        </div>
        <AuthInput
          placeholder={t('auth_register.full_name') + ' (*)'}
          placeholderTextColor={'#DFDFDF'}
          onChange={(full_name) => setFullName(full_name)}
          text={full_name}
          style={{ marginTop: 25, marginBottom: 12 }}
        />
        <AuthInput
          placeholder={t('auth_register.cell') + ' (*)'}
          placeholderTextColor={'#DFDFDF'}
          onChange={(phone) => setPhone(phone)}
          text={phone}
          style={{ marginBottom: 12 }}
        />
        <AuthInput
          placeholder={t('auth_login.email_address')}
          placeholderTextColor={'#DFDFDF'}
          onChange={(email) => setEmail(email)}
          text={email}
          style={{ marginBottom: 12 }}
        />
        <div
          onClick={() => {
            ShowDateModal(true);
          }}
          className={'w100 mb20'}>
          <div className={'align-middle birthday-view'}>
            <div
              className={'birthday-txt'}
              style={{ color: birthday == null ? '#DFDFDF' : Theme.colors.text }}>
              {birthday == null ? 'Birth Date (DD/MM/YYYY)' : moment(birthday).format('DD/MM/YYYY')}
            </div>
          </div>
        </div>
      </div>
      <DatePicker
        value={birthday == null ? new Date() : birthday}
        isOpen={isDateModal}
        showHeader={false}
        showFooter={false}
        onChange={(value) => {
          setBirthday(value);
        }}
        onCancel={() => {
          ShowDateModal(false);
        }}
      />
      <ImgPickOptionModal
        isOpen={showPickerModal}
        onClose={() => {
          setShowPickerModal(false);
        }}
        onCapture={() => {
          setShowPickerModal(false);
          setIsCameraOn(true);
        }}
        onGallery={() => {
          setShowPickerModal(false);
          if (file_input.current != null) {
            file_input.current.click();
          }
        }}
      />
      <input
        accept="image/jpeg/png"
        ref={file_input}
        hidden={true}
        type="file"
        onChange={onHandleGalleryImg}
      />
      <LoadingModal showModal={isLoading} />
      {isCameraOn && (
        <div className="align-col-middle camera-view">
          <ImageCapture
            onCapture={onCapture}
            onError={onError}
            width={window.innerWidth}
            userMediaConfig={config}
          />
        </div>
      )}
    </div>
  );
};

ProfileEditScreen.propTypes = {
  user: PropTypes.shape({
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {
  updateProfileDetails
})(ProfileEditScreen);
