import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { openExternalUrl } from '../../utils/common';
import { ROUTES_NAMES } from '../../constants';
import ProfileAvatarView from './avatarview';
import ProfileInfoItem from './infoview';
import './index.css';
import { TIRANA_CITY_LOCATION } from '../../constants/config';
import { logout } from '../../store/actions/auth';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

const Profile = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const btns = [
    { name: 'wallet', link: ROUTES_NAMES.cashback },
    { name: 'addresses', link: ROUTES_NAMES.addresses },
    // TODO add this later
    // { name: 'payment_method', link: ROUTES_NAMES.paymentMethods },
    { name: 'promotions_menu', link: ROUTES_NAMES.promotions },
    { name: 'preferred', link: ROUTES_NAMES.favs },
    { name: 'blog_menu', link: ROUTES_NAMES.blog },
    { name: 'settings', link: ROUTES_NAMES.settings },
    { name: 'rate_app', link: 'https://snapfood.al' },
    { name: 'become_a_partner', link: 'https://snapfood.al/merchant/' },
    { name: 'about', link: 'https://snapfood.al/about' },
    { name: 'logout', link: ROUTES_NAMES.login }
  ];

  const doLogout = () => {
    confirmAlert({
      title: t('alerts.confirm_logout_title'),
      message: t('alerts.confirm_logout'),
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: t('alerts.yes'),
          onClick: async () => {
            try {
              await props.logout();
              // eslint-disable-next-line no-empty
            } catch (e) {}
          }
        },
        {
          label: t('alerts.no'),
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <div className={'align-col-middle profile-screen'}>
      <div className={'scrollview'}>
        <React.Fragment>
          <ProfileAvatarView
            photo={props.user.photo}
            full_name={props.user.full_name}
            birthdate={props.user.birthdate}
            city={props.address.city || TIRANA_CITY_LOCATION.city}
            country={props.address.country || TIRANA_CITY_LOCATION.country}
            onEdit={() => {
              navigate(ROUTES_NAMES.editProfile);
            }}
          />
          <ProfileInfoItem email={props.user.email} phone={props.user.phone} />
        </React.Fragment>
        {btns.map((item) => (
          <div
            key={item.name}
            className={'align-middle list-btn btn-style'}
            onClick={(e) => {
              e.preventDefault();
              if (item.name === 'logout') {
                doLogout();
              } else {
                if (item.link != null && item.link.includes('http')) {
                  openExternalUrl(item.link);
                } else {
                  navigate(item.link);
                }
              }
            }}>
            <div className={'flex_1 item-text'}>{t('account.' + item.name)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    photo: PropTypes.string,
    birthdate: PropTypes.string,
    full_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }),
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string
  }),
  logout: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  address: app.address || {}
});

export default connect(mapStateToProps, { logout })(Profile);
