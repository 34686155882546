import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { AuthTab } from '../../../components/TabSelector';
import { AuthInput } from '../../../components/Inputs';
import { AuthBtn } from '../../../components/Buttons';
import AuthLayout from '../auth-layout';
import { login, register, setAsLoggedIn, updateProfileDetails } from '../../../store/actions/auth';
import PropTypes from 'prop-types';
import {
  extractErrorMessage,
  isEmpty,
  validateEmailAddress,
  validateUserData
} from '../../../utils/common';
import { Snackbar, Alert } from '@mui/material';
import './index.css';
import { ChevronLeft } from '@styled-icons/material';
import { loadUserSetting } from '../../../services/user';
import { confirmAlert } from 'react-confirm-alert';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import querystring from 'query-string';
import CommonTooltip from '../../../components/Common/CommonTooltip';

const LoginScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const parsed = querystring.parse(location.search);

  const [state, setState] = useState({
    loading: false,
    full_name: '',
    phone: '',
    email: '',
    password: '',
    curTab: parsed.type || 'login',
    backRoute: null,
    alertVisible: false,
    errorMessage: ''
  });

  useEffect(() => {
    if (props.isLoggedIn) {
      navigate(-1);
    }
  }, [props.isLoggedIn]);

  const _onChangeTab = (tab) => {
    setState({ ...state, curTab: tab, email: '', password: '', full_name: '', phone: '' });
  };

  const loginHandler = async () => {
    const { email, password } = state;
    if (isEmpty(email) || isEmpty(password)) {
      return confirmAlert({
        title: t('attention'),
        message: t('alerts.fill_all_fields'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (validateEmailAddress(email) === false) {
      return confirmAlert({
        title: t('attention'),
        message: t('alerts.wrong_email_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    setState({ ...state, loading: true });
    try {
      const logged_user_data = await props.login(email, password);

      setState({ ...state, loading: false });
      await loadUserSetting(props, logged_user_data);
      // navigate(-2);
    } catch (e) {
      setState({ ...state, loading: false });
      confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: extractErrorMessage(e),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const handleRegister = () => {
    const { full_name, phone, email, password } = state;
    let pass2 = password;
    validateUserData({ full_name, email, phone, password, pass2 }, true)
      .then(async () => {
        if (!state.loading) {
          setState({ ...state, loading: true });
          try {
            await props.register({ full_name, email, phone, password });

            const logged_user_data = await props.login(email, password);

            setState({ ...state, loading: false });
            await loadUserSetting(props, logged_user_data);

            // navigate(-2);
          } catch (e) {
            setState({ ...state, loading: false });
            confirmAlert({
              title: t('restaurant_details.we_are_sorry'),
              message: extractErrorMessage(e),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          }
        }
      })
      .catch((validate_msg) => {
        confirmAlert({
          title: t('attention'),
          message: t(validate_msg),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      });
  };

  const _renderLoginForm = () => {
    const { loading } = state;
    return (
      <div
        style={{
          flex: 1,
          width: '100%',
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: '#ffffff'
        }}>
        <div style={{ width: '100%' }}>
          <div className={'form'}>
            <AuthInput
              placeholder={t('auth_login.email')}
              onChange={(email) => setState({ ...state, email })}
              text={state.email}
              style={{ marginBottom: 20 }}
            />
            <AuthInput
              placeholder={t('auth_login.password')}
              onChange={(password) => setState({ ...state, password })}
              text={state.password}
              isSecure={true}
              style={{ marginBottom: 10 }}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <a
                target={'_blank'}
                href={'https://snapfood.al/auth/login'}
                style={styles.forgotPasswordText}
                className={'forgot-pass'}
                rel="noreferrer">
                {t('auth_login.forgot_your_password')}
              </a>
            </div>

            <AuthBtn
              isLoading={loading}
              title={t('auth_login.login_button')}
              onClick={loginHandler}
            />
          </div>
        </div>
        <div className={'align-middle'} style={{ width: '100%', marginTop: 80 }}>
          <div className={'align-middle flex-wrap'}>
            <p style={styles.descTxt}>{t('auth_login.no_account')}</p>
            <p
              style={styles.create_accountText}
              onClick={() => setState({ ...state.curTab, curTab: 'register' })}>
              {t('auth_register.submit')}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const _renderRegisterForm = () => {
    const { loading } = state;
    return (
      <div style={{ flex: 1, width: '100%', backgroundColor: '#ffffff' }}>
        <div className={'ph-20'} style={{ flex: 1, width: '100%' }}>
          <div style={styles.formdiv}>
            <AuthInput
              placeholder={t('auth_register.full_name')}
              onChange={(full_name) => setState({ ...state, full_name })}
              text={state.full_name}
              style={{ marginBottom: 20 }}
            />
            <div style={{ display: 'flex' }}>
              <AuthInput
                placeholder={t('auth_register.cell')}
                onChange={(phone) => setState({ ...state, phone })}
                text={state.phone}
                style={{ marginBottom: 20, marginRight: 5 }}
              />

              <CommonTooltip
                anchorStyle={{
                  marginTop: 8,
                  marginBottom: 8,
                  paddingLeft: 4,
                  fontSize: 15,
                  fontFamily: Theme.fonts.semiBold,
                  color: Theme.colors.text,
                  textDecorationLine: 'underline',
                  textDecorationColor: Theme.colors.text
                }}
                style={{
                  position: 'relative',
                  top: 15
                }}
                title={t('tooltip.phone_number_required')}
                description={t('tooltip.phone_number_required_description')}
              />
            </div>
            <AuthInput
              placeholder={t('auth_login.email_address')}
              onChange={(email) => setState({ ...state, email })}
              text={state.email}
              style={{ marginBottom: 20 }}
            />
            <AuthInput
              placeholder={t('auth_login.password')}
              onChange={(password) => setState({ ...state, password })}
              text={state.password}
              isSecure={true}
              style={{ marginBottom: 40 }}
            />
            <AuthBtn
              isLoading={loading}
              title={t('auth_register.submit')}
              onClick={handleRegister}
            />
          </div>
        </div>
        <div className={'align-middle flex-wrap'} style={{ marginTop: 80 }}>
          <p style={styles.descTxt}>{t('auth_register.already_registered')}</p>
          <p
            style={styles.create_accountText}
            onClick={() => setState({ ...state.curTab, curTab: 'login' })}>
            {t('auth_login.login_button')}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={'login-screen'}>
      <AuthLayout>
        <div className={'align-middle'}>
          <AuthTab
            curitem={state.curTab}
            onSelect={(item) => {
              _onChangeTab(item);
            }}
          />
        </div>
        {state.curTab === 'login' ? _renderLoginForm() : _renderRegisterForm()}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={state.alertVisible}
          autoHideDuration={3000}
          onClose={() => setState({ ...state, alertVisible: false })}>
          <Alert severity="error">{state.errorMessage}</Alert>
        </Snackbar>
      </AuthLayout>
      <div
        style={styles.backBtn}
        onClick={() => {
          navigate(-1);
        }}>
        <ChevronLeft size={28} color={Theme.colors.text} />
      </div>
    </div>
  );
};

const styles = {
  formdiv: { flex: 1, width: '100%', position: 'relative' },
  forgotPasswordText: {
    color: '#F55A00',
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    textAlign: 'right',
    marginTop: 12,
    marginBottom: 40,
    textDecorationLine: 'none'
  },
  title: { marginTop: 80, marginBottom: 35, fontFamily: Theme.fonts.bold },
  descTxt: {
    color: Theme.colors.text,
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    marginVertical: 12
  },
  create_accountText: {
    color: Theme.colors.btnPrimary,
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    marginVertical: 12,
    marginLeft: 6,
    cursor: 'pointer'
  },
  backBtn: {
    cursor: 'pointer',
    position: 'absolute',
    left: 20,
    top: 20,
    paddingBottom: 12,
    paddingTop: 0,
    paddingRight: 20
  }
};

LoginScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  login: PropTypes.func,
  register: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    isLoggedIn: app.isLoggedIn,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  login,
  register,
  setAsLoggedIn,
  updateProfileDetails,
  addDefaultAddress,
  getAddresses,
  setAddress
})(LoginScreen);
