import React from 'react';
import AppText from '../../AppText';
import { Theme } from '../../../assets';
import VendorItem from '../../Vendor/VendorItem';
import PropTypes from 'prop-types';
import './index.css';

const FeatureList = ({ label, items, goVendorDetail, onFavChange }) => {
  return (
    <div
      className="col_center"
      style={{ width: '100%', alignItems: 'flex-start', marginBottom: 16 }}>
      <AppText style={styles.subjectTitle}>{label}</AppText>
      <div className={'align-col-start featured-hlist'}>
        <div className={'align-row-start featured-list'}>
          {items.map((vendor) => (
            <VendorItem
              key={vendor.id}
              data={vendor}
              vendor_id={vendor.id}
              isFav={vendor.isFav}
              is_open={vendor.is_open}
              style={{
                minWidth: items.length === 1 ? 'calc( 100% - 0px )' : 300,
                maxWidth: items.length === 1 ? 'calc( 100% - 0px )' : 300,
                marginRight: items.length === 1 ? 0 : 16,
                marginBottom: 0
              }}
              onFavChange={onFavChange}
              onClick={() => goVendorDetail(vendor)}
            />
          ))}
        </div>
        <div className={'scrollview_hider'} />
      </div>
      <div className="divider" />
    </div>
  );
};

const styles = {
  subjectTitle: { fontSize: 18, fontFamily: Theme.fonts.bold, color: Theme.colors.text },
  scrolldivHider: {
    width: '100%',
    marginTop: -12,
    height: 15,
    backgroundColor: Theme.colors.white
  },
  divider: { width: '100%', height: 1, backgroundColor: '#F6F6F9' }
};

FeatureList.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  goVendorDetail: PropTypes.func,
  onFavChange: PropTypes.func
};
export default FeatureList;
