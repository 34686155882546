import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Splash from '../../views/tour/splash';
import PropTypes from 'prop-types';

const SplashRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route exact path={'/'} element={<Splash />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </React.Fragment>
  );
};

SplashRoutes.propTypes = {
  isAppLoaded: PropTypes.bool
};
export default SplashRoutes;
