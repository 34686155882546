import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Slider } from '@mui/material';
import Header from '../../../components/Header';
import FilterItem from '../../../components/Common/FilterItem';
import { setHomeVendorFilter, setHomeVendorSort } from '../../../store/actions/app';
import { MAX_DELIVERY_FEE } from '../../../constants/config';
import './index.css';
import PropTypes from 'prop-types';
import {
  VSort_FastDelivery,
  VSort_HighRating,
  VSort_PopularFirst,
  VSort_Closest
} from '../../../constants/config';

const HomeFilterScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sortList = [
    {
      id: VSort_FastDelivery,
      name: t('vendor_filter.fastest'),
      type: 'radio'
    },
    {
      id: VSort_HighRating,
      name: t('vendor_filter.high_rating'),
      type: 'radio'
    },
    {
      id: VSort_Closest,
      name: t('vendor_filter.closest'),
      type: 'radio'
    },
    // {
    // 	id: VSort_Low2HighPrice,
    // 	name: t('vendor_filter.low_high_price'),
    // 	type: 'radio',
    // },
    {
      id: VSort_PopularFirst,
      name: t('vendor_filter.most_popular_first'),
      type: 'radio'
    }
  ];

  const [is_meal, setMeal] = useState(props.home_vendor_filter.is_meal);
  const [is_dietary, setDietary] = useState(props.home_vendor_filter.is_dietary);
  const [ongoing_offer, setOngoingOffer] = useState(props.home_vendor_filter.ongoing_offer);
  const [open_now, setOpenNow] = useState(props.home_vendor_filter.open_now);
  const [online_payment, setOnlinePayment] = useState(props.home_vendor_filter.online_payment);

  const [feeRanges, setFeeRanges] = React.useState([
    props.home_vendor_filter.low_fee || 0,
    props.home_vendor_filter.high_fee || MAX_DELIVERY_FEE
  ]);

  const [sort, setSort] = useState(props.home_vendor_sort);

  const applyFilter = () => {
    props.setHomeVendorFilter({
      is_meal: is_meal,
      is_dietary: is_dietary,
      ongoing_offer: ongoing_offer,
      open_now: open_now,
      online_payment: online_payment,
      low_fee: feeRanges[0] === 0 ? null : feeRanges[0],
      high_fee: feeRanges[1] === MAX_DELIVERY_FEE ? null : feeRanges[1]
    });
    props.setHomeVendorSort(sort);
    navigate(-1);
  };

  return (
    <div className={'home-filter-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        onRight={() => applyFilter()}
        right={<div className={'apply-btn'}>{t('search.apply')}</div>}
        title={t('search.filter')}
      />
      <div className={'scrollview'}>
        <FilterItem
          item={{ name: t('vendor_filter.meal'), type: 'checkbox' }}
          isChecked={is_meal}
          onSelect={() => setMeal(!is_meal)}
        />
        <FilterItem
          item={{ name: t('vendor_filter.dietary'), type: 'checkbox' }}
          isChecked={is_dietary}
          onSelect={() => setDietary(!is_dietary)}
        />
        <FilterItem
          item={{ name: t('vendor_filter.ongoing_offer'), type: 'checkbox' }}
          isChecked={ongoing_offer}
          onSelect={() => setOngoingOffer(!ongoing_offer)}
        />
        <FilterItem
          item={{ name: t('vendor_filter.open_now'), type: 'checkbox' }}
          isChecked={open_now}
          onSelect={() => setOpenNow(!open_now)}
        />
        <FilterItem
          item={{ name: t('vendor_filter.accept_online_payment'), type: 'checkbox' }}
          isChecked={online_payment}
          onSelect={() => setOnlinePayment(!online_payment)}
        />
        <div className={'w100 delivery-fee-range-view'}>
          <h3>{t('vendor_filter.max_delivery_fee')}</h3>
          <div
            className={'w100'}
            style={{
              height: 80,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}>
            <Slider
              max={MAX_DELIVERY_FEE}
              getAriaLabel={() => t('vendor_filter.max_delivery_fee')}
              value={feeRanges}
              onChange={(event, newValue) => {
                setFeeRanges(newValue);
              }}
              valueLabelDisplay="on"
              disableSwap
            />
          </div>
        </div>
        <div className={'w100 sort-view'}>
          <h3>{t('search.sort')}</h3>
        </div>
        {sortList.map((item, index) => (
          <FilterItem
            key={index}
            item={item}
            isChecked={item.id === sort}
            onSelect={(item) => setSort(item.id)}
          />
        ))}
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

HomeFilterScreen.propTypes = {
  home_vendor_filter: PropTypes.shape({
    is_meal: PropTypes.bool,
    is_dietary: PropTypes.bool,
    ongoing_offer: PropTypes.bool,
    open_now: PropTypes.bool,
    online_payment: PropTypes.bool,
    low_fee: PropTypes.number,
    high_fee: PropTypes.number
  }),
  home_vendor_sort: PropTypes.string,
  setHomeVendorFilter: PropTypes.func,
  setHomeVendorSort: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_vendor_filter: app.home_vendor_filter,
  home_vendor_sort: app.home_vendor_sort
});

export default connect(mapStateToProps, { setHomeVendorFilter, setHomeVendorSort })(
  HomeFilterScreen
);
