import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SwitchBtn, RadioBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import './index.css';
import PropTypes from 'prop-types';
import { ROUTES_NAMES } from '../../../constants';
import { setAppLang } from '../../../store/actions/app';
import { updateProfileDetails } from '../../../store/actions/auth';

const NotiSetting = ({ item, value, onChange }) => {
  return (
    <div className={'flex_between'} style={{ paddingTop: 20 }}>
      <div className={'subject-title'}>{item}</div>
      <SwitchBtn onClick={onChange} isOn={value} />
    </div>
  );
};
NotiSetting.propTypes = {
  value: PropTypes.bool,
  item: PropTypes.string,
  onChange: PropTypes.func
};

const SettingScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const changeAppLang = async (lang) => {
    await props.setAppLang(lang);
  };

  const goChangePass = () => {
    navigate(ROUTES_NAMES.changePass);
  };

  const onUpdateNoti = (data) => {
    props
      .updateProfileDetails(data)
      .then(() => {})
      .catch(() => {});
  };

  const _renderLanguageSetting = () => {
    return (
      <div className={'align-col-start lang-bar'}>
        <div className={'flex_between'}>
          <div className={'subject-title'}>{t('account.lang_label')}</div>
        </div>
        <div
          onClick={() => changeAppLang('sq')}
          className={'flex_between mt16'}
          style={{ marginBottom: 16 }}>
          <div className={'lang-text'}>{t('account.albanian')}</div>
          <RadioBtn onClick={() => changeAppLang('sq')} checked={props.language === 'sq'} />
        </div>
        <div className={'divider'} />
        <div onClick={() => changeAppLang('en')} className={'flex_between mt16'}>
          <div className={'lang-text'}>{t('account.english')}</div>
          <RadioBtn onClick={() => changeAppLang('en')} checked={props.language === 'en'} />
        </div>
      </div>
    );
  };

  return (
    <div className={'settings-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        title={t('account.settings')}
      />
      <div className={'scrollview'}>
        <div className={'align-col-middle section-view'}>
          <NotiSetting
            item={t('account.push_noti')}
            value={props.user.notifications === 1}
            onChange={() => onUpdateNoti({ push_notis: props.user.notifications === 1 ? 0 : 1 })}
          />
          <NotiSetting
            item={t('account.promo_noti')}
            value={props.user.promotions === 1}
            onChange={() => onUpdateNoti({ promo_notis: props.user.promotions === 1 ? 0 : 1 })}
          />
          <NotiSetting
            item={t('account.email_noti')}
            value={props.user.email_notis === 1}
            onChange={() => onUpdateNoti({ email_notis: props.user.email_notis === 1 ? 0 : 1 })}
          />
        </div>
        <div className={'align-col-middle section-view'}>{_renderLanguageSetting()}</div>
        <div onClick={goChangePass} className={'align-row-start item-btn mt16'}>
          {t('account_change_pass.header_title')}
        </div>
        {props.pass_changed && (
          <div className={'noti-text'}>{t('account_change_pass.success')}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  pass_changed: app.pass_changed
});

SettingScreen.propTypes = {
  pass_changed: PropTypes.bool,
  language: PropTypes.string,
  user: PropTypes.shape({
    notifications: PropTypes.number,
    promotions: PropTypes.number,
    email_notis: PropTypes.number
  }),
  setAppLang: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

export default connect(mapStateToProps, {
  setAppLang,
  updateProfileDetails
  // setTmpPassChanged
})(SettingScreen);
