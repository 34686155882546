import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Theme } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import CardItem from '../../../components/Profile/CardItem';
import NoCardList from '../../../components/Empty/NoCardList';
import { updateProfileDetails } from '../../../store/actions/auth';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import apiFactory from '../../../services/apiFactory';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

const PaymentMethods = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _isMounted = useRef(true);
  const [loading, setLoading] = useState(null);
  const [cards, setCards] = useState([]);

  const [isConfirmModal, ShowConfirmModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    _loadPaymentMethods();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const _loadPaymentMethods = () => {
    setLoading(true);
    apiFactory.get(`stripe/payment-methods`).then(
      ({ data }) => {
        if (_isMounted.current === true) {
          setCards(data || []);
          setLoading(false);
        }
      },
      () => {
        if (_isMounted.current === true) {
          setLoading(false);
        }
      }
    );
  };

  const _changePrimary = async (card) => {
    try {
      await props.updateProfileDetails({
        default_card_id: card.id
      });
    } catch (error) {
      return confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const _deleteCard = () => {
    if (selectedCard == null) {
      return;
    }
    ShowConfirmModal(false);
    apiFactory.delete(`stripe/payment-methods/${selectedCard.id}`).then(
      ({ data }) => {
        if (data.success === 1) {
          if (_isMounted.current === true) {
            _loadPaymentMethods();
          }
        }
      },
      (error) => {
        if (_isMounted.current === true) {
          const message = error.message || t('generic_error');
          return confirmAlert({
            title: t('alerts.error'),
            message: message,
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      }
    );
  };

  return (
    <div
      className={'align-col-start flex_1 payment-methods'}
      style={{ backgroundColor: Theme.colors.white }}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        title={t('payment_method.title')}
      />
      {loading === false && cards.length === 0 ? (
        <NoCardList />
      ) : (
        <div className={'scrollview'}>
          {cards.map((item) => (
            <CardItem
              key={item.id}
              data={item}
              checked={props.user.default_card_id === item.id}
              onSelect={_changePrimary}
              onDelete={() => {
                ShowConfirmModal(true);
                setSelectedCard(item);
              }}
            />
          ))}
          <div style={{ height: 20 }} />
        </div>
      )}
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20, marginBottom: 40 }}>
        <MainBtn
          title={t('payment_method.add_new_card')}
          onClick={() => {
            navigate(ROUTES_NAMES.addCard);
          }}
        />
      </div>
      <ConfirmModal
        showModal={isConfirmModal}
        title={t('payment_method.confirm_del_card')}
        yes={t('payment_method.confirm_del_card_yes')}
        no={t('payment_method.confirm_del_card_no')}
        onYes={_deleteCard}
        onClose={() => ShowConfirmModal(false)}
      />
    </div>
  );
};

PaymentMethods.propTypes = {
  user: PropTypes.shape({
    default_card_id: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, { updateProfileDetails })(PaymentMethods);
