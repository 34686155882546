import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../components/Header';
import { Theme } from '../../assets';
import { Config, ROUTES_NAMES } from '../../constants';
import {
  updateCartItems,
  setCutleryCart,
  setCommentCart,
  setCouponCart,
  setPriceCart,
  setDeliveryInfoCart,
  setPaymentInfoCart,
  setVendorCart
} from '../../store/actions/shop';
import { setTmpFood } from '../../store/actions/app';
import GroceryFoodItem from '../../components/Vendor/GroceryFoodItem';
import CartItem from '../../components/Cart/CartItem';
import CartDelivery from '../../components/Cart/CartDelivery';
import apiFactory from '../../services/apiFactory';
import Counter from '../../components/Common/Counter';
import PriceBoard from '../../components/Cart/PriceBoard';
import CouponInput from '../../components/Cart/CouponInput';
import { MainBtn, RoundIconBtn } from '../../components/Buttons';
import { AuthInput, CommentInput } from '../../components/Inputs';
import { OrderType_Delivery, OrderType_Reserve } from '../../constants/config';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { calculateCartTotal, isEmpty } from '../../utils/common';
import './index.css';
import { getVendorDetail } from '../../store/actions/vendors';
import { confirmAlert } from 'react-confirm-alert';

const CartScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [suggestedItems, setSuggestedItems] = useState([]);
  const [state, setState] = useState({
    maxDeliveryTime: 0,
    minOrderPrice: 0,
    smallOrderFee: 0,
    coupon: '',
    outOfDeliveryArea: false,
    loading_coupon: false,
    has_valid_coupon: false,
    showInfoPop: false,
    isReady: false
  });
  const [vendorLoaded, setVendorLoaded] = useState(false);

  const getVendorId = () => {
    if (props.cartItems.length > 0) {
      return props.cartItems[0].vendor_id;
    }
    return null;
  };

  useEffect(() => {
    _loadSuggestedItems().then();
  }, [props.cartItems]);

  useEffect(() => {
    let vendor_id = getVendorId();
    if (vendor_id != null) {
      if (
        props.vendorData == null ||
        props.vendorData.id == null ||
        props.vendorData.id != vendor_id
      ) {
        _loadVendorData(vendor_id);
      } else {
        setVendorLoaded(true);
      }
    }
  }, [props.cartItems, props.coordinates]);

  useEffect(() => {
    setState({
      ...state,
      vendorData: props.vendorData,
      maxDeliveryTime: props.vendorData['maximum_delivery_time'] || 0,
      minOrderPrice: props.vendorData['delivery_minimum_order_price'] || 0,
      smallOrderFee: props.vendorData['small_order_fee'] || 0
    });
  }, []);

  const initialLoaded = useRef(false);
  useEffect(() => {
    if (initialLoaded.current == true) {
      _getDeliveryFees(
        props.vendorData.id,
        props.delivery_info.address.lat,
        props.delivery_info.address.lng
      ).then();
    }

    initialLoaded.current = true;
  }, [props.delivery_info.address]);

  const _loadVendorData = (vendor_id) => {
    let { latitude, longitude } = props.coordinates;
    getVendorDetail(vendor_id, latitude, longitude)
      .then((data) => {
        props.setVendorCart(data);
        setVendorLoaded(true);
        setState({
          ...state,
          vendorData: data,
          maxDeliveryTime: data['maximum_delivery_time'] || 0,
          minOrderPrice: data['delivery_minimum_order_price'] || 0,
          smallOrderFee: data['small_order_fee'] || 0
        });

        if (props.delivery_info.address != null) {
          _getDeliveryFees(
            vendor_id,
            props.delivery_info.address.lat,
            props.delivery_info.address.lng
          ).then();
        }
      })
      .catch(() => {});
  };

  const _getDeliveryFees = async (vendor_id, latitude, longitude) => {
    try {
      if (vendor_id == null || vendor_id === '' || latitude == null || longitude == null) {
        props.setPriceCart({
          ...props.cartPrice,
          delivery_fee: 0
        });
        return;
      }

      const params = [`vendor_id=${vendor_id}`];
      params.push(`lat=${latitude}`);
      params.push(`lng=${longitude}`);

      const queryParams = params.join('&');

      let response = await apiFactory.get(`checkout/delivery-fee?${queryParams}`);
      let data = response.data;

      setState({
        ...state,
        maxDeliveryTime: data['deliveryTime'] || state.maxDeliveryTime,
        minOrderPrice: data['minimumOrder'] || state.minOrderPrice,
        outOfDeliveryArea: data['outOfDeliveryArea'] || false,
        smallOrderFee: data['small_order_fee'] || state.smallOrderFee
      });

      props.setPriceCart({
        ...props.cartPrice,
        delivery_fee: data['deliveryFee'] || 0
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const _loadSuggestedItems = async () => {
    try {
      const cartItems = props.cartItems;
      const { vendorData } = props;
      if (vendorData == null || getVendorId() == null) {
        return;
      }
      let categories = vendorData.categories;
      if (categories == null) {
        const { latitude, longitude } = props.coordinates;
        let data = await getVendorDetail(
          getVendorId(),
          latitude,
          longitude,
          props.delivery_info.handover_method
        );
        props.setVendorCart(data);
        categories = data.categories;
      }

      let suggestedItems = [];
      cartItems.map((cartItm) => {
        let category = categories.find((cat) => cat.id === cartItm.category_id);

        if (category != null && category.products != null) {
          let tmpProducts = [];
          category.products.slice(0, 30).map((p) => {
            let foundCart = cartItems.find((i) => i.id === p.id);
            let foundSuggested = suggestedItems.find((i) => i.id === p.id);
            if (foundCart == null && foundSuggested == null) {
              tmpProducts.push(p);
            }
          });

          tmpProducts.sort(function (a, b) {
            return Math.abs(a.price - cartItm.price) - Math.abs(b.price - cartItm.price);
          });
          suggestedItems.push(...tmpProducts.slice(0, 6));
        }
      });

      suggestedItems
        .sort(function (a, b) {
          return a.price - b.price;
        })
        .slice(0, 10);

      setSuggestedItems(suggestedItems);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const _applyCoupon = (couponData) => {
    props.setCouponCart(couponData);
    if (couponData == null) {
      return;
    }
    let discountValue = 0;
    if (couponData.type === 'fixed') {
      discountValue = couponData.value ? couponData.value : 0;
    } else if (couponData.type === 'percentage') {
      discountValue = couponData.value ? couponData.value : 0;
    } else if (couponData.type === 'discount') {
      let cartItemProduct = props.cartItems.filter((i) => i.id === couponData.product_id);
      if (cartItemProduct.length > 0) {
        let qty = cartItemProduct.quantity - (couponData.value ? couponData.value : 0); // discount
        if (qty < 0) {
          discountValue = cartItemProduct.price * cartItemProduct.quantity;
        } else {
          discountValue = cartItemProduct.price * qty;
        }
      }
    } else if (couponData.type === 'free_delivery') {
      props.setPriceCart({
        ...props.cartPrice,
        delivery_fee: 0
      });
      return;
    }

    props.setPriceCart({
      ...props.cartPrice,
      discount: discountValue
    });
  };

  const _getSubTotal = () => {
    let sub_total = calculateCartTotal(props.cartItems);
    return sub_total;
  };

  const _getSmallOrderFee = () => {
    if (props.delivery_info.handover_method === OrderType_Delivery) {
      let subTotal = _getSubTotal();

      if (subTotal < state.minOrderPrice) {
        if (state.smallOrderFee != null) {
          return parseInt(state.smallOrderFee) || 0;
        }
      }
    }
    return 0;
  };

  const _getTotalPrice = () => {
    const { discount, cashback, delivery_fee } = props.cartPrice;

    let rider_tip = 0;
    // if (props.delivery_info.handover_method === OrderType_Delivery && props.vendorData.delivery_type === "Snapfood" && parseInt(props.delivery_info.tip_rider) > 0) {
    // 	rider_tip = parseInt(props.delivery_info.tip_rider);
    // }

    let subtotal = _getSubTotal();
    let total = subtotal - discount - cashback + _getSmallOrderFee() + delivery_fee + rider_tip;

    return total;
  };

  const _renderCartProducts = () => {
    const onPlusItem = async (p_id) => {
      try {
        let tmp = props.cartItems.slice(0, props.cartItems.length);
        let foundIndex = tmp.findIndex((i) => i.id === p_id);
        if (foundIndex !== -1) {
          tmp[foundIndex].quantity = tmp[foundIndex].quantity + 1;
          await props.updateCartItems(tmp);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    const onMinusItem = async (p_id) => {
      try {
        let tmp = props.cartItems.slice(0, props.cartItems.length);
        let foundIndex = tmp.findIndex((i) => i.id === p_id);
        if (foundIndex !== -1) {
          tmp[foundIndex].quantity = tmp[foundIndex].quantity - 1;
          await props.updateCartItems(tmp);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    const onRemoveItem = async (p_id) => {
      try {
        let curCartCount = props.cartItems.length;
        let tmp = props.cartItems.slice(0, props.cartItems.length);
        let foundIndex = tmp.findIndex((i) => i.id === p_id);
        if (foundIndex !== -1) {
          tmp.splice(foundIndex, 1);
          await props.updateCartItems(tmp);
          if (curCartCount === 1) {
            navigate(-1);
          }
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    return (
      <div className={'align-col-middle w100 mt20'}>
        {vendorLoaded && (
          <>
            <div className={'align-row-start'}>
              <RoundIconBtn
                style={styles.LogoView}
                icon={
                  props.vendorData != null && (
                    <img
                      style={styles.Logo}
                      src={Config.IMG_BASE_URL + props.vendorData.logo_thumbnail_path}
                    />
                  )
                }
                onClick={() => {}}
              />
              <div className={'logo-text'}>
                {props.vendorData != null && props.vendorData.title}
              </div>
            </div>
            <div className={'flex_between mt12'}>
              <div className={'suggested-title'}>{t('cart.your_items')}</div>
              <div
                style={{
                  fontSize: 16,
                  color: Theme.colors.cyan2,
                  fontFamily: Theme.fonts.semiBold
                }}
                onClick={() => {
                  props.setVendorCart(props.vendorData);
                  navigate(`/vendor/${props.vendorData.id}/${props.vendorData.order_method}`);
                }}>
                {t('cart.see_menu')}
              </div>
            </div>
          </>
        )}
        <div className={'align-col-middle cart-items-view'}>
          {props.cartItems.map((item, index) => (
            <CartItem
              key={index}
              data={item}
              onPlus={onPlusItem}
              onMinus={onMinusItem}
              onDelete={onRemoveItem}
            />
          ))}
        </div>
      </div>
    );
  };

  const _renderCutlery = () => {
    return (
      <div className={'flex_between section-view cutlery-row'}>
        <div className={'subject-title'}>{t('cart.cutlery_description_off')}</div>
        <Counter
          value={props.cutlery}
          onPlus={() => props.setCutleryCart(props.cutlery + 1)}
          onMinus={() => {
            if (props.cutlery >= 1) {
              props.setCutleryCart(props.cutlery - 1);
            }
          }}
          style={{
            width: 122,
            height: 40,
            padding: 6,
            paddingHorizontal: 12,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: Theme.colors.gray6,
            backgroundColor: Theme.colors.white
          }}
          btnColor={Theme.colors.cyan2}
          btnSize={20}
        />
      </div>
    );
  };

  const _renderCashBack = () => {
    return (
      <div className={'flex_between  section-view cashback-row'}>
        <div style={{ justifyContent: 'center' }}>
          <div className={'subject-title'}>{t('cart.want_use_cashback')}</div>
          <div className={'value'}>
            {t('wallet.balance')} {parseInt(props.user.cashback_amount) || 0} L
          </div>
        </div>
        <AuthInput
          style={{
            width: 122,
            height: 40,
            borderWidth: 1,
            borderColor: Theme.colors.gray6,
            backgroundColor: Theme.colors.white
          }}
          placeholder={t('cart.enter_cashback_value')}
          textAlign="center"
          editable={parseInt(props.user.cashback_amount) > 0}
          type={'number'}
          text={props.cartPrice.cashback === 0 ? '' : '' + props.cartPrice.cashback}
          onChange={(t) => {
            let int_val = t !== '' ? parseInt(t) : 0;
            let balance = props.user.cashback_amount || 0;

            if (int_val <= balance) {
              props.setPriceCart({
                ...props.cartPrice,
                cashback: int_val
              });
            }
          }}
        />
      </div>
    );
  };

  const _renderSuggestedProducts = () => {
    const onAddCart = async (data) => {
      try {
        let tmp = props.cartItems.slice(0, props.cartItems.length);
        let foundIndex = tmp.findIndex((i) => i.id === data.id);
        if (foundIndex !== -1) {
          tmp[foundIndex].quantity = tmp[foundIndex].quantity + 1;
        } else {
          let cartItem = data;
          cartItem.quantity = 1;
          cartItem.comments = '';
          cartItem.options = [];

          tmp.push(cartItem);
        }
        await props.updateCartItems(tmp);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    if (suggestedItems.length === 0) {
      return null;
    }
    return (
      <div className={'w100 suggested-hlist '}>
        <div className={'flex_1 suggested-title'}>{t('cart.suggested_items')}</div>
        <div className={'suggested-list'}>
          {suggestedItems.map((item, index) => (
            <GroceryFoodItem
              key={index}
              data={item}
              hideFav={true}
              style={{ width: 140, minWidth: 140, padding: 10, marginRight: 8 }}
              onAddCart={onAddCart}
              onRmvCart={() => {}}
              onFavChange={() => {}}
              onClick={() => {}}
              disabled={item.available !== 1}
            />
          ))}
        </div>
        <div className={'scrollview_hider'} />
        <div className={'divider'} />
      </div>
    );
  };

  const _onProceed = () => {
    if (props.delivery_info.handover_method === OrderType_Delivery) {
      if (
        props.vendorData.can_schedule === 1 &&
        props.delivery_info.is_schedule === 1 &&
        isEmpty(props.delivery_info.schedule_time)
      ) {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.select_schedule_time'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }

      if (props.delivery_info.address == null || props.delivery_info.address.id == null) {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.validate_select_address'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
      if (state.outOfDeliveryArea === true) {
        return confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('restaurant_details.no_in_vendor_support_zone'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    } else if (props.delivery_info.handover_method === OrderType_Reserve) {
      if (props.user == null) {
        return;
      }
      props.setDeliveryInfoCart({
        reserve_for: props.user
      });
    }

    if (_getTotalPrice() < 0) {
      return confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: t('cart.order_is_under_0'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    props.setPriceCart({
      ...props.cartPrice,
      subtotal: _getSubTotal(),
      small_order_fee: _getSmallOrderFee(),
      order_total: _getTotalPrice(),
      min_order_price: state.minOrderPrice
    });
    // props.setPaymentInfoCart({
    //   ...props.payment_info,
    //   splits: []
    // });
    navigate(ROUTES_NAMES.cartPayment);
  };

  return (
    <div className={'cart-screen'}>
      <Header
        style={{ marginBottom: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('cart.title')}
      />
      <div className={'scrollview'}>
        <CartDelivery outOfDeliveryArea={state.outOfDeliveryArea} />
        {_renderCartProducts()}
        {_renderCutlery()}
        {_renderCashBack()}
        {_renderSuggestedProducts()}
        <CouponInput
          vendorId={props.vendorData.id}
          subTotal={_getSubTotal()}
          applyCoupon={_applyCoupon}
        />
        <div className={'section-view'}>
          <PriceBoard
            minOrderPrice={state.minOrderPrice}
            small_order_fee={_getSmallOrderFee()}
            delivery_fee={props.cartPrice.delivery_fee}
            cashback={props.cartPrice.cashback}
            discount={props.cartPrice.discount}
            sub_total={_getSubTotal()}
            total_price={_getTotalPrice()}
            handover_method={props.delivery_info.handover_method}
          />
        </div>

        <div className={'comment-view'}>
          <div className={'subject-title'} style={{ marginBottom: 12 }}>
            {t('cart.comment')}
          </div>
          <CommentInput text={props.comments} onChange={(text) => props.setCommentCart(text)} />
        </div>
        <div className={'w100 '} style={{ marginBottom: 40 }}>
          <MainBtn title={t('cart.continue_to_payment')} onClick={_onProceed} />
        </div>
      </div>
    </div>
  );
};

const styles = {
  LogoView: { width: 34, height: 34, borderRadius: 8, backgroundColor: Theme.colors.white },
  Logo: { width: 32, height: 32, borderRadius: 8, objectFit: 'contain' }
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates,

  cartItems: shop.items || [],
  cutlery: shop.cutlery,
  coupon: shop.coupon,
  comments: shop.comments,
  cartPrice: shop.cartPrice,
  vendorData: shop.vendorData || {},

  delivery_info: shop.delivery_info,
  payment_info: shop.payment_info
});

CartScreen.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    num_guests: PropTypes.number,
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    order_method: PropTypes.string,
    maximum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    categories: PropTypes.array,
    can_schedule: PropTypes.number
  }),
  cartPrice: PropTypes.shape({
    cashback: PropTypes.number,
    discount: PropTypes.number,
    delivery_fee: PropTypes.number
  }),
  payment_info: PropTypes.object,
  cutlery: PropTypes.number,
  comments: PropTypes.string,
  user: PropTypes.object,
  updateCartItems: PropTypes.func,
  setDeliveryInfoCart: PropTypes.func,
  setCommentCart: PropTypes.func,
  setCutleryCart: PropTypes.func,
  setPaymentInfoCart: PropTypes.func,
  setCouponCart: PropTypes.func,
  setVendorCart: PropTypes.func,
  setPriceCart: PropTypes.func
};

export default connect(mapStateToProps, {
  updateCartItems,
  setCutleryCart,
  setCommentCart,
  setCouponCart,
  setPriceCart,
  setTmpFood,
  setDeliveryInfoCart,
  setPaymentInfoCart,
  setVendorCart
})(CartScreen);
