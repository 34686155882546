import React, { useState } from 'react';
import { Composer, InputToolbar, Send } from '../react-web-gifted-chat';
import { Theme } from '../../../assets';

import Svg_voicenote from '../../../assets/svgs/chats/voicenote.svg';

// import MessageInputToolbar from './MessageInputToolbar';
// import MessageSend from './MessageSend';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
// svgs
import Svg_emoji from '../../../assets/svgs/chats/emoji.svg';
import Svg_add from '../../../assets/svgs/chats/add.svg';
import Svg_close from '../../../assets/svgs/chats/close.svg';
import Svg_location from '../../../assets/svgs/chats/location.svg';
import Svg_send from '../../../assets/svgs/chats/send.svg';
import Svg_camera from '../../../assets/svgs/msg/camera.svg';
import Svg_gallery from '../../../assets/svgs/msg/image.svg';

import PropTypes from 'prop-types';

export const renderInputToolbar = (props, quote_msg, images, onCancelQuote, onRemoveImage) => (
  <InputToolbar
    {...props}
    containerStyle={{
      backgroundColor: Theme.colors.gray8,
      width: '100%',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 18,
      paddingBottom: 18,
      borderTopWidth: 0
    }}
    primaryStyle={{ alignItems: 'center', display: 'flex' }}
    quote_msg={quote_msg}
    onCancelQuote={onCancelQuote}
    images={images}
    onRemoveImage={onRemoveImage}
  />
);

const CustomComposer = ({
  props,
  onPressEmoji,
  onPressLocation,
  onImageUpload,
  onImageCapture
}) => {
  // onCapture
  const { t } = useTranslation();
  const [textInput, setTextInput] = useState(true);

  return (
    <div
      className={'align-middle'}
      style={{
        backgroundColor: Theme.colors.white,
        borderRadius: 60,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 8,
        paddingBottom: 8,
        flex: 1,
        justifyContent: 'space-between'
      }}>
      {textInput == true && (
        <IconButton style={{ padding: 0 }} onClick={onPressEmoji}>
          <img src={Svg_emoji} />
        </IconButton>
      )}
      <div className={'align-col-middle'} style={{ marginLeft: 4, marginRight: 4 }}>
        <div style={{ width: 1, backgroundColor: Theme.colors.gray6 }} />
      </div>
      {textInput == true && (
        <Composer
          {...props}
          placeholder={t('social.chat.type_something')}
          placeholderTextColor={Theme.colors.gray5}
          textInputStyle={{
            flex: 1,
            color: Theme.colors.text,
            fontSize: 18,
            lineHeight: 20,
            fontFamily: Theme.fonts.medium,
            backgroundColor: Theme.colors.white,
            paddingLeft: 8,
            paddingRight: 8,
            paddingTop: 12,
            marginLeft: 0,
            borderLeftWidth: 1,
            borderLeftColor: Theme.colors.gray6,
            borderLeftStyle: 'solid'
          }}
        />
      )}
      {!textInput && (
        <div
          className={'align-middle'}
          style={{ flex: 1, justifyContent: 'flex-end', height: 44, marginRight: 20 }}>
          <IconButton
            style={{ padding: 0, marginRight: 13 }}
            onClick={() => {
              setTextInput(true);
              onPressLocation();
            }}>
            <img src={Svg_location} />
          </IconButton>
          <IconButton
            style={{ padding: 0, marginRight: 13 }}
            onClick={() => {
              setTextInput(true);
              onImageUpload();
            }}>
            <img src={Svg_gallery} />
          </IconButton>
          <IconButton
            style={{ padding: 0, marginRight: 13 }}
            onClick={() => {
              setTextInput(true);
              onImageCapture();
            }}>
            <img src={Svg_camera} />
          </IconButton>
        </div>
      )}
      <IconButton style={{ padding: 0 }} onClick={() => setTextInput(!textInput)}>
        {textInput ? <img src={Svg_add} /> : <img src={Svg_close} />}
      </IconButton>
    </div>
  );
};

CustomComposer.propTypes = {
  props: PropTypes.object,
  onPressEmoji: PropTypes.func,
  onPressLocation: PropTypes.func,
  onImageUpload: PropTypes.func,
  onImageCapture: PropTypes.func
};
export const renderComposer = (
  props,
  onPressEmoji,
  onPressLocation,
  onImageUpload,
  onImageCapture
) => (
  <CustomComposer
    props={props}
    onPressEmoji={onPressEmoji}
    onPressLocation={onPressLocation}
    onImageUpload={onImageUpload}
    onImageCapture={onImageCapture}
  />
);

export const renderSend = (props, active, onRecord) =>
  active === false ? (
    <IconButton style={{ padding: 0 }} onClick={() => onRecord()}>
      <img src={Svg_voicenote} />
    </IconButton>
  ) : (
    <Send
      {...props}
      containerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 15
      }}>
      <img src={Svg_send} style={{ width: 40, height: 40 }} />
    </Send>
  );
