import React from 'react';
import { Theme } from '../../../assets';
import { getImageFullURL } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const SuggestedUserItem = ({ full_name, photo, onViewProfile }) => {
  const { t } = useTranslation();
  return (
    <div className={'align-col-middle'} style={styles.userItemView} onClick={onViewProfile}>
      <img style={styles.userItemAvatar} src={getImageFullURL(photo)} />
      <div className={'align-col-middle'}>
        <div style={styles.userItemName}>{full_name}</div>
        <div style={styles.userItemBtnName}>{t('chat.view_profile')}</div>
      </div>
    </div>
  );
};

const styles = {
  userItemView: {
    width: 120,
    minWidth: 120,
    height: 120,
    marginRight: 15,
    borderRadius: 12,
    backgroundColor: Theme.colors.gray8
  },
  userItemAvatar: { width: 50, height: 50, borderRadius: 10 },
  userItemName: {
    marginTop: 8,
    fontSize: 14,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.text
  },
  userItemBtnName: {
    marginTop: 10,
    fontSize: 14,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.cyan2
  }
};

SuggestedUserItem.propTypes = {
  id: PropTypes.number,
  full_name: PropTypes.string,
  photo: PropTypes.string,
  onViewProfile: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return !(
    prevProps.id !== nextProps.id ||
    (prevProps.id === nextProps.id && prevProps.full_name !== nextProps.full_name) ||
    (prevProps.id === nextProps.id && prevProps.photo !== nextProps.photo)
  );
}

export default React.memo(SuggestedUserItem, arePropsEqual);
