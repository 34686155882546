import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OrderType_Delivery } from '../../../constants/config';
import { Theme } from '../../../assets';
import SmallOrderFeeTooltip from '../../Common/SmallOrderFeeTooltip';
import './index.css';

const PriceBoard = ({
  minOrderPrice,
  small_order_fee,
  delivery_fee,
  cashback,
  discount,
  sub_total,
  total_price,
  handover_method
}) => {
  const { t } = useTranslation();
  return (
    <div className={'align-col-middle w100 price-board'}>
      <div className={'w100 flex_between mt8'}>
        <div className={'key-text'}>{t('cart.subtotal')}</div>
        <div className={'value-text'}>{parseInt(sub_total) + ' L'}</div>
      </div>
      <div className={'w100 flex_between mt8'}>
        <div className={'key-text'}>{t('cart.discount')}</div>
        <div className={'value-text'}>
          {parseInt(discount || 0) === 0 ? '0 L' : `-${parseInt(discount)} L`}
        </div>
      </div>
      <div className={'w100 flex_between mt8'}>
        <div className={'key-text'}>{t('wallet.cashback')}</div>
        <div className={'value-text'}>
          {parseInt(cashback || 0) === 0 ? '0 L' : `-${parseInt(cashback)} L`}
        </div>
      </div>
      {/* {
					delivery_info.handover_method === OrderType_Delivery &&
					vendorData.delivery_type === "Snapfood" &&
					<InfoRow name={t('cart.leave_rider_tip')} value={(parseInt(delivery_info.tip_rider) || 0) + ' L'} />
				} */}

      {handover_method === OrderType_Delivery && small_order_fee > 0 && (
        <div className={'w100 flex_between mt8'}>
          <div className={'key-text align-middle'}>
            {t('cart.small_order_fee')}
            <SmallOrderFeeTooltip
              delivery_minimum_order_price={minOrderPrice}
              small_order_fee={parseInt(small_order_fee)}
            />
          </div>
          <div className={'value-text'}>{parseInt(small_order_fee) + ' L'}</div>
        </div>
      )}
      {handover_method === OrderType_Delivery && (
        <div className={'w100 flex_between mt8'}>
          <div className={'key-text'}>{t('order_details.delivery_fee')}</div>
          <div className={'value-text'}>{parseInt(delivery_fee) + ' L'}</div>
        </div>
      )}
      <div className={'w100 flex_between mt8'}>
        <div className={'key-text'} style={{ fontFamily: Theme.fonts.bold }}>
          {t('cart.order_total')}
        </div>
        <div className={'value-text'} style={{ fontFamily: Theme.fonts.bold }}>
          {parseInt(total_price) + ' L'}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.handover_method === nextProps.handover_method &&
    prevProps.delivery_fee === nextProps.delivery_fee &&
    prevProps.minOrderPrice === nextProps.minOrderPrice &&
    prevProps.small_order_fee === nextProps.small_order_fee &&
    prevProps.cashback === nextProps.cashback &&
    prevProps.discount === nextProps.discount &&
    prevProps.sub_total === nextProps.sub_total &&
    prevProps.total_price === nextProps.total_price
  );
}

PriceBoard.propTypes = {
  handover_method: PropTypes.string,
  delivery_fee: PropTypes.number,
  minOrderPrice: PropTypes.number,
  small_order_fee: PropTypes.number,
  cashback: PropTypes.number,
  discount: PropTypes.number,
  sub_total: PropTypes.number,
  total_price: PropTypes.number
};

export default React.memo(PriceBoard, arePropsEqual);
