import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
// import { Theme } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import ImageCapture from 'react-image-data-capture';
import { useTranslation } from 'react-i18next';
import { SwitchBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import { MainBtn } from '../../../components/Buttons';
import { AuthInput } from '../../../components/Inputs';
import UserItem from '../../../components/Chats/UserItem';
import ImgPickOptionModal from '../../../components/Modals/ImgPickOptionModal';
// svgs
import Svg_upload from '../../../assets/svgs/chats/ic_upload.svg';
import './index.css';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { getImageFullURL } from '../../../utils/common';
import {
  createGroupChannel,
  sendGroupChatInviteNotification,
  uploadImage
} from '../../../services/chat';

const CreateGroup = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: false,
    isFriend: true,
    permitted: true,
    group_name: '',
    Friends: [props.user],
    group_admin: props.user
  });

  const file_input = useRef(null);

  const config = useMemo(() => ({ video: true }), []);
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [showPickerModal, setShowPickerModal] = useState(false);
  const [photoData, setPhotoData] = useState(null);

  useEffect(() => {
    setState({
      ...state,
      Friends: [props.user].concat(props.tmp_selected_group_chat)
    });
  }, [props.tmp_selected_group_chat]);

  const onCreateGroup = async () => {
    if (state.group_admin.id == null) {
      return confirmAlert({
        title: t('attention'),
        message: t('social.choose_group_admin'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (state.group_name == '') {
      return confirmAlert({
        title: t('attention'),
        message: t('social.enter_group_name'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    setState({ ...state, loading: true });
    let photo_url = getImageFullURL('default_group');
    if (photoData != null) {
      try {
        let res = await uploadImage(photoData.split(',')[1]);
        if (res != null && res.data != null && res.data.success == true) {
          photo_url = res.data.url;
        } else {
          setState({ ...state, loading: false });
          return confirmAlert({
            title: t('alerts.error'),
            message: t('checkout.something_is_wrong'),
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      } catch (error) {
        setState({ ...state, loading: false });
        return confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    }

    let members = [];
    state.Friends.map((friend) => {
      members.push({
        id: friend.id,
        full_name: friend.full_name,
        photo: friend.photo,
        phone: friend.phone,
        email: friend.email
      });
    });

    let group_data = {
      full_name: state.group_name,
      photo: photo_url,
      permitted: state.permitted,
      admin: {
        id: state.group_admin.id,
        full_name: state.group_admin.full_name,
        photo: state.group_admin.photo,
        phone: state.group_admin.phone,
        email: state.group_admin.email
      },
      creator: {
        id: props.user.id,
        full_name: props.user.full_name,
        photo: props.user.photo,
        phone: props.user.phone,
        email: props.user.email
      },
      members: members,
      users: [...members.map((i) => i.id)]
    };

    let channelID = await createGroupChannel(group_data);
    setState({ ...state, loading: false });
    if (channelID != null) {
      sendGroupChatInviteNotification(
        channelID,
        group_data.full_name,
        group_data.users.filter((i) => i != props.user.id)
      );

      navigate(`/messages/${channelID}`, { replace: true });
    } else {
      return confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onCapture = (imageData) => {
    if (imageData == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageData.blob);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };

    setIsCameraOn(false);
  };

  const onError = useCallback(() => {
    confirmAlert({
      title: '',
      message: t('account_details.no_camera'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {
            setIsCameraOn(false);
          }
        }
      ]
    });
  }, []);

  const onHandleGalleryImg = ({ target }) => {
    const reader = new FileReader();
    reader.readAsDataURL(target.files[0]);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setPhotoData(reader.result);
      }
    };
  };

  return (
    <div className={'flex_1 create-group-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('social.new_group')}
      />
      <div className={'flex_1 scrollview'}>
        <div className={'align-col-middle mt16'}>
          <div
            className={'align-col-middle avatar-view'}
            onClick={() => {
              setShowPickerModal(true);
            }}>
            {photoData != null ? (
              <img src={photoData} className={'avartar'} />
            ) : (
              <div className={'align-col-middle upload-btn'}>
                <img src={Svg_upload} />
                <p>{t('social.upload_avatar')}</p>
              </div>
            )}
          </div>
          <AuthInput
            placeholder={t('social.group_name')}
            placeholderTextColor={'#DFDFDF'}
            onChange={(group_name) => setState({ ...state, group_name })}
            style={{ marginTop: 20, height: 50 }}
          />
        </div>
        <div className={'w100 permission-view'}>
          <div className={'flex_between w100'}>
            <p> {t('social.member_can_add_people')} </p>
            <SwitchBtn
              isOn={state.permitted === true}
              onClick={() => {
                setState({ ...state, permitted: !state.permitted });
              }}
            />
          </div>
        </div>
        <div className={'subject-title'} style={{ marginTop: 20, marginBottom: 12 }}>
          {t('social.choose_group_admin')}
        </div>
        {state.Friends.map((item) => (
          <UserItem
            key={item.id}
            item={item}
            type="checkbox"
            checked={state.group_admin.id === item.id}
            onClick={() => {
              setState({ ...state, group_admin: item });
            }}
          />
        ))}
      </div>
      <div style={{ marginBottom: 40, width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <MainBtn
          loading={state.loading}
          disabled={state.loading}
          title={t('proceed')}
          style={{ width: '100%' }}
          onClick={onCreateGroup}
        />
      </div>
      <ImgPickOptionModal
        isOpen={showPickerModal}
        onClose={() => {
          setShowPickerModal(false);
        }}
        onCapture={() => {
          setShowPickerModal(false);
          setIsCameraOn(true);
        }}
        onGallery={() => {
          setShowPickerModal(false);
          if (file_input.current != null) {
            file_input.current.click();
          }
        }}
      />
      <input
        accept="image/jpeg/png"
        ref={file_input}
        hidden={true}
        type="file"
        onChange={onHandleGalleryImg}
      />
      {isCameraOn && (
        <div className="align-col-middle camera-view">
          <ImageCapture
            onCapture={onCapture}
            onError={onError}
            width={window.innerWidth}
            userMediaConfig={config}
          />
        </div>
      )}
    </div>
  );
};

CreateGroup.propTypes = {
  tmp_selected_group_chat: PropTypes.array,
  user: {
    id: PropTypes.number,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }
};

const mapStateToProps = ({ app }) => ({
  tmp_selected_group_chat: app.tmp_selected_group_chat,
  user: app.user
});

export default connect(mapStateToProps, {})(CreateGroup);
