import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setPaymentInfoCart } from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { MainBtn, RadioBtn } from '../../../components/Buttons';
import Counter from '../../../components/Common/Counter';
import Header from '../../../components/Header';
import { OrderType_Delivery } from '../../../constants/config';
import PropTypes from 'prop-types';
// import { ROUTES_NAMES } from '../../../constants';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';

const SplitOrderScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [curMethod, setMethod] = useState('Equally');
  const [isSavedManual, setSaveManual] = useState(false);
  const [manualPrices, setManualPrices] = useState({});

  const calculateOrderTotal = () => {
    const { subtotal, discount, cashback, small_order_fee, delivery_fee } = props.cartPrice;

    let total = subtotal - cashback - discount;
    if (props.delivery_info.handover_method === OrderType_Delivery) {
      total = total + small_order_fee;
      total = total + delivery_fee;

      // if (props.order_data.vendorData != null && props.order_data.vendorData.delivery_type == "Snapfood" && props.delivery_info.tip_rider > 0) {
      //     total = total + parseInt(props.delivery_info.tip_rider);
      // }
    }
    return total;
  };

  const getManualPriceForaUser = (user) => {
    if (manualPrices[user.id] == null) {
      let price = calculateOrderTotal() / props.splits.length;
      let remind = calculateOrderTotal() % props.splits.length;
      if (user.id === props.user.id && remind > 0) {
        return parseInt(price) + remind;
      }
      return parseInt(price);
    } else {
      return manualPrices[user.id];
    }
  };
  const onPlusUser = (user) => {
    const step = 50;
    let cur_price = getManualPriceForaUser(user);
    if (cur_price + step <= calculateOrderTotal()) {
      let found_id = props.splits.findIndex((i) => i.id === user.id);

      if (found_id !== -1 && props.splits.length > 1) {
        let next_user = null;
        if (found_id + 1 < props.splits.length) {
          next_user = props.splits[found_id + 1];
        } else {
          next_user = props.splits[0];
        }
        if (next_user != null && next_user.id !== user.id) {
          let next_cur_price = getManualPriceForaUser(next_user);
          if (next_cur_price > step) {
            setManualPrices({
              ...manualPrices,
              [user.id]: cur_price + step,
              [next_user.id]: next_cur_price - step
            });
          }
        }
      }
    }
  };
  const onMinusUser = (user) => {
    const step = 50;
    let cur_price = getManualPriceForaUser(user);
    if (cur_price > step) {
      let found_id = props.splits.findIndex((i) => i.id === user.id);

      if (found_id !== -1 && props.splits.length > 1) {
        let next_user = null;
        if (found_id + 1 < props.splits.length) {
          next_user = props.splits[found_id + 1];
        } else {
          next_user = props.splits[0];
        }
        if (next_user != null && next_user.id !== user.id) {
          let next_cur_price = getManualPriceForaUser(next_user);
          if (next_cur_price <= calculateOrderTotal()) {
            setManualPrices({
              ...manualPrices,
              [user.id]: cur_price - step,
              [next_user.id]: next_cur_price + step
            });
          }
        }
      }
    }
  };

  const onConfirmSplit = () => {
    if (curMethod === 'Equally') {
      let price = calculateOrderTotal() / props.splits.length;
      let tmp = [];
      props.splits.map((item) => {
        item.price = parseInt(price);
        tmp.push(item);
      });
      props.setPaymentInfoCart({
        ...props.payment_info,
        splits: tmp
      });
    } else {
      let tmp = [];
      props.splits.map((item) => {
        item.price = getManualPriceForaUser(item);
        tmp.push(item);
      });
      props.setPaymentInfoCart({
        ...props.payment_info,
        splits: tmp
      });
    }
    // navigate(ROUTES_NAMES.cartPayment);
    navigate(-2);
  };

  const onSaveManual = () => {
    let cur_total = 0;
    props.splits.map((item) => {
      cur_total = cur_total + getManualPriceForaUser(item);
    });
    if (cur_total !== calculateOrderTotal()) {
      return confirmAlert({
        title: t('warning'),
        message: t('split.wrong_split'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    setSaveManual(true);
  };

  const _renderMethod = (type) => {
    const isActive = curMethod === type;

    return (
      <div
        onClick={() => {
          if (type === 'Equally') {
            setSaveManual(false);
          }
          setMethod(type);
        }}
        className={'align-col-middle split-method-view'}>
        <div className={'flex_between'}>
          <div className={'name'} style={isActive ? { fontFamily: Theme.fonts.semiBold } : {}}>
            {t('split_title')} {t(type)}
          </div>
          <RadioBtn
            checked={isActive}
            onClick={() => {
              if (type === 'Equally') {
                setSaveManual(false);
              }
              setMethod(type);
            }}
          />
        </div>
        {isActive && (
          <div className={'w100 align-col-middle'}>
            <div style={styles.divider} />
            {props.splits.map((item, index) => (
              <div key={index} className={'flex_between mt6 mb6'}>
                <div className={'name'}>
                  {item.id === props.user.id ? t('you') : item.full_name}
                </div>
                {type !== 'Equally' && props.splits.length > 1 && !isSavedManual && (
                  <Counter
                    onPlus={() => onPlusUser(item)}
                    onMinus={() => onMinusUser(item)}
                    value={getManualPriceForaUser(item)}
                    style={styles.counter}
                    btnColor={Theme.colors.cyan2}
                    btnSize={22}
                  />
                )}
                {type !== 'Equally' && (props.splits.length === 1 || isSavedManual) && (
                  <div className={'name'} style={{ fontFamily: Theme.fonts.semiBold }}>
                    {parseInt(getManualPriceForaUser(item))} L
                  </div>
                )}
              </div>
            ))}
            <div style={styles.divider} />
            {type === 'Equally' || isSavedManual ? (
              <div className={'flex_between'}>
                <div className={'name'} style={{ fontFamily: Theme.fonts.semiBold }}>
                  {t('split.bill_total')}
                </div>
                <div className={'name'} style={{ fontFamily: Theme.fonts.semiBold }}>
                  {parseInt(calculateOrderTotal())} L
                </div>
              </div>
            ) : (
              <div style={styles.save_btn} onClick={() => onSaveManual()}>
                {t('save')}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={'split-order-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('filter.split_order')}
      />
      <div className={'scrollview'}>
        {_renderMethod('Equally')}
        {_renderMethod('Manually')}
      </div>
      <div style={{ width: '100%', padding: 20 }}>
        {(isSavedManual || curMethod === 'Equally') && (
          <MainBtn
            // disabled={loading}
            // loading={loading}
            title={t('confirm')}
            onClick={onConfirmSplit}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  divider: {
    width: '100%',
    marginTop: 16,
    marginBottom: 16,
    height: 1,
    backgroundColor: Theme.colors.gray6
  },
  save_btn: { fontSize: 16, color: Theme.colors.cyan2, fontFamily: Theme.fonts.semiBold },
  counter: {
    width: 111,
    height: 34,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    borderWidth: 0,
    backgroundColor: Theme.colors.gray8
  }
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  payment_info: shop.payment_info,
  delivery_info: shop.delivery_info,
  cartPrice: shop.cartPrice,
  splits: shop.payment_info.splits || []
});

SplitOrderScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  payment_info: PropTypes.shape({
    splits: PropTypes.array
  }),
  cartPrice: PropTypes.shape({
    subtotal: PropTypes.number,
    discount: PropTypes.number,
    cashback: PropTypes.number,
    small_order_fee: PropTypes.number,
    delivery_fee: PropTypes.number
  }),
  splits: PropTypes.array,
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string
  }),
  setPaymentInfoCart: PropTypes.func
};

export default connect(mapStateToProps, {
  setPaymentInfoCart
})(SplitOrderScreen);
