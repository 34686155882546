import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';
import PropTypes from 'prop-types';

const CartViewBtn = ({ onClick, cartItems, style }) => {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;
    cartItems.map((cartItem) => {
      total = total + cartItem.price * cartItem.quantity;
    });
    setTotalPrice(total);
  }, [cartItems]);

  return (
    <div className={'align-middle w100 ph-20 cartview-btn'} style={style} onClick={onClick}>
      <div className={'flex_between btn-container'}>
        <div className={'align-col-middle'}>
          <div className={'desc-text'}>{t('cart.total')}</div>
          <div className={'total-price'}>{parseInt(totalPrice)} L</div>
        </div>
        <div className={'align-middle'}>
          <div className={'total-price'}>{t('cart.view_cart')}</div>
          <div className={'align-col-middle badge'}>
            <div className={'badge-text'}>{cartItems.length}</div>
          </div>
        </div>
      </div>
      <div className={'anchor'} />
    </div>
  );
};

CartViewBtn.propTypes = {
  style: PropTypes.object,
  cartItems: PropTypes.array,
  onClick: PropTypes.func
};

export default CartViewBtn;
