import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { isEmpty, getImageFullURL } from '../../../utils/common';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import Header from '../../../components/Header';
import CashbackItem from '../../../components/Order/CashbackItem';
import NoCashback from '../../../components/Empty/NoCashback';
import apiFactory from '../../../services/apiFactory';
import { getLoggedInUser } from '../../../store/actions/auth';
// svgs
import Img_user from '../../../assets/images/user-default.png';
import Svg_balance from '../../../assets/svgs/profile/balance.svg';
import './index.css';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const PerPage = 10;

const Wallet = (props) => {
  const { t } = useTranslation();
  const _isMounted = useRef(true);

  const [loading, setLoading] = useState(null);
  const [cashbacks, setCashbacks] = useState([]);
  const [page, setCurPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    props.getLoggedInUser();
    loadCashback(1, PerPage, true);
    // pops.goActiveScreenFromPush({
    //   isWalletVisible: false
    // })r

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const loadCashback = (page, perPage, forceLoading = false) => {
    if (loading && forceLoading === false) {
      return;
    }
    setLoading(true);
    const params = [`page=${page}`, `per_page=${perPage}`];
    apiFactory.get(`cashback?${params.join('&')}`).then(
      ({ data }) => {
        if (_isMounted.current !== true) {
          return;
        }
        if (page > 1) {
          const currentOrderIds = cashbacks.map((x) => x.id);
          const newItems = data.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
          setCurPage(data['current_page']);
          setTotalPages(data['last_page']);
          setCashbacks([...cashbacks, ...newItems]);
        } else {
          setCurPage(data['current_page']);
          setTotalPages(data['last_page']);
          setCashbacks(data.data || []);
        }
        setLoading(false);
      },
      () => {
        if (_isMounted.current === true) {
          setLoading(false);
        }
      }
    );
  };

  const _renderAvatar = () => {
    return (
      <div className={'align-col-middle avatar'}>
        <div className={'align-col-middle photoview'}>
          <img
            src={
              isEmpty(props.user.photo) || props.user.photo === 'x'
                ? Img_user
                : getImageFullURL(props.user.photo)
            }
          />
        </div>
        <div className={'align-col-middle'}>
          <div className={'name'}>{props.user.full_name}</div>
        </div>
        {props.user.user_category != null && (
          <div className={'align-middle level'}>
            <div className={'level-txt'}>{props.user.user_category.name}</div>
          </div>
        )}
      </div>
    );
  };

  const _renderBalance = () => {
    return (
      <div className={'align-middle balance'}>
        <img src={Svg_balance} />
        <div
          className={'align-col-middle flex_1'}
          style={{ marginLeft: 10, alignItems: 'flex-start' }}>
          <div className={'balance-txt'}>{t('wallet.your_balance')}</div>
          <div className={'balance-desc'}>{t('wallet.your_balance_description')}</div>
        </div>
        <div className={'align-middle'} style={{ alignItems: 'flex-end', marginLeft: 12 }}>
          <div className={'balance-price'}>{props.user.cashback_amount || 0}</div>
          <div className={'balance-unit'}>L</div>
        </div>
      </div>
    );
  };

  return (
    <div className={'align-start wallet-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('account.wallet')}
      />
      <div className={'scrollview'}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (page < totalPages) {
              loadCashback(page + 1, PerPage);
            }
          }}
          hasMore={page < totalPages}
          loader={
            <div className="align-col-middle mt12 mb20" key={0}>
              <CircularProgress size={20} color="primary" />
            </div>
          }
          useWindow={false}>
          {_renderAvatar()}
          {_renderBalance()}
          <div className={'divider'} />
          <div className={'subject-title'}>{t('wallet.cashback_history')}</div>
          {loading === false && cashbacks.length === 0 ? (
            <NoCashback />
          ) : (
            cashbacks.map((item) => <CashbackItem key={item.id} id={item.id} data={item} />)
          )}
          <div style={{ height: 40 }} />
        </InfiniteScroll>
      </div>
    </div>
  );
};

Wallet.propTypes = {
  user: {
    full_name: PropTypes.string,
    photo: PropTypes.string,
    user_category: PropTypes.shape({
      name: PropTypes.string
    }),
    cashback_amount: PropTypes.number
  },
  getLoggedInUser: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {}
});

export default connect(mapStateToProps, { getLoggedInUser })(Wallet);
