import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import { MainBtn } from '../../Buttons';
import './index.css';
import TimePicker from '../../Cart/TimePicker';
import moment from 'moment';

const ScheduleTimeModal = ({ showModal, onConfirm, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [pickup_date, setDate] = React.useState('');
  const [pickup_time, setTime] = React.useState('');

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  useEffect(() => {
    const apm = moment(new Date()).format('A');

    let pickup_time = moment(new Date()).format('h') + ':00';
    if (apm === 'PM') {
      pickup_time = parseInt(pickup_time.split(':')[0]) + 12 + ':00';
    }
    setDate(moment(new Date()).format('YYYY-MM-DD'));
    setTime(pickup_time);
  }, []);

  const _onConfirm = () => {
    let _date = pickup_date;
    let _time = pickup_time;
    if (pickup_date === '') {
      _date = moment(new Date()).format('YYYY-MM-DD');
    }
    if (pickup_time === '') {
      const apm = moment(new Date()).format('A');
      _time = moment(new Date()).format('h') + ':00';
      if (apm === 'PM') {
        _time = parseInt(_time.split(':')[0]) + 12 + ':00';
      }
    }
    onConfirm(`${_date} ${_time}:00`);
  };

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal vendor-closed-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div style={styles.modalTitle}>{t('cart.schedule_order')}</div>
        <div style={{ marginBottom: 20 }}>
          <TimePicker
            onChangePickupDate={(pickup_date) => {
              setDate(pickup_date);
            }}
            onChangePickupTime={(pickup_time) => {
              setTime(pickup_time);
            }}
          />
        </div>
        <MainBtn title={t('confirm')} onClick={_onConfirm} />
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 30,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 20
  },
  seeBtnTxt: {
    fontSize: 16,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.cyan2
  },
  homeBtnTxt: {
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

ScheduleTimeModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};

export default React.memo(ScheduleTimeModal, arePropsEqual);
