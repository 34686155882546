import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CommonTabs } from '../../../components/TabSelector';
import Header from '../../../components/Header';
import VendorItem from '../../../components/Vendor/VendorItem';
import FoodItem from '../../../components/Vendor/FoodItem';
import NoFavs from '../../../components/Empty/NoFavs';
import { setVendorCart } from '../../../store/actions/shop';
import {
  getVendorFavourites,
  getProductFavourites,
  getVendorDetail
} from '../../../store/actions/vendors';
import './index.css';
import PropTypes from 'prop-types';

const Favorites = (props) => {
  const _isMounted = useRef(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [opType, setOpType] = useState('Vendors');
  const [vendors, setVendors] = useState([]);
  const [items, setItems] = useState([]);

  const [vendorLoading, setVendorLoading] = useState(null);
  const [itemsLoading, setItemsLoading] = useState(null);

  useEffect(() => {
    loadVendorFavorites();
    loadProductFavorites();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const loadVendorFavorites = () => {
    setVendorLoading(true);
    getVendorFavourites()
      .then((data) => {
        if (_isMounted.current === true) {
          setVendors(data);
          setVendorLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setVendorLoading(false);
        }
      });
  };

  const loadProductFavorites = () => {
    setItemsLoading(true);
    getProductFavourites()
      .then((data) => {
        if (_isMounted.current === true) {
          setItems(data);
          setItemsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setItemsLoading(false);
        }
      });
  };

  const deleteFavorite = () => {
    loadVendorFavorites();
  };
  const deleteProductFavorite = () => {
    loadProductFavorites();
  };

  const goProductVendor = (product) => {
    if (product.vendor_id == null) {
      return;
    }
    let { latitude, longitude } = props.coordinates;
    getVendorDetail(product.vendor_id, latitude, longitude)
      .then((data) => {
        if (_isMounted.current === true) {
          props.setVendorCart(data);
          navigate(`/vendor/${data.id}/${data.order_method}`);
        }
      })
      .catch(() => {});
  };

  return (
    <div className={'flex_1 fav-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        title={t('account.preferred')}
      />
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['Vendors', 'Items']}
            item={opType}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => setOpType(item)}
          />
        </div>
      </div>
      <div className={'flex_1 scrollview'}>
        <div className={opType === 'Vendors' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {vendorLoading === false && vendors.length === 0 ? (
            <NoFavs isVendor={opType === 'Vendors'} style={{ marginTop: 40 }} />
          ) : (
            vendors.map((vendor) => (
              <VendorItem
                key={vendor.id}
                id={vendor.id}
                data={vendor}
                vendor_id={vendor.id}
                isFav={vendor.isFav}
                isOpen={vendor.is_open}
                style={{ width: '100%', marginBottom: 12 }}
                onClick={(e) => {
                  e.preventDefault();
                  props.setVendorCart(vendor);
                  navigate(`/vendor/${vendor.id}/${vendor.order_method}`);
                }}
                onFavChange={deleteFavorite}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
        <div className={opType === 'Items' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {itemsLoading === false && items.length === 0 ? (
            <NoFavs isVendor={opType === 'Vendors'} style={{ marginTop: 40 }} />
          ) : (
            items.map((item, index) => (
              <FoodItem
                key={index}
                id={item.id}
                data={item}
                isFav={item.isFav}
                onClick={() => {
                  goProductVendor(item);
                }}
                onFavChange={deleteProductFavorite}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
      </div>
    </div>
  );
};

Favorites.propTypes = {
  coordinates: {
    latitude: PropTypes.number,
    longitude: PropTypes.number
  },
  setVendorCart: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates
});

export default connect(mapStateToProps, { setVendorCart })(Favorites);
