import Cookies from 'universal-cookie';
import { Config } from '../constants';
import { createUUID } from '../utils/common';

const cookies = new Cookies();

export const KEYS = {
  LANGUAGE: 'snapfood-language',
  DEVICE_UNIQUE_KEY: 'snapfood-device-unique-id',
  APP_TOKEN: 'snapfood-app-token',
  RECENT_FILTER: 'snapfood-recent-filter',
  CART_ITEMS: 'snapfood-cart-items',
  LAST_COORDINATES: 'snapfood-last-coordinates',
  SEEN_ONBOARD: 'snapfood-seen-onboard'
};

export const setAppToken = (token) => {
  let d = new Date();
  d.setTime(d.getTime() + 6 * 30 * 24 * 60 * 60 * 1000); // 6 months expiration
  cookies.set(KEYS.APP_TOKEN, token, { path: '/', expires: d });
};

export const clearAppToken = () => {
  let d = new Date('1970-01-01');
  cookies.remove(KEYS.APP_TOKEN, { path: '/', expires: d });
};

export const getAppToken = () => {
  // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0LCJ0b2tlbiI6InNjNFJDbUVqdVl4aXNESDc4b3M0TTY2NUZHNkRxOENhbkliVjNpRnpqMldveVVlS29hMjZqaU01TnhTdGtKWm5HTWtldEg0MzdVWFluY2gxTEN4eUZ1c2tIb0g4QlpndkxQNWkifQ.nwboPh3MkBlHFMzzLJqYYLo0ZJ5wOaQHMKwHopoQ65U';
  // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjg0LCJ0b2tlbiI6IktYU1I3dEF5bVN6UXlMS2N4VjBlSUMxZG1zT296bEtUZVVoRWpxRjY3N2tlYndGcktaemFjUFo2NHFydzZaTXlUeVFDVXo4WlJReFBNTjFlQmZGTTNQNFNUeTY1dWJKdHE5cncifQ.ZWe6VzgL78IhovqcHaFMdXnfFV9Iz7QjtpsqGRO_a60';
  return cookies.get(KEYS.APP_TOKEN);
};

export const getDeviceUniqueId = () => {
  let uniqueId = localStorage.getItem(KEYS.DEVICE_UNIQUE_KEY);
  if (uniqueId == null) {
    uniqueId = createUUID();
    localStorage.setItem(KEYS.DEVICE_UNIQUE_KEY, uniqueId);
  }
  return uniqueId;
};

export const getLanguage = () => {
  let savedLang = localStorage.getItem(KEYS.LANGUAGE);
  if (savedLang == null || (savedLang !== 'en' && savedLang !== 'sq')) {
    savedLang = Config.DEFAULT_LANG;
    localStorage.setItem(KEYS.LANGUAGE, savedLang);
  }
  return savedLang;
};

export const setLanguage = (lang) => {
  localStorage.setItem(KEYS.LANGUAGE, lang);
};

export const getFilterRecent = () => {
  let recentFilter = localStorage.getItem(KEYS.RECENT_FILTER);
  if (recentFilter == null) {
    recentFilter = [];
  } else {
    recentFilter = JSON.parse(recentFilter) || [];
  }
  return recentFilter;
};

export const setFilterRecent = (recents) => {
  localStorage.setItem(KEYS.RECENT_FILTER, JSON.stringify(recents));
};

export const removeFilterRecent = () => {
  localStorage.removeItem(KEYS.RECENT_FILTER);
};

export const setCartItems = (items) => {
  localStorage.setItem(KEYS.CART_ITEMS, JSON.stringify(items));
};

export const getCartItems = () => {
  let items = localStorage.getItem(KEYS.CART_ITEMS);
  if (items == null) {
    items = [];
  } else {
    items = JSON.parse(items);
  }
  return items;
};

export const getLastCoordinates = () => {
  let location = localStorage.getItem(KEYS.LAST_COORDINATES);
  if (location != null) {
    location = JSON.parse(location);
  }
  return location;
};

export const setLastCoordinates = (location) => {
  localStorage.setItem(KEYS.LAST_COORDINATES, JSON.stringify(location));
};

export const getSeenOnboard = () => {
  let flag = localStorage.getItem(KEYS.SEEN_ONBOARD);
  if (flag != null) {
    flag = JSON.parse(flag);
  }
  return flag === true;
};

export const setSeenOnboard = (flag) => {
  localStorage.setItem(KEYS.SEEN_ONBOARD, JSON.stringify(flag));
};
