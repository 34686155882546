import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import './index.css';

const RewardHistItem = ({ is_received, data, onClick }) => {
  const { t } = useTranslation();
  const getStatusColor = () => {
    if (data.status === 'Used') {
      return '#00C22D';
    } else if (data.status === 'available') {
      return Theme.colors.cyan2;
    } else if (data.status === 'Expired') {
      return Theme.colors.gray7;
    }
    return Theme.colors.cyan2;
  };
  return (
    <div
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick(data);
            }
          : () => {}
      }
      className={'reward-hist-item w100'}>
      <div className={'flex_between'}>
        <div className={'name'}>{data.receiver.full_name}</div>
        {is_received && data.status === 'available' && (
          <div className={'invite_code'}>
            <span>{data.remaining_time_to_use}</span>
          </div>
        )}
      </div>
      <div className={'flex_between mt16'}>
        {!is_received && data.status === 'Used' ? (
          <div className={'invite_code'}> {t(`You earned ${data.used_rewards_amount} L`)} </div>
        ) : (
          <div className={'invite_code'}>
            {t('Code')} : <span>{data.invite_code}</span>
          </div>
        )}
      </div>
      <div className={'flex_between mt16'}>
        <div className={'date'}>
          {data.invite_time.split(' ')[0]} <span>|</span> {data.invite_time.split(' ')[1]}
        </div>
        <div className={'status'} style={{ color: getStatusColor() }}>
          {is_received && data.status === 'available' && t('Use code')}
          {!is_received && data.status === 'available' && t('Sent')}
          {data.status !== 'available' && t(data.status)}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

RewardHistItem.propTypes = {
  id: PropTypes.string,
  is_received: PropTypes.bool,
  data: PropTypes.shape({
    receiver: PropTypes.shape({
      full_name: PropTypes.string
    }),
    invite_code: PropTypes.string,
    invite_time: PropTypes.string,
    remaining_time_to_use: PropTypes.string,
    used_rewards_amount: PropTypes.number,
    used_rewards_rate: PropTypes.number,
    status: PropTypes.string
  }),
  onClick: PropTypes.func
};
export default React.memo(RewardHistItem, arePropsEqual);
