import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import NoFriendList from '../../../components/Empty/NoFriendList';
import './index.css';
import { setPaymentInfoCart } from '../../../store/actions/shop';
import { getFriends } from '../../../store/actions/app';
import { MainCustomBtn } from '../../../components/Buttons';
import { ROUTES_NAMES } from '../../../constants';
import RadioBtn from '../../../components/Buttons/RadioBtn';
import PropTypes from 'prop-types';

const SplitFriends = (props) => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [friendItems, setFriendItems] = useState([]);
  const [friendsLoading, setFriendsLoading] = useState(null);

  useEffect(() => {}, []);

  useEffect(() => {
    props.setPaymentInfoCart({
      ...props.payment_info,
      splits: []
    });
    setFriendsLoading(true);
    getFriends('accepted', null, null, 1)
      .then((data) => {
        if (_isMounted.current) {
          setFriendItems(data);
          setFriendsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current) {
          setFriendsLoading(false);
        }
      });

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getSelectedCnt = () => {
    let found = props.splits.filter((i) => i.id !== props.user.id);
    return found.length;
  };

  const onSelectFriend = (item) => {
    let tmp = props.splits.slice(0, props.splits.length);
    let foundUser = props.splits.findIndex((i) => i.id === item.id);
    if (foundUser >= 0) {
      tmp.splice(foundUser, 1);
    } else {
      tmp.push(item);
    }
    props.setPaymentInfoCart({
      ...props.payment_info,
      splits: tmp
    });
  };

  const goSplitOrder = () => {
    let tmp = props.splits.slice(0, props.splits.length);
    let found = tmp.findIndex((i) => i.id === props.user.id);
    if (found < 0) {
      tmp = [props.user].concat(tmp);
    }

    props.setPaymentInfoCart({
      ...props.payment_info,
      splits: tmp
    });
    navigate(ROUTES_NAMES.splitOrder);
  };

  return (
    <div className={'flex_1 split-friends-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('cart.split_with')}
      />
      <div className={'flex_1 scrollview'}>
        <div style={{ height: 20 }} />
        {friendsLoading === false && friendItems.length === 0 ? (
          <NoFriendList
            title={t('social.no_snapfooders_from_split_bill')}
            desc={t('social.no_snapfooders_desc_from_split_bill')}
          />
        ) : (
          friendItems.map((item) => (
            <div
              key={item.id}
              className={'flex_between user-item'}
              onClick={() => onSelectFriend(item)}>
              <p>{item.full_name}</p>
              <RadioBtn
                onClick={() => onSelectFriend(item)}
                checked={props.splits.findIndex((i) => i.id === item.id) >= 0}
              />
            </div>
          ))
        )}
        <div style={{ height: 40 }} />
      </div>
      {getSelectedCnt() > 0 && (
        <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20, marginBottom: 35 }}>
          <MainCustomBtn onClick={goSplitOrder}>
            <div className={'flex_between'}>
              <div className={'btn-txt-1'}>
                {getSelectedCnt()} {t('selected')}
              </div>
              <div className={'btn-txt-2'}>{t('proceed')}</div>
            </div>
          </MainCustomBtn>
        </div>
      )}
    </div>
  );
};

SplitFriends.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  payment_info: PropTypes.shape({
    splits: PropTypes.array
  }),
  splits: PropTypes.array,
  setPaymentInfoCart: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  payment_info: shop.payment_info,
  splits: shop.payment_info.splits || []
});

export default connect(mapStateToProps, { setPaymentInfoCart })(SplitFriends);
