import React from 'react';
import PropTypes from 'prop-types';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { seconds2Time } from '../../../utils/common';
import './index.css';

const AudioMsgItem = ({ source, duration }) => {
  return (
    <AudioPlayer
      src={source}
      autoPlayAfterSrcChange={false}
      timeFormat={'mm:ss'}
      defaultCurrentTime={'00:00'}
      defaultDuration={duration == null ? '--:--' : seconds2Time(duration)}
    />
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.audio != null && prevProps.audio.url == nextProps.audio.url;
}

AudioMsgItem.propTypes = {
  source: PropTypes.string,
  duration: PropTypes.number
};
export default React.memo(AudioMsgItem, arePropsEqual);
