import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import { ClockFill } from '@styled-icons/bootstrap';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import { Storage } from '../../../services';

const VendorInfoLoc = ({ data }) => {
  const { t } = useTranslation();
  const getOpeningTime = () => {
    let tm_str = t('vendor_profile.open_at');
    if (data.vendor_opening_days != null) {
      let today = new Date().getDay().toString();
      let foundIndex = data.vendor_opening_days.findIndex((item) => item.week_day === today);

      moment.locale('en');

      if (foundIndex !== -1) {
        tm_str =
          tm_str +
          moment(data.vendor_opening_days[foundIndex].time_open, 'HH:mm:ss').format('H:mm A');
        tm_str =
          tm_str +
          ' - ' +
          t('vendor_profile.close_at') +
          moment(data.vendor_opening_days[foundIndex].time_close, 'HH:mm:ss').format('H:mm A');
      }

      let lang = Storage.getLanguage();
      moment.locale(lang);
    }
    return tm_str;
  };

  return (
    <div className={'align-col-middle vendor-info-loc'}>
      <div className={'w100 align-row-start'}>
        <MapMarkerAlt size={18} color={Theme.colors.text} />
        <p>{data.address}</p>
        <Link
          to={`${ROUTES_NAMES.vendorOnMap}?lat=${data.latitude}&lng=${data.longitude}&title=${
            data.title
          }&distance=${(parseFloat(data.distance) / 1000).toFixed(1)}&logo=${
            data.logo_thumbnail_path
          }&active=${data.is_open}`}>
          {t('vendor_profile.view_on_map')}
        </Link>
      </div>
      <div className={'align-row-start w100 mt12'}>
        <ClockFill size={16} color={Theme.colors.text} />
        <p>{getOpeningTime()}</p>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.vendor_id === nextProps.vendor_id;
}

VendorInfoLoc.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
    vendor_opening_days: PropTypes.array,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    is_open: PropTypes.number,
    distance: PropTypes.number
  })
};

export default React.memo(VendorInfoLoc, arePropsEqual);
