import React from 'react';
import { ChevronRight } from '@styled-icons/evaicons-solid';
import { CheckBoxBtn, RadioBtn } from '../../Buttons';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';

const FilterItem = ({ item, isChecked, onSelect }) => {
  return (
    <div onClick={() => onSelect(item)} className={'flex_between'} style={{ ...styles.listItem }}>
      <div style={styles.item_txt}>{item.name || item.title}</div>
      <div>
        {item.type === 'list' && <ChevronRight size={22} color={Theme.colors.text} />}
        {item.type === 'checkbox' && (
          <CheckBoxBtn onClick={() => onSelect(item)} checked={isChecked} />
        )}
        {item.type === 'radio' && <RadioBtn onClick={() => onSelect(item)} checked={isChecked} />}
      </div>
    </div>
  );
};

FilterItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string
  }),
  isChecked: PropTypes.bool,
  onSelect: PropTypes.func
};

const styles = {
  listItem: {
    height: 54,
    marginBottom: 12,
    borderRadius: 15,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: '#FAFAFC'
  },
  item_txt: { fontSize: 16, fontFamily: Theme.fonts.semiBold, color: Theme.colors.text }
};

export default FilterItem;
