import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import './index.css';

const AuthBtn = ({ isLoading, title, style, onClick, isDisabled }) => {
  return (
    <Button
      disabled={isLoading || isDisabled}
      variant="contained"
      className="app-btn auth-btn"
      style={style}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={24} /> : title}
    </Button>
  );
};

AuthBtn.propTypes = {
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.object,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};
export default React.memo(AuthBtn);
