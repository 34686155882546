import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import FilterItem from '../../../components/Common/FilterItem';
import { setHomeOrdersFilter } from '../../../store/actions/app';
import './index.css';
import PropTypes from 'prop-types';

const OrdersFilterScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [discount, setDiscount] = useState(false);
  const [cashback, setCashback] = useState(false);
  const [promotion, setPromotion] = useState(false);
  const [split, setSplit] = useState(false);

  useEffect(() => {
    setDiscount(props.home_orders_filter.discount);
    setCashback(props.home_orders_filter.cashback);
    setPromotion(props.home_orders_filter.promotion);
    setSplit(props.home_orders_filter.split);
  }, [
    props.home_orders_filter.discount,
    props.home_orders_filter.cashback,
    props.home_orders_filter.promotion,
    props.home_orders_filter.split
  ]);

  const applyFilter = () => {
    props.setHomeOrdersFilter({
      discount,
      cashback,
      promotion,
      split
    });
    navigate(-1);
  };

  return (
    <div className={'orders-filter-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        onRight={() => applyFilter()}
        right={<div className={'apply-btn'}>{t('search.apply')}</div>}
        title={t('search.filter')}
      />
      <div className={'scrollview'}>
        <FilterItem
          item={{
            name: t('filter.discount'),
            type: 'checkbox'
          }}
          isChecked={discount}
          onSelect={() => {
            setDiscount(!discount);
          }}
        />
        <FilterItem
          item={{
            name: t('filter.cashback'),
            type: 'checkbox'
          }}
          isChecked={cashback}
          onSelect={() => {
            setCashback(!cashback);
          }}
        />
        <FilterItem
          item={{
            name: t('filter.promotion'),
            type: 'checkbox'
          }}
          isChecked={promotion}
          onSelect={() => {
            setPromotion(!promotion);
          }}
        />
        <FilterItem
          item={{
            name: t('filter.split_order'),
            type: 'checkbox'
          }}
          isChecked={split}
          onSelect={() => {
            setSplit(!split);
          }}
        />
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

OrdersFilterScreen.propTypes = {
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter
});

export default connect(mapStateToProps, { setHomeOrdersFilter })(OrdersFilterScreen);
