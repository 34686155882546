import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import Header from '../../../components/Header';
import './index.css';
import { Config } from '../../../constants';
import { RoundIconBtn } from '../../../components/Buttons';
import { Theme } from '../../../assets';
import { Share } from '@styled-icons/entypo';
import { useParams } from 'react-router-dom';
import apiFactory from '../../../services/apiFactory';
// import { confirmAlert } from 'react-confirm-alert';
import { RWebShare } from 'react-web-share';

const BlogDetails = () => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const { blog_id } = useParams();

  const [blog, setBlogData] = useState({});

  useEffect(() => {
    if (blog_id != null) {
      getBlogDetails(blog_id);
    }
  }, [blog_id]);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getBlogDetails = async (id) => {
    // props.goActiveScreenFromPush({
    //   isBlogVisible: false
    // });
    const blog = await apiFactory.get(`blogs/${id}`);
    if (blog.data.blog && _isMounted.current === true) {
      setBlogData(blog.data.blog);
    }
  };

  const parseStyles = (styles) => {
    const results = {
      width: window.innerWidth - 40,
      height: 200,
      objectFit: 'contain'
    };
    if (styles) {
      styles.split(';').map((style) => {
        const p = style.replace(' ', '').replace('px', '').split(':');
        if (['height', 'width'].indexOf(p[0]) !== -1) {
          results[p[0]] = parseInt(p[1]);
        }
      });
    }
    const screenWidth = window.innerWidth - 40;
    const diff = results.width / screenWidth;
    results.width = screenWidth;
    results.height = results.height / diff;
    return results;
  };

  const transform = (node) => {
    if (node.type === 'tag' && node.name === 'img') {
      return <img src={node.attribs.src} style={parseStyles(node.attribs.style)} />;
    }
  };

  // const onShare = () => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: 'Snapfood Blog',
  //         text: 'Link for Snapfood',
  //         url: `${Config.WEB_PAGE_URL}blogs/${blog['hash_id']}/${blog['slug']}`
  //       })
  //       .then(() => {})
  //       .catch(() => {
  //         window.location.reload(true);
  //       });
  //   } else {
  //     confirmAlert({
  //       title: t('alerts.error'),
  //       message: t('share_not_supported'),
  //       closeOnEscape: true,
  //       closeOnClickOutside: true,
  //       buttons: [
  //         {
  //           label: t('Ok'),
  //           className: 'error-ok-btn',
  //           onClick: () => {}
  //         }
  //       ]
  //     });
  //   }
  // };

  return (
    <div className={'blog-details-screen'}>
      <div className={'flex_1'}>
        <div
          className={'header-bg'}
          style={{
            backgroundImage: `url('${Config.IMG_BASE_URL}${blog.image_cover}??h=600')`
          }}>
          <Header
            left={
              <RoundIconBtn
                style={{
                  ...styles.headerBtn
                }}
                icon={<ChevronLeft size={22} color={Theme.colors.text} />}
                onClick={() => {}}
              />
            }
            title={'Blog'}
            right={
              <RWebShare
                data={{
                  title: 'Snapfood Blog',
                  text: 'Link for Snapfood',
                  url: `${Config.WEB_PAGE_URL}blogs/${blog['hash_id']}/${blog['slug']}`
                }}
                onClick={() => {}}>
                <RoundIconBtn
                  style={styles.headerBtn}
                  icon={<Share size={20} color={Theme.colors.text} />}
                  onClick={() => {}}
                />
              </RWebShare>
            }
          />
        </div>
        <div className={'flex_1 align-col-middle ph-20 main'}>
          <div className={'blog-body'}>
            <div className={'flex_between mt20'}>
              <div className={'align-row-start tags'}>
                {blog.categories && (
                  <div className={'tag-text'}>{blog.categories.map((x) => x.title).join(', ')}</div>
                )}
              </div>
              <div className={'date-text'}>{blog.created_at}</div>
            </div>
            <div className={'align-row-start title'}>
              <h1>{blog.title}</h1>
            </div>
            <div className={'align-row-start author'}>
              <div>{t('blog.written_by')}</div>
              <div>{blog.author}</div>
            </div>
            <div className={'align-row-start'}>
              <div className={'content'}>{ReactHtmlParser(blog.content, { transform })}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white }
};

export default BlogDetails;
