import React from 'react';
import './index.css';
import { X } from '@styled-icons/feather';
import { Theme } from '../../../assets';
import AppText from '../../AppText';
import PropTypes from 'prop-types';

const StartItem = ({ title, onPress, onRemove }) => {
  return (
    <div className={'align-middle cat'} onClick={onPress}>
      <div className={'cat-title-container'}>
        <AppText style={{ color: Theme.colors.text, fontFamily: Theme.fonts.medium, fontSize: 14 }}>
          {title}
        </AppText>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onRemove(title);
        }}>
        <X size={18} color={Theme.colors.gray5} />
      </div>
    </div>
  );
};

StartItem.propTypes = {
  title: PropTypes.string,
  onPress: PropTypes.func,
  onRemove: PropTypes.func
};

export default StartItem;
