import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import './index.css';

const MainCustomBtn = ({ children, className, style, onClick }) => {
  return (
    <Button
      variant="contained"
      className={`app-btn main-btn ${className}`}
      style={style}
      onClick={onClick}>
      {children}
    </Button>
  );
};

MainCustomBtn.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
export default React.memo(MainCustomBtn);
