import React, { useState } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { useTranslation } from 'react-i18next';
// import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '@styled-icons/bootstrap';
import { Theme } from '../../../assets';
import { setAsSeenOnboard } from '../../../store/actions/auth';
// import AlmostDone from '../almost-done';
// svgs
import Svg_onboard1 from '../../../assets/svgs/auth/1.svg';
import Svg_onboard2 from '../../../assets/svgs/auth/2.svg';
import Svg_onboard3 from '../../../assets/svgs/auth/3.svg';
import Svg_onboard4 from '../../../assets/svgs/auth/4.svg';
import PropTypes from 'prop-types';
// import { ROUTES_NAMES } from '../../../constants';
import './index.css';

const config = [
  {
    id: 1,
    colors: ['#23CBD8', '#07CCFE'],
    title: 'chat_social',
    desc: 'Ndërvepro me miqtë e tu rreth benefiteve të Snapfood. Eksperiencë unike!',
    img: Svg_onboard1
  },
  {
    id: 2,
    colors: ['#F55A00', '#FF6767'],
    title: 'spend_wallet',
    desc: 'Menaxhoni Cashbackun tuaj direkt nga Balanca në Snapfood.',
    img: Svg_onboard2
  },
  {
    id: 3,
    colors: ['#00C22D', '#08F1EA'],
    title: 'save_cashback',
    desc: 'Porosisni sa më shumë dhe përfitoni cashback si kthim për cdo porosi qe ju bëni.',
    img: Svg_onboard3
  },
  {
    id: 4,
    colors: ['#9900FF', '#08F1EA'],
    title: 'split_bill',
    desc: 'Opsioni Ndaj Faturën i Snapfood lejon përdorues të ndryshëm të ndajnë të njëjtën faturën për një porosi.',
    img: Svg_onboard4
  }
];

const OnboardingScreen = (props) => {
  const { t } = useTranslation();

  const [page_step, setStep] = useState(0);

  const _renderHeader = () => {
    return (
      <div
        className={'skip-btn'}
        onClick={(e) => {
          e.preventDefault();
          props.setAsSeenOnboard();
        }}>
        {t('skip')}
      </div>
    );
  };

  const _renderStepper = (step) => {
    return (
      <div className={'align-middle stepper'}>
        {config.map((item, index) => (
          <div key={index} style={item.id === step + 1 ? styles.activeStep : styles.inactiveStep} />
        ))}
      </div>
    );
  };

  const _renderBottomView = (page, step) => {
    return (
      <div key={step} className={'align-col-middle bottom-view'}>
        <div className={'btm-title'}>{t(page.title)}</div>
        <div className={'btm-desc'}> {page.desc}</div>
        {_renderStepper(step)}
        <div className={'align-middle w100'}>
          {step > 0 && (
            <div onClick={() => setStep(step - 1)}>
              <div className={'btm-btn-text'}>{t('previous')}</div>
            </div>
          )}
          <div style={{ flex: 1 }} />
          <div
            className={'align-middle'}
            onClick={() => {
              setStep(step + 1);
            }}>
            {step === config.length - 1 ? (
              <div
                className={'btm-btn-text'}
                style={{ color: Theme.colors.red1, marginRight: 6 }}
                onClick={() => {
                  props.setAsSeenOnboard();
                }}>
                {t('get_started')}
              </div>
            ) : (
              <div className={'btm-btn-text'} style={{ marginRight: 6 }}>
                {t('next')}
              </div>
            )}
            <ArrowRight
              size={18}
              color={step === config.length - 1 ? Theme.colors.red1 : '#000'}
              style={{ marginTop: 3 }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'flex_1 w100 onboarding-screen'}>
      <SwipeableViews
        onChangeIndex={(index) => {
          if (index === config.length) {
            props.setAsSeenOnboard();
          }
        }}
        autoplay={false}
        index={page_step}>
        <div className={'page-slide onboarding-slide-1'}>
          <div className={'align-col-middle image-container'}>
            <img src={config[0].img} />
          </div>
          {_renderBottomView(config[0], 0)}
        </div>
        <div className={'page-slide onboarding-slide-2'}>
          <div className={'align-col-middle image-container'}>
            <img src={config[1].img} />
          </div>
          {_renderBottomView(config[1], 1)}
        </div>
        <div className={'page-slide onboarding-slide-3'}>
          <div className={'align-col-middle image-container'}>
            <img src={config[2].img} />
          </div>
          {_renderBottomView(config[2], 2)}
        </div>
        <div className={'page-slide onboarding-slide-4'}>
          <div className={'align-col-middle image-container'}>
            <img src={config[3].img} />
          </div>
          {_renderBottomView(config[3], 3)}
        </div>
        {/*<AlmostDone {...props} />*/}
      </SwipeableViews>
      {_renderHeader()}
    </div>
  );
};

const styles = {
  inactiveStep: {
    marginRight: 8,
    width: 6,
    height: 3,
    borderRadius: 4,
    backgroundColor: Theme.colors.gray5
  },
  activeStep: {
    marginRight: 8,
    width: 30,
    height: 5,
    borderRadius: 4,
    backgroundColor: Theme.colors.gray5
  }
};

OnboardingScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasLocation: PropTypes.bool,
  seenOnboard: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool,
  setAsSeenOnboard: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    coordinates: app.coordinates,
    isLoggedIn: app.isLoggedIn,
    hasLocation: app.hasLocation,
    seenOnboard: app.seenOnboard,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  setAsSeenOnboard
})(OnboardingScreen);
