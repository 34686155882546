import React from 'react';
import { connect } from 'react-redux';
import { Theme } from '../../../assets';
import { Config } from '../../../constants';
import { StarFill } from '@styled-icons/bootstrap';
import PropTypes from 'prop-types';
import './index.css';

const ShopInfoView = ({ data, is_open }) => {
  return (
    <div className={'align-row-start shopinfo-view'}>
      <img src={Config.IMG_BASE_URL + data.logo_thumbnail_path} className={'vendor-logo'} />
      <div className={'right'}>
        <div className={'align-row-start mt8'}>
          <h1>{data.title}</h1>
          <div className={is_open === 1 ? 'active-badge' : 'inactive-badge'} />
        </div>
        <h3>{data.custom_text}</h3>
        {data.rating_interval != null ? (
          <div className={'align-row-start'}>
            <StarFill size={14} color={Theme.colors.gray5} style={{ marginRight: 6 }} />
            <h3>{(parseFloat(data.rating_interval) / 2).toFixed(1)}</h3>
          </div>
        ) : (
          <div style={{ height: 20 }} />
        )}
      </div>
    </div>
  );
};

ShopInfoView.propTypes = {
  vendor_id: PropTypes.number,
  is_open: PropTypes.number,
  handover_method: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    custom_text: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    rating_interval: PropTypes.string
  })
};
const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language
});
export default connect(mapStateToProps, {})(ShopInfoView);
