import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Theme } from '../../../assets';
import './index.css';
import { ChevronRight } from '@styled-icons/evaicons-solid';

const SplitWithModal = ({ isOpen, onClose, onSplitWithFriends }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal split-with-modal'}>
      <div className={'content'} style={styles.modalContent}>
        <div style={styles.modalTitle}>{t('cart.split_with')}</div>
        <div
          onClick={onSplitWithFriends}
          className={'flex_between'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <div style={styles.modalBtnTxt}>{t('friends')}</div>
          <ChevronRight size={22} color={Theme.colors.text} />
        </div>
        <div style={styles.divider} />
        {/*<div*/}
        {/*  onClick={goSplitOrderNotuser}*/}
        {/*  className={'align-middle'}*/}
        {/*  style={{ width: '100%', height: 50, cursor: 'pointer' }}>*/}
        {/*  <Users size={22} color={Theme.colors.text} />*/}
        {/*  <div style={styles.modalBtnTxt}>{t('social.snapfooders')}</div>*/}
        {/*</div>*/}
      </div>
    </Dialog>
  );
};

const styles = {
  modalContent: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 30,
    paddingTop: 20,
    backgroundColor: Theme.colors.white,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30
  },
  modalTitle: {
    width: '100%',
    textAlign: 'left',
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text,
    marginBottom: 12
  },
  modalBtnTxt: {
    flex: 1,
    marginLeft: 8,
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text
  },
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 }
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
SplitWithModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSplitWithFriends: PropTypes.func
};
export default React.memo(SplitWithModal, arePropsEqual);
