import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { MainBtn, DotBorderButton } from '../../../components/Buttons';
import { LocationInput } from '../../../components/Inputs';
import Header from '../../../components/Header';
import { saveAddress, getAddresses, setTmpLocationPicked } from '../../../store/actions/app';
import { updateProfileDetails } from '../../../store/actions/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import './index.css';
import TextSelector from '../../../components/Dropdowns/TextSelector';
import { CommentInput } from '../../../components/Inputs';
import { ROUTES_NAMES } from '../../../constants';

const NewAddressScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);
  const [loading, setLoading] = useState(false);

  const isEdit = parsed.is_edit === 'true';
  const isFromCart = parsed.from_cart === 'true';

  const [address_text, setAddressText] = useState('');

  useEffect(() => {
    let text = props.tmp_new_address.street || '';
    if (props.tmp_new_address.city != null && props.tmp_new_address.city !== '') {
      text = `${text} ${props.tmp_new_address.city}`;
    }
    if (props.tmp_new_address.country != null && props.tmp_new_address.country !== '') {
      text = `${text}, ${props.tmp_new_address.country}`;
    }
    setAddressText(text);
  }, [props.tmp_new_address.street, props.tmp_new_address.city, props.tmp_new_address.country]);

  const _renderForm = () => {
    return (
      <div className={'w100 align-col-start mt20'}>
        <div style={{ width: '100%', paddingBottom: 20 }}>
          <div className={'subject-title'} style={{ marginBottom: 12 }}>
            {t('address_new.street')}
          </div>
          <LocationInput
            defaultInput={address_text}
            placeholder={t('address_new.search_location')}
            onChange={(loc) => {
              let tmpAddress = {
                coords: {
                  latitude: loc.latitude,
                  longitude: loc.longitude
                },
                street: loc.street,
                building: loc.building,
                country: loc.country,
                city: loc.city
              };
              props.setTmpLocationPicked({
                ...props.tmp_new_address,
                ...tmpAddress
              });
            }}
          />
          <div style={{ height: 12 }} />
          <div className={'subject-title'} style={{ marginBottom: 12 }}>
            {t('address_new.note')}
          </div>
          <CommentInput
            placeholder={t('address_new.note_placeholder')}
            text={props.tmp_new_address.notes}
            onChange={(text) => {
              props.setTmpLocationPicked({
                ...props.tmp_new_address,
                notes: text
              });
            }}
          />
        </div>
        <DotBorderButton
          text={
            (props.tmp_new_address.street || '') !== '' ||
            (props.tmp_new_address.building || '') !== ''
              ? t('address_new.relocate_on_map')
              : t('address_new.find_on_map')
          }
          style={{ width: '100%' }}
          onPress={() => {
            navigate(ROUTES_NAMES.findAddressOnMap);
          }}
        />
      </div>
    );
  };

  const onSaveAddress = async () => {
    if (props.tmp_new_address == null || props.tmp_new_address.coords == null) {
      return console.error(t('attention'), t('add_new_address.please_enter_address'));
    }

    let lat = props.tmp_new_address.coords.latitude || props.coordinates.latitude;
    let lng = props.tmp_new_address.coords.longitude || props.coordinates.longitude;

    let country = props.tmp_new_address.country || props.address.country;
    let city = props.tmp_new_address.city || props.address.city;

    let new_address = {
      id: props.tmp_new_address.id,
      address_type: props.tmp_new_address.address_type || 'Home',
      notes: props.tmp_new_address.notes,
      lat: lat,
      lng: lng,
      country: country,
      city: city,
      street: props.tmp_new_address.street || city,
      building: props.tmp_new_address.building,
      floor: props.tmp_new_address.floor,
      apartment: props.tmp_new_address.apartment,
      favourite: 1
    };

    try {
      setLoading(true);
      await saveAddress(new_address);
      await props.getAddresses();
      setLoading(false);

      if (isFromCart) {
        navigate(-2);
      } else {
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const _renderType = () => {
    return (
      <div className={'align-col-start section-view address-type'}>
        <div className={'flex_between w100'}>
          <div className={'subject-title'}>{t('address_new.address_name')}</div>
          <div className={'hand-over-select'} style={{ width: 155 }}>
            <TextSelector
              style={{ height: 40 }}
              values={['home', 'work', 'custom']}
              value={props.tmp_new_address.address_type || 'home'}
              handleChange={(type) => {
                props.setTmpLocationPicked({
                  ...props.tmp_new_address,
                  address_type: type
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'add-address-screen'}>
      <Header
        style={styles.header}
        title={isEdit ? t('address_edit.header_title') : t('address_new.header_title')}
      />
      <div className={'scrollview'}>
        {_renderType()}
        {_renderForm()}
      </div>
      <div className={'w100 ph-20'} style={{ marginBottom: 40 }}>
        <MainBtn
          disabled={loading}
          loading={loading}
          title={t('address_new.submit')}
          onClick={onSaveAddress}
        />
      </div>
    </div>
  );
};

const styles = {
  header: {
    height: 65,
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: 20,
    paddingRight: 20
  },
  marginB20: { marginBottom: 20 }
};

NewAddressScreen.propTypes = {
  address: PropTypes.shape({
    country: PropTypes.string,
    city: PropTypes.string
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string
  }),
  tmp_new_address: PropTypes.shape({
    address_type: PropTypes.string,
    notes: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    floor: PropTypes.string,
    apartment: PropTypes.string,
    coords: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  }),

  getAddresses: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language,
  tmp_new_address: app.tmp_new_address,
  coordinates: app.coordinates,
  address: app.address
});

export default connect(mapStateToProps, {
  updateProfileDetails,
  getAddresses,
  setTmpLocationPicked
})(NewAddressScreen);
