import React from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import './index.css';

const ReferralsHistItem = ({ data, onClick }) => {
  const { t } = useTranslation();
  const getStatusColor = () => {
    if (data.status === 'used') {
      return '#00C22D';
    }
    return Theme.colors.gray7;
  };
  return (
    <div
      onClick={
        onClick
          ? (e) => {
              e.preventDefault();
              onClick(data);
            }
          : () => {}
      }
      className={'reward-hist-item w100'}>
      <div className={'flex_between'}>
        <div className={'date'}>{data.date}</div>
        {data.status === 'used' && (
          <div className={'invite_code'}>
            <span>Earned {data.earned_amount}L</span>
          </div>
        )}
      </div>
      <div className={'flex_between mt16'}>
        <div className={'invite_code'}>
          {t('Code')} : <span>{data.invite_code}</span>
        </div>
      </div>
      <div className={'flex_between mt16'}>
        {data.status === 'used' ? (
          <div className={'register_user'}>Registered User: {data.registered_user.full_name}</div>
        ) : (
          <div className={'register_user'}>No users registered yet</div>
        )}
        <div className={'status'} style={{ color: getStatusColor() }}>
          {data.status === 'used' ? 'Used' : 'Not Used'}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

ReferralsHistItem.propTypes = {
  id: PropTypes.string,
  data: PropTypes.shape({
    registered_user: PropTypes.shape({
      full_name: PropTypes.string
    }),
    invite_code: PropTypes.string,
    date: PropTypes.string,
    earned_amount: PropTypes.string,
    used_rewards_amount: PropTypes.number,
    used_rewards_rate: PropTypes.number,
    status: PropTypes.string
  }),
  onClick: PropTypes.func
};
export default React.memo(ReferralsHistItem, arePropsEqual);
