import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import FilterItem from '../../../components/Common/FilterItem';
import { setProfileBlogFilter } from '../../../store/actions/app';
import './filter.css';
import PropTypes from 'prop-types';

const BlogFilterScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selected_catid, setCategoryId] = useState(props.profile_blog_filter.category_id);

  const applyFilter = () => {
    props.setProfileBlogFilter({ category_id: selected_catid });
    navigate(-1);
  };

  return (
    <div className={'blog-filter-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        onRight={() => applyFilter()}
        right={<div className={'apply-btn'}>{t('search.apply')}</div>}
        title={t('search.filter')}
      />
      <div className={'scrollview mt20'}>
        <FilterItem
          item={{ name: t('blog.all_topic'), type: 'checkbox' }}
          isChecked={selected_catid == null}
          onSelect={() => {
            setCategoryId(null);
          }}
        />
        {props.blog_categories.map((item, index) => (
          <FilterItem
            key={index}
            item={{ ...item, type: 'checkbox' }}
            isChecked={selected_catid === item.id}
            onSelect={(data) => {
              setCategoryId(data.id);
            }}
          />
        ))}
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

BlogFilterScreen.propTypes = {
  profile_blog_filter: PropTypes.shape({
    category_id: PropTypes.string
  }),
  blog_categories: PropTypes.array,
  setProfileBlogFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  blog_categories: app.blog_categories || [],
  profile_blog_filter: app.profile_blog_filter
});

export default connect(mapStateToProps, {
  setProfileBlogFilter
})(BlogFilterScreen);
