import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CommonTabs } from '../../../../components/TabSelector';
import Header from '../../../../components/Header';
import RewardHistItem from '../../../../components/Profile/RewardHistItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import { useNavigate } from 'react-router-dom';
import './index.css';

const RewardsInvitationsHistory = () => {
  const navigate = useNavigate();
  const _isMounted = useRef(true);

  const { t } = useTranslation();

  const [opType, setOpType] = useState('sent');
  const [receivedItems, setReceivedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);

  const [receivedLoading, setReceivedLoading] = useState(null);
  const [sentLoading, setSentLoading] = useState(null);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setReceivedLoading(true);
    setSentLoading(true);
    let tmp = [];
    let tmp1 = [];
    for (let i = 1; i < 12; i++) {
      tmp.push({
        id: i.toString(),
        receiver: {
          full_name: 'Amber Porter'
        },
        invite_code: '85AGC36',
        invite_time: '10/11/2021 1:30PM',
        remaining_time_to_use: i % 3 === 1 ? 'Ends in 2days' : '',
        used_rewards_amount: i % 4 === 0 ? 20 : 0,
        status: i % 4 === 0 ? 'Used' : i % 3 === 1 ? 'available' : 'Expired'
      });

      tmp1.push({
        id: i.toString(),
        receiver: {
          full_name: 'Kathryn Miller'
        },
        invite_code: '85AGC36',
        invite_time: '10/11/2021 1:30PM',
        remaining_time_to_use: i % 3 === 1 ? 'Ends in 2days' : '',
        used_rewards_amount: i % 4 === 0 ? 20 : 0,
        status: i % 4 === 0 ? 'Used' : i % 3 === 1 ? 'available' : 'Expired'
      });
    }

    setReceivedItems(tmp);
    setSentItems(tmp1);
    setReceivedLoading(false);
    setSentLoading(false);
  }, []);

  return (
    <div className={'flex_1 invitation-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('refer_rewards.invitation_hist')}
      />
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['sent', 'received']}
            item={opType}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => setOpType(item)}
          />
        </div>
      </div>
      <div className={'flex_1 scrollview'}>
        <div className={opType === 'received' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {receivedLoading === false && receivedItems.length === 0 ? (
            <NoFriendList
              title={t('social.no_invitations')}
              desc={t('social.no_received_invitations')}
            />
          ) : (
            receivedItems.map((item) => (
              <RewardHistItem
                key={item.id}
                id={item.id}
                is_received={true}
                data={item}
                onClick={() => {
                  navigate(`/referrals-invite/received`);
                }}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
        <div className={opType === 'sent' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {sentLoading === false && sentItems.length === 0 ? (
            <NoFriendList
              title={t('social.no_invitations')}
              desc={t('social.no_sent_invitations')}
            />
          ) : (
            sentItems.map((item) => (
              <RewardHistItem
                key={item.id}
                id={item.id}
                is_received={false}
                data={item}
                onClick={() => {
                  navigate(`/referrals-invite/sent`);
                }}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn
});

export default connect(mapStateToProps, {})(RewardsInvitationsHistory);
