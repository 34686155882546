import React from 'react';
import ReactTooltip from 'react-tooltip';
import { InfoCircle } from '@styled-icons/boxicons-solid';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './index.css';

const SmallOrderFeeTooltip = ({ delivery_minimum_order_price, small_order_fee }) => {
  const { t } = useTranslation();
  return (
    <div style={{ paddingLeft: 4, paddingBottom: 4 }} className={'small-order-fee'}>
      <a data-tip data-for="SmallOrderFeeTooltip">
        <InfoCircle size={18} color={Theme.colors.gray7} />
      </a>
      <ReactTooltip
        id="SmallOrderFeeTooltip"
        className={'tooltip'}
        aria-haspopup="true"
        backgroundColor="#FFFFFF">
        <p>
          {t('cart.small_order_fee_desc')
            .replace('{0}', delivery_minimum_order_price)
            .replace('{1}', small_order_fee ? small_order_fee : 0)}
        </p>
      </ReactTooltip>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.text === nextProps.text;
}

SmallOrderFeeTooltip.propTypes = {
  delivery_minimum_order_price: PropTypes.number,
  small_order_fee: PropTypes.number
};

export default React.memo(SmallOrderFeeTooltip, arePropsEqual);
