import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const AuthLayout = ({ children }) => {
  return (
    <div className={'auth-layout'}>
      <div className={'main'}>{children}</div>
    </div>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element
};

export default AuthLayout;
