import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import './index.css';
import PropTypes from 'prop-types';
import { Copy } from '@styled-icons/fluentui-system-filled';
import { ShareFill } from '@styled-icons/bootstrap';
import { CommonTabs } from '../../../components/TabSelector';
import Svg_invite from '../../../assets/svgs/profile/invite_earn.svg';
import { MainBtn, MainCustomBtn } from '../../../components/Buttons';
import { Theme } from '../../../assets';
import { ChevronRight } from '@styled-icons/evaicons-solid';
import RedeemDoneModal from '../../../components/Modals/RedeemDone';
import InviteOptionModal from '../../../components/Modals/InviteOption';
import SendingInvitationModal from '../../../components/Modals/SendingInvitation';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../../constants';
import { setPickedUserIdForRewards } from '../../../store/actions/app';
import { CSSTransition } from 'react-transition-group';
import InviteEarnTooltip from '../../../components/Common/InviteEarnTooltip';

const InviteAndEarn = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isInviteModal, showInviteModal] = useState(false);
  // const [isSendInviteModal, showSendInviteModal] = useState(false);
  const [isRedeemDoneModal, showRedeemDoneModal] = useState(false);

  const [isInvited, setInvited] = useState(false);

  const [tab, setTab] = useState(props.rewards_picked_user != null ? 'earn' : 'invite');

  const [referralCode] = useState('2D365V4');

  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const [showCode, setShowCode] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (showCode) setShowCode(false);
      else setShowCode(true);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [showCode]);

  return (
    <div className={'align-start invite-and-earn-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('refer_rewards.title')}
      />
      <div className={'scrollview'}>
        <div className={'align-col-middle'}>
          <img src={Svg_invite} className={'header-img'} />
        </div>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['invite', 'earn']}
            item={tab}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => setTab(item)}
          />
        </div>
        {tab === 'invite' && (
          <div className={'align-col-middle w100 refer-tab'}>
            <h3 className={'flex_1 w100'}>{t('refer_rewards.how_refer_work')}</h3>
            <div className={'align-row-start w100 '} style={{ alignItems: 'flex-start' }}>
              <div className={'index-num'}>1.</div>
              <div className={'flex_1 align-col-start'}>
                <h4 className={'flex_1 w100'}>Share your code</h4>
                <p>
                  Earn 100L on your balance when someone signup using your referral link. Your
                  friends also get 100L on their Balance
                </p>
              </div>
            </div>
            <div className={'align-row-start w100 mt20 mb20'} style={{ alignItems: 'flex-start' }}>
              <div className={'index-num'}>2.</div>
              <div className={'flex_1 align-col-start'}>
                <h4 className={'flex_1 w100'}>Earn Invitation Included</h4>
                <p>
                  When your friend signs up using your referral link, they also get an invitation
                  from you to order at specific hours and earn cashback. Meanwhile, you will earn %
                  as rewards for this order. Click Learn more for more info.
                </p>
              </div>
            </div>
            <InviteEarnTooltip reward_user={'you'} text={t('refer_rewards.learn_more')} />
            <div className={'refer-code-view-wrapper'}>
              {!isCodeCopied && (
                <div className={'refer-code-view flex_between'}>
                  <h2>{referralCode}</h2>
                  <div className={'row_center'}>
                    <div
                      className={'copy-btn btn-style'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(referralCode);
                        setIsCodeCopied(true);
                      }}>
                      <Copy color={Theme.colors.cyan2} size={18} />
                      <span style={{ paddingLeft: 4 }}>{t('refer_rewards.copy')}</span>
                    </div>
                    <div className={'share-btn btn-style'}>
                      <ShareFill color={Theme.colors.cyan2} size={15} />
                      <span style={{ paddingLeft: 4 }}>{t('refer_rewards.share')}</span>
                    </div>
                  </div>
                </div>
              )}
              {isCodeCopied && (
                <CSSTransition in={showCode} timeout={300} classNames="copiedCode" unmountOnExit>
                  <div className={'refer-code-view flex_between'}>
                    <h1 style={{ margin: 'auto' }}>{referralCode}</h1>
                  </div>
                </CSSTransition>
              )}
              {isCodeCopied && (
                <CSSTransition in={!showCode} timeout={300} classNames="copiedAlert" unmountOnExit>
                  <div className={'refer-code-view code-copied-alert flex_between'}>
                    <h3 style={{ margin: 'auto', color: '#23cbd8' }}>Code copied!</h3>
                  </div>
                </CSSTransition>
              )}
            </div>

            <div className={'w100 '} style={{ marginBottom: 35, marginTop: 90 }}>
              <div className={'divider'} />
              <div
                className={'hist_btn flex_between'}
                onClick={() => {
                  navigate(ROUTES_NAMES.referralsHistory);
                }}>
                <h4>Referrals Status</h4>
                <ChevronRight size={22} color={Theme.colors.text} />
              </div>
            </div>
          </div>
        )}
        {tab === 'earn' && (
          <div className={'align-col-middle w100 invite-tab'}>
            <p className={'w100 '}>
              Invite 1 Snapfood User every 1 hour and earn % as reward when he order using your
              invitation code at specific hours. The other user will earn cashback from his order.
            </p>
            <InviteEarnTooltip
              reward_user={'you'}
              text={t('refer_rewards.learn_more')}
              style={{ marginTop: 24 }}
            />
            <div className={'w100 '} style={{ marginTop: 24, marginBottom: 20 }}>
              {isInvited ? (
                <MainCustomBtn className={'invite-again-btn'}>
                  <div className={'align-middle'}>
                    <CircularProgress size={22} variant="determinate" value={70} />
                    <p>Invite again in 20 mins</p>
                  </div>
                </MainCustomBtn>
              ) : (
                <MainBtn
                  title={t('invite')}
                  onClick={(e) => {
                    e.preventDefault();
                    showInviteModal(true);
                  }}
                />
              )}
            </div>
            <div className={'divider'} />
            <div
              className={'hist_btn flex_between'}
              onClick={() => {
                navigate(ROUTES_NAMES.rewardsHistory);
              }}>
              <h4>{t('refer_rewards.invitation_hist')}</h4>
              <ChevronRight size={22} color={Theme.colors.text} />
            </div>
          </div>
        )}
      </div>
      <RedeemDoneModal
        showModal={isRedeemDoneModal}
        onClose={() => {
          showRedeemDoneModal(false);
        }}
      />
      <InviteOptionModal
        isOpen={isInviteModal}
        onClose={() => {
          showInviteModal(false);
        }}
        onFriend={() => {
          showInviteModal(false);
          navigate('/rewards-pickuser/friends');
        }}
        onSnapfooder={() => {
          showInviteModal(false);
          navigate('/rewards-pickuser/snapfooders');
        }}
      />
      <SendingInvitationModal
        text={props.rewards_picked_user}
        showModal={props.rewards_picked_user != null}
        onClose={() => {
          props.setPickedUserIdForRewards(null);
        }}
        onSend={() => {
          props.setPickedUserIdForRewards(null);
          setInvited(true);
        }}
      />
    </div>
  );
};

InviteAndEarn.propTypes = {
  user: {
    full_name: PropTypes.string
  },
  rewards_picked_user: PropTypes.string,
  setPickedUserIdForRewards: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  rewards_picked_user: app.rewards_picked_user
});

export default connect(mapStateToProps, { setPickedUserIdForRewards })(InviteAndEarn);
