import { ACTION_TYPES } from '../../constants';

const initialState = {
  orders: []
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_ORDERS:
      return { orders: action.payload };
    default:
      return state;
  }
};

export default orders;
