import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { Pay_COD, Pay_Card, Pay_Paypal, Pay_Apple } from '../../../constants/config';
import PropTypes from 'prop-types';
import './index.css';

const OrderItem = ({ order_id, data, order_status, style }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const payment_methods = [Pay_COD, Pay_Paypal, Pay_Card, Pay_Apple];

  const _statusColor = (status) => {
    if (status === 'new') {
      return Theme.colors.red1;
    } else if (status === 'processing') {
      return Theme.colors.blue1;
    } else if (status === 'picked_by_rider') {
      return Theme.colors.cyan2;
    } else if (status === 'delivered' || status === 'picked_up' || status === 'reserved') {
      return '#00ff00';
    } else if (status === 'declined') {
      return '#ff0000';
    } else if (status === 'canceled') {
      return Theme.colors.gray7;
    } else {
      return Theme.colors.gray7;
    }
  };

  const _getPaymentMethod = () => {
    if (
      data.payment != null &&
      data.payment.payment_methods_id != null &&
      data.payment.payment_methods_id > 0 &&
      data.payment.payment_methods_id <= payment_methods.length
    ) {
      return t(payment_methods[data.payment.payment_methods_id - 1]);
    }
    return '';
  };

  const onClick = (e) => {
    e.preventDefault();
    navigate(`/order/${order_id}`);
  };

  return (
    <div onClick={onClick} className={'align-col-middle order-item'} style={style}>
      <div className={'w100 align-middle'}>
        <div className={'vendor-title'}>{data.vendor == null ? '' : data.vendor.title}</div>
        <div style={{ flex: 1 }} />
      </div>
      <div className={'payment_method'}>{_getPaymentMethod()}</div>
      <div className={'date'}>{data.ordered_date.split(' ')[0]}</div>
      <div className={'align-middle w100 mt6'}>
        <div className={'price'}>{parseInt(data.total_price)} L</div>
        <div style={{ flex: 1 }} />
        <div className={'status'} style={{ color: _statusColor(order_status) }}>
          {t('order.' + order_status)}
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.order_id === nextProps.order_id && prevProps.order_status === nextProps.order_status
  );
}

OrderItem.propTypes = {
  order_id: PropTypes.number,
  order_status: PropTypes.string,
  data: PropTypes.shape({
    vendor: PropTypes.shape({
      title: PropTypes.string
    }),
    ordered_date: PropTypes.string,
    total_price: PropTypes.string,
    status: PropTypes.string,
    payment: PropTypes.shape({
      payment_methods_id: PropTypes.number
    })
  }),
  style: PropTypes.object,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  onClick: PropTypes.func
};
export default React.memo(OrderItem, arePropsEqual);
