import React from 'react';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
// svgs
import Svg_allcohol from '../../../assets/svgs/food_cats/allcohol.svg';
import Svg_bio from '../../../assets/svgs/food_cats/bio.svg';
import Svg_chinese from '../../../assets/svgs/food_cats/chinese.svg';
import Svg_crepe from '../../../assets/svgs/food_cats/crepe.svg';
import Svg_dessert from '../../../assets/svgs/food_cats/dessert.svg';
import Svg_drugs from '../../../assets/svgs/food_cats/drugs.svg';
import Svg_fastfood from '../../../assets/svgs/food_cats/fastfood.svg';
import Svg_grocery from '../../../assets/svgs/food_cats/grocery.svg';
import Svg_japanese from '../../../assets/svgs/food_cats/japanese.svg';
import Svg_juice from '../../../assets/svgs/food_cats/juice.svg';
import Svg_market from '../../../assets/svgs/food_cats/market.svg';
import Svg_meat from '../../../assets/svgs/food_cats/meat.svg';
import Svg_mediterranean from '../../../assets/svgs/food_cats/mediterranean.svg';
import Svg_mexican from '../../../assets/svgs/food_cats/mexican.svg';
import Svg_smoothie from '../../../assets/svgs/food_cats/smoothie.svg';
import Svg_soup from '../../../assets/svgs/food_cats/soup.svg';
import Svg_pizza from '../../../assets/svgs/food_cats/pizza.svg';
import Svg_breakfast from '../../../assets/svgs/food_cats/breakfast.svg';
import Svg_sushi from '../../../assets/svgs/food_cats/sushi.svg';
import Svg_burger from '../../../assets/svgs/food_cats/burger.svg';
import Svg_vegan from '../../../assets/svgs/food_cats/vegan.svg';
import Svg_winery from '../../../assets/svgs/food_cats/winery.svg';

const btnWidth = 59;
const btnHeight = 92;
const CategItem = ({ isSelected, category, onSelect }) => {
  return (
    <div
      onClick={() => onSelect(category)}
      style={{
        ...styles.container2,
        ...(isSelected ? { backgroundColor: Theme.colors.cyan2 } : {})
      }}>
      <div className={'align-middle'} style={styles.imgView}>
        {category.icon === 'glass-3' ? (
          <img src={Svg_allcohol} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'pizza' ? (
          <img src={Svg_pizza} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'broccoli' ? (
          <img src={Svg_bio} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'noodles' ? (
          <img src={Svg_chinese} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'kebab' ? (
          <img src={Svg_crepe} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'cupcake' ? (
          <img src={Svg_dessert} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'tea' ? (
          <img src={Svg_drugs} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'kebab' ? (
          <img src={Svg_fastfood} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'apple' ? (
          <img src={Svg_grocery} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'sushi-2' ? (
          <img src={Svg_japanese} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'frappe' ? (
          <img src={Svg_juice} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'groceries' ? (
          <img src={Svg_market} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'steak' ? (
          <img src={Svg_meat} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'spaguetti' ? (
          <img src={Svg_mediterranean} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'taco' ? (
          <img src={Svg_mexican} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'coffee-4' ? (
          <img src={Svg_smoothie} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'coffee' ? (
          <img src={Svg_soup} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'sushi-1' ? (
          <img src={Svg_sushi} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'hamburguer-1' ? (
          <img src={Svg_burger} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'carrot' ? (
          <img src={Svg_vegan} style={{ width: 25, height: 25 }} />
        ) : category.icon === 'glass-4' ? (
          <img src={Svg_winery} style={{ width: 25, height: 25 }} />
        ) : (
          <img src={Svg_breakfast} style={{ width: 25, height: 25 }} />
        )}
      </div>
      <div
        className={'numline-22'}
        style={{ ...styles.text, ...(isSelected ? { color: Theme.colors.white } : {}) }}>
        {category.title_sq}
      </div>
      <div style={styles.indicator} />
    </div>
  );
};

const styles = {
  imgView: { width: 45, height: 45, borderRadius: 24, backgroundColor: Theme.colors.white },
  text: {
    fontSize: 12,
    textAlign: 'center',
    color: Theme.colors.gray7,
    fontFamily: Theme.fonts.semiBold,
    width: '100%'
  },
  indicator: { width: 7, height: 7, borderRadius: 3.5, backgroundColor: Theme.colors.gray6 },
  container2: {
    width: btnWidth,
    height: btnHeight,
    alignItems: 'center',
    margin: '0px 5px',
    borderRadius: '50px',
    backgroundColor: Theme.colors.gray6,
    padding: 8,
    marginRight: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
};

// function arePropsEqual(prevProps, nextProps) {
//     if (prevProps.isSelected != nextProps.isSelected ||
//         prevProps.cat_id != nextProps.cat_id
//     ) {
//         return false;
//     }
//     return true;
// }

CategItem.propTypes = {
  category: PropTypes.object,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func
};
// CategItem.displayName = 'CategoryItem';

export default CategItem;
