import React from 'react';
import AppText from './AppText';
import { Theme } from '../assets';
import PropTypes from 'prop-types';

const AppBadge = ({ value }) => {
  return (
    <div className="align-col-middle" style={styles.container}>
      <AppText style={styles.txt}>{value}</AppText>
    </div>
  );
};

const styles = {
  container: {
    minWidth: 19,
    height: 19,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 10,
    backgroundColor: Theme.colors.cyan2,
    position: 'absolute',
    top: -5,
    right: -4
  },
  txt: {
    fontSize: 8,
    fontfamily: Theme.fonts.medium,
    color: Theme.colors.white
  }
};
AppBadge.propTypes = {
  value: PropTypes.number
};

export default AppBadge;
