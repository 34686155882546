import React from 'react';
import { useTranslation } from 'react-i18next';
import SmallOrderFeeTooltip from '../../Common/SmallOrderFeeTooltip';
import './index.css';
import PropTypes from 'prop-types';

const VendorInfo = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={'align-col-middle vendor-info-item'} style={{ alignItems: 'flex-start' }}>
      <div className={'title'}>
        {t('vendor_profile.offers_method')} {data.order_method}
      </div>
      <div className={'w100 align-row-start flex-wrap'}>
        <div className={'desc'}>
          {t('vendor_profile.delivery_fee')} : {data.delivery_fee ? data.delivery_fee : 0} L
        </div>
        <div className={'desc'}>
          {t('vendor_profile.delivery_time')} :{'   '}
          {data.minimum_delivery_time ? data.minimum_delivery_time : 0} {t('vendor_profile.mins')}
        </div>
        <div className={'desc'}>
          {t('vendor_profile.min_order')} :{'   '}
          {data.delivery_minimum_order_price ? data.delivery_minimum_order_price : 0} L
        </div>
        {data.small_order_fee != null && data.small_order_fee !== 0 && (
          <div className={'align-row-start'}>
            <div className={'desc'}>{t('vendor_profile.small_order_fee')}</div>
            <SmallOrderFeeTooltip
              delivery_minimum_order_price={data.delivery_minimum_order_price}
              small_order_fee={data.small_order_fee}
            />
            <div className={'desc'}>
              : {'  '} {data.small_order_fee} L
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.vendor_id === nextProps.vendor_id;
}

VendorInfo.propTypes = {
  data: PropTypes.shape({
    order_method: PropTypes.string,
    delivery_fee: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number
  })
};

export default React.memo(VendorInfo, arePropsEqual);
