import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../assets';
import { setUserData, setAllChatChannels } from '../../store/actions/app';
import NoChatList from '../../components/Empty/NoChatList';
import DropdownMenus from '../../components/Dropdowns';
import ChatItem from '../../components/Chats/ChatItem';
import { SearchInput } from '../../components/Inputs';
import { Search } from '@styled-icons/evil';
import NewConvModal from '../../components/Modals/NewConv';
import { ApiFactory } from '../../services';
import { setTmpSnapfoodMapData } from '../../store/actions/app';
// svgs
import Svg_newmsg from '../../assets/svgs/chats/btn_new_chat.svg';
import Svg_more from '../../assets/svgs/chats/btn_more.svg';
import './index.css';
import { ROUTES_NAMES } from '../../constants';
import PropTypes from 'prop-types';
// import { confirmAlert } from 'react-confirm-alert';
import { channel_collection } from '../../services/chat';

const ChatScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const _isMounted = useRef(true);
  const chatchannel_listener = useRef(null);

  const [state, setState] = useState({
    new_invites: [],
    searchTerm: ''
  });
  const [isLoadingChat, setLoadingChat] = useState(null);
  const [display_channels, setDisplayChannels] = useState(props.chat_channels);

  const [isNewConvModal, openConvModal] = React.useState(false);

  useEffect(() => {
    getNewInvites();
    if (props.user.id != null) {
      getChatChannelsListner(props.user.id);
    }
  }, [props.user.id]);

  const getChatChannelsListner = (user_id) => {
    if (chatchannel_listener.current != null) {
      chatchannel_listener.current();
    }
    setLoadingChat(true);
    chatchannel_listener.current = channel_collection
      .where('users', 'array-contains', user_id)
      .orderBy('last_msg.createdAt', 'desc')
      .onSnapshot(
        (snapshots) => {
          const tmp_channels = [];
          snapshots.forEach((doc) => {
            tmp_channels.push(doc.data());
          });
          setLoadingChat(false);
          setDisplayChannels(tmp_channels);
          props.setAllChatChannels(tmp_channels);
        },
        () => {
          setLoadingChat(false);
        }
      );
  };

  const getNewInvites = () => {
    ApiFactory.get(`users/invitations?seen=0`).then(
      ({ data }) => {
        const res_invitations = data['invitations'];
        if (_isMounted.current === true) {
          setState({ ...state, new_invites: res_invitations });
        }
      },
      () => {}
    );
  };

  const onChangeSearch = async (search) => {
    setState({ ...state, searchTerm: search });
    search = search.toLowerCase();
    const user_id = props.user.id;
    let filtered_channels = [];
    props.chat_channels.map((channel) => {
      if (channel.channel_type === 'single' && channel.partner != null && channel.creator != null) {
        if (
          user_id === channel.creator.id &&
          channel.partner.full_name != null &&
          channel.partner.full_name.toLowerCase().includes(search)
        ) {
          filtered_channels.push(channel);
        } else if (
          user_id === channel.partner.id &&
          channel.creator.full_name != null &&
          channel.creator.full_name.toLowerCase().includes(search)
        ) {
          filtered_channels.push(channel);
        }
      } else {
        if (channel.full_name != null && channel.full_name.toLowerCase().includes(search)) {
          filtered_channels.push(channel);
        }
      }
    });

    setDisplayChannels(filtered_channels);
  };

  const _renderSearchbar = () => {
    return (
      <div className={'w100 align-middle search-cntainer'}>
        <SearchInput
          placeholder={t('social.search.chat')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45, marginRight: 12 }}
          value={state.searchTerm}
          onChange={onChangeSearch}
        />
        <div style={styles.spaceRow} />
        <div
          onClick={() => {
            openConvModal(true);
          }}>
          <img src={Svg_newmsg} />
        </div>
        <div style={styles.spaceRow} />
        <DropdownMenus
          onClick={(index) => {
            if (index === 0) {
              navigate(ROUTES_NAMES.invitations);
            } else if (index === 1) {
              navigate(ROUTES_NAMES.myFriends);
            } else if (index === 2) {
              props.setTmpSnapfoodMapData({
                latitude: null,
                longitude: null,
                zoom: null
              });
              navigate(ROUTES_NAMES.snapfoodMap);
            }
          }}
          button={
            <div style={styles.moreContainer}>
              <img src={Svg_more} />
              {state.new_invites.length > 0 && <div style={styles.moreAlert} />}
            </div>
          }
          menuItems={[
            // eslint-disable-next-line react/jsx-key
            <div
              className={'flex_between w100'}
              style={{
                paddingTop: 6,
                paddingBottom: 6,
                alignItems: 'center',
                borderColor: '#F6F6F9',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                flexDirection: 'row'
              }}>
              <div className={'flex_1'} style={styles.popupText}>
                {t('social.invitation')}
              </div>
              {state.new_invites.length > 0 && (
                <div
                  style={{
                    color: '#F55A00',
                    fontSize: 10,
                    marginLeft: 5,
                    fontFamily: Theme.fonts.medium
                  }}>
                  ({state.new_invites.length} new)
                </div>
              )}
            </div>,
            // eslint-disable-next-line react/jsx-key
            <div
              style={{
                paddingTop: 6,
                paddingBottom: 6,
                borderColor: '#F6F6F9',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                flexDirection: 'row',
                width: '100%'
              }}>
              <div style={styles.popupText}>{t('social.my_friends')}</div>
            </div>,
            // eslint-disable-next-line react/jsx-key
            <div style={{ paddingTop: 6, paddingBottom: 6, flexDirection: 'row' }}>
              <div style={styles.popupText}>{t('social.snapfood_map')}</div>
            </div>
          ]}
        />
      </div>
    );
  };

  return (
    <div className={'flex_1 align-col-middle chat-screen'}>
      {_renderSearchbar()}
      <div className={'scrollview'}>
        {isLoadingChat == false && display_channels.length === 0 ? (
          <NoChatList />
        ) : (
          display_channels.map((item) => (
            <ChatItem key={item.id} item={item} channel_id={item.id} user_id={props.user.id} />
          ))
        )}
      </div>

      <NewConvModal
        isOpen={isNewConvModal}
        onClose={() => {
          openConvModal(false);
        }}
        onSingleChat={() => {
          navigate(ROUTES_NAMES.newChat);
        }}
        onGroupChat={() => {
          navigate(ROUTES_NAMES.newGroup);
        }}
      />
    </div>
  );
};

const styles = {
  moreContainer: {
    width: 45,
    height: 45,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#E9E9F7',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  moreAlert: {
    width: 10,
    height: 10,
    backgroundColor: '#F55A00',
    position: 'absolute',
    top: -5,
    right: -5,
    borderRadius: 10
  },
  spaceRow: {
    width: 15
  },
  spaceCol: {
    height: 15
  },
  popupText: {
    color: Theme.colors.text,
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold
  }
};

ChatScreen.propTypes = {
  user: {
    id: PropTypes.number
  },
  chat_channels: PropTypes.array,
  setUserData: PropTypes.func,
  setAllChatChannels: PropTypes.func,
  setTmpSnapfoodMapData: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  language: app.language,
  user: app.user,
  chat_channels: app.chat_channels
});

export default connect(mapStateToProps, { setUserData, setAllChatChannels, setTmpSnapfoodMapData })(
  ChatScreen
);
