import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../constants';
import PhoneVerificationScreen from '../../views/tour/verify-phone';
import EditPhoneScreen from '../../views/tour/edit-phone';
import PropTypes from 'prop-types';

const PhoneVerifyRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route exact path={'/'} element={<PhoneVerificationScreen />} />
        <Route exact path={ROUTES_NAMES.editPhone} element={<EditPhoneScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </React.Fragment>
  );
};

PhoneVerifyRoutes.propTypes = {
  isAppLoaded: PropTypes.bool
};
export default PhoneVerifyRoutes;
