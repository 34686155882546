import React from 'react';
import PropTypes from 'prop-types';
import { useTextWidth } from '@tag0/use-text-width';
import Svg_loc from '../../../assets/svgs/markers/ic_locpin.svg';
import { Config } from '../../../constants';
import './index.css';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import { Theme } from '../../../assets';

const VendorMarker = ({ title, logo_thumbnail_path, distance, isOpen }) => {
  const width = useTextWidth({ text: title, font: '18px Yellix-Bold' });
  const isPopup = true;

  return (
    <div className={'map-marker vendor-marker'}>
      <div className={'marker-body'}>
        <div className={['popup', !isPopup && 'hidden'].join(' ')}>
          <div className={'align-middle vendor-info'}>
            <img src={Config.IMG_BASE_URL + logo_thumbnail_path} alt={''} />
            <div className={'flex_1 pl-10'}>
              <div className={'align-row-start'}>
                <p style={{ width: width + 8 }}>{title}</p>
                <div
                  className={`indicator`}
                  style={{ backgroundColor: isOpen ? '#0f0' : '#f00' }}
                />
              </div>
              <div className={'align-row-start rate-item'}>
                <MapMarkerAlt size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
                <span>{distance} Km</span>
              </div>
            </div>
          </div>
          <div className={'anchor'} />
        </div>
        <img
          src={Svg_loc}
          onClick={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.title === nextProps.title &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.logo_thumbnail_path === nextProps.logo_thumbnail_path &&
    prevProps.distance === nextProps.distance
  );
}

VendorMarker.propTypes = {
  title: PropTypes.string,
  logo_thumbnail_path: PropTypes.string,
  distance: PropTypes.number,
  isOpen: PropTypes.number
};
export default React.memo(VendorMarker, arePropsEqual);
