import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { changePassword } from '../../../store/actions/auth';
import { setTmpPassChanged } from '../../../store/actions/app';
import Header from '../../../components/Header';
import { AuthBtn } from '../../../components/Buttons';
import { AuthInput } from '../../../components/Inputs';
import PropTypes from 'prop-types';
import './change-pass.css';
import { confirmAlert } from 'react-confirm-alert';
import { extractErrorMessage } from '../../../utils/common';

const ChangePasswordScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [curPass, setCurPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');

  const validatePassword = (curPass, password, passwordConfirmation) => {
    return new Promise((resolve, reject) => {
      if (!curPass || !password || !passwordConfirmation) {
        reject(t('alerts.fill_all_fields'));
      } else if (!password || password !== passwordConfirmation) {
        reject(t('alerts.password_mismatch'));
      } else if (curPass === password) {
        reject(t('cannot_use_old_password'));
      } else {
        resolve();
      }
    });
  };

  const onChangePass = () => {
    validatePassword(curPass, newPass, confirmPass)
      .then(async () => {
        try {
          setLoading(true);
          props
            .changePassword(curPass, newPass)
            .then(() => {
              setLoading(false);
              props.setTmpPassChanged(true);
              navigate(-1);
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
            });
        } catch (e) {
          setLoading(false);
          console.log(e);
          confirmAlert({
            title: t('restaurant_details.we_are_sorry'),
            message: extractErrorMessage(e),
            closeOnEscape: true,
            closeOnClickOutside: true,
            buttons: [
              {
                label: t('Ok'),
                className: 'error-ok-btn',
                onClick: () => {}
              }
            ]
          });
        }
      })
      .catch((msg) => {
        confirmAlert({
          title: t('attention'),
          message: msg,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      });
  };

  return (
    <div className={'change-pass-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        onLeft={() => {
          props.setTmpPassChanged(false);
          navigate(-1);
        }}
        title={t('account_change_pass.header_title')}
      />
      <div className={'scrollview'}>
        <AuthInput
          placeholder={t('account_change_pass.cur_password')}
          onChange={(val) => setCurPass(val)}
          text={curPass}
          isSecure={true}
          style={{ marginBottom: 20 }}
        />
        <AuthInput
          placeholder={t('account_change_pass.new_password')}
          onChange={(val) => setNewPass(val)}
          text={newPass}
          isSecure={true}
          style={{ marginBottom: 20 }}
        />
        <AuthInput
          placeholder={t('account_change_pass.re_type_password')}
          onChange={(val) => setConfirmPass(val)}
          text={confirmPass}
          isSecure={true}
          style={{ marginBottom: 20 }}
        />
      </div>

      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20, marginBottom: 40 }}>
        <AuthBtn
          isLoading={loading}
          title={t('account_change_pass.header_title')}
          onClick={() => {
            onChangePass();
          }}
        />
      </div>
    </div>
  );
};

ChangePasswordScreen.propTypes = {
  setTmpPassChanged: PropTypes.func,
  changePassword: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    language: app.language
  };
}

export default connect(mapStateToProps, {
  setTmpPassChanged,
  changePassword
})(ChangePasswordScreen);
