import React from 'react';
import './index.css';
import PropTypes from 'prop-types';

const ReserveItem = ({ full_name, phone, style }) => {
  return (
    <div className={'reserve-item'} style={style}>
      <h2>{full_name}</h2>
      <p>{phone}</p>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.full_name === nextProps.full_name && prevProps.phone === nextProps.phone;
}

ReserveItem.propTypes = {
  full_name: PropTypes.string,
  phone: PropTypes.string,
  style: PropTypes.object
};

export default React.memo(ReserveItem, arePropsEqual);
