import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import {
  setPaymentInfoCart,
  setDeliveryInfoCart,
  clearCart,
  sendOrder
} from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { Config, ROUTES_NAMES } from '../../../constants';
import apiFactory from '../../../services/apiFactory';
import {
  Pay_COD,
  // Pay_Paypal,
  // Pay_Apple,
  OrderType_Delivery,
  OrderType_Reserve
} from '../../../constants/config';
import { AuthBtn, DotBorderButton } from '../../../components/Buttons';
import PayMethodItem from '../../../components/Cart/PayMethodItem';
// import CardPayMethodItem from '../../../components/Cart/CardPayMethodItem';
import PriceBoard from '../../../components/Cart/PriceBoard';
import SplitWithModal from '../../../components/Modals/SplitWith';
import Header from '../../../components/Header';
import OrderFailedModal from '../../../components/Modals/OrderFailed';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty } from '../../../utils/common';

const CartPaymentScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stripe = useStripe();
  const [applePaymentRequest, setApplePaymentRequest] = useState(null);
  const paypal_dropin_instance = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isSplitModal, openSplitModal] = useState(false);
  // const [setCards] = useState([]);
  // const [cards, setCards] = useState([]);
  const [isOrderFailedModal, showOrderFailedModal] = useState(false);
  const [OrderFailedMessage, setOrderFailedMessage] = useState(t('cart.order_failed'));
  const [paypal_client_token, setPaypalClientToken] = useState(null);

  useEffect(() => {
    loadPaymentMethods();
  }, [props.user.default_card_id]);

  useEffect(() => {
    loadPaypalClientToken();
  }, []);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'IT',
        currency: 'all',
        total: {
          label: t('cart.total'),
          amount: calculateOrderTotal()
        }
      });
      // Check the availability of the Payment Request API first.
      pr.canMakePayment().then((result) => {
        if (result) {
          if (result.applePay === true) {
            pr.on('paymentmethod', handlePaymentMethodReceived);
            setApplePaymentRequest(pr);
          }
        }
      });
    }
  }, [stripe]);

  const setDefaultCard = (card_list) => {
    let found_index = card_list.findIndex((card) => card.id === props.user.default_card_id);
    if (found_index === -1) {
      props.setPaymentInfoCart({
        ...props.payment_info,
        selected_card: card_list.length > 0 ? card_list[0] : null
      });
    } else {
      props.setPaymentInfoCart({
        ...props.payment_info,
        selected_card: card_list[found_index]
      });
    }
  };

  const loadPaymentMethods = () => {
    apiFactory.get(`stripe/payment-methods`).then(
      ({ data }) => {
        let loadedCards = data || [];
        // setCards(loadedCards);
        setDefaultCard(loadedCards);
      },
      () => {}
    );
  };

  const loadPaypalClientToken = () => {
    apiFactory.post(`checkout/get-paypal-client-token`).then(
      ({ data }) => {
        setPaypalClientToken(data.client_token);
      },
      () => {}
    );
  };

  const calculateOrderTotal = () => {
    const { subtotal, discount, cashback, small_order_fee, delivery_fee } = props.cartPrice;

    let total = subtotal - cashback - discount;
    if (props.delivery_info.handover_method === OrderType_Delivery) {
      total = total + small_order_fee;
      total = total + delivery_fee;

      // if (props.order_data.vendorData != null && props.order_data.vendorData.delivery_type == "Snapfood" && props.delivery_info.tip_rider > 0) {
      //     total = total + parseInt(props.delivery_info.tip_rider);
      // }
    }
    return total;
  };

  const orderBilled = () => {
    let splits = props.payment_info.splits || [];
    if (splits.length === 0) {
      return false;
    }

    let isBilled = true;
    splits.map((item) => {
      if (item.price == null) {
        isBilled = false;
      }
    });
    return isBilled;
  };

  const _renderPaymentMethod = () => {
    return (
      <div className={'align-col-start section-view'}>
        <div className={'subject-title w100'} style={{ marginBottom: 16 }}>
          {t('cart.payment_method')}
        </div>
        <PayMethodItem
          title={t(Pay_COD)}
          checked={props.payment_info.method === 'cash'}
          onClick={() => {
            props.setPaymentInfoCart({
              ...props.payment_info,
              method: 'cash',
              splits: []
            });
          }}
        />
        {/*{props.order_data.vendorData != null && props.order_data.vendorData.online_payment === 1 && (*/}
        {/*  <React.Fragment>*/}
        {/*    <CardPayMethodItem*/}
        {/*      checked={props.payment_info.method === 'stripe'}*/}
        {/*      cards={cards}*/}
        {/*      curCard={props.payment_info.selected_card}*/}
        {/*      onClick={() => {*/}
        {/*        props.setPaymentInfoCart({*/}
        {/*          ...props.payment_info,*/}
        {/*          method: 'stripe'*/}
        {/*        });*/}
        {/*      }}*/}
        {/*      onPressCard={(card) => {*/}
        {/*        props.setPaymentInfoCart({*/}
        {/*          ...props.payment_info,*/}
        {/*          selected_card: card*/}
        {/*        });*/}
        {/*      }}*/}
        {/*      onAddCard={() => {*/}
        {/*        navigate(ROUTES_NAMES.addCard);*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    {!isEmpty(paypal_client_token) && (*/}
        {/*      <PayMethodItem*/}
        {/*        title={t(Pay_Paypal)}*/}
        {/*        checked={props.payment_info.method === 'paypal'}*/}
        {/*        onClick={() => {*/}
        {/*          props.setPaymentInfoCart({*/}
        {/*            ...props.payment_info,*/}
        {/*            method: 'paypal'*/}
        {/*          });*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    {applePaymentRequest != null && (*/}
        {/*      <PayMethodItem*/}
        {/*        title={t(Pay_Apple)}*/}
        {/*        checked={props.payment_info.method === 'apple'}*/}
        {/*        onClick={() => {*/}
        {/*          props.setPaymentInfoCart({*/}
        {/*            ...props.payment_info,*/}
        {/*            method: 'apple'*/}
        {/*          });*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </React.Fragment>*/}
        {/*)}*/}
      </div>
    );
  };

  const _renderOrderTotal = () => {
    return (
      <div className={'align-col-start w100'}>
        <div className={'align-col-start section-view'}>
          <PriceBoard
            minOrderPrice={props.cartPrice.min_order_price}
            small_order_fee={props.cartPrice.small_order_fee}
            delivery_fee={props.cartPrice.delivery_fee}
            cashback={props.cartPrice.cashback}
            discount={props.cartPrice.discount}
            sub_total={props.cartPrice.subtotal}
            total_price={calculateOrderTotal()}
            handover_method={props.delivery_info.handover_method}
          />
        </div>
        {props.payment_info.method !== 'cash' && (
          <DotBorderButton
            text={t('cart.split_bill')}
            style={{ width: '100%', marginTop: 16 }}
            onPress={() => {
              openSplitModal(true);
            }}
          />
        )}
      </div>
    );
  };

  const _renderOrderBill = () => {
    const isEqual = () => {
      let equal = true;
      let tmpPrice = props.payment_info.splits[0].price;
      props.payment_info.splits.map((item) => {
        if (tmpPrice != item.price) {
          equal = false;
        }
      });
      return equal;
    };
    return (
      <div className={'align-col-middle w100'}>
        <div className={'align-col-start section-view'}>
          <div
            className={'align-middle w100 '}
            style={{ justifyContent: 'flex-start', marginBottom: 8 }}>
            <div className={'subject-title'}>Bill Split</div>
            <div className={'among-text pl-10'}>
              ({t('cart.among')} {props.payment_info.splits.length} {t('cart.people')})
            </div>
          </div>
          {isEqual() ? (
            <div style={{ width: '100%' }}>
              <div className={'w100 flex_between mt8 mb8'}>
                <div className={'key-text'}>{t('you')}</div>
                <div className={'value-text'}>
                  {parseInt(props.payment_info.splits[0].price) + ' L'}
                </div>
              </div>
              <div className={'w100 flex_between mt8 mb8'}>
                <div className={'key-text'}>
                  {t('each_friend') + ' (x' + (props.payment_info.splits.length - 1) + ')'}
                </div>
                <div className={'value-text'}>
                  {parseInt(props.payment_info.splits[0].price) + ' L'}
                </div>
              </div>
            </div>
          ) : (
            props.payment_info.splits.map((item, index) => (
              <div key={index} className={'w100 flex_between mt8 mb8'}>
                <div className={'key-text'}>
                  {item.id === props.user.id ? t('you') : item.full_name}
                </div>
                <div className={'value-text'}>{parseInt(item.price) + ' L'}</div>
              </div>
            ))
          )}
        </div>
        <div className={'align-col-start section-view'} style={{ borderBottomWidth: 0 }}>
          <PriceBoard
            minOrderPrice={props.cartPrice.min_order_price}
            small_order_fee={props.cartPrice.small_order_fee}
            delivery_fee={props.cartPrice.delivery_fee}
            cashback={props.cartPrice.cashback}
            discount={props.cartPrice.discount}
            sub_total={props.cartPrice.subtotal}
            total_price={calculateOrderTotal()}
            handover_method={props.delivery_info.handover_method}
          />
        </div>
      </div>
    );
  };

  const doPay = async () => {
    const { method } = props.payment_info;
    if (method === 'paypal') {
      if (paypal_dropin_instance.current != null) {
        try {
          const { nonce } = await paypal_dropin_instance.current.requestPaymentMethod();
          finalizeCheckout(nonce);
        } catch (error) {
          if (error.message != null) {
            confirmAlert({
              title: t('alerts.error'),
              message: error.message,
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          } else {
            confirmAlert({
              title: t('alerts.error'),
              message: t('checkout.something_is_wrong'),
              closeOnEscape: true,
              closeOnClickOutside: true,
              buttons: [
                {
                  label: t('Ok'),
                  className: 'error-ok-btn',
                  onClick: () => {}
                }
              ]
            });
          }
        }
      }
    } else if (method === 'apple') {
      // doApplepay();
      // show apple pay modal
      if (applePaymentRequest) {
        applePaymentRequest.show();
      }
    } else {
      finalizeCheckout();
    }
  };

  const getOrderData = (paypal_nonce) => {
    // show loading
    const { items, vendorData, cutlery, coupon, comments } = props.order_data;
    let cartProducts = items.filter((i) => i.vendor_id == vendorData.id);
    const products = cartProducts.map((item) => {
      return {
        id: item.id,
        qty: item.quantity,
        options: item.options && item.options.length > 0 ? item.options.map((x) => x.id) : [],
        item_instructions: item.comments
      };
    });

    const {
      address,
      handover_method,
      contactless_delivery,
      // tip_rider,
      pickup_date,
      pickup_time,
      num_guests,
      reserve_for,
      is_schedule,
      schedule_time
    } = props.delivery_info;
    const { cashback } = props.cartPrice;
    const { method, selected_card, splits } = props.payment_info;

    let orderData = {
      vendor_id: vendorData.id,
      products,
      order_note: comments,
      has_cutlery: cutlery > 0 ? 1 : 0,
      cutlery: cutlery,
      source: Config.PLATFORM,
      coupon_code: coupon.code,
      repeated: 0,
      handover_method: handover_method === 'Pickup at store' ? 'Pickup' : handover_method,
      delivery_instruction: props.delivery_info.comments,
      cashback: cashback,
      payment_method: method,
      contactless_delivery: contactless_delivery ? 1 : 0,
      is_schedule: is_schedule === 1 ? 1 : 0,
      schedule_time: is_schedule === 1 ? schedule_time : null
    };

    if (handover_method === OrderType_Reserve) {
      orderData.reserve_for = reserve_for.id;
      orderData.num_guests = parseInt(num_guests);
    }

    if (handover_method !== OrderType_Delivery) {
      orderData.pickup_date = pickup_date;
      orderData.pickup_time = pickup_time;
    } else {
      orderData.address_id = address.id;

      // if (vendorData != null && vendorData.delivery_type == "Snapfood" && tip_rider > 0) {
      //     orderData.tip_rider = parseInt(tip_rider);
      // }
      // else {
      //     orderData.tip_rider = 0;
      // }
      orderData.tip_rider = 0;
    }

    if (method === 'stripe') {
      if (selected_card == null) {
        confirmAlert({
          title: t('warning'),
          message: t('cart.select_card'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
        return null;
      } else {
        orderData.payment_method_id = selected_card.id;
      }
    }

    if (method === 'paypal') {
      orderData.paypal_nonce = paypal_nonce;
    }

    if (orderBilled() === true) {
      orderData.splits = splits.map((item) => {
        return {
          person_id: item.id,
          amount: item.price,
          person_name: item.full_name
        };
      });
    }

    return orderData;
  };

  const finalizeCheckout = async (paypal_nonce) => {
    let orderData = getOrderData(paypal_nonce);
    if (orderData == null) {
      return;
    }

    setLoading(true);
    sendOrder(orderData).then(
      (order) => {
        props.clearCart([]);
        setLoading(false);
        navigate(`/order/${order.id}?is_new=true`, { replace: true });
      },
      (error) => {
        setLoading(false);
        setOrderFailedMessage(error.message ? error.message : t('cart.order_failed'));
        showOrderFailedModal(true);
      }
    );
  };

  const handlePaymentMethodReceived = async (event) => {
    // const paymentDetails = {
    //   payment_method: event.paymentMethod.id,
    //   shipping: {
    //     name: event.shippingAddress.recipient,
    //     phone: event.shippingAddress.phone,
    //     address: {
    //       line1: event.shippingAddress.addressLine[0],
    //       city: event.shippingAddress.city,
    //       postal_code: event.shippingAddress.postalCode,
    //       state: event.shippingAddress.region,
    //       country: event.shippingAddress.country
    //     }
    //   }
    // };

    let orderData = getOrderData();

    setLoading(true);
    apiFactory
      .post('checkout/get-applepay-client-secret', orderData)
      .then(async ({ data }) => {
        const { error, paymentIntent } = await stripe.confirmCardPayment(data.client_secret, {
          payment_method: event.paymentMethod.id
        });

        setLoading(false);
        if (error) {
          return;
        }
        if (paymentIntent.status === 'succeeded') {
          finalizeCheckout();
        } else {
          console.warn(`Unexpected status: ${paymentIntent.status} for ${paymentIntent}`);
        }
      })
      .catch((error) => {
        setLoading(false);
        setOrderFailedMessage(error.message ? error.message : t('cart.order_failed'));
        showOrderFailedModal(true);
      });
  };

  return (
    <div className={'cart-payment-screen'}>
      <Header
        style={{ marginBottom: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('cart.title')}
      />
      <div className={'scrollview'}>
        {_renderPaymentMethod()}
        {orderBilled() ? _renderOrderBill() : _renderOrderTotal()}
        <div style={{ height: 20 }} />
      </div>
      <div style={{ width: '100%', padding: 20, paddingBottom: 35 }}>
        {props.payment_info.method === 'apple' && applePaymentRequest && (
          <PaymentRequestButtonElement options={{ applePaymentRequest }} />
        )}
        {props.payment_info.method === 'paypal' && !isEmpty(paypal_client_token) && (
          <DropIn
            options={{
              authorization: paypal_client_token,
              paypal: {
                flow: 'checkout',
                amount: calculateOrderTotal(),
                currency: 'ALL'
              },
              card: true
            }}
            onInstance={(instance) => (paypal_dropin_instance.current = instance)}
          />
        )}
        <AuthBtn
          isLoading={loading}
          title={props.payment_info.splits.length > 0 ? t('cart.confirm_order') : t('proceed')}
          onClick={doPay}
        />
      </div>
      <SplitWithModal
        isOpen={isSplitModal}
        onClose={() => {
          openSplitModal(false);
        }}
        onSplitWithFriends={() => {
          openSplitModal(false);
          navigate(ROUTES_NAMES.splitFriends);
        }}
      />
      <OrderFailedModal
        showModal={isOrderFailedModal}
        title={OrderFailedMessage}
        onClose={() => {
          showOrderFailedModal(false);
        }}
        onTry={() => {
          showOrderFailedModal(false);
          doPay();
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  order_data: shop,
  delivery_info: shop.delivery_info,
  payment_info: shop.payment_info,
  cartPrice: shop.cartPrice
});

CartPaymentScreen.propTypes = {
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string,
      lat: PropTypes.string,
      lng: PropTypes.string
    }),
    tip_rider: PropTypes.number,
    num_guests: PropTypes.number,
    contactless_delivery: PropTypes.bool,
    pickup_date: PropTypes.string,
    pickup_time: PropTypes.string,
    reserve_for: PropTypes.shape({
      id: PropTypes.number
    }),
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),

  user: PropTypes.shape({
    id: PropTypes.number,
    default_card_id: PropTypes.string
  }),
  order_data: PropTypes.shape({
    items: PropTypes.array,

    cutlery: PropTypes.number,
    coupon: PropTypes.object,
    comments: PropTypes.string,

    vendorData: PropTypes.shape({
      id: PropTypes.number,
      delivery_type: PropTypes.string,
      online_payment: PropTypes.number
    })
  }),
  payment_info: PropTypes.shape({
    splits: PropTypes.array,
    method: PropTypes.string,
    selected_card: PropTypes.object
  }),
  cartPrice: PropTypes.shape({
    min_order_price: PropTypes.number,
    small_order_fee: PropTypes.number,
    subtotal: PropTypes.number,
    cashback: PropTypes.number,
    discount: PropTypes.number,
    delivery_fee: PropTypes.number
  }),
  clearCart: PropTypes.func,
  setPaymentInfoCart: PropTypes.func
};
export default connect(mapStateToProps, {
  setPaymentInfoCart,
  sendOrder,
  setDeliveryInfoCart,
  clearCart
})(CartPaymentScreen);
