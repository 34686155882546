import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Eye, EyeSlashFill } from '@styled-icons/bootstrap';
import './index.css';
import { Theme } from '../../assets';

const AuthInput = ({
  type,
  name,
  text,
  setRef,
  placeholder,
  editable,
  isSecure,
  style,
  onChange
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <div style={style} className="input auth-input">
      <div className={'flex_1'}>
        <input
          ref={setRef}
          name={name}
          value={text}
          disabled={editable === false}
          placeholder={placeholder}
          type={isSecure === true && visible === false ? 'password' : type ? type : 'text'}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
      {isSecure && (
        <IconButton
          className={'eye'}
          onClick={() => {
            setVisible(!visible);
          }}>
          {visible ? (
            <EyeSlashFill size={22} color={Theme.colors.red1} />
          ) : (
            <Eye size={22} color={Theme.colors.gray5} />
          )}
        </IconButton>
      )}
    </div>
  );
};

AuthInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  isSecure: PropTypes.bool,
  editable: PropTypes.bool,
  style: PropTypes.object,
  onChange: PropTypes.func,
  setRef: PropTypes.object
};
export default React.memo(AuthInput);
