import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppText from '../../AppText';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';

const btnWidth = 100;
const btnHeight = 40;
const Switch = ({ cur_item, items, onSelect }) => {
  const { t } = useTranslation();
  const [curItem, setCurItem] = useState(cur_item == null ? items[0] : cur_item);
  useEffect(() => {
    setCurItem(cur_item == null ? items[0] : cur_item);
  }, [cur_item]);

  return (
    <div className="row_center" style={styles.container}>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setCurItem(item);
            onSelect(item);
          }}
          className="row_center"
          style={item === curItem ? styles.activeBtn : styles.inactiveBtn}>
          <AppText style={item === curItem ? styles.activeTxt : styles.inactiveTxt}>
            {t(item)}
          </AppText>
        </div>
      ))}
    </div>
  );
};

const styles = {
  container: {
    height: btnHeight,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Theme.colors.gray6,
    borderStyle: 'solid'
  },
  activeBtn: {
    flex: 1,
    width: btnWidth,
    height: btnHeight - 2,
    borderRadius: 12,
    backgroundColor: Theme.colors.cyan2
  },
  activeTxt: { fontSize: 16, color: Theme.colors.white, fontFamily: Theme.fonts.semiBold },
  inactiveBtn: { flex: 1, width: btnWidth, backgroundColor: Theme.colors.white },
  inactiveTxt: { fontSize: 16, color: Theme.colors.text, fontFamily: Theme.fonts.semiBold }
};

function arePropsEqual(prevProps, nextProps) {
  let isEqual = true;
  if (
    prevProps.items.length != nextProps.items.length ||
    nextProps.items.filter((x) => prevProps.items.indexOf(x) === -1).length > 0
  ) {
    isEqual = false;
  }
  if (prevProps.cur_item != nextProps.cur_item) {
    isEqual = false;
  }
  return isEqual;
}

Switch.propTypes = {
  cur_item: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func
};

export default React.memo(Switch, arePropsEqual);
