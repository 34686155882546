import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { ChevronLeft } from '@styled-icons/material';
import { Share } from '@styled-icons/entypo';
import { ShoppingBag } from '@styled-icons/material-rounded';
import { Heart } from '@styled-icons/foundation';
import LoadingOverlay from 'react-loading-overlay';
import { setTmpFood } from '../../store/actions/app';
import { useNavigate, useParams } from 'react-router-dom';
import { RWebShare } from 'react-web-share';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../assets';
import { Config, ROUTES_NAMES } from '../../constants';
import { appMoment } from '../../localisations';
import { RoundIconBtn } from '../../components/Buttons';
import CartViewBtn from '../../components/VendorMenu/CartViewBtn';
import OrderMethodTab from '../../components/TabSelector/OrderMethodTab';
import PastOrderView from '../../components/VendorMenu/PastOrderView';
import VendorInfoLoc from '../../components/VendorMenu/VendorInfoLoc';
import VendorInfo from '../../components/VendorMenu/VendorInfo';
import ShopInfoView from '../../components/VendorMenu/ShopInfoView';
import FeatureInfoView from '../../components/VendorMenu/FeatureInfoView';
import VendorFoodList from '../../components/VendorMenu/VedorFoodList';
import HScrollMenu from '../../components/VendorMenu/HScrollMenu';
import { setVendorCart, getDiscount, resetCart } from '../../store/actions/shop';
import './index.css';
import PropTypes from 'prop-types';
import FoodItem from '../../components/Vendor/FoodItem';
import VendorClosedModal from '../../components/Modals/VendorClosed';
import { CommonTabs } from '../../components/TabSelector';
import { OrderType_Delivery } from '../../constants/config';
import { getVendorDetail, toggleFavourite } from '../../store/actions/vendors';
import { getPastOrders } from '../../store/actions/orders';
import { isEmpty } from '../../utils/common';
import BlockSpinner from '../../components/BlockSpinner';
// import { confirmAlert } from 'react-confirm-alert';

const getClosedVendorModalTitle = (vendorData, language) => {
  if (isEmpty(vendorData.custom_closed_info) === false) {
    return vendorData.custom_closed_info;
  }
  if (vendorData.vendor_opening_days != null) {
    let day_index = vendorData.vendor_opening_days.findIndex(
      (i) => i.week_day === new Date().getDay().toString()
    );
    if (day_index !== -1) {
      if (vendorData.vendor_opening_days[day_index].time_open != null) {
        let open_time = appMoment(
          vendorData.vendor_opening_days[day_index].time_open,
          'HH:mm:ss'
        ).format('h:mm A');
        if (language === 'en') {
          return `This vendor is currently closed and will open at ${open_time}. You can still see their menu.`;
        } else {
          return `Ky shitës aktualisht është i mbyllur dhe do të hapet në ${open_time}. Ende mund të shihni menunë e tyre.`;
        }
      }
    }
  }
  if (language === 'en') {
    return 'This vendor is currently closed. You can still see their menu.';
  } else {
    return 'Ky shitës aktualisht është i mbyllur. Ende mund të shihni menunë e tyre.';
  }
};

const HEADER_HEIGHT = 80;
const VendorScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { vendor_id, order_method } = useParams();

  const _mounted = useRef(true);

  const tabs = ['Menu', 'Offers', 'Info'];
  const [curTab, setCurTab] = useState('Menu');
  const currentTabRef = useRef('Menu');

  const categoriesRef = useRef(null);

  const [isLoading, showLoading] = useState(false);
  const [isClosedVendorModal, showClosedVendorModal] = useState(false);
  const [goCartLoading, showGoCartLoading] = useState(false);
  const [cartVendorLoaded, setCartVendorLoaded] = useState(false);

  const [horizontalScrollMenuIndex, setHorizontalScrollMenuIndex] = useState(0);

  const [past_orders, setPastOrders] = useState([]);
  const [latest_offers, setLatestOffers] = useState([]);
  const [all_offers, setAllOffers] = useState([]);

  const [order_methods, setOrderMethods] = useState([]);
  const [handover_method, setHandoverMethod] = useState(null);

  const isPrevShowHeader = useRef(false);
  const [isShowHeaderTitle, setShowHeaderTitle] = useState(false);

  const isPrevStickyApplied = useRef(false);
  const [isStickyApplied, setStickyApplied] = useState(false);

  const operationTab = useRef(null);
  const categoryView = useRef(null);

  const operationTabOffset = useRef(null);
  const categoryViewOffset = useRef(null);

  const closedVendorTitle = useMemo(
    () => getClosedVendorModalTitle(props.vendorData, props.language),
    [props.vendorData, props.language]
  );

  useEffect(() => {
    if (operationTab.current !== null) {
      operationTabOffset.current = operationTab.current.offsetTop;
    }
    if (categoryView.current !== null) {
      categoryViewOffset.current = categoryView.current.offsetTop;
    }

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY >= 200 && isPrevShowHeader.current === false) {
        setShowHeaderTitle(true);
        isPrevShowHeader.current = true;
      }
      if (currentScrollY < 200 && isPrevShowHeader.current === true) {
        setShowHeaderTitle(false);
        isPrevShowHeader.current = false;
      }

      let offsetTop = operationTabOffset.current;
      if (currentTabRef.current === 'Menu') {
        offsetTop = categoryViewOffset.current;
      }

      if (currentScrollY >= offsetTop - HEADER_HEIGHT && isPrevStickyApplied.current === false) {
        setStickyApplied(true);
        isPrevStickyApplied.current = true;
      }
      if (currentScrollY < offsetTop - HEADER_HEIGHT && isPrevStickyApplied.current === true) {
        setStickyApplied(false);
        isPrevStickyApplied.current = false;
      }

      if (categoriesRef.current != null && categoriesRef.current.length > 0) {
        let found_index = 0;
        categoriesRef.current.map((cat, index) => {
          if (document.getElementById(`vendor-food-category-${index}`) != null) {
            let category_offset = document.getElementById(
              `vendor-food-category-${index}`
            ).offsetTop;

            if (currentScrollY > category_offset - 200) {
              found_index = index;
            }
          }
        });
        setHorizontalScrollMenuIndex(found_index);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setHorizontalScrollMenuIndex(0);

    loadOffer(vendor_id);
    loadPastOrders(vendor_id);
    if (
      props.vendorData == null ||
      props.vendorData.id == null ||
      props.vendorData.id != vendor_id ||
      props.vendorData.categories == null
    ) {
      loadData();
    } else {
      if (order_method != null) {
        let supported_order_methods = order_method.split('-');
        if (supported_order_methods.length > 0) {
          setOrderMethods(supported_order_methods);
          setHandoverMethod(supported_order_methods[0]);
          loadVendorDetails(
            vendor_id,
            supported_order_methods[0],
            cartVendorLoaded === false,
            true
          );
        }
      }
      categoriesRef.current = props.vendorData.categories;
    }
    return () => {
      _mounted.current = false;
    };
  }, [vendor_id]);

  const loadData = () => {
    _mounted.current = true;

    if (order_method != null) {
      let supported_order_methods = order_method.split('-');
      if (supported_order_methods.length > 0) {
        setOrderMethods(supported_order_methods);
        setHandoverMethod(supported_order_methods[0]);
        loadVendorDetails(vendor_id, supported_order_methods[0], cartVendorLoaded === false);
      }
    }
  };

  useEffect(() => {
    onProductFavChange(props.tmpFoodData);
  }, [props.tmpFoodData.isFav]);

  const loadVendorDetails = async (id, order_method, showClosedModal, isHideLoading) => {
    if (id == null) {
      return;
    }
    let { latitude, longitude } = props.coordinates;

    if (isHideLoading !== true) {
      showLoading(true);
    }

    getVendorDetail(id, latitude, longitude, order_method)
      .then((data) => {
        categoriesRef.current = data.categories;
        props.setVendorCart(data);
        showLoading(false);

        setTimeout(() => {
          if (showClosedModal === true) {
            showClosedVendorModal(data.is_open !== 1);
          }
        }, 200);
      })
      .catch(() => {
        showLoading(false);
      });
  };

  const loadPastOrders = (vendor_id) => {
    if (props.isLoggedIn !== true) {
      return;
    }
    getPastOrders(vendor_id)
      .then((data) => {
        if (_mounted.current === true) {
          setPastOrders(data);
        }
      })
      .catch(() => {});
  };

  const loadOffer = (vendor_id) => {
    if (props.isLoggedIn !== true) {
      return;
    }
    getDiscount(vendor_id, 'time')
      .then((res) => {
        if (_mounted.current) {
          setLatestOffers(res);
        }
      })
      .catch(() => {});

    getDiscount(vendor_id)
      .then((res) => {
        if (_mounted.current) {
          setAllOffers(res);
        }
      })
      .catch(() => {});
  };

  const onPressFav = () => {
    toggleFavourite(vendor_id, props.vendorData.isFav === 1 ? 0 : 1)
      .then(() => {
        if (_mounted.current) {
          props.setVendorCart({ ...props.vendorData, isFav: props.vendorData.isFav === 1 ? 0 : 1 });
        }
      })
      .catch(() => {});
  };

  const goFoodDetail = (food_data) => {
    props.setTmpFood(food_data);
    navigate(`/food/${props.vendorData.id}/${food_data.id}`);
  };

  const goCart = () => {
    if (props.isLoggedIn === false) {
      navigate(ROUTES_NAMES.welcome);
      return;
    }
    if (props.cartItems != null && props.cartItems.length > 0) {
      if (vendor_id === props.cartItems[0].vendor_id) {
        props.resetCart();
        navigate(ROUTES_NAMES.cart);
      } else {
        window.history.replaceState(
          {},
          '',
          `/vendor/${props.cartItems[0].vendor_id}/${OrderType_Delivery}`
        );
        props.resetCart();
        navigate(ROUTES_NAMES.cart);

        showGoCartLoading(false);
        setCartVendorLoaded(true);

        // showGoCartLoading(true);
        // let { latitude, longitude } = props.coordinates;
        // getVendorDetail(props.cartItems[0].vendor_id, latitude, longitude)
        //   .then((data) => {
        //     showGoCartLoading(false);
        //     if (_mounted.current === true) {
        //       setCartVendorLoaded(true);
        //       console.info(`/vendor/${vendor_id}/${data.order_method}`);
        //       window.history.replaceState({}, '', `/vendor/${vendor_id}/${data.order_method}`);
        //       props.resetCart();
        //       props.setVendorCart(data);
        //       navigate(ROUTES_NAMES.cart);
        //     }
        //   })
        //   .catch(() => {
        //     showGoCartLoading(false);
        //   });
      }
    }
  };

  // const onShare = async () => {
  //   if (navigator.share) {
  //     try {
  //       await navigator.share({
  //         title: 'Snapfood Vendor',
  //         text: 'Link for Snapfood',
  //         url: `https://snapfood.al/restaurant/${props.vendorData['hash_id']}/${props.vendorData['slug']}`
  //       });
  //     } catch (err) {
  //       // console.warn(err.name, err.message);
  //       window.location.reload(true);
  //     }
  //   } else {
  //     confirmAlert({
  //       title: t('alerts.error'),
  //       message: t('share_not_supported'),
  //       closeOnEscape: true,
  //       closeOnClickOutside: true,
  //       buttons: [
  //         {
  //           label: t('Ok'),
  //           className: 'error-ok-btn',
  //           onClick: () => {}
  //         }
  //       ]
  //     });
  //   }
  // };

  const onProductFavChange = (data) => {
    const { categories } = props.vendorData;
    if (categories && categories.length && categories.length > 0) {
      let tmp = categories.slice(0, categories.length);
      let cat_index = tmp.findIndex((i) => i.id === data.category_id);
      if (cat_index !== -1) {
        if (
          tmp[cat_index].products &&
          tmp[cat_index].products.length &&
          tmp[cat_index].products.length > 0
        ) {
          let product_index = tmp[cat_index].products.findIndex((i) => i.id === data.id);
          if (product_index !== -1) {
            tmp[cat_index].products[product_index].isFav = data.isFav;

            props.setVendorCart({ ...props.vendorData, categories: tmp });
          }
        }
      }
    }
  };

  const _renderHeader = () => {
    return (
      <div
        className={'align-middle header'}
        style={{ backgroundColor: isShowHeaderTitle ? '#fff' : 'transparent' }}>
        <RoundIconBtn
          style={{
            ...styles.headerBtn,
            borderWidth: isShowHeaderTitle ? 0 : 1,
            marginLeft: isShowHeaderTitle ? -8 : 0
          }}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        {isShowHeaderTitle && (
          <div className={'align-row-start'}>
            <RoundIconBtn
              style={styles.topLogoView}
              icon={
                <img
                  src={Config.IMG_BASE_URL + props.vendorData.logo_thumbnail_path}
                  style={styles.topLogo}
                />
              }
              onClick={() => {}}
            />
            <div className={'vendor-title'}>{props.vendorData.title}</div>
          </div>
        )}
        <div style={{ flex: 1 }} />
        <div className={'align-middle'} style={{ alignItems: 'flex-end' }}>
          <RWebShare
            data={{
              title: 'Snapfood Vendor',
              text: 'Link for Snapfood',
              url: `https://snapfood.al/restaurant/${props.vendorData['hash_id']}/${props.vendorData['slug']}`
            }}
            onClick={() => {}}>
            <RoundIconBtn
              style={styles.headerBtn}
              icon={<Share size={20} color={Theme.colors.text} />}
              onClick={() => {}}
            />
          </RWebShare>
          <div
            style={{
              width: 33,
              height: 48,
              marginLeft: 10,
              justifyContent: 'flex-end',
              display: 'flex',
              alignItems: 'flex-end',
              position: 'relative'
            }}>
            <RoundIconBtn
              diabled={props.cartItems != null && props.cartItems.length === 0}
              style={styles.headerBtn}
              icon={<ShoppingBag size={20} color={Theme.colors.text} />}
              onClick={goCart}
            />
            {props.cartItems != null && props.cartItems.length > 0 && (
              <div style={styles.cartBadge} />
            )}
          </div>
          {props.isLoggedIn && (
            <RoundIconBtn
              style={{ ...styles.headerBtn, marginLeft: 10 }}
              icon={
                <Heart
                  size={24}
                  color={props.vendorData.isFav === 1 ? Theme.colors.cyan2 : Theme.colors.gray5}
                />
              }
              onClick={() => onPressFav()}
            />
          )}
        </div>
      </div>
    );
  };

  const _renderOperationTabs = () => {
    return (
      <div className={'align-middle operation-tab '}>
        <CommonTabs
          items={tabs}
          item={curTab}
          style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
          onChange={(item) => {
            setCurTab(item);
            currentTabRef.current = item;
          }}
        />
      </div>
    );
  };

  const _renderHandover = () => {
    return (
      <div className={'align-col-middle ph-20 handover-view'}>
        <OrderMethodTab
          items={order_methods}
          curitem={handover_method}
          onSelect={(item) => {
            setHandoverMethod(item);

            // temporarily comment this
            // loadVendorDetails(vendor_id, item, false);
          }}
        />
      </div>
    );
  };

  const renderCategoriesMenu = () => {
    return (
      <div
        ref={categoryView}
        className={[
          'align-col-middle categ-list bg-white',
          isStickyApplied ? 'sticky-header ph-20' : ''
        ].join(' ')}>
        <HScrollMenu
          selectedItem={horizontalScrollMenuIndex}
          onItemSelected={(index) => {
            let stickyHeaderOffset = operationTabOffset.current;
            if (currentTabRef.current === 'Menu') {
              stickyHeaderOffset = categoryViewOffset.current;
            }
            let category_offset = document.getElementById(
              `vendor-food-category-${index}`
            ).offsetTop;
            if (category_offset > stickyHeaderOffset && isStickyApplied === false) {
              window.scrollTo(0, category_offset - 280);
            } else {
              window.scrollTo(0, category_offset - 160);
            }
          }}
          items={props.vendorData.categories || []}
        />
      </div>
    );
  };

  const getCartCnt = (food_data) => {
    let foundIndex = props.cartItems.findIndex((i) => i.id === food_data.id);
    if (foundIndex !== -1) {
      return props.cartItems[foundIndex].quantity;
    }
    return 0;
  };

  const _renderOffers = (title, list) => {
    return (
      <div className={'align-col-start offers-view'}>
        <div className={'subject-title'}>{title}</div>
        {list.length == null || list.length === 0 ? (
          <div className={'no-offer'}>
            {title === t('vendor_profile.all_offers')
              ? t('vendor_profile.no_all_offer')
              : t('vendor_profile.no_latest_offer')}
            {props.vendorData.title}
          </div>
        ) : (
          list.map((item) => (
            <FoodItem
              key={item.id}
              type="offer"
              cartEnabled={true}
              id={item.id}
              isFav={item.isFav}
              // disabled={false}
              cartCnt={getCartCnt(item)}
              data={item}
              onFavChange={onProductFavChange}
              onClick={() => {
                goFoodDetail(item);
              }}
            />
          ))
        )}
      </div>
    );
  };

  return (
    <div className={'w100 flex_1 vendor-menu-screen'}>
      {/*<Spinner visible={goCartLoading} />*/}
      <LoadingOverlay active={goCartLoading} />
      <div
        className={'vendor-bg overlay-dark'}
        style={{
          backgroundImage: `url('${Config.IMG_BASE_URL}${props.vendorData.profile_path}?w=600&h=600')`
        }}>
        <ShopInfoView
          data={props.vendorData}
          vendor_id={props.vendorData.id}
          is_open={props.vendorData.is_open}
        />
      </div>
      <div className={'w100 ph-20'}>
        <FeatureInfoView data={props.vendorData} handover_method={handover_method} />
      </div>
      {props.vendorData.order_method !== OrderType_Delivery &&
        order_methods.length > 1 &&
        _renderHandover()}
      <div
        ref={operationTab}
        className={[
          'align-col-middle w100 ph-20 bg-white',
          isStickyApplied ? 'sticky-header' : ''
        ].join(' ')}>
        {_renderOperationTabs()}
      </div>

      {!isLoading && curTab === 'Menu' && (
        <div className={'align-col-start w100 ph-20 bg-white'}>
          {props.isLoggedIn && !isLoading && past_orders.length > 0 && (
            <PastOrderView vendor_id={vendor_id} />
          )}
          {!isLoading && renderCategoriesMenu()}
          {!isLoading && <VendorFoodList vendor_id={vendor_id} />}
        </div>
      )}
      {!isLoading && curTab === 'Offers' && (
        <div className={'align-col-start w100 ph-20 bg-white'}>
          {_renderOffers(t('vendor_profile.latest_offers'), latest_offers || [])}
          {_renderOffers(t('vendor_profile.all_offers'), all_offers || [])}
        </div>
      )}
      {!isLoading && curTab === 'Info' && (
        <div className={'align-col-start w100 ph-20 bg-white mt20'}>
          <VendorInfoLoc data={props.vendorData} vendor_id={vendor_id} style={{ marginTop: 12 }} />
          <VendorInfo data={props.vendorData} vendor_id={vendor_id} />
        </div>
      )}
      {isLoading && <BlockSpinner style={{ minHeight: 120 }} />}
      {props.cartItems.filter((i) => i.vendor_id === props.vendorData.id).length > 0 && (
        <div style={{ height: 80 }} />
      )}
      {props.cartItems.filter((i) => i.vendor_id === props.vendorData.id).length > 0 && (
        <CartViewBtn
          onClick={() => {
            if (props.isLoggedIn) {
              props.resetCart();
              navigate(ROUTES_NAMES.cart);
            } else {
              navigate(ROUTES_NAMES.welcome);
            }
          }}
          diabled={props.vendorData.is_open !== 1}
          cartItems={props.cartItems}
          style={styles.cartview}
        />
      )}
      <VendorClosedModal
        showModal={isClosedVendorModal}
        title={closedVendorTitle}
        goHome={() => {
          showClosedVendorModal(false);
          navigate(ROUTES_NAMES.home);
        }}
        onClose={() => showClosedVendorModal(false)}
      />
      {_renderHeader()}
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogoView: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogo: { width: 26, height: 26 },
  cartview: { position: 'fixed', bottom: 40, left: 0 },
  cartBadge: {
    position: 'absolute',
    top: 11,
    right: -3,
    width: 11,
    height: 11,
    borderRadius: 5.5,
    backgroundColor: Theme.colors.red1
  }
};

const mapStateToProps = ({ app, shop }) => ({
  language: app.language,
  coordinates: app.coordinates,
  tmpFoodData: app.tmpFoodData,
  cartItems: shop.items,
  vendorData: shop.vendorData,
  isLoggedIn: app.isLoggedIn
});

VendorScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  language: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    is_open: PropTypes.number,
    order_method: PropTypes.string,
    categories: PropTypes.array,
    hash_id: PropTypes.string,
    slug: PropTypes.string
  }),
  tmpFoodData: PropTypes.shape({
    isFav: PropTypes.number
  }),
  setTmpFood: PropTypes.func,
  setVendorCart: PropTypes.func,
  resetCart: PropTypes.func
};

export default connect(mapStateToProps, {
  setVendorCart,
  setTmpFood,
  resetCart
})(VendorScreen);
