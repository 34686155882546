import React from 'react';
import { useTranslation } from 'react-i18next';
import Svg_img from '../../../assets/images/search.png';
import './index.css';
import PropTypes from 'prop-types';

const NoSearchRestaurants = ({ searchTerm, style }) => {
  const { t } = useTranslation();
  return (
    <div className={'align-col-middle no_restaurant_view'} style={style}>
      <img src={Svg_img} style={{ width: 40, height: 40, objectFit: 'contain' }} />
      <div className={'description'} style={{ marginTop: 30 }}>
        {t('search.not_found_part_one')}
      </div>
      <div className={'description'} style={{ marginBottom: 4 }}>
        {"'" + searchTerm + "'"}
      </div>
      <div className={'description'} style={{ marginBottom: 4 }}>
        {t('search.not_found_part_two')}
      </div>
    </div>
  );
};

NoSearchRestaurants.propTypes = {
  searchTerm: PropTypes.string,
  style: PropTypes.object
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.searchTerm === nextProps.searchTerm;
}
export default React.memo(NoSearchRestaurants, arePropsEqual);
