import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import './index.css';

const MainBtn = ({ title, disabled, loading, className, style, onClick }) => {
  return (
    <Button
      disabled={disabled}
      variant="contained"
      className={`app-btn main-btn ${className}`}
      style={style}
      onClick={onClick}>
      {loading ? <CircularProgress size={24} /> : title}
    </Button>
  );
};

MainBtn.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};
export default React.memo(MainBtn);
