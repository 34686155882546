import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Swipeout from 'rc-swipeout';
import { useTranslation } from 'react-i18next';
import { Heart } from '@styled-icons/foundation';
import { Config } from '../../../constants';
import { toggleProductFavourite } from '../../../store/actions/vendors';
import { removeProductFromCart } from '../../../store/actions/shop';
import './index.css';
import { Theme } from '../../../assets';
import { isEmpty } from '../../../utils/common';

const FoodItem = (props) => {
  const { id, data, isFav, cartCnt, type, onClick, onFavChange, disabled, cartEnabled } = props;

  const { t } = useTranslation();

  const onPressFav = (e) => {
    e.stopPropagation();
    if (data.available != 1) {
      return;
    }
    toggleProductFavourite(id, isFav == 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav == 1 ? 0 : 1;
        onFavChange(data);
      })
      .catch(() => {});
  };

  const onRemoveFromCart = async () => {
    try {
      await props.removeProductFromCart(data, true);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const getDateLimit = () => {
    let left_days = moment(data.end_time, 'YYYY-MM-DD  hh:mm:ss').diff(moment(new Date()), 'days');
    return `${left_days} days left`;
  };

  return (
    <Swipeout
      right={[
        {
          text: t('address_list.delete'),
          onPress: () => {
            onRemoveFromCart();
          },
          style: {
            backgroundColor: '#f44336',
            color: 'white',
            fontFamily: 'Yellix-Medium',
            underlayColor: 'rgba(0, 0, 0, 0.6)'
          }
        }
      ]}
      disabled={cartEnabled !== true || cartCnt === 0}
      style={{ width: '100%', marginBottom: 12 }}>
      <div
        className={
          cartEnabled === true && cartCnt > 0 ? 'food-item food-item-cart-border' : 'food-item'
        }
        style={{ backgroundColor: data.available == 1 ? '#FAFAFC' : '#EFEFF3' }}
        onClick={() => {
          if (disabled === true || data.available !== 1) {
            return;
          }
          onClick();
        }}>
        <div className={'align-row-start'} style={{ width: '100%', marginBottom: 4 }}>
          <div className={'align-row-start'} style={{ flex: 1, flexWrap: 'wrap', marginRight: 60 }}>
            {cartEnabled === true && cartCnt > 0 && (
              <div className={'cart-cnt numline-1'}>{cartCnt} x</div>
            )}
            <div
              className={'food-title numline-1'}
              style={data.available != 1 ? { textDecorationLine: 'line-through' } : {}}>
              {type === 'offer' ? data.name : data.title}
            </div>
            {data.available != 1 && (
              <div className={'unavailable numline-1'}>
                {t('vendor_profile.unavailable_item_title')}
              </div>
            )}
          </div>
          {type === 'offer' ? (
            <div className={'date-limit'}>{getDateLimit()}</div>
          ) : (
            props.isLoggedIn && (
              <div onClick={onPressFav}>
                <Heart size={20} color={isFav ? Theme.colors.cyan2 : Theme.colors.gray5} />
              </div>
            )
          )}
        </div>
        <div className={'align-middle w100'}>
          <div className={'align-col-start flex_1 '} style={{ alignItems: 'flex-start' }}>
            <div className={'food-desc numline-2'}>{data.description}</div>
            {type === 'offer' ? (
              <div className={'price'}>
                {data.value != null && parseInt(data.value) >= 0 ? parseInt(data.value) : 0} L
              </div>
            ) : (
              <div className={'price'}>
                {data.price != null && parseInt(data.price) >= 0 ? parseInt(data.price) : 0} L
              </div>
            )}
          </div>
          <div className={' food-img'}>
            {type === 'offer' && data.product && !isEmpty(data.product.image_thumbnail_path) ? (
              <img src={Config.IMG_BASE_URL + data.product.image_thumbnail_path + `?w=200&h=200`} />
            ) : (
              !isEmpty(data.image_thumbnail_path) && (
                <img src={Config.IMG_BASE_URL + data.image_thumbnail_path + `?w=200&h=200`} />
              )
            )}
          </div>
        </div>
      </div>
    </Swipeout>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.id === nextProps.id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.onClick === nextProps.onClick &&
    prevProps.onFavChange === nextProps.onFavChange
  );
}

FoodItem.propTypes = {
  id: PropTypes.number,
  isFav: PropTypes.number,
  disabled: PropTypes.bool,
  cartEnabled: PropTypes.bool,
  cartCnt: PropTypes.number,
  type: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  data: PropTypes.shape({
    isFav: PropTypes.number,
    available: PropTypes.number,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    image_thumbnail_path: PropTypes.string,
    value: PropTypes.number,
    price: PropTypes.number,
    discount: PropTypes.number,
    end_time: PropTypes.string,
    product: PropTypes.shape({
      image_thumbnail_path: PropTypes.string
    })
  }),
  removeProductFromCart: PropTypes.func,
  onClick: PropTypes.func,
  onFavChange: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  isLoggedIn: app.isLoggedIn,
  cartItems: shop.items
});
export default connect(mapStateToProps, { removeProductFromCart })(
  React.memo(FoodItem, arePropsEqual)
);
