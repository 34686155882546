import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AddProduct2Cart, updateCartItems } from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { RWebShare } from 'react-web-share';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ChevronLeft } from '@styled-icons/material';
import { Share } from '@styled-icons/entypo';
import { Heart } from '@styled-icons/foundation';
import { Theme } from '../../../assets';
import { Config } from '../../../constants';
import { setTmpFood } from '../../../store/actions/app';
import { getVendorDetail, toggleProductFavourite } from '../../../store/actions/vendors';
import { RoundIconBtn, RadioBtn, AuthBtn } from '../../../components/Buttons';
import Counter from '../../../components/Common/Counter';
import CommentInput from '../../../components/Inputs/CommentInput';
import PropTypes from 'prop-types';
import './index.css';

const FoodScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { vendor_id, food_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [curModifier1, setModi1] = useState({});
  const [curModifier2, setModi2] = useState({});
  const [comments, setComments] = useState('');
  const [cartNum, setCartNum] = useState(1);
  const [isVendorOpen, setIsVendorOpen] = useState(0);

  useEffect(() => {
    if (props.tmpFoodData == null || props.tmpFoodData.id == null) {
      loadFoodData(vendor_id, food_id);
    }
    checkVendorIsOpen(vendor_id)
  }, [vendor_id, food_id]);

  useEffect(() => {
    let foundIndex = props.cartItems.findIndex((i) => i.id === props.tmpFoodData.id);
    if (foundIndex !== -1) {
      setCartNum(props.cartItems[foundIndex].quantity);
      setComments(props.cartItems[foundIndex].comments || '');

      if (
        props.cartItems[foundIndex].options != null &&
        props.cartItems[foundIndex].options.length > 0
      ) {
        props.cartItems[foundIndex].options.map((option) => {
          if (option.name === 'addition') {
            setModi1(option);
          } else {
            setModi2(option);
          }
        });
      }
    } else {
      setCartNum(1);
    }
  }, [props.cartItems, props.tmpFoodData]);

  const loadFoodData = (vendor_id, food_id) => {
    let { latitude, longitude } = props.coordinates;
    getVendorDetail(vendor_id, latitude, longitude)
      .then((data) => {
        setIsVendorOpen(data.id);
        for (let i = 0; data.categories != null && i < data.categories.length; i++) {
          if (data.categories[i].products != null && data.categories[i].products.length != null) {
            let foundIndex = data.categories[i].products.findIndex((p) => p.id == food_id);
            if (foundIndex !== -1) {
              props.setTmpFood(data.categories[i].products[foundIndex]);
              break;
            }
          }
        }
      })
      .catch(() => {});
  };

  const checkVendorIsOpen = (vendor_id) => {
    let { latitude, longitude } = props.coordinates;
    getVendorDetail(vendor_id, latitude, longitude)
      .then((data) => {
        setIsVendorOpen(data.is_open);
      })
      .catch(() => {});
  };

  const onPressFav = () => {
    toggleProductFavourite(props.tmpFoodData.id, props.tmpFoodData.isFav === 1 ? 0 : 1)
      .then(() => {
        props.setTmpFood({ ...props.tmpFoodData, isFav: props.tmpFoodData.isFav === 1 ? 0 : 1 });
      })
      .catch(() => {});
  };

  // const onShare = () => {
  //   if (navigator.share) {
  //     navigator
  //       .share({
  //         title: 'Snapfood Vendor',
  //         text: 'Link for Snapfood',
  //         url: `https://snapfood.al/restaurant/${props.vendorData['hash_id']}/${props.vendorData['slug']}`
  //       })
  //       .then(() => {})
  //       .catch(() => {
  //         window.location.reload(true);
  //       });
  //   } else {
  //     confirmAlert({
  //       title: t('alerts.error'),
  //       message: t('share_not_supported'),
  //       closeOnEscape: true,
  //       closeOnClickOutside: true,
  //       buttons: [
  //         {
  //           label: t('Ok'),
  //           className: 'error-ok-btn',
  //           onClick: () => {}
  //         }
  //       ]
  //     });
  //   }
  // };

  const onConfirmReset = async () => {
    let selectedOptions = [];
    if (curModifier1.id != null) {
      selectedOptions.push(curModifier1);
    }
    if (curModifier2.id != null) {
      selectedOptions.push(curModifier2);
    }

    // option_selected_required
    let cartItem = { ...props.tmpFoodData };
    cartItem.quantity = cartNum;
    cartItem.comments = comments;
    cartItem.options = selectedOptions;

    setLoading(true);
    await props.updateCartItems([cartItem]);
    setTimeout(() => {
      setLoading(false);
      navigate(-1);
    }, 200);
  };

  const onAddCart = () => {
    let selectedOptions = [];
    if (curModifier1.id != null) {
      selectedOptions.push(curModifier1);
    }
    if (curModifier2.id != null) {
      selectedOptions.push(curModifier2);
    }

    let cartItem = { ...props.tmpFoodData };
    cartItem.quantity = cartNum;
    cartItem.comments = comments;
    cartItem.options = selectedOptions;

    setLoading(true);
    props.AddProduct2Cart(cartItem);
    setTimeout(() => {
      setLoading(false);
      navigate(-1);
    }, 200);
  };

  const onRemoveItem = async () => {
    try {
      let tmp = props.cartItems.slice(0, props.cartItems.length);
      let foundIndex = tmp.findIndex((i) => i.id === props.tmpFoodData.id);

      setLoading(true);

      if (foundIndex !== -1) {
        tmp = tmp.filter((i) => i.id !== props.tmpFoodData.id);
        await props.updateCartItems(tmp);
      }

      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 200);
    } catch (error) {
      setLoading(false);
    }
  };

  const onPressAddCart = () => {
    let items = props.cartItems.filter((i) => i.vendor_id !== props.vendorData.id);
    if (items.length > 0) {
      confirmAlert({
        title: t('restaurant_details.new_order_question'),
        message: t('restaurant_details.new_order_text'),
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
          {
            label: t('confirm'),
            onClick: onConfirmReset
          },
          {
            label: t('cancel'),
            onClick: () => {}
          }
        ]
      });
    } else {
      if (cartNum <= 0) {
        // remove item from cart
        onRemoveItem();
      } else {
        onAddCart();
      }
    }
  };

  const _renderHeader = () => {
    return (
      <div className={'align-middle header'}>
        <RoundIconBtn
          style={styles.headerBtn}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'align-middle flex_1'} style={{ justifyContent: 'flex-end' }}>
          <RWebShare
            data={{
              title: 'Snapfood Vendor',
              text: 'Link for Snapfood',
              url: `https://snapfood.al/restaurant/${props.vendorData['hash_id']}/${props.vendorData['slug']}`
            }}
            onClick={() => {}}>
            <RoundIconBtn
              style={styles.headerBtn}
              icon={<Share size={20} color={Theme.colors.text} />}
              onClick={() => {}}
            />
          </RWebShare>

          <RoundIconBtn
            style={{
              ...styles.headerBtn,
              marginLeft: 6
            }}
            icon={
              <Heart
                size={22}
                color={props.tmpFoodData.isFav === 1 ? Theme.colors.cyan2 : Theme.colors.gray5}
              />
            }
            onClick={onPressFav}
          />
        </div>
      </div>
    );
  };

  const _renderOptionList = (name) => {
    let list = [];
    if (props.tmpFoodData['product_options'] && props.tmpFoodData['product_options'].length > 0) {
      props.tmpFoodData['product_options'].map((option) => {
        if (option.type === name) {
          list.push(option);
        }
      });
    }

    return list.length === 0 ? null : (
      <div className={'align-col-start info-view'}>
        <div className={'subject-title'}>
          {name === 'addition' ? t('restaurant_details.extras') : t('restaurant_details.options')}
        </div>
        {list.map((item, index) => (
          <div key={index} className={'align-middle option-item'}>
            <p>{item.title}</p>
            {name === 'addition' ? (
              <RadioBtn checked={item.id === curModifier1.id} onClick={() => setModi1(item)} />
            ) : (
              <RadioBtn checked={item.id === curModifier2.id} onClick={() => setModi2(item)} />
            )}
          </div>
        ))}
      </div>
    );
  };

  const _renderCartBtns = () => {
    return (
      <div className={'flex_between cart-btns'}>
        <Counter
          value={cartNum}
          onPlus={() => setCartNum(cartNum + 1)}
          onMinus={() => {
            let foundIndex = props.cartItems.findIndex((i) => i.id === props.tmpFoodData.id);
            if (foundIndex !== -1) {
              // if edit ?
              setCartNum(cartNum > 0 ? cartNum - 1 : cartNum);
            } else {
              setCartNum(cartNum > 1 ? cartNum - 1 : cartNum);
            }
          }}
        />
        <div style={{ flex: 1, marginLeft: 20 }}>
          <AuthBtn
            isLoading={loading}
            title={t('restaurant_details.add_to_cart')}
            style={{ width: '100%' }}
            onClick={onPressAddCart}
            isDisabled={isVendorOpen !== 1}
          />
        </div>
      </div>
    );
  };

  if (props.tmpFoodData == null) {
    return null;
  }
  return (
    <div className={'align-col-start food-details-screen'}>
      {props.tmpFoodData.image_path != null && props.tmpFoodData.image_path !== '' ? (
        <img
          src={`${Config.IMG_BASE_URL}${props.tmpFoodData.image_path}?w=600&h=600`}
          style={styles.img}
        />
      ) : (
        <div style={{ height: 100 }} />
      )}
      {
        <div style={{ padding: 20, width: '100%' }}>
          <div className={'align-col-start info-view'}>
            <h2>{props.tmpFoodData.title}</h2>
            <p>{props.tmpFoodData.description}</p>
            <h2>{parseInt(props.tmpFoodData.price)} L</h2>
          </div>
          {_renderOptionList('addition')}
          {_renderOptionList('option')}
          <div style={{ height: 12 }} />
          <div className={'subject-title'}>{t('restaurant_details.additional_note')}</div>
          <div className={'w100'}>
            <CommentInput
              placeholder={t('restaurant_details.add_additional_note')}
              text={comments}
              onChange={(text) => setComments(text)}
            />
          </div>
          {_renderCartBtns()}
        </div>
      }
      {_renderHeader()}
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  img: { width: '100%', height: 190, resizeMode: 'cover' }
};

const mapStateToProps = ({ app, shop }) => ({
  tmpFoodData: app.tmpFoodData,
  coordinates: app.coordinates,
  cartItems: shop.items,
  vendorData: shop.vendorData
});

FoodScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  cartItems: PropTypes.array,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    hash_id: PropTypes.string,
    slug: PropTypes.string
  }),
  tmpFoodData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    isFav: PropTypes.number,
    image_path: PropTypes.string,
    product_options: PropTypes.array,
  }),
  setTmpFood: PropTypes.func,
  AddProduct2Cart: PropTypes.func,
  updateCartItems: PropTypes.func
};

export default connect(mapStateToProps, {
  AddProduct2Cart,
  updateCartItems,
  setTmpFood
})(FoodScreen);
