import PropTypes from 'prop-types';
import React from 'react';
import { utils, MessageText } from '../react-web-gifted-chat';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import { Config } from '../../../constants';
import GoogleMapReact from 'google-map-react';
import LongPressDiv from '../../Common/LongPressDiv';
import AudioMsgItem from '../AudioMsgItem';

const { isSameDay, isSameUser } = utils;
const DEFAULT_OPTION_TITLES = ['Copy div', 'Cancel'];

class MessageBubble extends React.Component {
  constructor() {
    super(...arguments);
    this.onLongPress = () => {
      const { currentMessage } = this.props;
      if (this.props.onLongPress) {
        this.props.onLongPress(currentMessage);
      }
    };
    this.onPressMsg = () => {
      const { currentMessage } = this.props;
      if (this.props.onPressMsg) {
        this.props.onPressMsg(currentMessage);
      }
    };
    this.onShowGalleryMsgs = (images) => {
      if (this.props.onShowGalleryMsgs) {
        this.props.onShowGalleryMsgs(images);
      }
    };
    this.state = {
      value: 0,
      isPlaying: false
    };
  }

  getMaxContentWidth() {
    return Math.min(window.innerWidth, 450) - 130;
  }

  isToNext() {
    const { currentMessage, nextMessage, position } = this.props;
    return (
      currentMessage &&
      nextMessage &&
      position &&
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage)
    );
  }

  isToPrevious() {
    const { currentMessage, previousMessage, position } = this.props;
    return (
      currentMessage &&
      previousMessage &&
      position &&
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage)
    );
  }

  styledBubbleToNext() {
    const { position, containerToNextStyle } = this.props;
    if (!this.isToNext()) {
      return {
        ...styles[position].containerToNext,
        ...(containerToNextStyle && containerToNextStyle[position])
      };
    }
    return null;
  }

  styledBubbleToPrevious() {
    const { position, containerToPreviousStyle } = this.props;
    if (!this.isToPrevious()) {
      return {
        ...styles[position].containerToPrevious,
        ...(containerToPreviousStyle && containerToPreviousStyle[position])
      };
    }
    return null;
  }

  renderReply() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.reply && currentMessage.reply.user) {
      return (
        <div className={'align-col-middle'} style={styles.content.replyMsg}>
          <div style={styles.content.replyUserName}>{currentMessage.reply.user.full_name}</div>
          <div style={styles.content.replyText}>{currentMessage.reply.text}</div>
        </div>
      );
    }
    return null;
  }

  renderMap(coords) {
    const { latitude, longitude } = coords;
    if (latitude == null || longitude == null) {
      return null;
    }
    return (
      <div
        style={{ overflow: 'hidden', width: '100%', minWidth: 120, height: 90, borderRadius: 10 }}
        onClick={this.onPressMsg}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          defaultCenter={[latitude, longitude]}
          defaultZoom={12}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: Config.SocialMapScreenStyles
          }}>
          <div
            lat={latitude}
            lng={longitude}
            className={'align-col-middle'}
            style={{
              width: 22,
              height: 22,
              borderRadius: 11,
              backgroundColor: '#25DEE240'
            }}>
            <div style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: '#23CBD8' }} />
          </div>
        </GoogleMapReact>
      </div>
    );
  }

  renderMapLocation() {
    const { t, currentMessage } = this.props;
    if (currentMessage && currentMessage.map && currentMessage.map.coords) {
      return (
        <div
          className={'align-col-middle '}
          style={{ alignItems: 'flex-start', width: this.getMaxContentWidth() }}>
          <div
            style={{
              fontSize: 10,
              fontFamily: Theme.fonts.medium,
              color: Theme.colors.white,
              marginBottom: 6
            }}>
            {t('social.chat.you_shared_location')}
          </div>
          {this.renderMap(currentMessage.map.coords)}
        </div>
      );
    }
    return null;
  }

  renderMessageText() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.text) {
      let marginTop = 0;
      if (
        currentMessage.reply ||
        (currentMessage.images && currentMessage.images.length > 0) ||
        currentMessage.audio ||
        currentMessage.video ||
        currentMessage.map
      ) {
        marginTop = 10;
      }

      return (
        <MessageText
          {...this.props}
          containerStyle={{
            left: { marginTop: marginTop },
            right: { marginTop: marginTop }
          }}
          textStyle={{
            left: {
              color: Theme.colors.text,
              fontFamily: Theme.fonts.medium,
              fontSize: 15,
              lineHeight: '21px'
            },
            right: {
              color: Theme.colors.white,
              fontFamily: Theme.fonts.medium,
              fontSize: 15,
              lineHeight: '21px'
            }
          }}
          linkStyle={{
            left: { color: 'orange' },
            right: { color: 'orange' }
          }}
          customTextStyle={{ fontSize: 15, lineHeight: '21px', wordBreak: 'break-all' }}
        />
      );
    }
    return null;
  }

  renderImageList(imgs = []) {
    const sizeItem = 45;
    const marginWidth = 5;

    var showCnt = parseInt(this.getMaxContentWidth() / (sizeItem + marginWidth));
    var plusCnt = 0;
    if (showCnt < imgs.length) {
      plusCnt = imgs.length - showCnt + 1;
      showCnt = showCnt - 1;
    }

    return (
      <div className={'align-middle'}>
        {imgs.slice(0, showCnt).map((img, index) => (
          <div
            key={index}
            className={'align-col-middle'}
            style={{
              width: sizeItem,
              height: sizeItem,
              marginRight: marginWidth
            }}
            onClick={() => {
              this.onShowGalleryMsgs([img]);
            }}>
            <img
              style={{
                width: sizeItem,
                height: sizeItem,
                marginRight: marginWidth,
                borderRadius: 5,
                objectFit: 'cover'
              }}
              src={img}
            />
          </div>
        ))}
        {plusCnt > 0 && (
          <div
            className={'align-col-middle'}
            style={{
              width: sizeItem,
              height: sizeItem,
              marginRight: marginWidth,
              position: 'relative'
            }}
            onClick={() => {
              this.onShowGalleryMsgs(imgs.slice(showCnt, imgs.length));
            }}>
            <img
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: sizeItem,
                height: sizeItem,
                borderRadius: 5,
                objectFit: 'cover'
              }}
              src={showCnt < imgs.length ? imgs[showCnt] : ''}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 5,
                width: sizeItem,
                height: sizeItem,
                backgroundColor: '#00000099'
              }}
            />
            <div
              style={{
                fontSize: 11,
                fontFamily: Theme.fonts.medium,
                color: Theme.colors.white,
                zIndex: 0
              }}>
              +{plusCnt}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderMessageImage() {
    if (
      this.props.currentMessage &&
      this.props.currentMessage.images &&
      this.props.currentMessage.images.length > 0
    ) {
      return (
        <div>
          {this.props.currentMessage.images.length == 1 ? (
            <div
              className={'align-col-middle'}
              onClick={() => {
                this.onShowGalleryMsgs(this.props.currentMessage.images);
              }}>
              <img
                style={{
                  width: this.getMaxContentWidth(),
                  height: 100,
                  borderRadius: 13,
                  margin: 3,
                  objectFit: 'cover'
                }}
                src={this.props.currentMessage.images[0]}
              />
            </div>
          ) : (
            this.renderImageList(this.props.currentMessage.images)
          )}
        </div>
      );
    }
    return null;
  }

  renderMessageAudio() {
    const { currentMessage, position } = this.props;
    if (currentMessage && currentMessage.audio) {
      return (
        <AudioMsgItem
          source={currentMessage.audio.url}
          duration={currentMessage.audio.duration}
          position={position}
        />
      );
    }
    return null;
  }

  renderTicks() {
    const { currentMessage, renderTicks, user } = this.props;
    if (renderTicks && currentMessage) {
      return renderTicks(currentMessage);
    }
    if (currentMessage && user && currentMessage.user && currentMessage.user._id !== user._id) {
      return null;
    }
    if (
      currentMessage &&
      (currentMessage.sent || currentMessage.received || currentMessage.pending)
    ) {
      return (
        <div style={styles.content.tickView}>
          {!!currentMessage.sent && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>✓</div>
          )}
          {!!currentMessage.received && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>✓</div>
          )}
          {!!currentMessage.pending && (
            <div style={{ ...styles.content.tick, ...this.props.tickStyle }}>🕓</div>
          )}
        </div>
      );
    }
    return null;
  }

  renderTime() {
    const { currentMessage, nextMessage } = this.props;
    if (currentMessage && currentMessage.createdAt) {
      if (nextMessage && nextMessage.user && currentMessage.user._id == nextMessage.user._id) {
        return null;
      }
      return (
        <div style={{ ...styles.content.usernameView, marginTop: 6 }}>
          <div style={{ fontSize: 11, fontFamily: Theme.fonts.medium, color: Theme.colors.gray5 }}>
            {moment(currentMessage.createdAt).format('LT')}
          </div>
        </div>
      );
    }
    return null;
  }

  renderUsername() {
    const { currentMessage, previousMessage, user, isGroup } = this.props;
    if (this.props.renderUsernameOnMessage && currentMessage) {
      if ((user && currentMessage.user._id === user._id) || !isGroup) {
        return null;
      }
      if (
        previousMessage &&
        previousMessage.user &&
        currentMessage.user._id == previousMessage.user._id
      ) {
        return null;
      }
      return (
        <div style={styles.content.usernameView}>
          <div style={{ ...styles.content.username, ...this.props.usernameStyle }}>
            {currentMessage.user.full_name}
          </div>
        </div>
      );
    }
    return null;
  }

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props);
    }
    return null;
  }

  renderBubbleContent() {
    return (
      <div className={'align-col-middle'}>
        {this.renderReply()}
        {this.renderMapLocation()}
        {this.renderMessageImage()}
        {this.renderMessageAudio()}
        {this.renderMessageText()}
      </div>
    );
  }

  getWrapperStyle() {
    const { currentMessage } = this.props;
    if (currentMessage && currentMessage.reply && currentMessage.reply.user) {
      return {
        paddingTop: 14
      };
    }
    if (
      currentMessage &&
      !currentMessage.reply &&
      !currentMessage.audio &&
      currentMessage.images &&
      currentMessage.images.length > 0 &&
      currentMessage.text
    ) {
      return {
        paddingTop: 10
      };
    }
    if (
      currentMessage &&
      !currentMessage.reply &&
      !currentMessage.images &&
      !currentMessage.audio &&
      currentMessage.text
    ) {
      return {
        borderRadius: 60
      };
    }
    return null;
  }

  render() {
    const { currentMessage, position, containerStyle, wrapperStyle } = this.props;
    return (
      <div
        style={{ ...styles[position].container, ...(containerStyle && containerStyle[position]) }}>
        {this.renderUsername()}
        {currentMessage && currentMessage.emoji && currentMessage.emoji.length > 0 ? (
          <div style={styles.content.emoji}>
            {currentMessage.emoji.map((item) => item.code).join('')}
          </div>
        ) : (
          <LongPressDiv
            style={{
              ...styles[position].wrapper,
              ...(wrapperStyle && wrapperStyle[position]),
              ...this.styledBubbleToPrevious(),
              ...this.styledBubbleToNext(),
              ...this.getWrapperStyle()
            }}
            onClick={this.onPressMsg}
            onLongPress={this.onLongPress}
            {...this.props.touchableProps}>
            {this.renderBubbleContent()}
          </LongPressDiv>
        )}
        {this.renderTime()}
        {/*
            {this.renderTicks()} */}
      </div>
    );
  }
}
MessageBubble.contextTypes = {
  actionSheet: PropTypes.func
};
MessageBubble.defaultProps = {
  touchableProps: {},
  onLongPress: null,
  onPressMsg: null,
  onShowGalleryMsgs: null,
  renderMessageImage: null,
  renderMessageVideo: null,
  renderMessageText: null,
  renderCustomView: null,
  renderUsername: null,
  renderTicks: null,
  renderTime: null,
  renderQuickReplies: null,
  onQuickReply: null,
  position: 'left',
  optionTitles: DEFAULT_OPTION_TITLES,
  currentMessage: {
    text: null,
    createdAt: null,
    image: null
  },
  nextMessage: {},
  previousMessage: {},
  containerStyle: {},
  wrapperStyle: {},
  bottomContainerStyle: {},
  tickStyle: {},
  usernameStyle: {},
  containerToNextStyle: {},
  containerToPreviousStyle: {}
};
MessageBubble.propTypes = {
  t: PropTypes.func,
  isGroup: PropTypes.bool,
  user: PropTypes.object.isRequired,
  touchableProps: PropTypes.object,
  onLongPress: PropTypes.func,
  onPressMsg: PropTypes.func,
  onShowGalleryMsgs: PropTypes.func,
  renderMessageImage: PropTypes.func,
  renderMessageVideo: PropTypes.func,
  renderMessageText: PropTypes.func,
  renderCustomView: PropTypes.func,
  isCustomViewBottom: PropTypes.bool,
  renderUsernameOnMessage: PropTypes.bool,
  renderUsername: PropTypes.func,
  renderTime: PropTypes.func,
  renderTicks: PropTypes.func,
  renderQuickReplies: PropTypes.func,
  onQuickReply: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right']),
  optionTitles: PropTypes.arrayOf(PropTypes.string),
  currentMessage: PropTypes.object,
  nextMessage: PropTypes.object,
  previousMessage: PropTypes.object,
  containerStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object
  }),
  wrapperStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object
  }),
  bottomContainerStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object
  }),
  tickStyle: PropTypes.any,
  usernameStyle: PropTypes.any,
  containerToNextStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object
  }),
  containerToPreviousStyle: PropTypes.shape({
    left: PropTypes.object,
    right: PropTypes.object
  })
};

const styles = {
  left: {
    container: {
      flex: 1,
      alignItems: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    wrapper: {
      borderRadius: 28,
      backgroundColor: Theme.colors.gray8,
      marginRight: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
      paddingLeft: 18,
      paddingRight: 18,
      paddingTop: 14,
      paddingBottom: 14
    },
    containerToNext: {
      borderBottomLeftRadius: 3
    },
    containerToPrevious: {
      borderBottomLeftRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }
  },
  right: {
    container: {
      flex: 1,
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    wrapper: {
      borderRadius: 28,
      backgroundColor: Theme.colors.cyan2,
      marginLeft: 60,
      minHeight: 20,
      justifyContent: 'flex-end',
      paddingLeft: 18,
      paddingRight: 18,
      paddingTop: 14,
      paddingBottom: 14
    },
    containerToNext: {
      borderBottomRightRadius: 3
    },
    containerToPrevious: {
      borderBottomRightRadius: 3
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'flex-end'
    }
  },
  content: {
    tick: {
      fontSize: 11
      // backgroundColor: Color.backgroundTransparent,
      // color: Color.white,
    },
    tickView: {
      flexDirection: 'row',
      marginRight: 10
    },
    username: {
      top: -3,
      left: 0,
      fontSize: 11,
      fontFamily: Theme.fonts.medium,
      backgroundColor: 'transparent',
      color: Theme.colors.cyan2
    },
    usernameView: {
      flexDirection: 'row',
      marginHorizontal: 10
    },
    replyMsg: {
      alignItems: 'flex-start',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 20,
      backgroundColor: Theme.colors.white
    },
    replyUserName: { fontSize: 13, fontFamily: Theme.fonts.bold, color: Theme.colors.red1 },
    replyText: {
      marginTop: 6,
      fontSize: 13,
      fontFamily: Theme.fonts.medium,
      color: Theme.colors.text
    },
    emoji: { fontSize: 24 }
  }
};

export default withTranslation()(MessageBubble);
