import React from 'react';
import PropTypes from 'prop-types';
import { findZodiacSign, getImageFullURL } from '../../../utils/common';
import './index.css';
import { CheckBoxBtn } from '../../Buttons';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';

const UserItem = ({ item, checked, type, invite_status, onClick, onRightBtnPress }) => {
  const { t } = useTranslation();
  return (
    <div className={'user-item'} onClick={onClick ? onClick : () => {}}>
      <img className={'avatar'} src={getImageFullURL(item.photo)} />
      <div className={'align-row-start'} style={{ flex: 1 }}>
        <div className={'name'}>{item.full_name}</div>
        {item.birthdate != null && (
          <img src={findZodiacSign(item.birthdate)} className={'zodiac'} />
        )}
      </div>
      {type === 'checkbox' && (
        <CheckBoxBtn checked={checked === true} onClick={onClick ? onClick : () => {}} />
      )}
      {type === 'snapfooder' && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (onRightBtnPress) {
              onRightBtnPress();
            }
          }}>
          <div
            className={'invite_status'}
            style={{
              color: invite_status === 'invited' ? Theme.colors.gray7 : Theme.colors.cyan2
            }}>
            {invite_status === 'invited' ? t('chat.cancel') : t('chat.invite')}
          </div>
        </div>
      )}
      {type === 'invite_status' && (
        <div
          className={'invite_status'}
          style={{
            color: invite_status === 'invited' ? Theme.colors.gray7 : Theme.colors.cyan2
          }}>
          {invite_status === 'invited' ? t('chat.already_invited') : t('chat.invite')}
        </div>
      )}
    </div>
  );
};

UserItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    birthdate: PropTypes.string
  }),
  checked: PropTypes.bool,
  type: PropTypes.string,
  invite_status: PropTypes.string,
  onClick: PropTypes.func,
  onRightBtnPress: PropTypes.func
};

export default React.memo(UserItem);
