import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CommonTabs } from '../../../components/TabSelector';
import Header from '../../../components/Header';
import InvitationItem from '../../../components/Chats/InvitationItem';
import NoFriendList from '../../../components/Empty/NoFriendList';
import { ApiFactory } from '../../../services';
import { getFriends } from '../../../store/actions/app';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';

const Invitations = (props) => {
  const _isMounted = useRef(true);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [opType, setOpType] = useState('received');

  const [sentLoading, setSentLoading] = useState(null);
  const [receivedLoading, setReceivedLoading] = useState(null);
  const [receivedItems, setReceivedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);

  useEffect(() => {
    seenInvitation();
    getAllInvites();
    getAllSents();

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getAllInvites = () => {
    setReceivedLoading(true);
    ApiFactory.get(`users/invitations`).then(
      ({ data }) => {
        const res_invitations = data['invitations'];
        if (_isMounted.current == true) {
          setReceivedLoading(false);
          setReceivedItems(res_invitations);
        }
      },
      (error) => {
        setReceivedLoading(false);
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const getAllSents = () => {
    setSentLoading(true);
    getFriends('invited')
      .then((data) => {
        if (_isMounted.current == true) {
          setSentLoading(false);
          setSentItems(data);
        }
      })
      .catch(() => {
        setSentLoading(false);
      });
  };

  const seenInvitation = () => {
    ApiFactory.post(`users/invitations/seen`, {
      friend_id: props.user.id
    }).then(
      () => {},
      () => {}
    );
  };

  const replyInvitation = (item, status) => {
    ApiFactory.post(`users/friends/update`, {
      user_id: item.id,
      friend_id: props.user.id,
      status: status
    }).then(
      () => {
        getAllInvites();
      },
      (error) => {
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = (item) => {
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: item.id
    }).then(
      () => {
        getAllSents();
      },
      (error) => {
        const message = error.message || t('generic_error');
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div className={'flex_1 invitation-screen'}>
      <Header title={t('social.invitation')} />
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['received', 'sent']}
            item={opType}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => setOpType(item)}
          />
        </div>
      </div>
      <div className={'flex_1 scrollview'}>
        <div className={opType == 'received' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {receivedLoading == false && receivedItems.length === 0 ? (
            <NoFriendList
              title={t('social.no_invitations')}
              desc={t('social.no_received_invitations')}
            />
          ) : (
            receivedItems.map((item) => (
              <InvitationItem
                isSent={false}
                key={item.id}
                id={item.id}
                item={item}
                style={{ width: '100%', marginBottom: 12 }}
                onClick={() => {
                  navigate(`/snapfooder/${item.id}`);
                }}
                onAccept={() => {
                  replyInvitation(item, 'accepted');
                }}
                onDecline={() => {
                  replyInvitation(item, 'canceled');
                }}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
        <div className={opType == 'sent' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {sentLoading == false && sentItems.length === 0 ? (
            <NoFriendList
              title={t('social.no_invitations')}
              desc={t('social.no_sent_invitations')}
            />
          ) : (
            sentItems.map((item) => (
              <InvitationItem
                isSent={true}
                key={item.id}
                id={item.id}
                item={item}
                style={{ width: '100%', marginBottom: 12 }}
                onClick={() => {
                  navigate(`/snapfooder/${item.id}`);
                }}
                onCancel={() => {
                  onCancelInvitation(item);
                }}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
      </div>
    </div>
  );
};

Invitations.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};
const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {})(Invitations);
