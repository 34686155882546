import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

const BlockSpinner = ({ style }) => {
  return (
    <div className={'flex_1 align-col-middle'} style={style}>
      <CircularProgress size={36} color="primary" />
    </div>
  );
};

BlockSpinner.propTypes = {
  style: PropTypes.object
};

export default BlockSpinner;
