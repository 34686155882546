import AuthService from '../../services/apiAuth';
import { Storage } from '../../services';
import { ACTION_TYPES } from '../../constants';
import { ApiFactory } from '../../services';
import { getWebPushToken } from '../../services/pushWeb';

const getLoggedInUserData = () => {
  return new Promise((resolve, reject) => {
    try {
      AuthService.getLoggedInUserData()
        .then((res) => {
          resolve(res.data.user);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (e) {
      reject(e);
    }
  });
};

export const getLoggedInUser = () => (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    try {
      const user = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: user
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!user['verified_by_mobile']
      });
      resolve(user);
    } catch (e) {
      resolve();
    }
  });
};

export const setAsLoggedIn = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({
        type: ACTION_TYPES.APP_LOGGED_IN,
        payload: true
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const updateProfileDetails = (user) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    AuthService.updateProfile(user).then(async ({ data }) => {
      await dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: data.user
      });
      resolve(data.user);
    }, reject);
  });
};

export const setAsSeenOnboard = () => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      Storage.setSeenOnboard(true);
      dispatch({
        type: ACTION_TYPES.APP_SEEN_ONBOARD,
        payload: true
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const login = (email, password) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      const device = { token: getWebPushToken() };

      AuthService.emailLogin({ email, password, device }).then(
        async (response) => {
          const { token, verified_by_mobile } = response.data;
          Storage.setAppToken(token);
          const user = await getLoggedInUserData();
          dispatch({
            type: ACTION_TYPES.APP_SET_USER_DATA,
            payload: user
          });
          dispatch({
            type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
            payload: !!verified_by_mobile
          });

          resolve(user);
        },
        async (e) => {
          reject(e);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const legacyLogin = (token) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      const device = {
        token: getWebPushToken()
      };

      ApiFactory.post(
        'login/legacy',
        { device },
        {
          headers: {
            Authorization: token
          }
        }
      ).then(
        async (response) => {
          const { token, verified_by_mobile } = response.data;
          Storage.setAppToken(token);

          const user = await getLoggedInUserData();
          dispatch({
            type: ACTION_TYPES.APP_SET_USER_DATA,
            payload: user
          });
          dispatch({
            type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
            payload: !!verified_by_mobile
          });

          resolve(user);
        },
        async (e) => {
          reject(e);
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};

export const register = (user) => () => {
  return new Promise((resolve, reject) => {
    try {
      const device = { token: getWebPushToken() };
      AuthService.register({ ...user, device }).then(resolve, reject);
    } catch (e) {
      reject(e);
    }
  });
};

export const facebookLogin = (token) => async (dispatch) => {
  try {
    const device = { token: getWebPushToken() };
    const response = await ApiFactory.post('login/facebook', { access_token: token, device });
    Storage.setAppToken(response.data.token);
    const user = await getLoggedInUserData();
    dispatch({
      type: ACTION_TYPES.APP_SET_USER_DATA,
      payload: user
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
      payload: !!user['verified_by_mobile']
    });
    return { data: user, status: 'OK' };
  } catch (e) {
    return { data: e, status: 'ERROR' };
  }
};

export const googleLogin = (id_token) => async (dispatch) => {
  try {
    const device = { token: getWebPushToken() };
    const response = await ApiFactory.post('login/google', { id_token: id_token, device });

    Storage.setAppToken(response.data.token);
    const user = await getLoggedInUserData();
    dispatch({
      type: ACTION_TYPES.APP_SET_USER_DATA,
      payload: user
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
      payload: !!user['verified_by_mobile']
    });
    return { data: user, status: 'OK' };
  } catch (e) {
    return { data: e, status: 'ERROR' };
  }
};

export const appleLogin =
  ({ user, identityToken, email, fullName }) =>
  async (dispatch) => {
    try {
      const device = { token: getWebPushToken() };
      if (!email) {
        email = '';
      }
      if (!fullName.nickName) {
        fullName = '';
      } else {
        fullName = fullName.nickName;
      }
      const data = {
        apple_id: user,
        apple_identity_token: identityToken,
        email: email,
        name: fullName,
        device
      };
      const response = await ApiFactory.post('login/apple', data);
      const { token, verified_by_mobile } = response.data;
      Storage.setAppToken(token);
      const loggedUser = await getLoggedInUserData();
      dispatch({
        type: ACTION_TYPES.APP_SET_USER_DATA,
        payload: loggedUser
      });
      dispatch({
        type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
        payload: !!verified_by_mobile
      });
      return { data: loggedUser, status: 'OK' };
    } catch (e) {
      return { data: e, status: 'ERROR' };
    }
  };

export const logout = () => async (dispatch) => {
  try {
    await ApiFactory.get('logout');
    Storage.clearAppToken();
    dispatch({
      type: ACTION_TYPES.APP_LOGGED_IN,
      payload: false
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_ADDRESSES,
      payload: []
    });
    dispatch({
      type: ACTION_TYPES.APP_SET_USER_DATA,
      payload: {}
    });
    return { data: '', status: 'OK' };
  } catch (e) {
    return { data: e, status: 'ERROR' };
  }
};

export const changePassword = (old_password, password) => () => {
  return new Promise((resolve, reject) => {
    try {
      AuthService.changePass({
        old_password: old_password,
        password: password
      }).then(resolve, reject);
    } catch (e) {
      reject(e);
    }
  });
};

export const setHasVerifiedPhone = (value) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE,
    payload: value
  });
};

export const setUserNeedLogin = (value) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.APP_SET_NEED_LOGIN,
    payload: value
  });
};
