import React from 'react';
import { ChevronLeft } from '@styled-icons/material';
import { Theme } from '../../assets';
import PropTypes from 'prop-types';
import './index.css';
import { useNavigate } from 'react-router-dom';

const Header = ({ left, right, title, style, onLeft, onRight }) => {
  const navigate = useNavigate();
  return (
    <div className={'header'} style={style}>
      <div className={'w100 align-middle'}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={
            onLeft
              ? onLeft
              : () => {
                  navigate(-1);
                }
          }>
          {left ? left : <ChevronLeft size={28} color={Theme.colors.text} />}
        </div>
        <p className={'flex_1 title'}>{title}</p>
        <div style={{ justifyContent: 'flex-end', paddingRight: onRight ? 8 : 28 }}>
          <div onClick={onRight ? onRight : () => {}}>{right}</div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  title: PropTypes.string,
  style: PropTypes.object,
  onLeft: PropTypes.func,
  onRight: PropTypes.func
};
export default Header;
