import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Config } from '../../../constants';
import './index.css';
import { Theme } from '../../../assets';

const BlogItem = ({ id, data, style }) => {
  const navigate = useNavigate();

  const onClick = (e) => {
    e.preventDefault();
    navigate(`/blog/${data.id}`);
  };

  return (
    <div key={id} className={'blog-item'} style={style} onClick={onClick}>
      <div
        className="img bg-cover bg-theme-color-light overlay-dark"
        style={{
          backgroundImage: `url('${Config.IMG_BASE_URL}${data.image_cover}??h=250')`
        }}
      />
      <div className="info">
        <div className={'flex_1'}>
          <div className={'align-row-start tags'}>
            {data.categories && (
              <div className={'tag-text'}>{data.categories.map((x) => x.title).join(', ')}</div>
            )}
          </div>
          <div className={'flex_between mt12'}>
            <h3 className={'blog-title'}>{data.title}</h3>
          </div>
          <div className={'align-row-start mt12'}>
            <h3 className={'author-txt'}>{data.author}</h3>
            <div
              style={{
                width: 1,
                height: 14,
                marginLeft: 5,
                marginRight: 5,
                backgroundColor: Theme.colors.gray5
              }}
            />
            <h3 className={'date-txt'}>{data.created_at}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.id === nextProps.id;
}

BlogItem.propTypes = {
  id: PropTypes.number,
  data: PropTypes.shape({
    title: PropTypes.string,
    image_cover: PropTypes.string,
    categories: PropTypes.array,
    author: PropTypes.string,
    created_at: PropTypes.string,
    slug: PropTypes.string,
    id: PropTypes.number
  }),
  style: PropTypes.object
};
export default React.memo(BlogItem, arePropsEqual);
