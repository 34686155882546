import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ChevronRight } from '@styled-icons/evaicons-solid';
import { setDeliveryInfoCart } from '../../../store/actions/shop';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { DotBorderButton } from '../../Buttons';
import { AuthInput, CommentInput } from '../../Inputs';
import AddressItem from '../../Order/AddressItem';
import TextSelector from '../../Dropdowns/TextSelector';
import ReserveItem from '../ReserveItem';
import TimePicker from '../TimePicker';
import ScheduleTab from '../../TabSelector/ScheduleTab';
import PropTypes from 'prop-types';
import './index.css';
import ScheduleTimeModal from '../../Modals/ScheduleTimePicker';
import { isEmpty } from '../../../utils/common';

const CartDelivery = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isNoteEdit, setNoteEdit] = useState(false);
  const [isScheduleModal, setScheduleModal] = useState(false);
  const [order_methods, setOrderMethods] = useState([]);

  useEffect(() => {
    if (props.addresses != null && props.addresses.length > 0) {
      let comments = '';
      if (props.delivery_info.handover_method === OrderType_Delivery) {
        comments = props.addresses[0].notes;
      }
      props.setDeliveryInfoCart({
        address: props.addresses[0],
        comments: comments
      });
    } else {
      props.setDeliveryInfoCart({
        address: {}
      });
    }
  }, [props.addresses]);

  useEffect(() => {
    if (props.vendorData.order_method != null) {
      let supported_order_methods = props.vendorData.order_method.split('-');
      if (supported_order_methods.length > 0) {
        setOrderMethods(supported_order_methods);
        if (props.delivery_info.handover_method == null) {
          props.setDeliveryInfoCart({
            handover_method: supported_order_methods[0]
          });
        }
      }
    }
  }, [props.vendorData.id]);

  useEffect(() => {
    const apm = moment(new Date()).format('A');

    let pickup_time = moment(new Date()).format('h') + ':00';
    if (apm === 'PM') {
      pickup_time = parseInt(pickup_time.split(':')[0]) + 12 + ':00';
    }
    props.setDeliveryInfoCart({
      pickup_date: moment(new Date()).format('YYYY-MM-DD'),
      pickup_time: pickup_time
    });
  }, []);

  const _renderHandover = () => {
    return (
      <div className={'align-col-start section-view'}>
        <div className={'flex_between w100'}>
          <div className={'subject-title'}>{t('vendor_profile.handover_method')}</div>
          <div className={'hand-over-select'} style={{ width: 155 }}>
            <TextSelector
              style={{ height: 40 }}
              values={order_methods}
              value={props.delivery_info.handover_method}
              handleChange={(method) => {
                let comments = '';
                if (method === OrderType_Delivery && props.delivery_info.address != null) {
                  comments = props.delivery_info.address.notes;
                }
                props.setDeliveryInfoCart({
                  handover_method: method,
                  comments: comments,
                  tip_rider: 0
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const _renderDeliveryInfo = () => {
    const onGoAddress = () => {
      navigate(`/addresses?from_cart=true`);
    };
    return (
      <div className={'align-col-start section-view'}>
        <div className={'subject-title'} style={{ marginBottom: 8 }}>
          {t('cart.delivery_info')}
        </div>
        {props.delivery_info.address != null && props.delivery_info.address.id != null ? (
          <AddressItem
            id={props.delivery_info.address.id}
            data={props.delivery_info.address}
            phone={props.user.phone}
            onEdit={onGoAddress}
            edit_text={t('cart.change_address')}
            outOfDeliveryArea={props.outOfDeliveryArea === true}
            textSize={13}
          />
        ) : (
          <DotBorderButton
            text={t('address_list.add_new_address')}
            style={{ width: '100%' }}
            onPress={onGoAddress}
          />
        )}
        <div
          className={'align-middle mt8 delivery-note-title'}
          onClick={() => setNoteEdit(!isNoteEdit)}>
          <p>{t('cart.delivery_note')}</p>
          <ChevronRight size={16} color={Theme.colors.text} />
        </div>
        <div style={{ width: '100%', paddingTop: 8 }}>
          {isNoteEdit === true ? (
            <CommentInput
              placeholder={t('cart.delivery_note')}
              text={props.delivery_info.comments}
              onChange={(text) => {
                props.setDeliveryInfoCart({
                  comments: text
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );
  };

  const _renderPickupInfo = () => {
    return (
      <div className={'align-col-start section-view'}>
        <div style={{ width: '100%', paddingBottom: 20 }}>
          <div className={'subject-title'} style={{ marginBottom: 12 }}>
            {t('cart.pickup_note')}
          </div>
          <CommentInput
            placeholder={t('cart.add_your_note')}
            text={props.delivery_info.comments}
            onChange={(text) => {
              props.setDeliveryInfoCart({
                comments: text
              });
            }}
          />
        </div>
        <div className={'pickup-noti'}>{t('cart.pickup_after_30')}</div>
        <div className={'w100 subject-title'} style={{ marginBottom: 16 }}>
          {t('cart.custom_pickup')}
        </div>
        <TimePicker
          onChangePickupDate={(pickup_date) => {
            props.setDeliveryInfoCart({
              pickup_date: pickup_date
            });
          }}
          onChangePickupTime={(pickup_time) => {
            props.setDeliveryInfoCart({
              pickup_time: pickup_time
            });
          }}
        />
      </div>
    );
  };

  const _renderReserveInfo = () => {
    return (
      <div className={'align-col-start section-view'}>
        <div className={'w100 subject-title'} style={{ marginBottom: 16 }}>
          {t('cart.reserve_for')}
        </div>
        {
          <ReserveItem
            full_name={props.user.full_name}
            phone={props.user.phone}
            onEdit={() => {}}
            onSelect={() => {}}
          />
        }
        {_renderNumGuests()}
        <div className={'w100 subject-title'} style={{ marginBottom: 16 }}>
          {t('cart.date_time')}
        </div>
        <TimePicker
          onChangePickupDate={(pickup_date) => {
            props.setDeliveryInfoCart({
              pickup_date: pickup_date
            });
          }}
          onChangePickupTime={(pickup_time) => {
            props.setDeliveryInfoCart({
              pickup_time: pickup_time
            });
          }}
        />
      </div>
    );
  };

  const _renderNumGuests = () => {
    return (
      <div className={'flex_between w100 num-guests'} style={{ marginBottom: 10 }}>
        <div className={'subject-title'}>{t('cart.num_guests')}</div>
        <AuthInput
          style={{
            width: 122,
            height: 40,
            marginBottom: 0,
            borderWidth: 1,
            borderColor: Theme.colors.gray6,
            backgroundColor: Theme.colors.white
          }}
          placeholder=""
          type={'number'}
          text={props.delivery_info.num_guests || ''}
          onChange={(text) => {
            props.setDeliveryInfoCart({
              num_guests: parseInt(text) || 0
            });
          }}
        />
      </div>
    );
  };

  const _renderScheduleInfo = () => {
    return (
      <div className={'align-col-middle'} style={{ marginBottom: 10 }}>
        <ScheduleTab
          curitem={props.delivery_info.is_schedule === 0 ? 'now' : 'schedule'}
          onSelect={(item) => {
            if (item === 'schedule') {
              props.setDeliveryInfoCart({
                is_schedule: 1,
                schedule_time: null
              });
              setScheduleModal(true);
            } else {
              props.setDeliveryInfoCart({
                is_schedule: 0,
                schedule_time: null
              });
            }
          }}
        />
        {!isEmpty(props.delivery_info.schedule_time) && props.delivery_info.is_schedule === 1 && (
          <div className={'align-middle mt4'} style={{ width: 216 }}>
            <div className={'flex_1'} />
            <div className={'flex_1 schedule-time-label'}>
              {moment(props.delivery_info.schedule_time).format('ddd-h:mm A')}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={'cart-delivery-item'}>
      {props.delivery_info.handover_method === OrderType_Delivery &&
        props.vendorData.can_schedule === 1 &&
        _renderScheduleInfo()}
      {props.vendorData.order_method !== OrderType_Delivery &&
        order_methods.length > 0 &&
        _renderHandover()}
      {props.delivery_info.handover_method === OrderType_Delivery && _renderDeliveryInfo()}
      {props.delivery_info.handover_method === OrderType_Pickup && _renderPickupInfo()}
      {props.delivery_info.handover_method === OrderType_Reserve && _renderReserveInfo()}
      <ScheduleTimeModal
        showModal={isScheduleModal}
        onClose={() => {
          setScheduleModal(false);
        }}
        onConfirm={(date_time) => {
          props.setDeliveryInfoCart({
            schedule_time: date_time
          });
          setScheduleModal(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ app, shop }) => ({
  user: app.user,
  addresses: app.addresses,
  delivery_info: shop.delivery_info,
  vendorData: shop.vendorData || {}
});

CartDelivery.propTypes = {
  outOfDeliveryArea: PropTypes.bool,
  addresses: PropTypes.array,
  user: PropTypes.shape({
    full_name: PropTypes.string,
    phone: PropTypes.string
  }),
  delivery_info: PropTypes.shape({
    handover_method: PropTypes.string,
    comments: PropTypes.string,
    address: PropTypes.shape({
      id: PropTypes.number,
      notes: PropTypes.string
    }),
    num_guests: PropTypes.number,
    is_schedule: PropTypes.number,
    schedule_time: PropTypes.string
  }),
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    order_method: PropTypes.string,
    can_schedule: PropTypes.number
  }),
  setDeliveryInfoCart: PropTypes.func,
  onMinus: PropTypes.func,
  onDelete: PropTypes.func
};

export default connect(mapStateToProps, {
  setDeliveryInfoCart
})(CartDelivery);
