import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './CommonTabs.css';

const CommonTabs = ({ items, item, style, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={'align-middle common-tabs'}>
      {items &&
        items.map((it) => (
          <div key={it} className={'flex_1 h100'}>
            <Button
              variant="contained"
              className={'tab-btn ' + (it === item ? 'tab-btn-active' : 'tab-btn-inactive')}
              style={style}
              onClick={() => {
                onChange(it);
              }}>
              {t(it)}
            </Button>
          </div>
        ))}
    </div>
  );
};

CommonTabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  item: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func
};
export default React.memo(CommonTabs);
