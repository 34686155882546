import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import Swipeout from 'rc-swipeout';
import 'rc-swipeout/assets/index.css';
import { MainBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import AddressItem from '../../../components/Order/AddressItem';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import {
  getAddresses,
  saveAddress,
  deleteAddress,
  setLocallyAddresses,
  setTmpLocationPicked
} from '../../../store/actions/app';
import './index.css';
import PropTypes from 'prop-types';

const Addresses = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);
  const isFromCart = parsed.from_cart == 'true';

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isConfirmModal, ShowConfirmModal] = useState(false);

  useEffect(async () => {
    try {
      await props.getAddresses();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, []);

  const onEditAddress = (addressItem) => {
    let tmpAddress = {
      ...addressItem,
      coords: {
        latitude: addressItem.lat,
        longitude: addressItem.lng
      }
    };
    props.setTmpLocationPicked(tmpAddress);
    navigate(`/add-address?is_edit=true&from_cart=${isFromCart}`);
  };

  const onSelectAddress = async (addressItem) => {
    try {
      let item = { ...addressItem };
      item.favourite = 1;
      await saveAddress(item);
      await props.getAddresses();

      if (isFromCart) {
        navigate(-1);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const onDeleteAddress = async () => {
    try {
      ShowConfirmModal(false);
      if (selectedAddress == null) {
        return;
      }
      await props.deleteAddress(selectedAddress.id);
      await props.getAddresses();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  return (
    <div className={'align-col-start addresses-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('address_list.header_title')}
      />
      <div className={'scrollview'}>
        {props.addresses.map((address) => (
          <Swipeout
            key={address.id}
            autoClose
            style={{ marginBottom: 16 }}
            disabled={address.favourite === 1}
            right={[
              {
                text: t('address_list.delete'),
                onPress: () => {
                  setSelectedAddress(address);
                  ShowConfirmModal(true);
                },
                style: {
                  backgroundColor: '#f44336',
                  color: 'white',
                  underlayColor: 'rgba(0, 0, 0, 0.6)'
                }
              }
            ]}>
            <AddressItem
              id={address.id}
              favourite={address.favourite}
              data={address}
              onEdit={() => {
                onEditAddress(address);
              }}
              onClick={() => {
                onSelectAddress(address);
              }}
            />
          </Swipeout>
        ))}
        <div style={{ height: 20 }} />
      </div>
      <div className={'ph-20 w100'} style={{ marginBottom: 40 }}>
        <MainBtn
          title={t('address_list.add_new_address')}
          onClick={() => {
            props.setTmpLocationPicked({});
            navigate(`/add-address?from_cart=${isFromCart}`);
          }}
        />
      </div>
      <ConfirmModal
        showModal={isConfirmModal}
        title={t('address_list.delete_confirm')}
        yes={t('address_list.delete_confirm_yes')}
        no={t('address_list.delete_confirm_no')}
        onYes={onDeleteAddress}
        onClose={() => ShowConfirmModal(false)}
      />
    </div>
  );
};

Addresses.propTypes = {
  addresses: PropTypes.array,
  getAddresses: PropTypes.func,
  deleteAddress: PropTypes.func,
  setTmpLocationPicked: PropTypes.func,
  setLocallyAddresses: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  addresses: app.addresses || []
});

export default connect(mapStateToProps, {
  getAddresses,
  deleteAddress,
  setTmpLocationPicked,
  setLocallyAddresses
})(Addresses);
