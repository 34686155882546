import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './OrderMethodTab.css';

const OrderMethodTab = ({ items, curitem, onSelect }) => {
  const { t } = useTranslation();

  const [selectedItem, setCurItem] = useState(curitem == null ? items[0] : curitem);
  useEffect(() => {
    setCurItem(curitem == null ? items[0] : curitem);
  }, [curitem]);

  return (
    <div className={'align-middle order-method-tab'}>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setCurItem(item);
            onSelect(item);
          }}
          className={[
            'align-middle btn',
            item === selectedItem ? 'btn-active' : 'btn-inactive'
          ].join(' ')}>
          <div
            className={['btn-txt', item === selectedItem ? 'active_txt' : 'inactive_txt'].join(
              ' '
            )}>
            {t(item)}
          </div>
        </div>
      ))}
    </div>
  );
};

OrderMethodTab.propTypes = {
  items: PropTypes.array,
  curitem: PropTypes.string,
  onSelect: PropTypes.func
};

export default React.memo(OrderMethodTab);
