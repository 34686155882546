import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Header';
import './index.css';
import PropTypes from 'prop-types';
import Svg_img from '../../../../assets/svgs/profile/timer.svg';
import { MainBtn } from '../../../../components/Buttons';
import InviteEarnTooltip from '../../../../components/Common/InviteEarnTooltip';
import { useParams } from 'react-router-dom';

const ReferralsInvitationDetails = () => {
  const { t } = useTranslation();

  const { invitation_id } = useParams();

  const [invitationData, setInvitationData] = useState({});
  // const [loading, showLoading] = useState(false);

  useEffect(() => {
    loadInvitation(invitation_id);
  }, [invitation_id]);

  const loadInvitation = () => {
    setInvitationData({
      receiver: {
        full_name: 'Amber Porter'
      },
      invite_code: '85AGC36',
      invite_time: '10/11/2021 1:30PM',
      remaining_time_to_use: '2 days remaining',
      status: 'available'
    });
  };

  return (
    <div className={'align-start referrals-invitation-details-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('refer_rewards.invitation_details')}
      />
      <div className={'scrollview'}>
        <div className={'align-col-middle'}>
          <img src={Svg_img} className={'header-img'} />
        </div>
        <div className={'align-col-middle w100 details-view'}>
          <h5>{invitationData.remaining_time_to_use}</h5>
          <h2>{invitationData.invite_code}</h2>
          <div className={'divider'} />
          <div className={'info-view flex_between'}>
            <h3>
              {invitation_id === 'sent' ? t('refer_rewards.receiver') : t('refer_rewards.sender')}
            </h3>
            <div className={'date-btn'}>
              {invitationData.receiver != null && invitationData.receiver.full_name}
            </div>
          </div>
          <div className={'info-view flex_between'}>
            <h3>{t('refer_rewards.date')}</h3>
            <div className={'row_center'}>
              <div className={'date-btn '}>
                {invitationData.invite_time != null && invitationData.invite_time.split(' ')[0]}
              </div>
              <div className={'time-btn '}>
                {invitationData.invite_time != null && invitationData.invite_time.split(' ')[1]}
              </div>
            </div>
          </div>
          {invitation_id === 'sent' && (
            <div className={'not_used_text'}>
              This invitation has not been used yet by Jasmine Evans
            </div>
          )}
          <InviteEarnTooltip
            reward_user={
              invitation_id === 'sent'
                ? 'you'
                : invitationData.receiver != null
                ? invitationData.receiver.full_name
                : ''
            }
            text={t('refer_rewards.how_does_it_work')}
          />
          {invitation_id !== 'sent' && (
            <MainBtn
              title={t('refer_rewards.use_code')}
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ marginTop: 40 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ReferralsInvitationDetails.propTypes = {
  user: {
    full_name: PropTypes.string
  }
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {}
});

export default connect(mapStateToProps, {})(ReferralsInvitationDetails);
