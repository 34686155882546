import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import Svg_order_failed from '../../../assets/svgs/order_status/order_failed.svg';

const OrderFailedModal = ({ showModal, title, onTry, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal order-failed-modal'}>
      <div className={'align-col-middle content'}>
        <img src={Svg_order_failed} />
        <div className={'title'}>{title}</div>
        <div
          onClick={onTry}
          className={'align-middle'}
          style={{ width: '100%', height: 40, cursor: 'pointer' }}>
          <div className={'try-btn'}>{t('cart.try_again')}</div>
        </div>
        <div
          onClick={onClose}
          className={'align-middle'}
          style={{ width: '100%', height: 40, cursor: 'pointer' }}>
          <div className={'dismiss-btn'}>{t('cart.dismiss')}</div>
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal && prevProps.title === nextProps.title;
}

OrderFailedModal.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onTry: PropTypes.func
};

export default React.memo(OrderFailedModal, arePropsEqual);
