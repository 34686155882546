import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import { Groups } from '@styled-icons/material-sharp';
import { useTranslation } from 'react-i18next';
import { ApiFactory } from '../../../services';
import { createSingleChannel, findChannel } from '../../../services/chat';
import { Theme } from '../../../assets';
import { Config } from '../../../constants';
import { setVendorCart } from '../../../store/actions/shop';
import { setTmpSnapfoodMapData } from '../../../store/actions/app';
import { UserMarker, SnapfooderMarker, VendorMarker } from '../../../components/Markers';
import { ROUTES_NAMES } from '../../../constants';
import { RoundIconBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import PropTypes from 'prop-types';
import './index.css';
import LoadingModal from '../../../components/Modals/LoadingModal';
import { confirmAlert } from 'react-confirm-alert';
import { checkLocationPermission, getCurrentLocation } from '../../../services/location';

const MIN_DELTA = 0.03;

const SnapfoodMapScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const _isMounted = useRef(true);

  const [state, setState] = useState({
    loading: false,
    isCreatingChannel: false,
    snapfooders: [],
    vendors: []
  });

  const [myLoc, setMyLoc] = useState(null);
  const [mapPos, setMapPos] = useState(null);

  useEffect(() => {
    if (
      props.tmpSnapfoodMapData &&
      props.tmpSnapfoodMapData.latitude &&
      props.tmpSnapfoodMapData.longitude &&
      props.tmpSnapfoodMapData.zoom &&
      props.tmpSnapfoodMapData.my_latitude &&
      props.tmpSnapfoodMapData.my_longitude &&
      props.tmpSnapfoodMapData.snapfooders &&
      props.tmpSnapfoodMapData.vendors
    ) {
      setState({
        ...state,
        snapfooders: props.tmpSnapfoodMapData.snapfooders || [],
        vendors: props.tmpSnapfoodMapData.vendors || []
      });

      setMyLoc({
        latitude: props.tmpSnapfoodMapData.my_latitude,
        longitude: props.tmpSnapfoodMapData.my_longitude
      });

      setMapPos({
        center: {
          lat: parseFloat(props.tmpSnapfoodMapData.latitude),
          lng: parseFloat(props.tmpSnapfoodMapData.longitude)
        },
        zoom: props.tmpSnapfoodMapData.zoom || 15
      });
    } else {
      _setCurrentLocation();
    }

    return () => {
      _isMounted.current = false;
    };
  }, []);

  const _setCurrentLocation = async () => {
    let location_flag = false;
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        const location = await getCurrentLocation();
        if (location) {
          location_flag = true;
          setMyLocation(location.latitude, location.longitude);
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (location_flag !== true) {
      setMyLocation(props.coordinates.latitude, props.coordinates.longitude);
    }
  };

  const setMyLocation = async (latitude, longitude) => {
    if (latitude && longitude) {
      setMyLoc({
        latitude: latitude,
        longitude: longitude
      });
      props.setTmpSnapfoodMapData({
        my_latitude: latitude,
        my_longitude: longitude
      });
      loadData(
        {
          latitude: latitude,
          longitude: longitude,
          latitudeDelta: MIN_DELTA,
          longitudeDelta: MIN_DELTA
        },
        {
          center: {
            lat: parseFloat(latitude),
            lng: parseFloat(longitude)
          },
          zoom: 15
        },
        true
      );
    }
  };

  const onGoVendor = (restaurant) => {
    props.setVendorCart(restaurant);
    navigate(`/vendor/${restaurant.id}/${restaurant.order_method}`);
  };

  const onGoUserProfile = (user) => {
    navigate(`/snapfooder/${user.id}`);
  };

  const onEnterChannel = async (partner) => {
    let found_channel = await findChannel(props.user.id, partner.id);
    if (found_channel != null) {
      navigate(`/messages/${found_channel.id}`);
    } else {
      setState({ ...state, isCreatingChannel: true });
      let channelID = await createSingleChannel(props.user, partner);
      setState({ ...state, isCreatingChannel: false });
      if (channelID != null) {
        navigate(`/messages/${channelID}`);
      } else {
        console.error(t('alerts.error'), t('checkout.something_is_wrong'));
      }
    }
  };

  const onBoundChange = ({ bounds }) => {
    if (myLoc == null) {
      return;
    }

    const size = {
      width: window.innerWidth, // Map width in pixels
      height: window.innerHeight // Map height in pixels
    };

    const { center, zoom } = fitBounds({ ne: bounds.ne, sw: bounds.sw }, size);

    let latitudeDelta = Math.abs(bounds.ne.lat - bounds.se.lat);
    let longitudeDelta = Math.abs(bounds.nw.lng - bounds.ne.lng);

    let center_lat = center.lat;
    let center_lng = center.lng;
    if (center_lng < -180) {
      center_lng = center_lng + 360;
    }

    const changed_region = {
      latitude: center_lat,
      longitude: center_lng,
      latitudeDelta: latitudeDelta || MIN_DELTA,
      longitudeDelta: longitudeDelta || MIN_DELTA
    };
    loadData(
      changed_region,
      {
        center,
        zoom
      },
      false
    );
  };

  const loadData = (region, map_pos, isForce) => {
    if (
      isForce !== true &&
      mapPos != null &&
      Math.abs(region.latitude - mapPos.center.lat) <= region.latitudeDelta / 2 &&
      Math.abs(region.longitude - mapPos.center.lng) <= region.longitudeDelta / 2
    ) {
      return;
    }

    ApiFactory.post(`users/snapfooders-range`, {
      latitude: region.latitude,
      longitude: region.longitude,
      latitudeDelta: region.latitudeDelta,
      longitudeDelta: region.longitudeDelta
    }).then(
      ({ data }) => {
        if (_isMounted.current == true) {
          setState({
            ...state,
            loading: false,
            snapfooders: data.snapfooders || [],
            vendors: data.vendors || []
          });
          setMapPos(map_pos);

          props.setTmpSnapfoodMapData({
            latitude: map_pos.center.lat,
            longitude: map_pos.center.lng,
            zoom: map_pos.zoom,
            snapfooders: data.snapfooders || [],
            vendors: data.vendors || []
          });
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current == true) {
          setState({
            ...state,
            loading: false
          });
          setMapPos(map_pos);
        }
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const _renderMap = () => {
    if (mapPos == null) {
      return null;
    }
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: Config.SocialMapScreenStyles
        }}
        zoom={mapPos.zoom}
        center={mapPos.center}
        onChange={onBoundChange}>
        <UserMarker key={'me'} lat={parseFloat(myLoc.latitude)} lng={parseFloat(myLoc.longitude)} />
        {state.snapfooders.map((value) => (
          <SnapfooderMarker
            key={'snapfooders_' + value.id}
            lat={parseFloat(value.latitude)}
            lng={parseFloat(value.longitude)}
            user_id={value.id}
            user={value}
            is_friend={value.is_friend}
            onGoUserProfile={() => {
              onGoUserProfile(value);
            }}
            onChat={() => {
              onEnterChannel(value);
            }}
          />
        ))}
        {state.vendors.map((value) => (
          <VendorMarker
            key={'vendors_' + value.id}
            lat={parseFloat(value.latitude)}
            lng={parseFloat(value.longitude)}
            vendor_id={value.id}
            vendor={value}
            onGoVendor={() => {
              onGoVendor(value);
            }}
          />
        ))}
      </GoogleMapReact>
    );
  };

  return (
    <div className={'snapfood-map-screen'}>
      {_renderMap()}
      <Header
        left={
          <RoundIconBtn
            style={{
              ...styles.headerBtn
            }}
            icon={<ChevronLeft size={22} color={Theme.colors.text} />}
            onClick={() => {}}
          />
        }
        right={
          <RoundIconBtn
            style={styles.headerBtn}
            icon={<Groups size={20} color={Theme.colors.text} />}
            onClick={() => {
              navigate(ROUTES_NAMES.snapfooders);
            }}
          />
        }
        style={styles.titleContainer}
      />
      <LoadingModal showModal={state.isCreatingChannel} />
    </div>
  );
};

const styles = {
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'absolute',
    top: 15,
    left: 0
  },
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white }
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  coordinates: app.coordinates,
  tmpSnapfoodMapData: app.tmpSnapfoodMapData
});

SnapfoodMapScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  tmpSnapfoodMapData: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    zoom: PropTypes.number,
    my_latitude: PropTypes.number,
    my_longitude: PropTypes.number,
    snapfooders: PropTypes.array,
    vendors: PropTypes.array
  }),
  setVendorCart: PropTypes.func,
  setTmpSnapfoodMapData: PropTypes.func
};

export default connect(mapStateToProps, {
  setVendorCart,
  setTmpSnapfoodMapData
})(SnapfoodMapScreen);
