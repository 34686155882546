import React, { useEffect, useState, useRef } from 'react';
import LocationMarker from '../../../components/Markers/LocationMarker';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';
import Geocode from 'react-geocode';
import { Config } from '../../../constants';
import Header from '../../../components/Header';
import { LocationInput } from '../../../components/Inputs';
import { MainBtn, RoundIconBtn } from '../../../components/Buttons';
import { useTranslation } from 'react-i18next';
import { extractCityInfo } from '../../../utils/common';
import { setTmpLocationPicked } from '../../../store/actions/app';
import './address-map.css';
import { ChevronLeft } from '@styled-icons/boxicons-regular';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const AddressMap = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [curLoc, setCurLoc] = useState({
    latitude: Config.TIRANA_CITY_LOCATION.latitude,
    longitude: Config.TIRANA_CITY_LOCATION.longitude,
    street: '',
    building: '',
    country: '',
    city: '',
    formatted_address: ''
  });

  const curLocData = useRef(curLoc);

  useEffect(() => {
    if (
      props.tmp_new_address.coords != null &&
      props.tmp_new_address.coords.latitude != null &&
      props.tmp_new_address.coords.longitude != null
    ) {
      _changeMarkerPosition(
        parseFloat(props.tmp_new_address.coords.latitude),
        parseFloat(props.tmp_new_address.coords.longitude)
      );
    } else {
      _changeMarkerPosition(props.coordinates.latitude, props.coordinates.longitude);
    }
  }, []);

  const _changeLocData = (data) => {
    curLocData.current = data;
    setCurLoc(data);
  };

  const _changeMarkerPosition = (lat, lng) => {
    Geocode.setLanguage(i18n.language || Config.DEFAULT_LANG);
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        let data = extractCityInfo(response.results[0].address_components);
        _changeLocData({
          formatted_address: address || '',
          street: data.street,
          building: data.building,
          country: data.country,
          city: data.city,
          latitude: lat,
          longitude: lng
        });
      },
      (error) => {
        console.error('Geocode.fromLatLng', error);
      }
    );
  };

  const _saveLocation = (e) => {
    e.preventDefault();

    let tmpAddress = {
      coords: {
        latitude: curLoc.latitude,
        longitude: curLoc.longitude
      },
      street: curLoc.street,
      building: curLoc.building,
      country: curLoc.country,
      city: curLoc.city
    };
    props.setTmpLocationPicked({
      ...props.tmp_new_address,
      ...tmpAddress
    });

    setTimeout(() => {
      navigate(-1);
    }, 200);
  };

  return (
    <div className={'address-map-screen'}>
      <div className={'map-view'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'] }}
          // defaultCenter={[curLoc.latitude, curLoc.longitude]}
          center={[curLoc.latitude, curLoc.longitude]}
          defaultZoom={12}
          options={{
            styles: Config.SocialMapScreenStyles
          }}
          onClick={(e) => {
            _changeMarkerPosition(e.lat, e.lng);
          }}>
          <LocationMarker lat={curLoc.latitude} lng={curLoc.longitude} />
        </GoogleMapReact>
      </div>
      <div className={'w100 align-col-middle search-bar'}>
        <div className={'w100'}>
          <LocationInput
            defaultInput={curLoc.formatted_address}
            placeholder={t('search_location.search_location')}
            onChange={(loc) => {
              _changeLocData(loc);
            }}
          />
          <MainBtn
            title={t('address_new.add_address')}
            onClick={_saveLocation}
            style={{ marginTop: 20 }}
          />
        </div>
      </div>
      <Header
        left={
          <RoundIconBtn
            style={{
              ...styles.headerBtn
            }}
            icon={<ChevronLeft size={22} color={Theme.colors.text} />}
            onClick={() => {}}
          />
        }
        style={styles.titleContainer}
      />
    </div>
  );
};

const styles = {
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    position: 'absolute',
    top: 15,
    left: 0
  },
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white }
};

AddressMap.propTypes = {
  tmp_new_address: PropTypes.shape({
    address_type: PropTypes.string,
    notes: PropTypes.string,
    street: PropTypes.string,
    building: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    id: PropTypes.number,
    floor: PropTypes.string,
    apartment: PropTypes.string,
    coords: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number
    })
  }),
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  setTmpLocationPicked: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  coordinates: app.coordinates,
  tmp_new_address: app.tmp_new_address
});

export default connect(mapStateToProps, {
  setTmpLocationPicked
})(AddressMap);
