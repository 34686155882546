import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { AuthInput } from '../../../components/Inputs';
import AuthLayout from '../auth-layout';
import { updateProfileDetails } from '../../../store/actions/auth';
import PropTypes from 'prop-types';
import { extractErrorMessage, isEmpty } from '../../../utils/common';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';
import { CircularProgress } from '@mui/material';
import { ROUTES_NAMES } from '../../../constants';
import { ApiFactory } from '../../../services';
import { setHasVerifiedPhone } from '../../../store/actions/auth';
import MainBtn from '../../../components/Buttons/MainBtn';

const PhoneVerificationScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const code1_ref = useRef(null);
  const code2_ref = useRef(null);
  const code3_ref = useRef(null);
  const code4_ref = useRef(null);
  const code5_ref = useRef(null);
  const code6_ref = useRef(null);

  const [state, setState] = useState({
    loading: false,
    phone: '',
    loadingResend: false,
    status: 'sms', //'call'
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: ''
  });

  useEffect(() => {
    if (props.user != null && isEmpty(props.user.phone)) {
      navigate(ROUTES_NAMES.editPhone);
    }
  }, [props.user]);

  const verify = async () => {
    const { code1, code2, code3, code4, code5, code6 } = state;
    setState({ ...state, loading: true });
    ApiFactory.post('verify-code', { code: code1 + code2 + code3 + code4 + code5 + code6 }).then(
      async () => {
        setState({ ...state, loading: false });
        await props.setHasVerifiedPhone(true);
      },
      async () => {
        setState({
          ...state,
          loading: false,
          code1: '',
          code2: '',
          code3: '',
          code4: '',
          code5: '',
          code6: ''
        });
        return confirmAlert({
          title: t('attention'),
          message: t('phone_verification.wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const resend = async () => {
    if (state.loadingResend) {
      return;
    }
    const { user } = props;
    await setState({ ...state, loadingResend: true });
    ApiFactory.post('send-verification-code', { phone: user.phone }).then(
      () => {
        setState({ ...state, loadingResend: false });
      },
      (error) => {
        setState({ ...state, loadingResend: false });
        return confirmAlert({
          title: t('attention'),
          message: t(extractErrorMessage(error)),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const changeNumber = () => {
    navigate(ROUTES_NAMES.editPhone);
  };

  const getCodeInput = (item) => {
    if (item === 1) {
      return state.code1;
    } else if (item === 2) {
      return state.code2;
    } else if (item === 3) {
      return state.code3;
    } else if (item === 4) {
      return state.code4;
    } else if (item === 5) {
      return state.code5;
    } else if (item === 6) {
      return state.code6;
    }
  };

  return (
    <div className={'verify-phone-screen'}>
      <AuthLayout>
        <div className={'scrollview'}>
          <div style={styles.title}>{t('phone_verification.header')}</div>
          <div style={{ ...styles.descTxt, marginTop: 18 }}>
            {t('phone_verification.text')}
            <div style={styles.phoneNumber} onClick={changeNumber}>
              {isEmpty(props.user.phone)
                ? t('auth_verification.add_phone_number')
                : props.user.phone}
            </div>
          </div>
          <div
            className={'verify-code-inputs align-middle w100'}
            style={{ marginTop: 30, justifyContent: 'space-between' }}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <AuthInput
                key={item}
                type={'number'}
                text={getCodeInput(item)}
                style={{ marginBottom: 20, width: 50, height: 50 }}
                setRef={(input) => {
                  if (item === 1) {
                    code1_ref.current = input;
                  } else if (item === 2) {
                    code2_ref.current = input;
                  } else if (item === 3) {
                    code3_ref.current = input;
                  } else if (item === 4) {
                    code4_ref.current = input;
                  } else if (item === 5) {
                    code5_ref.current = input;
                  } else if (item === 6) {
                    code6_ref.current = input;
                  }
                }}
                onChange={(code) => {
                  if (item === 1) {
                    if (code.length <= 1) {
                      setState({ ...state, code1: code });
                    }
                    if (code.length >= 1) {
                      if (code2_ref.current != null) {
                        code2_ref.current.focus();
                      }
                    }
                  } else if (item === 2) {
                    if (code.length <= 1) {
                      setState({ ...state, code2: code });
                    }
                    if (code.length >= 1) {
                      if (code3_ref.current != null) {
                        code3_ref.current.focus();
                      }
                    }
                    if (code.length == 0) {
                      code1_ref.current.focus();
                    }
                  } else if (item === 3) {
                    if (code.length <= 1) {
                      setState({ ...state, code3: code });
                    }
                    if (code.length >= 1) {
                      if (code4_ref.current != null) {
                        code4_ref.current.focus();
                      }
                    }
                    if (code.length == 0) {
                      code2_ref.current.focus();
                    }
                  } else if (item === 4) {
                    if (code.length <= 1) {
                      setState({ ...state, code4: code });
                    }
                    if (code.length >= 1) {
                      if (code5_ref.current != null) {
                        code5_ref.current.focus();
                      }
                    }
                    if (code.length == 0) {
                      code3_ref.current.focus();
                    }
                  } else if (item === 5) {
                    if (code.length <= 1) {
                      setState({ ...state, code5: code });
                    }
                    if (code.length >= 1) {
                      if (code6_ref.current != null) {
                        code6_ref.current.focus();
                      }
                    }
                    if (code.length == 0) {
                      code4_ref.current.focus();
                    }
                  } else if (item === 6) {
                    if (code.length <= 1) {
                      setState({ ...state, code6: code });
                    }
                    if (code.length >= 1) {
                      //Keyboard.dismiss()
                    }
                    if (code.length == 0) {
                      code5_ref.current.focus();
                    }
                  }
                }}
              />
            ))}
          </div>
          <MainBtn
            title={t('phone_verification.button')}
            loading={state.loading}
            style={{ width: '100%', marginTop: 50 }}
            onClick={verify}
          />
        </div>
        <div className={'align-middle'} style={{ marginTop: 80 }}>
          <div style={styles.callmeTxt}> {t('phone_verification.not_received')} </div>
          <div onClick={resend}>
            {state.loadingResend ? (
              <CircularProgress size={24} />
            ) : (
              <div style={styles.resendText}>{t('phone_verification.resend')}</div>
            )}
          </div>
        </div>
      </AuthLayout>
    </div>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    fontSize: 18,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.bold
  },
  descTxt: {
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text,
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 18
  },
  phoneNumber: {
    fontSize: 16,
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.red1,
    lineHeight: '24px',
    textDecorationLine: 'underline'
  },
  backBtn: {
    cursor: 'pointer',
    position: 'absolute',
    left: 20,
    top: 30,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 20
  },
  callmeTxt: {
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.text,
    marginRight: 6
  },
  resendText: {
    fontSize: 16,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.cyan2
  }
};

PhoneVerificationScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasLocation: PropTypes.bool,
  seenOnboard: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool,
  user: PropTypes.shape({
    phone: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func,
  setHasVerifiedPhone: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    isLoggedIn: app.isLoggedIn,
    hasLocation: app.hasLocation,
    seenOnboard: app.seenOnboard,
    hasVerifiedPhone: app.hasVerifiedPhone,
    user: app.user
  };
}

export default connect(mapStateToProps, {
  updateProfileDetails,
  setHasVerifiedPhone
})(PhoneVerificationScreen);
