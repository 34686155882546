import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationMarker from '../../../components/Markers/LocationMarker';
import { Config } from '../../../constants';
import { LocationInput } from '../../../components/Inputs';
import { MainBtn, RoundIconBtn } from '../../../components/Buttons';
import { getAddressByCoordinates, getCurrentLocation } from '../../../services/location';
import './index.css';
import PropTypes from 'prop-types';
import { ChevronLeft } from '@styled-icons/material';
import { Theme } from '../../../assets';
import { sendMessage } from '../../../services/chat';
import { confirmAlert } from 'react-confirm-alert';
import { extractErrorMessage } from '../../../utils/common';

const PickupLocation = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { channel_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [curLoc, setCurLoc] = useState({
    latitude: props.coordinates.latitude,
    longitude: props.coordinates.longitude
  });

  const curLocData = useRef(curLoc);

  useEffect(() => {
    _setCurrentLocation();
  }, []);

  const _changeLocData = (data) => {
    curLocData.current = data;
    setCurLoc(data);
  };

  const _setCurrentLocation = async () => {
    try {
      const coords = await getCurrentLocation();
      if (coords) {
        _changeMarkerPosition(coords.latitude, coords.longitude);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const _changeMarkerPosition = async (latitude, longitude) => {
    try {
      const address = await getAddressByCoordinates({
        latitude: latitude,
        longitude: longitude
      });
      if (address) {
        _changeLocData(address);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const _shareLocation = async (e) => {
    e.preventDefault();
    try {
      if (channel_id == null) {
        return null;
      }
      setLoading(true);

      let newMsg = {
        user: {
          _id: props.user.id,
          full_name: props.user.full_name,
          photo: props.user.photo,
          phone: props.user.phone,
          email: props.user.email
        },
        map: {
          coords: {
            latitude: curLoc.latitude,
            longitude: curLoc.longitude
          },
          type: 1 // 0 : my location, 1 : a location
        }
      };
      await sendMessage(channel_id, props.user.id, newMsg);

      navigate(-1);
    } catch (error) {
      setLoading(false);
      console.warn('on setup location', error);
      confirmAlert({
        title: t('alerts.error'),
        message: extractErrorMessage(error),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  return (
    <div className={'location-pickup-screen'}>
      <div className={'map-view'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'] }}
          center={[curLoc.latitude, curLoc.longitude]}
          defaultZoom={12}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: Config.SocialMapScreenStyles
          }}
          onClick={(e) => {
            _changeMarkerPosition(e.lat, e.lng);
          }}>
          <LocationMarker lat={curLoc.latitude} lng={curLoc.longitude} />
        </GoogleMapReact>
      </div>
      <div className={'w100 align-col-middle bottom-view'}>
        <MainBtn
          disabled={loading}
          loading={loading}
          title={t('social.chat.share_pin_location')}
          onClick={_shareLocation}
        />
      </div>
      <div className={'align-middle ph-20 header'}>
        <RoundIconBtn
          style={styles.headerBtn}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div style={{ paddingLeft: 20, flex: 1 }}>
          <LocationInput
            style={{ backgroundColor: '#fff' }}
            defaultInput={curLoc.formatted_address}
            placeholder={t('search_location.search_location')}
            onChange={(loc) => {
              _changeLocData(loc);
            }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  headerBtn: { width: 40, height: 40, borderRadius: 8, backgroundColor: Theme.colors.white }
};

PickupLocation.propTypes = {
  isLoggedIn: PropTypes.bool,
  user: {
    id: PropTypes.number,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  },
  coordinates: {
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    coordinates: app.coordinates,
    isLoggedIn: app.isLoggedIn
  };
}

export default connect(mapStateToProps, {})(PickupLocation);
