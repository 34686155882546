import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { MainBtn } from '../../../components/Buttons';
import BlockSpinner from '../../../components/BlockSpinner';
import { Theme } from '../../../assets';
import { ROUTES_NAMES } from '../../../constants';

import FaceBook from '../../../components/SocialLogin/FacebookLogin';
import Google from '../../../components/SocialLogin/GoogleLogin';
import Apple from '../../../components/SocialLogin/AppleLogin';

import {
  setAsLoggedIn,
  updateProfileDetails,
  facebookLogin,
  googleLogin,
  appleLogin
  // getLoggedInUser,
  // setUserNeedLogin,
} from '../../../store/actions/auth';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import Logo from '../../../components/Logo';
import './index.css';
import { ChevronLeft } from '@styled-icons/material';
import PropTypes from 'prop-types';
import { loadUserSetting } from '../../../services/user';
import { confirmAlert } from 'react-confirm-alert';
import { extractErrorMessage } from '../../../utils/common';

const WelcomeScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.isLoggedIn) {
      navigate(-1);
    }
  }, [props.isLoggedIn]);

  const handleFbLogin = async (accessToken) => {
    setLoading(true);
    props.facebookLogin(accessToken.toString()).then(
      async (logged_user_data) => {
        setLoading(false);
        await loadUserSetting(props, logged_user_data);
        // navigate(-1);
      },
      (error) => {
        setLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const handleAppleLogin = async (appleAuthRequestResponse) => {
    const { user, identityToken, email, fullName } = appleAuthRequestResponse;
    setLoading(true);
    props.appleLogin({ user, identityToken, email, fullName }).then(
      async (logged_user_data) => {
        setLoading(false);
        await loadUserSetting(props, logged_user_data);
        // navigate(-1);
      },
      (error) => {
        setLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const handleGoogleLogin = (id_token) => {
    setLoading(true);
    props.googleLogin(id_token).then(
      async (logged_user_data) => {
        setLoading(false);
        await loadUserSetting(props, logged_user_data);
        // navigate(-1);
      },
      (error) => {
        setLoading(false);
        confirmAlert({
          title: t('attention'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const _renderSocialBtns = () => {
    if (loading) {
      return (
        <div className={'align-col-middle loading-wrapper'}>
          <BlockSpinner style={{ minHeight: 80 }} />
        </div>
      );
    } else {
      return (
        <div className={'socials'}>
          <FaceBook onLoginResponse={handleFbLogin} />
          <Google onLoginResponse={handleGoogleLogin} />
          <Apple onLoginResponse={handleAppleLogin} />
        </div>
      );
    }
  };

  const _renderDivider = () => {
    return (
      <div className={'align-middle w100'}>
        <div className={'divider_line'} />
        <div className={'or-txt'}>{t('or')}</div>
        <div className={'divider_line'} />
      </div>
    );
  };

  return (
    <div className={'align-col-middle welcome-screen'}>
      <div className={'align-col-middle top'}>
        <Logo className={'logo'} />
      </div>
      <div className={'w100 align-col-middle'}>
        <MainBtn
          title={t('auth_register.submit')}
          onClick={() => {
            navigate(ROUTES_NAMES.login + '?type=register');
          }}
        />
        <div
          className={'login-btn'}
          onClick={() => {
            navigate(ROUTES_NAMES.login + '?type=login');
          }}>
          {t('auth_login.login_button')}
        </div>
        {_renderDivider()}
        {_renderSocialBtns()}
      </div>
      <div
        className={'back-btn'}
        onClick={() => {
          navigate(-1);
        }}>
        <ChevronLeft size={28} color={Theme.colors.text} />
      </div>
    </div>
  );
};

WelcomeScreen.propTypes = {
  isLoggedIn: PropTypes.bool,
  facebookLogin: PropTypes.func,
  appleLogin: PropTypes.func,
  googleLogin: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user,
    isLoggedIn: app.isLoggedIn,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  setAsLoggedIn,
  facebookLogin,
  googleLogin,
  appleLogin,
  updateProfileDetails,
  addDefaultAddress,
  getAddresses,
  setAddress
})(WelcomeScreen);
