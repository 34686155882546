import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import UserItem from '../../../components/Chats/UserItem';
import NoFriendList from '../../../components/Empty/NoFriendList';
import './index.css';
import { SearchInput } from '../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import { Theme } from '../../../assets';
import InfiniteScroll from 'react-infinite-scroller';
import { CircularProgress } from '@mui/material';
import { ApiFactory } from '../../../services';
import { confirmAlert } from 'react-confirm-alert';

const Snapfooders = () => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: null,
    snapfooders: [],
    page: 1,
    totalPages: 1
  });

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getSnapfooders(searchTerm, state.page);
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getSnapfooders = async (search, page) => {
    const params = [`name=${search}`, `page=${page}`];
    if (state.loading == true) {
      return;
    }
    setState({
      ...state,
      loading: true
    });
    ApiFactory.get(`users/snapfooders?${params.join('&')}`).then(
      ({ data }) => {
        const res_snapfooders = data['snapfooders'];
        if (_isMounted.current === true) {
          if (page > 1) {
            const currentSnapfooderIds = state.snapfooders.map((x) => x.id);
            const newItems = res_snapfooders['data'].filter(
              (x) => currentSnapfooderIds.indexOf(x.id) === -1
            );
            setState({
              ...state,
              loading: false,
              snapfooders: state.snapfooders.concat(newItems),
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          } else {
            setState({
              ...state,
              loading: false,
              snapfooders: res_snapfooders['data'] || [],
              page: res_snapfooders['current_page'],
              totalPages: res_snapfooders['last_page']
            });
          }
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        setState({
          ...state,
          loading: false
        });
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div className={'flex_1 snapfooder-list-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('social.snapfooders')}
      />
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('social.search.snapfooders')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45 }}
          value={searchTerm}
          onChange={(text) => {
            setSearchTerm(text);
            getSnapfooders(text, 1);
          }}
        />
      </div>
      <div className={'scrollview'}>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            if (state.page < state.totalPages) {
              getSnapfooders(searchTerm, state.page + 1);
            }
          }}
          hasMore={state.page < state.totalPages}
          loader={
            <div className="align-col-middle mt12 mb20" key={0}>
              <CircularProgress size={20} color="primary" />
            </div>
          }
          useWindow={false}>
          <div style={{ height: 20 }} />
          {state.loading === false && state.snapfooders.length === 0 ? (
            <NoFriendList
              title={t('social.no_snapfooders')}
              desc={t('social.no_snapfooders_desc')}
            />
          ) : (
            state.snapfooders.map((item) => (
              <UserItem
                key={item.id}
                item={item}
                id={item.id}
                invite_status={item.invite_status}
                type="invite_status"
                onClick={() => {
                  navigate(`/snapfooder/${item.id}`);
                }}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </InfiniteScroll>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn
});

export default connect(mapStateToProps, {})(Snapfooders);
