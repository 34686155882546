import React from 'react';
import { useNavigate } from 'react-router-dom';
import Svg_location from '../../../assets/svgs/tour/location_illustration.svg';
import { MainBtn } from '../../../components/Buttons';
import './index.css';
import { TIRANA_CITY_LOCATION } from '../../../constants/config';
import { checkLocationPermission, getCurrentLocation } from '../../../services/location';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import { updateProfileDetails } from '../../../store/actions/auth';
import { useTranslation } from 'react-i18next';

const AlmostDone = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const _onSetupLocation = async (e) => {
    e.preventDefault();
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        const location = await getCurrentLocation();
        if (location) {
          navigate(`/setup-location?lat=${location.latitude}&lng=${location.longitude}`);
          return;
        }
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    navigate(
      `/setup-location?lat=${TIRANA_CITY_LOCATION.latitude}&lng=${TIRANA_CITY_LOCATION.longitude}`
    );
  };

  const _onLater = (e) => {
    e.preventDefault();
    setDefaultAddress();
  };

  const setDefaultAddress = async () => {
    try {
      if (props.isLoggedIn) {
        await props.updateProfileDetails({
          latitude: TIRANA_CITY_LOCATION.latitude,
          longitude: TIRANA_CITY_LOCATION.longitude
        });

        let address_data = {
          lat: TIRANA_CITY_LOCATION.latitude,
          lng: TIRANA_CITY_LOCATION.longitude,
          country: TIRANA_CITY_LOCATION.country,
          city: TIRANA_CITY_LOCATION.city,
          street: TIRANA_CITY_LOCATION.street
        };
        await props.addDefaultAddress(address_data);
        await props.getAddresses();
      }
    } catch (error) {
      console.warn('setDefaultAddress', error);
    }
    await props.setAddress({
      coordinates: {
        latitude: TIRANA_CITY_LOCATION.latitude,
        longitude: TIRANA_CITY_LOCATION.longitude
      },
      address: {
        country: TIRANA_CITY_LOCATION.country,
        city: TIRANA_CITY_LOCATION.city,
        street: TIRANA_CITY_LOCATION.street
      }
    });
  };

  return (
    <div className={'align-col-start almostdone-screen'}>
      <img src={Svg_location} className={'top-img'} />
      <h2>{t('allmost_done.title')}</h2>
      <p>{t('allmost_done.description')}</p>
      <div className={'flex_1'} />
      <MainBtn title={t('allmost_done.button')} onClick={_onSetupLocation} />
      <div className={'later-btn'} onClick={_onLater}>
        {t('allmost_done.later')}
      </div>
    </div>
  );
};

AlmostDone.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasLocation: PropTypes.bool,
  seenOnboard: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool,
  setAddress: PropTypes.func,
  addDefaultAddress: PropTypes.func,
  getAddresses: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    hasLocation: app.hasLocation,
    coordinates: app.coordinates,
    isLoggedIn: app.isLoggedIn,
    seenOnboard: app.seenOnboard,
    hasVerifiedPhone: app.hasVerifiedPhone
  };
}

export default connect(mapStateToProps, {
  setAddress,
  addDefaultAddress,
  getAddresses,
  updateProfileDetails
})(AlmostDone);
