import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Config } from '../../../constants';
import UserMarker from './UserMarker';
import './index.css';
import PropTypes from 'prop-types';

const MapView = ({ lat, lng }) => {
  return (
    <div className={'snapfooder-detail-map-view flex_1'}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
        center={[lat, lng]}
        zoom={12}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: Config.SocialMapScreenStyles
        }}>
        <UserMarker lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  );
};
MapView.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};
export default React.memo(MapView);
