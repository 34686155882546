import { Config } from '../constants';
import Svg_aquarius from '../assets/svgs/zodiac/aquarius.svg';
import Svg_aries from '../assets/svgs/zodiac/aries.svg';
import Svg_cancer from '../assets/svgs/zodiac/cancer.svg';
import Svg_capricorn from '../assets/svgs/zodiac/capricorn.svg';
import Svg_gemini from '../assets/svgs/zodiac/gemini.svg';
import Svg_leo from '../assets/svgs/zodiac/leo.svg';
import Svg_libra from '../assets/svgs/zodiac/libra.svg';
import Svg_pisces from '../assets/svgs/zodiac/pisces.svg';
import Svg_sagittarius from '../assets/svgs/zodiac/sagittarius.svg';
import Svg_scorpio from '../assets/svgs/zodiac/scorpio.svg';
import Svg_virgo from '../assets/svgs/zodiac/virgo.svg';
import Svg_taurus from '../assets/svgs/zodiac/taurus.svg';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
export const PrintLog = (object) => {
  //console.log(object);
};

export const createUUID = () => {
  let s = [];
  let hexDigits = '0123456789ABCDEF';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';

  let uuid = s.join('');
  return uuid;
};

export const validateEmailAddress = (email) => {
  let reg =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
};

export const validatePhoneNumber = (text) => {
  const PHONE_REGEXP = /^06[6-9][1-9]\d{6}$|04[3-9][1-9]\d{5}$/;
  return PHONE_REGEXP.test(text);
};

export const validateUserData = ({ full_name, email, phone, password, pass2 }, isNew) => {
  return new Promise((resolve, reject) => {
    const mobileValidate = phone && validatePhoneNumber(phone.replace(/\s/g, ''));
    const emailValidate = email && validateEmailAddress(email);
    if (!full_name || !email || !phone || !phone.replace(/\s/g, '') || (isNew && !password)) {
      reject('alerts.fill_all_fields');
    } else if (isNew && password != pass2) {
      reject('alerts.password_mismatch');
    } else if (emailValidate === false) {
      reject('alerts.wrong_email_format');
    } else if (mobileValidate === false) {
      reject('alerts.wrong_phone_format');
    }
    // todo fix this
    // else if (password.length < 6) {
    //   reject('auth_register.validate_cell_pass_min');
    //   reject();
    // }
    else {
      resolve();
    }
  });
};

export const isFullURL = (str) => {
  if (str == null) {
    return false;
  }
  return str.includes('http');
};

export const isEmpty = (string) => {
  if (string != null && string.length > 0) {
    return false;
  }
  return true;
};

export const getImageFullURL = (photo) => {
  if (isFullURL(photo)) {
    return photo;
  }
  if (photo === 'x') {
    return Config.USER_PROFILE_IMG_BASE_URL + 'default?';
  }
  if (photo === 'default') {
    return Config.USER_PROFILE_IMG_BASE_URL + 'default?';
  }
  return Config.USER_PROFILE_IMG_BASE_URL + (isEmpty(photo) ? 'default?' : photo);
};

export const convertTimestamp2Date = (timestamp) => {
  if (timestamp == null) return new Date();
  return new Date(timestamp.seconds * 1000);
};

export const seconds2Time = (seconds) => {
  // const h = parseInt(seconds / (60 * 60));
  const m = parseInt((seconds % (60 * 60)) / 60);
  const s = parseInt(seconds % 60);

  return (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
  // return ((h < 10 ? '0' + h : h) + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s));
};

export const checkInSameWeek = (date = new Date()) => {
  let week_days = [];
  let today = new Date();
  let day_week = today.getDay();
  for (let i = -day_week; i < 0; i++) {
    week_days.push(moment(today).add(i, 'days').format('DD/MM/YYYY'));
  }
  for (let i = 0; i < 7 - day_week; i++) {
    week_days.push(moment(today).add(i, 'days').format('DD/MM/YYYY'));
  }
  return week_days.includes(moment(date).format('DD/MM/YYYY'));
};

export const extractCityInfo = (addressComp) => {
  let street = '';
  let city = '';
  let country = '';
  let building = '';
  for (let i = 0; i < addressComp.length; i++) {
    // PrintLog(details.address_components[i].types, details.address_components[i].long_name)
    // PrintLog('details.geometry.location', details.geometry.location)
    if (addressComp[i].types.includes('neighborhood') || addressComp[i].types.includes('route')) {
      street = addressComp[i].long_name;
    }
    // building
    if (addressComp[i].types.includes('premise') || addressComp[i].types.includes('floor')) {
      building = addressComp[i].long_name;
    }
    // city
    if (addressComp[i].types.includes('locality') || addressComp[i].types.includes('postal_town')) {
      city = addressComp[i].long_name;
    }
    // country
    if (addressComp[i].types.includes('country')) {
      country = addressComp[i].long_name;
    }
  }

  return {
    street,
    city,
    country,
    building
  };
};

export const extractErrorMessage = (error) => {
  if (error == null) {
    return 'generic_error';
  }
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    if (error.length) {
      error = error[0];
    } else {
      error = error[Object.keys(error)[0]];
      if (typeof error === 'object') {
        error = error[0];
      }
    }
  }
  if (error == null) {
    return 'generic_error';
  }
  if (error.error) {
    error = error.error;
  }
  if (error.body) {
    error = error.body.error;
  }
  if (error.data) {
    error = error.data;
  }
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error.message === 'string') {
    return error.message;
  }
  try {
    Object.keys(error.message).forEach((key) => {
      if (typeof error.message[key] === 'string') {
        return error.message[key];
      } else {
        error.message[key].forEach((message) => {
          return message;
        });
      }
    });
  } catch (e) {
    return 'generic_error';
  }
};

export const openExternalUrl = (url) => {
  try {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
export const findZodiacSign = (birthday) => {
  const days = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
  const sign_icons = [
    Svg_aquarius,
    Svg_pisces,
    Svg_aries,
    Svg_taurus,
    Svg_gemini,
    Svg_cancer,
    Svg_leo,
    Svg_virgo,
    Svg_libra,
    Svg_scorpio,
    Svg_sagittarius,
    Svg_capricorn
  ];

  const date = moment(birthday).toDate();

  let month = date.getMonth();
  let day = date.getDate();
  if (month === 0 && day <= 20) {
    month = 11;
  } else if (day < days[month]) {
    month--;
  }

  return sign_icons[month];
};

export const calculateCartTotal = (items) => {
  let total = 0;

  items.map((item) => {
    let productPrice = parseFloat(item.price);
    if (item.options) {
      item.options.map((option) => (productPrice += parseFloat(option.price)));
    }
    total += parseFloat(productPrice * parseFloat(item.quantity));
  });

  return total;
};
