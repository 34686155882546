import { combineReducers } from 'redux';
// import reducers
import alert from './alert';
import app from './app';
import shop from './shop';
import orders from './orders';

export default combineReducers({
  alert,
  app,
  shop,
  orders,
});
