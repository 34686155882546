import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Header from '../../../../components/Header';
import ReferralsHistItem from '../../../../components/Profile/ReferralsHistItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';

const ReferralsHistory = () => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();

  const [items, setHistory] = useState([]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    let tmp = [];
    for (let i = 1; i < 12; i++) {
      tmp.push({
        id: i.toString(),
        registered_user: {
          full_name: 'Amber Porter'
        },
        invite_code: '85AGC36',
        date: '10/11/2021 1:30PM',
        earned_amount: i % 3 !== 0 ? 20 : 0,
        status: i % 3 === 0 ? 'expired' : 'used'
      });
    }

    setHistory(tmp);
    setLoading(false);
  }, []);

  return (
    <div className={'flex_1 referrals-status-screen'}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={t('refer_rewards.referrals_hist')}
      />
      <div className={'flex_1 scrollview'}>
        <div>
          <div style={{ height: 20 }} />
          {isLoading === false && items.length === 0 ? (
            <NoFriendList
              title={t('social.no_invitations')}
              desc={t('social.no_received_invitations')}
            />
          ) : (
            items.map((item) => (
              <ReferralsHistItem
                key={item.id}
                id={item.id}
                is_received={true}
                data={item}
                onClick={() => {}}
              />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn
});

export default connect(mapStateToProps, {})(ReferralsHistory);
