import { ACTION_TYPES } from '../../constants';
import { OrderType_Delivery, VSort_Title } from '../../constants/config';

const initialState = {
  isLoggedIn: false,
  hasVerifiedPhone: false,
  hasLocation: false,
  seenOnboard: false,
  isSidebarOpened: false,

  user: {},
  tmpFoodData: {},
  language: 'sq',
  tmp_new_address: {},
  coordinates: {},
  address: {},
  addresses: [],
  profile_blog_filter: {
    category_id: null,
    searchTerm: ''
  },
  blog_categories: [],
  home_vendor_filter: {
    vendor_type: 'vendors',
    order_type: OrderType_Delivery,
    food_categs: [],
    is_meal: false,
    is_dietary: false,
    ongoing_offer: false,
    open_now: false,
    online_payment: false,
    low_fee: null,
    high_fee: null,
    searchTerm: ''
  },
  home_vendor_sort: VSort_Title,
  home_orders_filter: {
    discount: false,
    cashback: false,
    promotion: false,
    split: false,
    searchTerm: '',
    orders_tab: 'current'
  },

  pass_changed: false,
  rewards_picked_user: null,

  chat_channels: [],

  tmp_selected_group_chat: [],
  tmpSnapfoodMapData: {},

  activeMarker: {}
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.APP_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };
    case ACTION_TYPES.APP_SET_USER_DATA:
      return { ...state, user: action.payload };
    case ACTION_TYPES.APP_SET_HAS_VERIFIED_PHONE:
      return { ...state, hasVerifiedPhone: !!action.payload };
    case ACTION_TYPES.APP_SET_LANGE: {
      return { ...state, language: action.payload || 'sq' };
    }
    case ACTION_TYPES.APP_SEEN_ONBOARD: {
      return { ...state, seenOnboard: action.payload || false };
    }

    case ACTION_TYPES.APP_SET_REWARDS_PICKED_USER: {
      return { ...state, rewards_picked_user: action.payload };
    }

    case ACTION_TYPES.APP_SET_VENDOR_FILTER: {
      return { ...state, home_vendor_filter: { ...state.home_vendor_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_SET_VENDOR_SORT: {
      return { ...state, home_vendor_sort: action.payload || VSort_Title };
    }

    case ACTION_TYPES.APP_TOGGLE_SIDE_BAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case ACTION_TYPES.SET_FOOD:
      return { ...state, tmpFoodData: action.payload || {} };
    case ACTION_TYPES.APP_APPLY_LOCATION: {
      return {
        ...state,
        hasLocation: true,
        coordinates: action.payload.coordinates,
        address: action.payload.address
      };
    }
    case ACTION_TYPES.APP_SET_ADDRESSES:
      return { ...state, addresses: action.payload || [] };
    case ACTION_TYPES.APP_SET_PROFILE_BLOG_FILTER: {
      return { ...state, profile_blog_filter: { ...state.profile_blog_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_SET_BLOG_CATEGORIES: {
      return { ...state, blog_categories: action.payload || [] };
    }
    case ACTION_TYPES.APP_SET_ORDERS_FILTER: {
      return { ...state, home_orders_filter: { ...state.home_orders_filter, ...action.payload } };
    }
    case ACTION_TYPES.APP_TMP_ADDR_PICKED: {
      return { ...state, tmp_new_address: action.payload || {} };
    }
    case ACTION_TYPES.APP_TMP_PASS_CHANGED: {
      return { ...state, pass_changed: action.payload || false };
    }
    case ACTION_TYPES.APP_SET_CHAT_CHANNELS:
      return { ...state, chat_channels: action.payload || [] };

    case ACTION_TYPES.APP_TMP_SET_SELECTED_FOR_GROUP_CHAT:
      return { ...state, tmp_selected_group_chat: action.payload || [] };

    case ACTION_TYPES.APP_TMP_SNAPFOOD_MAP_DATA: {
      return { ...state, tmpSnapfoodMapData: { ...state.tmpSnapfoodMapData, ...action.payload } };
    }

    case ACTION_TYPES.APP_SET_ACTIVE_MARKER: {
      return { ...state, activeMarker: action.payload || {} };
    }

    default:
      return state;
  }
};

export default app;
