import React, { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import HomeTabs from '../../components/Common/HomeTabs';
import { Config, ROUTES_NAMES } from '../../constants';
import { Storage } from '../../services';
import { updateCartItems } from '../../store/actions/shop';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loadUserSetting } from '../../services/user';
import {
  getLoggedInUser,
  legacyLogin,
  setAsLoggedIn,
  updateProfileDetails
} from '../../store/actions/auth';
import { setAsSeenOnboard } from '../../store/actions/auth';
import { setI18nConfig } from '../../localisations';
import { addDefaultAddress, getAddresses, setAddress, setAppLang } from '../../store/actions/app';
import AppRoutes from './appRoutes';
import PhoneVerifyRoutes from './phoneVerifyRoutes';
import SplashRoutes from './splashRoutes';
import OnboardRoutes from './onboardRoutes';
import SetupLocationRoutes from './setupLocationRoutes';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

const stripePromise = loadStripe(Config.STRIPE_PUBLISHABLE_KEY);
Geocode.setApiKey(Config.GOOGLE_MAP_API_KEY);

const App = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const bottomTabRoutes = [
    ROUTES_NAMES.home,
    ROUTES_NAMES.search,
    ROUTES_NAMES.chat,
    ROUTES_NAMES.orders,
    ROUTES_NAMES.profile
  ];

  const [isAppLoaded, setAppLoaded] = useState(false);

  useEffect(() => {
    const scrollviews = document.getElementsByClassName('scrollview');

    if (scrollviews != null && scrollviews.length > 0) {
      disableBodyScroll(scrollviews[0]);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [location.pathname]);

  useEffect(() => {
    loadLoginInfo();
  }, []);

  useEffect(() => {
    navigate('/', { replace: true });
  }, [isAppLoaded, props.isLoggedIn, props.seenOnboard, props.hasLocation, props.hasVerifiedPhone]);

  const loadSettings = async () => {
    // seen onboard
    const seenOnboard = Storage.getSeenOnboard();
    if (seenOnboard === true) {
      await props.setAsSeenOnboard();
    }

    // cart
    const cartItems = Storage.getCartItems();
    props.updateCartItems(cartItems);

    // load app lang
    setI18nConfig();
    let lang = Storage.getLanguage();
    await props.setAppLang(lang);
  };

  const loadLoginInfo = async () => {
    let logged_user_data = null;
    try {
      let token = Storage.getAppToken();
      if (token) {
        if (!token.startsWith('Bearer')) {
          token = `Bearer ${token}`;
        }
        logged_user_data = await props.legacyLogin(token);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
      await loadUserSetting(props, logged_user_data);
      // eslint-disable-next-line no-empty
    } catch (error) {}

    try {
      await loadSettings();
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setAppLoaded(true);
  };

  const renderRoute = () => {
    if (isAppLoaded) {
      if (!props.isLoggedIn && props.seenOnboard !== true) {
        return <OnboardRoutes />;
      } else if (props.isLoggedIn && props.hasVerifiedPhone !== true) {
        return <PhoneVerifyRoutes />;
      } else if (!props.hasLocation) {
        return <SetupLocationRoutes />;
      } else {
        return <AppRoutes />;
      }
    }
    return <SplashRoutes />;
  };

  return (
    <Elements stripe={stripePromise}>
      <div className={'app-layout'}>
        {renderRoute()}
        {bottomTabRoutes.includes(location.pathname) && <HomeTabs />}
      </div>
    </Elements>
  );
};

App.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasLocation: PropTypes.bool,
  seenOnboard: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool,
  legacyLogin: PropTypes.func,
  getLoggedInUser: PropTypes.func,
  updateCartItems: PropTypes.func,
  setAsSeenOnboard: PropTypes.func,
  setAppLang: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  hasLocation: app.hasLocation,
  seenOnboard: app.seenOnboard,
  hasVerifiedPhone: app.hasVerifiedPhone
});

export default connect(mapStateToProps, {
  updateCartItems,
  legacyLogin,
  getLoggedInUser,
  setAsSeenOnboard,
  updateProfileDetails,
  addDefaultAddress,
  getAddresses,
  setAddress,
  setAsLoggedIn,
  setAppLang
})(App);
