import { Storage } from './index';
import {
  // checkLocationPermission, getCurrentLocation,
  getAddressByCoordinates
} from './location';
import { isEmpty } from '../utils/common';
import { TIRANA_CITY_LOCATION } from '../constants/config';

export const loadUserSetting = async (props, logged_user_data) => {
  try {
    let address = null;
    let location = Storage.getLastCoordinates();
    if (location) {
      // try {
      //   let hasPermission = await checkLocationPermission();
      //   console.info('checkLocationPermission ', hasPermission);
      //   if (hasPermission === 'granted') {
      //     console.info('getCurrentLocation ');
      //     location = await getCurrentLocation();
      //   }
      //   // eslint-disable-next-line no-empty
      // } catch (error) {}

      address = await getAddressByCoordinates(location);

      await props.setAddress({
        coordinates: {
          latitude: location.latitude,
          longitude: location.longitude
        },
        address
      });
    }

    if (logged_user_data != null && location) {
      if (isEmpty(logged_user_data.latitude) || isEmpty(logged_user_data.longitude)) {
        // for old users
        await props.updateProfileDetails({
          latitude: location.latitude,
          longitude: location.longitude
        });
      }
      if (address != null) {
        let address_data = {
          lat: location.latitude,
          lng: location.longitude,
          country: address.country || TIRANA_CITY_LOCATION.country,
          city: address.city || TIRANA_CITY_LOCATION.city,
          street: address.street || TIRANA_CITY_LOCATION.street
        };
        await props.addDefaultAddress(address_data);
      }
    }
    if (logged_user_data != null) {
      props.getAddresses();
      props.setAsLoggedIn();
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
