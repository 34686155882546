import React from 'react';
import PropTypes from 'prop-types';
import Svg_loc from '../../assets/svgs/markers/ic_locpin.svg';
import { useTranslation } from 'react-i18next';
import './index.css';

const UserMarker = () => {
  const { t } = useTranslation();

  return (
    <div className={'map-marker user-marker'}>
      <div className={'marker-body'}>
        <div className={['align-middle', 'popup'].join(' ')}>
          <div className={'align-middle flex_1 user-info'}>
            <p>{t('you')}</p>
          </div>
          <div className={'anchor'} />
        </div>
        <img src={Svg_loc} />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return !(prevProps.lat !== nextProps.lat || prevProps.lng !== nextProps.lng);
}

UserMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};
export default React.memo(UserMarker, arePropsEqual);
