const ACTION_TYPES = {
  SHOW_ALERT: 'SHOW_ALERT',
  DISMISS_ALERT: 'DISMISS_ALERT',
  SET_FAVOURITES: 'APP.SET_FAVOURITES',
  SET_ALL_FAVOURITES: 'APP.SET_ALL_FAVOURITES',
  APP_SET_USER_DATA: 'APP.SET_USER_DATA',
  APP_SET_HAS_VERIFIED_PHONE: 'APP.SET_HAS_VERIFIED_PHONE',
  APP_LOGGED_IN: 'APP.LOGGED_IN',
  APP_TOGGLE_SIDE_BAR: 'APP.TOGGLE_SIDE_BAR',
  APP_SEEN_ONBOARD: 'APP.SEEN_ONBOARD',
  APP_SET_ADDRESSES: 'APP.SET_ADDRESSES',
  APP_TMP_ADDR_PICKED: 'APP.TMP_ADDR_PICKED',
  APP_APPLY_LOCATION: 'APP_APPLY_LOCATION',
  APP_SET_ACTIVE_SCREEN_FROM_PUSH: 'APP.SET_ACTIVE_SCREEN_FROM_PUSH',
  APP_SET_PROFILE_BLOG_FILTER: 'APP.SET_PROFILE_BLOG_FILTER',
  APP_SET_BLOG_CATEGORIES: 'APP_SET_BLOG_CATEGORIES',
  APP_SET_ORDERS_FILTER: 'APP_SET_ORDERS_FILTER',
  APP_SET_LANGE: 'APP.SET_LANG',
  APP_SET_REWARDS_PICKED_USER: 'APP_SET_REWARDS_PICKED_USER',
  APP_SET_CHAT_CHANNELS: 'APP_SET_CHAT_CHANNELS',

  APP_TMP_SET_SELECTED_FOR_GROUP_CHAT: 'APP_TMP_SET_SELECTED_FOR_GROUP_CHAT',

  APP_TMP_SNAPFOOD_MAP_DATA: 'APP_TMP_SNAPFOOD_MAP_DATA',

  APP_SET_VENDOR_FILTER: 'APP.SET_VENDOR_FILTER',
  APP_SET_VENDOR_SORT: 'APP.SET_VENDOR_SORT',

  APP_SET_ACTIVE_MARKER: 'APP_SET_ACTIVE_MARKER',

  // Profile
  APP_DELETED_ADDRESS: 'APP_DELETED_ADDRESS',

  // CART
  SET_VENDOR_CART: 'CARTSET_VENDOR_CART',

  RESET_CART: 'CART.RESET_CART',
  CLEAR_CART: 'CART.CLEAR_CART',
  UPDATE_CART_ITEMS: 'CART.UPDATE_CART_ITEMS',
  ADD_ITEM_CART: 'CART.ADD_ITEM_CART',
  REMOVE_ITEM_CART: 'CART.REMOVE_ITEM_CART',
  SET_CART_SPLIT: 'CART.SET_CART_SPLIT',
  SET_CUTLERY_CART: 'CART.SET_CUTLERY_CART',
  SET_COMMENT_CART: 'CART.SET_COMMENT_CART',
  SET_COUPON_CART: 'CART.SET_COUPON_CART',
  SET_PRICE_CART: 'CART.SET_PRICE_CART',
  SET_DELIVERY_INFO_CART: 'CART.SET_DELIVERY_INFO_CART',
  SET_PAYMENT_INFO_CART: 'CART.SET_PAYMENT_INFO_CART',
  SET_DEFAULT_DELIVERY_ADDRESS: 'CART.SET_DEFAULT_DELIVERY_ADDRESS',

  // TMP
  SET_FOOD: 'TMP.SET_FOOD',
  APP_TMP_PASS_CHANGED: 'APP_TMP_PASS_CHANGED'
};

export default ACTION_TYPES;
