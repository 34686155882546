import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Picker from 'react-mobile-picker';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './index.css';

const TimePicker = ({ onChangePickupDate, onChangePickupTime }) => {
  const { t } = useTranslation();
  const DAYS = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday')
  ];

  const [valueGroups, setValueGroups] = useState({
    date: DAYS[0],
    time: '0:00',
    apm: 'AM'
  });

  const [optionGroups, setOptionGroups] = useState({
    date: DAYS,
    time: [
      '0:00',
      '1:00',
      '2:00',
      '3:00',
      '4:00',
      '5:00',
      '6:00',
      '7:00',
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00'
    ],
    apm: ['PM', 'AM']
  });

  const dateData = useRef([]);

  const loadDays = () => {
    let dates = [];
    let days = [];
    for (let i = 0; i < 7; i++) {
      let day = moment(new Date()).add(i, 'days').toDate();
      days.push({
        date: moment(day).format('YYYY-MM-DD'),
        day: DAYS[day.getDay()]
      });

      dates.push(DAYS[day.getDay()]);
    }
    days[0].day = t('today');
    days[1].day = t('tomorrow');

    dates[0] = t('today');
    dates[1] = t('tomorrow');

    setOptionGroups({
      ...optionGroups,
      date: dates
    });

    setValueGroups({
      date: dates[0],
      time: moment(new Date()).format('h') + ':00',
      apm: new Date().getHours() < 12 ? 'AM' : 'PM'
    });

    dateData.current = days;
  };

  useEffect(() => {
    loadDays();
  }, []);

  const _handleChange = (name, value) => {
    setValueGroups({
      ...valueGroups,
      [name]: value
    });

    if (name === 'date') {
      onChangeDay(value);
    } else if (name === 'time') {
      onChangeTime(value);
    } else if (name === 'apm') {
      onChangeAP(value);
    }
  };

  const onChangeDay = (_date) => {
    let index = dateData.current.findIndex((i) => i.day === _date);
    if (index !== -1) {
      onChangePickupDate(dateData.current[index].date);
    }
  };

  const onChangeTime = (_time) => {
    let pickup_time = _time;
    if (valueGroups.apm === 'PM') {
      pickup_time = parseInt(_time.split(':')[0]) + 12 + ':00';
    }
    onChangePickupTime(pickup_time);
  };

  const onChangeAP = (_apm) => {
    let pickup_time = valueGroups.time;
    if (_apm === 'PM') {
      pickup_time = parseInt(valueGroups.time.split(':')[0]) + 12 + ':00';
    }
    onChangePickupTime(pickup_time);
  };

  return (
    <div className="picker-inline-container">
      <Picker
        height={120}
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={_handleChange}
        onClick={_handleChange}
      />
    </div>
  );
};

TimePicker.propTypes = {
  onChangePickupDate: PropTypes.func,
  onChangePickupTime: PropTypes.func
};

export default React.memo(TimePicker);
