import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Config } from '../../../constants';
import { isEmpty } from '../../../utils/common';
import { MemorizedFoodTitle, MemorizedFavBtn, MemorizedLogoImg } from './ValueItems';
import './index.css';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../assets';
import { MinusCircle, PlusCircle } from '@styled-icons/boxicons-solid';
import { toggleProductFavourite } from '../../../store/actions/vendors';
import { IconButton } from '@mui/material';

const GroceryFoodItem = (props) => {
  const {
    data,
    isFav,
    isLoggedIn,
    vendorData,
    cartCnt,
    onSelect,
    onFavChange,
    onAddCart,
    onRmvCart,
    style,
    hideFav,
    disabled
  } = props;

  const { t } = useTranslation();

  const onPressFav = () => {
    toggleProductFavourite(data.id, isFav == 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav == 1 ? 0 : 1;
        onFavChange(data);
      })
      .catch(() => {});
  };

  const getImage = () => {
    if (isEmpty(data.image_path)) {
      return vendorData.logo_thumbnail_path;
    }
    return data.image_path;
  };

  return (
    <div
      className={'grocery-food-item'}
      style={style}
      onClick={(e) => {
        e.preventDefault();
        if (disabled === true) {
          return;
        }
        onSelect();
      }}>
      <div className={'food-img align-middle'}>
        <MemorizedLogoImg url={`${Config.IMG_BASE_URL}${getImage()}?w=200&h=200`} />
        {hideFav !== true && isLoggedIn && <MemorizedFavBtn isFav={isFav} onChange={onPressFav} />}
        {hideFav !== true && cartCnt > 0 && <div className={'cart-cnt'}>{cartCnt}</div>}
      </div>
      <div className={'align-row-start mt12'}>
        <MemorizedFoodTitle text={data.title, disabled} />
      </div>
      <div className={'align-middle w100 mt6'} style={disabled === true ? { textDecorationLine: 'line-through' } : {}}>
        <div className={'flex_1'}>
          <div className={'price'}>{data.price} L</div>
        </div>
        {cartCnt > 0 && (
          <IconButton
            disabled={disabled === true}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onRmvCart(data);
            }}
            style={{ padding: 0, marginRight: 10 }}>
            <MinusCircle size={22} color={Theme.colors.gray7} />
          </IconButton>
        )}
        {disabled == false && (
        <IconButton
          disabled={disabled === true}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onAddCart(data);
          }}
          style={{ padding: 0 }}>
          <PlusCircle size={22} color={Theme.colors.text} />
        </IconButton>
        )}
      </div>
      {disabled == true && (
              <div className={'unavailable numline-1'}>
                {t('vendor_profile.unavailable_item_title')}
              </div>
            )}
    </div>
  );
};

GroceryFoodItem.propTypes = {
  food_id: PropTypes.number,
  isFav: PropTypes.number,
  disabled: PropTypes.bool,
  hideFav: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  cartCnt: PropTypes.number,
  data: PropTypes.shape({
    id: PropTypes.number,
    isFav: PropTypes.number,
    title: PropTypes.string,
    image_path: PropTypes.string,
    price: PropTypes.number
  }),
  vendorData: PropTypes.shape({
    logo_thumbnail_path: PropTypes.string
  }),
  style: PropTypes.object,
  onSelect: PropTypes.func,
  onAddCart: PropTypes.func,
  onRmvCart: PropTypes.func,
  onFavChange: PropTypes.func
};

const mapStateToProps = ({ app, shop }) => ({
  isLoggedIn: app.isLoggedIn,
  vendorData: shop.vendorData
});
export default connect(mapStateToProps, {})(React.memo(GroceryFoodItem));
