import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Heart } from '@styled-icons/foundation';
import './index.css';
import { Theme } from '../../../assets';

const PriceItem = ({ value }) => {
  return (
    <div className={'align-middle price-item'}>
      <span className={'default-price'}>{value} L</span>
    </div>
  );
};

const FavBtn = ({ isFav, onChange }) => {
  return (
    <IconButton
      className={'fav-btn'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange(!isFav);
      }}>
      <Heart size={16} color={isFav ? Theme.colors.cyan2 : Theme.colors.gray5} />
    </IconButton>
  );
};

const FoodTitle = ({ text, available }) => {
  return <div className={'food-title numline-1'} style={available === true ? { textDecorationLine: 'line-through' } : {}}>{text}</div>;
};

const LogoImg = ({ url }) => {
  return <img src={url} alt={''} />;
};

PriceItem.propTypes = {
  value: PropTypes.number,
  discount: PropTypes.number
};
FavBtn.propTypes = {
  isFav: PropTypes.number,
  onChange: PropTypes.func
};
FoodTitle.propTypes = {
  text: PropTypes.string,
  available: PropTypes.bool,
};
LogoImg.propTypes = {
  url: PropTypes.string
};

const MemorizedPriceItem = React.memo(PriceItem);
const MemorizedFavBtn = React.memo(FavBtn);
const MemorizedFoodTitle = React.memo(FoodTitle);
const MemorizedLogoImg = React.memo(LogoImg);

export { MemorizedPriceItem, MemorizedFavBtn, MemorizedFoodTitle, MemorizedLogoImg };
