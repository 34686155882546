import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import apiFactory from '../../../services/apiFactory';
import { AuthBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import ReviewInput from '../../../components/Order/ReviewInput';
// svgs
import Svg_restaurant from '../../../assets/svgs/review/rate_restaurant.svg';
import Svg_dishes from '../../../assets/svgs/review/rate_dishes.svg';
import Svg_rider from '../../../assets/svgs/review/rate_rider.svg';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import './index.css';
import { confirmAlert } from 'react-confirm-alert';

const OrderReviewScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { order_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [rate_restaurant, setRateRestaurant] = useState({});
  const [rate_dishes, setRateDishes] = useState({});
  const [rate_rider, setRateRider] = useState({});

  const rates = [
    {
      icon: Svg_restaurant,
      type: 'restaurant',
      question: t('order_review.rate_restaurant'),
      placeholder: t('order_review.restaurant_comment'),
      options: [
        t('order_review.great_menu'),
        t('order_review.reasonable_price'),
        t('order_review.good_service')
      ]
    },
    {
      icon: Svg_dishes,
      type: 'dish',
      question: t('order_review.rate_dish'),
      placeholder: t('order_review.dishes_comment'),
      options: [
        t('order_review.great_dish'),
        t('order_review.tasty'),
        t('order_review.perfectly_cooked')
      ]
    },
    {
      icon: Svg_rider,
      type: 'rider',
      question: t('order_review.rate_rider'),
      placeholder: t('order_review.rider_comment'),
      options: [
        t('order_review.fast_accurate'),
        t('order_review.hot_meal'),
        t('order_review.friendly_handover')
      ]
    }
  ];

  const getCategTxt = (options) => {
    let txt = '';
    if (options.length === 0) {
      return txt;
    }
    options.slice(0, options.length - 1).map((i) => {
      txt = txt + i + ',';
    });
    txt = txt + options[options.length - 1];
    return txt;
  };

  const onSubmitReview = () => {
    if (rate_restaurant.rating == null) {
      return confirmAlert({
        title: t('attention'),
        message: t('order_review.add_restaurant_rating'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (rate_dishes.rating == null) {
      return confirmAlert({
        title: t('attention'),
        message: t('order_review.add_dishes_rating'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
    if (rate_rider.rating == null) {
      return confirmAlert({
        title: t('attention'),
        message: t('order_review.add_courier_rating'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    let order_review = {
      vendor_rating: rate_restaurant.rating,
      dish_rating: rate_dishes.rating,
      rider_rating: rate_rider.rating,

      vendor_categ: getCategTxt(rate_restaurant.options || []),
      dish_categ: getCategTxt(rate_dishes.options || []),
      rider_categ: getCategTxt(rate_rider.options || []),

      vendor_comment: rate_restaurant.comment,
      dish_comment: rate_dishes.comment,
      rider_comment: rate_rider.comment,

      customer_name: props.user.full_name
    };

    setLoading(true);
    apiFactory.post(`orders/${order_id}/review`, order_review).then(
      () => {
        setLoading(false);
        confirmAlert({
          title: '',
          message: t('order_review.review_success'),
          closeOnEscape: false,
          closeOnClickOutside: false,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {
                navigate(-1);
              }
            }
          ]
        });
      },
      () => {
        setLoading(false);
        confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div className={'order-review-screen'}>
      <Header title={t('order_review.review_order')} />
      <div className={'scrollview'}>
        {rates.map((rate_input) => (
          <ReviewInput
            key={rate_input.type}
            type={rate_input.type}
            data={rate_input}
            changeReview={(data) => {
              if (rate_input.type === 'restaurant') {
                setRateRestaurant(data);
              } else if (rate_input.type === 'dish') {
                setRateDishes(data);
              } else if (rate_input.type === 'rider') {
                setRateRider(data);
              }
            }}
          />
        ))}
        <AuthBtn
          isLoading={loading}
          title={t('order_review.submit_review')}
          onClick={onSubmitReview}
        />
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

OrderReviewScreen.propTypes = {
  type: PropTypes.string,
  user: PropTypes.shape({
    full_name: PropTypes.string
  }),
  changeReview: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {})(OrderReviewScreen);
