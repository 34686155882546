export const API_BASE_URL = 'https://prodapi.snapfood.al/api/';
// export const API_BASE_URL = 'http://localhost:8000/api/';
export const IMG_BASE_URL = 'https://snapfoodal.imgix.net/';
export const WEB_PAGE_URL = 'https://prod.snapfood.al/';
export const USER_PROFILE_IMG_BASE_URL = 'https://sf-users.s3.eu-central-1.amazonaws.com/';
export const APP_KEY = 'SNAPFOOD-3exeAtM4CMRAKNWNdza92QyP4';
export const GOOGLE_MAP_API_KEY = 'AIzaSyAEe-vcJ-r8w9FQdVEskAozi1v9cWy6YAA';
export const PLATFORM = 'web';
export const DEFAULT_LANG = 'sq';
export const DEFAULT_SEO_LANG = 'en';
export const DEFAULT_SEO_META_DESCRIPTION =
  'Delivery & Pickup from best local restaurants, breakfast, lunch, dinner and more, delivered safely to your door. Now offering Pickup and Reserve.';

export const STRIPE_PUBLISHABLE_KEY =
  'pk_test_51HW5IXCu9YeioLarfAExO0hfGZvIXq3qxW5dWE2KzMYqqCCn0KlzPFupkbib2tCQzkGQxHr4AWFLU2F0fz1qmZVh00OhQ6LLVB';

export const TIRANA_CITY_LOCATION = {
  latitude: 41.32754,
  longitude: 19.81869,
  country: 'Albania',
  city: 'Tirana',
  street: 'Sheshi Skenderbej'
};
export const SocialMapScreenStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5'
      }
    ]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161'
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5'
      }
    ]
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9'
      }
    ]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e'
      }
    ]
  }
];

export const OrderType_Delivery = 'Delivery';
export const OrderType_Pickup = 'Pickup';
export const OrderType_Reserve = 'Reserve';

export const VendorType_Restaurant = 'Restaurant';
export const VendorType_Grocery = 'Grocery';

export const VSort_Title = 'title';
export const VSort_FastDelivery = 'maximum_delivery_time';
export const VSort_HighRating = 'rating_interval';
export const VSort_Closest = 'distance';
export const VSort_Low2HighPrice = 'delivery_minimum_order_price';
export const VSort_PopularFirst = 'total_ratings';

export const Pay_COD = 'Cash On Delivery';
export const Pay_Card = 'Pay With Card';
export const Pay_Paypal = 'Paypal';
export const Pay_Apple = 'Apple Pay';

export const Order_Pending = 0;
export const Order_Reserved = 1;
export const Order_Preparing = 1;
export const Order_Accepted = 1;
export const Order_Outfordelivery = 2;
export const Order_Ready_pickup = 2;
export const Order_Delivered = 3;
export const Order_Pickedup = 3;
export const Order_Cancelled = -1;

export const MAX_DELIVERY_FEE = 300;
