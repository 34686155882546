import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES_NAMES } from '../../constants';
import WelcomeScreen from '../../views/tour/welcome';
import SetupLocation from '../../views/tour/setup-location';
import Home from '../../views/home';
import HomeFilterScreen from '../../views/home/filter';
import Search from '../../views/search';
import Chat from '../../views/chat';
import MessagesScreen from '../../views/chat/messages';
import LocationMsgScreen from '../../views/chat/location-message';
import PickupLocation from '../../views/chat/pick-location';
import Orders from '../../views/orders';
import OrdersFilterScreen from '../../views/orders/filter';
import OrderReviewScreen from '../../views/orders/order-review';
import Vendor from '../../views/vendor';
import Profile from '../../views/profile';
import ProfileEditScreen from '../../views/profile/edit-profile';
import LoginScreen from '../../views/tour/login';
import Favorites from '../../views/profile/favorites';
import Wallet from '../../views/profile/wallet';
import InviteAndEarn from '../../views/profile/invite-and-earn';
import RewardsInvitationsHistory from '../../views/profile/invite-and-earn/invitation-hist';
import ReferralsHistory from '../../views/profile/invite-and-earn/referrals-hist';
import ReferralsInvitationDetails from '../../views/profile/invite-and-earn/invitation-details';
import Addresses from '../../views/profile/addresses';
import NewAddressScreen from '../../views/profile/add-address';
import AddressMap from '../../views/profile/add-address/address-map';
import OrderSumm from '../../views/orders/order-summary';
import PaymentMethods from '../../views/profile/payment-methods';
import NewCardScreen from '../../views/profile/add-card';
import Promotions from '../../views/profile/promotions';
import Blog from '../../views/profile/blog';
import BlogDetails from '../../views/profile/blog-details';
import BlogFilterScreen from '../../views/profile/blog/filter';
import SettingScreen from '../../views/profile/settings';
import ChangePasswordScreen from '../../views/profile/settings/change-pass';
import MyFriends from '../../views/chat/myfriends';
import Invitations from '../../views/chat/invitations';
import SnapfoodMapScreen from '../../views/chat/snapfood-map';
import Snapfooder from '../../views/chat/snapfooder';
import Snapfooders from '../../views/chat/snapfooders';
import NewChat from '../../views/chat/newchat';
import NewGroup from '../../views/chat/groupchat';
import CreateGroup from '../../views/chat/create-group';
import FoodScreen from '../../views/home/food-details';
import PastOrders from '../../views/home/past-orders';
import VendorOnMap from '../../views/home/vendor-on-map';
import CartScreen from '../../views/cart';
import CartPaymentScreen from '../../views/cart/cart-payment';
import SplitFriends from '../../views/cart/split-friends';
import SplitOrderScreen from '../../views/cart/split-order';
import PickUserInvite from '../../views/profile/invite-and-earn/pick-user-invite';
import PropTypes from 'prop-types';

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route exact path={ROUTES_NAMES.welcome} element={<WelcomeScreen />} />
        <Route exact path={ROUTES_NAMES.setupLocation} element={<SetupLocation />} />
        <Route exact path={ROUTES_NAMES.login} element={<LoginScreen />} />
        <Route exact path={ROUTES_NAMES.home} element={<Home />} />
        <Route exact path={ROUTES_NAMES.homeFilters} element={<HomeFilterScreen />} />
        <Route exact path={ROUTES_NAMES.search} element={<Search />} />
        <Route exact path={ROUTES_NAMES.chat} element={<Chat />} />
        <Route exact path={ROUTES_NAMES.messages} element={<MessagesScreen />} />
        <Route exact path={ROUTES_NAMES.locationMsg} element={<LocationMsgScreen />} />
        <Route exact path={ROUTES_NAMES.pickupLocation} element={<PickupLocation />} />
        <Route exact path={ROUTES_NAMES.orders} element={<Orders />} />
        <Route exact path={ROUTES_NAMES.orderSummary} element={<OrderSumm />} />
        <Route exact path={ROUTES_NAMES.reviewOrder} element={<OrderReviewScreen />} />
        <Route exact path={ROUTES_NAMES.ordersFilter} element={<OrdersFilterScreen />} />
        <Route exact path={ROUTES_NAMES.profile} element={<Profile />} />
        <Route exact path={ROUTES_NAMES.editProfile} element={<ProfileEditScreen />} />
        <Route exact path={ROUTES_NAMES.favs} element={<Favorites />} />
        <Route exact path={ROUTES_NAMES.cashback} element={<Wallet />} />
        <Route exact path={ROUTES_NAMES.inviteAndEarn} element={<InviteAndEarn />} />
        <Route exact path={ROUTES_NAMES.rewardsHistory} element={<RewardsInvitationsHistory />} />
        <Route exact path={ROUTES_NAMES.referralsHistory} element={<ReferralsHistory />} />
        <Route
          exact
          path={ROUTES_NAMES.referralsInvitationDetails}
          element={<ReferralsInvitationDetails />}
        />
        <Route exact path={ROUTES_NAMES.pickUserRewards} element={<PickUserInvite />} />
        <Route exact path={ROUTES_NAMES.addresses} element={<Addresses />} />
        <Route exact path={ROUTES_NAMES.addAddress} element={<NewAddressScreen />} />
        <Route exact path={ROUTES_NAMES.findAddressOnMap} element={<AddressMap />} />
        <Route exact path={ROUTES_NAMES.paymentMethods} element={<PaymentMethods />} />
        <Route exact path={ROUTES_NAMES.addCard} element={<NewCardScreen />} />
        <Route exact path={ROUTES_NAMES.promotions} element={<Promotions />} />
        <Route exact path={ROUTES_NAMES.blog} element={<Blog />} />
        <Route exact path={ROUTES_NAMES.blogDetails} element={<BlogDetails />} />
        <Route exact path={ROUTES_NAMES.blogFilter} element={<BlogFilterScreen />} />
        <Route exact path={ROUTES_NAMES.settings} element={<SettingScreen />} />
        <Route exact path={ROUTES_NAMES.changePass} element={<ChangePasswordScreen />} />
        <Route exact path={ROUTES_NAMES.myFriends} element={<MyFriends />} />
        <Route exact path={ROUTES_NAMES.invitations} element={<Invitations />} />
        <Route exact path={ROUTES_NAMES.snapfoodMap} element={<SnapfoodMapScreen />} />
        <Route exact path={ROUTES_NAMES.snapfooder} element={<Snapfooder />} />
        <Route exact path={ROUTES_NAMES.snapfooders} element={<Snapfooders />} />
        <Route exact path={ROUTES_NAMES.newChat} element={<NewChat />} />
        <Route exact path={ROUTES_NAMES.vendor} element={<Vendor />} />
        <Route exact path={ROUTES_NAMES.newGroup} element={<NewGroup />} />
        <Route exact path={ROUTES_NAMES.createGroup} element={<CreateGroup />} />
        <Route exact path={ROUTES_NAMES.foodDetails} element={<FoodScreen />} />
        <Route exact path={ROUTES_NAMES.pastOrders} element={<PastOrders />} />
        <Route exact path={ROUTES_NAMES.vendorOnMap} element={<VendorOnMap />} />
        <Route exact path={ROUTES_NAMES.cart} element={<CartScreen />} />
        <Route exact path={ROUTES_NAMES.cartPayment} element={<CartPaymentScreen />} />
        <Route exact path={ROUTES_NAMES.splitFriends} element={<SplitFriends />} />
        <Route exact path={ROUTES_NAMES.splitOrder} element={<SplitOrderScreen />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </React.Fragment>
  );
};

AppRoutes.propTypes = {
  isAppLoaded: PropTypes.bool
};
export default AppRoutes;
