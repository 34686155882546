import React from 'react';
import PropTypes from 'prop-types';
import { MapMarker } from '@styled-icons/open-iconic';
import { StarFill } from '@styled-icons/bootstrap';
import { Heart } from '@styled-icons/foundation';
import { Clock } from '@styled-icons/bootstrap';
import { useTranslation } from 'react-i18next';
import { toggleFavourite } from '../../../store/actions/vendors';
import './index.css';
import { Theme } from '../../../assets';
import { OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import { Config } from '../../../constants';
import Svg_euro from '../../../assets/svgs/ic_euro.svg';
import { connect } from 'react-redux';
import { IconButton } from '@mui/material';

const VendorItem = ({ isFav, isLoggedIn, data, style, onFavChange, onClick }) => {
  const { t } = useTranslation();
  const onPressFav = () => {
    toggleFavourite(data.id, isFav === 1 ? 0 : 1)
      .then(() => {
        data.isFav = isFav === 1 ? 0 : 1;
        onFavChange(data);
      })
      .catch(() => {});
  };

  const renderInfo = () => {
    if (data.selected_order_method === OrderType_Pickup) {
      return (
        <React.Fragment>
          <div className={'align-row-start w100 mt6'}>
            <StarFill size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
            <div className={'text'}>{(parseFloat(data.rating_interval) / 2).toFixed(1)} </div>
            <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
              |
            </div>
            {data.distance != null && parseFloat(data.distance) > 0 && (
              <React.Fragment>
                <MapMarker size={18} color={Theme.colors.gray7} style={{ marginRight: 4 }} />
                <div className={'text'}>{(parseFloat(data.distance) / 1000).toFixed(2)} Km</div>
              </React.Fragment>
            )}
          </div>
          <div className={'align-row-start w100 mt6'}>
            <Clock size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
            <div className={'text'}>
              {data.minimum_delivery_time} {t('vendor_profile.mins')}
            </div>
          </div>
        </React.Fragment>
      );
    }
    if (data.selected_order_method === OrderType_Reserve) {
      return (
        <div className={'align-row-start w100 mt6'}>
          <StarFill size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
          <div className={'text'}>{(parseFloat(data.rating_interval) / 2).toFixed(1)} </div>
          <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
            |
          </div>
          {data.distance != null && parseFloat(data.distance) > 0 && (
            <React.Fragment>
              <MapMarker size={18} color={Theme.colors.gray7} style={{ marginRight: 4 }} />
              <div className={'text'}>{(parseFloat(data.distance) / 1000).toFixed(2)} Km</div>
            </React.Fragment>
          )}
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className={'align-row-start w100 mt6'}>
          <StarFill size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
          <div className={'text'}>{(parseFloat(data.rating_interval) / 2).toFixed(1)} </div>
        </div>
        <div className={'align-row-start w100 mt6'} style={{ paddingLeft: 2 }}>
          <img src={Svg_euro} style={{ marginRight: 6 }} />
          <div className={'text'}>
            {' '}
            {data.delivery_minimum_order_price == null
              ? 0
              : parseInt(data.delivery_minimum_order_price)}{' '}
            L
          </div>
          <div className={'text '} style={{ paddingLeft: 10, paddingRight: 10 }}>
            |
          </div>
          <Clock size={16} color={Theme.colors.gray7} style={{ marginRight: 6 }} />
          <div className={'text flex_1'}>
            {data.minimum_delivery_time} {t('vendor_profile.mins')}
          </div>
          {data.can_schedule === 1 && (
            <div className={'schedule-label'}>{t('vendor_item.schedule')}</div>
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={'vendor-item'} onClick={onClick} style={style}>
      <div className={'align-col-middle vendor-logo'}>
        <img src={`${Config.IMG_BASE_URL}${data.logo_thumbnail_path}?w=200&h=200`} />
      </div>
      <div
        style={{
          flex: 1,
          paddingLeft: 12,
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
        <div className={'align-row-start w100'}>
          <div className={'vendor-title'}>{data.title}</div>
          <div className={data.is_open === 1 ? 'indicator' : 'inactive-indicator'} />
          <div
            style={{
              flex: 1,
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              display: 'flex'
            }}>
            {isLoggedIn && (
              <IconButton
                className={'fav-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onPressFav(data);
                }}>
                <Heart
                  size={22}
                  color={data.isFav === 1 ? Theme.colors.cyan2 : Theme.colors.gray5}
                />
              </IconButton>
            )}
          </div>
        </div>
        <div className={'align-row-start w100 mt6'}>
          <div className={'text'}>{data.custom_text}</div>
        </div>
        {renderInfo()}
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.vendor_id === nextProps.vendor_id &&
    prevProps.isFav === nextProps.isFav &&
    prevProps.is_open === nextProps.is_open
  );
}

VendorItem.propTypes = {
  vendor_id: PropTypes.number,
  isFav: PropTypes.number,
  isOpen: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    isFav: PropTypes.number,
    is_open: PropTypes.number,
    can_schedule: PropTypes.number,
    selected_order_method: PropTypes.string,
    custom_text: PropTypes.string,
    profile_path: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    tags_en: PropTypes.string,
    tags: PropTypes.string,
    rating_interval: PropTypes.string,
    distance: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number
  }),
  style: PropTypes.object,
  onClick: PropTypes.func,
  onFavChange: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn
});
export default connect(mapStateToProps, {})(React.memo(VendorItem, arePropsEqual));
