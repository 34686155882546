import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import Svg_img from '../../../assets/svgs/profile/rewarded.svg';
import { MainBtn } from '../../Buttons';

const RedeemDoneModal = ({ showModal, onClose }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal redeem-done-modal'}>
      <div className={'align-col-middle content'}>
        <img src={Svg_img} />
        <div className={'title'}>{t('refer_rewards.awesome')}</div>
        <div className={'desc'}>You just got a 1% discount on your next order. Enjoy ordering!</div>
        <MainBtn onClick={onClose} title={t('refer_rewards.thanks')} />
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

RedeemDoneModal.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func
};

export default React.memo(RedeemDoneModal, arePropsEqual);
