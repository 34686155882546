import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Order_Pending,
  Order_Preparing,
  Order_Outfordelivery,
  Order_Delivered,
  Order_Reserved,
  Order_Accepted,
  Order_Ready_pickup,
  Order_Pickedup,
  OrderType_Delivery,
  OrderType_Reserve
} from '../../../constants/config';
// svgs
import Svg_inactive from '../../../assets/svgs/order_status/inactive_status.svg';
import Svg_pending from '../../../assets/svgs/order_status/pending.svg';
import Svg_preparing from '../../../assets/svgs/order_status/preparing.svg';
import Svg_out_for_delivery from '../../../assets/svgs/order_status/out_for_delivery.svg';
import Svg_delivered from '../../../assets/svgs/order_status/delivered.svg';
import PropTypes from 'prop-types';
import './order-stepper.css';
import { Theme } from '../../../assets';

const OrderStepper = ({ order }) => {
  const { t } = useTranslation();
  const delivery_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_delivery_status.pending'),
      desc: t('order_delivery_status.pending_desc')
    },
    {
      status: Order_Preparing,
      id: 'processing',
      name: t('order_delivery_status.prepare_order'),
      desc: t('order_delivery_status.prepare_order_desc')
    },
    {
      status: Order_Outfordelivery,
      id: 'picked_by_rider',
      name: t('order_delivery_status.out_for_delivery'),
      desc: t('order_delivery_status.out_for_delivery_desc')
    },
    {
      status: Order_Delivered,
      id: 'delivered',
      name: t('order_delivery_status.delivered'),
      desc: t('order_delivery_status.delivered_desc')
    }
  ];

  const pickup_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_pickup_status.pending'),
      desc: t('order_pickup_status.pending_desc')
    },
    {
      status: Order_Accepted,
      id: 'accepted',
      name: t('order_pickup_status.accepted_order'),
      desc: t('order_pickup_status.accepted_order_desc')
    },
    {
      status: Order_Ready_pickup,
      id: 'ready_to_pickup',
      name: t('order_pickup_status.ready_for_pickup'),
      desc: t('order_pickup_status.ready_for_pickup_desc')
    },
    {
      status: Order_Pickedup,
      id: 'picked_up',
      name: t('order_pickup_status.picked_up'),
      desc: t('order_pickup_status.picked_up_desc')
    }
  ];

  const reserve_steps = [
    {
      status: Order_Pending,
      id: 'new',
      name: t('order_reserve_status.pending'),
      desc: t('order_reserve_status.pending_desc')
    },
    {
      status: Order_Reserved,
      id: 'reserved',
      name: t('order_reserve_status.reserved'),
      desc: t('order_reserve_status.reserved_desc')
    }
  ];

  const [items, setItems] = useState([]);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    if (order.order_type === OrderType_Delivery) {
      setItems(delivery_steps);
      setStatus(delivery_steps.findIndex((i) => i.id === order.status));
    } else if (order.order_type === OrderType_Reserve) {
      setItems(reserve_steps);
      setStatus(reserve_steps.findIndex((i) => i.id === order.status));
    } else {
      setItems(pickup_steps);
      setStatus(pickup_steps.findIndex((i) => i.id === order.status));
    }
  }, [order]);

  const icon = (item) => {
    if (item.id === 'new' && item.status <= status) {
      return <img src={Svg_pending} />;
    } else if (
      (item.id === 'processing' || item.id === 'accepted' || item.id === 'reserved') &&
      item.status <= status
    ) {
      return <img src={Svg_preparing} />;
    } else if (
      (item.id === 'picked_by_rider' || item.id === 'ready_to_pickup') &&
      item.status <= status
    ) {
      return <img src={Svg_out_for_delivery} />;
    } else if ((item.id === 'delivered' || item.id === 'picked_up') && item.status <= status) {
      return <img src={Svg_delivered} />;
    } else {
      return <img src={Svg_inactive} />;
    }
  };

  if (order.status === 'declined') {
    return (
      <div className={'align-col-middle w100 order-stepper'}>
        <div className={'align-middle w100'}>
          <img src={Svg_pending} />
          <div className={'flex_1 active-name'}>{t('orders.status_rejected')}</div>
        </div>
        <div className={'align-middle w100'} style={{ paddingLeft: 30 }}>
          <div className={'inactive-desc'} style={{ color: '#6E6E6F' }}>
            {t('orders.order_declined_desc')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={'align-col-middle order-stepper'}
      style={items.length === 0 ? { height: 220 } : {}}>
      {items.map((item, index) => (
        <div key={index} className={'align-col-middle w100'}>
          <div className={'align-middle w100'}>
            {icon(item)}
            <div
              className={[item.status === status ? 'active-name' : 'inactive-name']}
              style={
                item.status === status && item.name === t('order_delivery_status.out_for_delivery')
                  ? { flex: 1 }
                  : { flex: 1 }
              }>
              {item.name}
            </div>
          </div>
          <div className={'align-middle w100 pl-10'}>
            <div
              className={'align-col-middle desc-view'}
              style={{
                ...(item.status <= status && { borderLeftColor: Theme.colors.text }),
                ...(index + 1 === items.length && { borderLeftWidth: 0 })
              }}>
              <div
                className={'inactive-desc'}
                style={item.status <= status ? { color: '#6E6E6F' } : {}}>
                {item.desc}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

OrderStepper.propTypes = {
  order: PropTypes.shape({
    status: PropTypes.string,
    order_type: PropTypes.string
  }),
  onTrackOrder: PropTypes.func
};
export default OrderStepper;
