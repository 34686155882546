import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import { MainBtn } from '../../Buttons';
import { AuthInput } from '../../Inputs';
import { Theme } from '../../../assets';

const SendingInvitationModal = ({ text, showModal, onClose, onSend }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(showModal);
  }, [showModal]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal sending-invitation-modal'}>
      <div className={'align-col-middle content'}>
        <div className={'flex_between'}>
          <div className={'title'}>{t('refer_rewards.send_invitation_to')}</div>
          <div className={'cancel-btn btn-style'} onClick={onClose}>
            {t('cancel')}
          </div>
        </div>
        <div className={'w100 mt20 mb20'}>
          <AuthInput
            text={text}
            style={{ borderRadius: 15, backgroundColor: Theme.colors.gray9 }}
            onChange={() => {}}
          />
        </div>
        <MainBtn onClick={onSend} title={t('refer_rewards.send_invitation')} />
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.showModal === nextProps.showModal;
}

SendingInvitationModal.propTypes = {
  text: PropTypes.string,
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func
};

export default React.memo(SendingInvitationModal, arePropsEqual);
