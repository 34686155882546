import React from 'react';
import { connect } from 'react-redux';
import { Theme } from '../../../assets';
import { OrderType_Delivery, OrderType_Pickup, OrderType_Reserve } from '../../../constants/config';
import Svg_euro from '../../../assets/svgs/ic_euro.svg';
import { useTranslation } from 'react-i18next';
import { Clock } from '@styled-icons/bootstrap';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import PropTypes from 'prop-types';
import CommonTooltip from '../../Common/CommonTooltip';
import './index.css';
import Svg_bike from '../../../assets/svgs/vendor/bike.svg';
import Svg_snapfood from '../../../assets/svgs/vendor/snapfood.svg';

const FeatureInfoView = ({ data, handover_method }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  const getTags = () => {
    let tags_str = language === 'en' ? data.tags_en : data.tags;
    if (tags_str == null) {
      return [];
    }
    return tags_str.split(',');
  };

  return (
    <div className={'align-col-middle shop-feature-view'}>
      {getTags().length > 0 && (
        <div className={'align-middle tags-view '}>
          {getTags().map((tag) => (
            <div key={tag} className={'tag'}>
              {tag}
            </div>
          ))}
        </div>
      )}
      {handover_method === OrderType_Delivery &&
        data.delivery_minimum_order_price != null &&
        data.minimum_delivery_time != null && (
          <div className={'align-middle w100 mt8'}>
            <img src={Svg_euro} />
            <div className={'feature-text'} style={{ marginLeft: 8 }}>
              {` ${parseInt(data.delivery_minimum_order_price)} L`}
            </div>
            <div className={'feature-text'} style={{ marginLeft: 12, marginRight: 12 }}>
              |
            </div>
            <Clock size={16} color={Theme.colors.gray7} />
            <div className={'feature-text'} style={{ marginLeft: 8 }}>
              {data.minimum_delivery_time} {t('vendor_profile.mins')}
            </div>
          </div>
        )}
      {handover_method === OrderType_Pickup && (
        <div className={'align-middle w100 mt8'}>
          {data.distance != null && parseFloat(data.distance) > 0 && (
            <React.Fragment>
              <MapMarkerAlt size={16} color={Theme.colors.gray7} />
              <div className={'feature-text'} style={{ marginLeft: 8 }}>
                {(parseFloat(data.distance) / 1000).toFixed(2)} Km
              </div>
            </React.Fragment>
          )}
          <div className={'feature-text'} style={{ marginLeft: 12, marginRight: 12 }}>
            |
          </div>
          <Clock size={16} color={Theme.colors.gray7} />
          <div className={'feature-text'} style={{ marginLeft: 8 }}>
            {data.minimum_delivery_time} {t('vendor_profile.mins')}
          </div>
        </div>
      )}
      {handover_method === OrderType_Reserve && (
        <div className={'align-middle w100 mt8'}>
          {data.distance != null && parseFloat(data.distance) > 0 && (
            <React.Fragment>
              <MapMarkerAlt size={16} color={Theme.colors.gray7} />
              <div className={'feature-text'} style={{ marginLeft: 8 }}>
                {(parseFloat(data.distance) / 1000).toFixed(2)} Km
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      {data.delivery_type === 'Snapfood' ? (
        <div className={'row_center mt8'} style={{ flexWrap: 'nowrap' }}>
          <img src={Svg_snapfood} />
          <CommonTooltip
            anchor={t('vendor_profile.snapfood_delivery')}
            anchorStyle={{
              marginTop: 8,
              marginBottom: 8,
              paddingLeft: 4,
              fontSize: 15,
              fontFamily: Theme.fonts.semiBold,
              color: Theme.colors.text,
              textDecorationLine: 'underline',
              textDecorationColor: Theme.colors.text
            }}
            title={t('tooltip.what_means')}
            description={t('tooltip.snapfood_delivery_desc')}
          />
        </div>
      ) : (
        <div className={'row_center mt8'} style={{ flexWrap: 'nowrap' }}>
          <img src={Svg_bike} />
          <CommonTooltip
            anchor={t('vendor_profile.store_delivery')}
            anchorStyle={{
              marginTop: 8,
              marginBottom: 8,
              paddingLeft: 4,
              fontSize: 15,
              fontFamily: Theme.fonts.semiBold,
              color: Theme.colors.text,
              textDecorationLine: 'underline',
              textDecorationColor: Theme.colors.text
            }}
            title={t('tooltip.what_means')}
            description={t('tooltip.own_delivery_desc')}
          />
        </div>
      )}
    </div>
  );
};

FeatureInfoView.propTypes = {
  vendor_id: PropTypes.number,
  handover_method: PropTypes.string,
  data: PropTypes.shape({
    tags_en: PropTypes.string,
    tags: PropTypes.string,
    delivery_type: PropTypes.string,
    distance: PropTypes.number,
    delivery_fee: PropTypes.number,
    minimum_delivery_time: PropTypes.number,
    delivery_minimum_order_price: PropTypes.number,
    small_order_fee: PropTypes.number
  })
};
const mapStateToProps = ({ app }) => ({
  user: app.user,
  language: app.language
});
export default connect(mapStateToProps, {})(FeatureInfoView);
