import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ContentLoader from 'react-content-loader';
import Header from '../../../components/Header';
import { OrderType_Delivery } from '../../../constants/config';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { Theme } from '../../../assets';
import { MainBtn, RoundIconBtn } from '../../../components/Buttons';
import AddressItem from '../../../components/Order/AddressItem';
import OrderStepper from './order-stepper';
import OrderReviewItem from '../../../components/Order/OrderReview';
import { Config, ROUTES_NAMES } from '../../../constants';
import './index.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrderDetail } from '../../../store/actions/orders';
import { reOrder } from '../../../store/actions/shop';
import SmallOrderFeeTooltip from '../../../components/Common/SmallOrderFeeTooltip';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import { extractErrorMessage } from '../../../utils/common';

const OrderSumm = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { order_id } = useParams();

  const parsed = querystring.parse(location.search);

  const isNew = parsed.is_new === 'true';

  const past_status = ['delivered', 'picked_up', 'reserved'];
  const [isLoaded, setLoaded] = useState(false);
  const [order, setOrder] = useState({});

  useEffect(() => {
    setLoaded(false);
    getOrderDetail(order_id)
      .then((order_data) => {
        setOrder(order_data);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });

    return () => {};
  }, [order_id]);

  const isPastOrder = (order) => {
    return !(past_status.find((i) => i === order.status) == null);
  };

  const getDiscountAmount = (order) => {
    if (order.coupon_amount != null && parseInt(order.coupon_amount) > 0) {
      return parseInt(order.coupon_amount);
    } else if (order.discount_amount != null && parseInt(order.discount_amount) > 0) {
      return parseInt(order.discount_amount);
    }
    return 0;
  };

  const reorderRequest = (order, restaurant) => {
    props.reOrder(order, restaurant).then(
      () => {
        navigate(ROUTES_NAMES.cart);
      },
      (error) => {
        confirmAlert({
          title: t('restaurant_details.we_are_sorry'),
          message: extractErrorMessage(error),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const reorder = () => {
    const restaurant = order.vendor;
    if (restaurant == null) {
      return confirmAlert({
        title: t('restaurant_details.we_are_sorry'),
        message: t('order_summary.reorder_unavailable_vendor'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    let items = props.cartItems.filter((i) => i.vendor_id !== restaurant.id);
    if (items.length > 0) {
      confirmAlert({
        title: t('restaurant_details.new_order_question'),
        message: t('restaurant_details.new_order_text'),
        closeOnEscape: false,
        closeOnClickOutside: false,
        buttons: [
          {
            label: t('confirm'),
            onClick: () => {
              reorderRequest(order, restaurant);
            }
          },
          {
            label: t('cancel'),
            onClick: () => {}
          }
        ]
      });
    } else {
      reorderRequest(order, restaurant);
    }
  };

  const _renderAddress = () => {
    return (
      <div className={'align-col-start section-view'} style={{ paddingBottom: 0 }}>
        <AddressItem data={order.address || {}} editable={false} textSize={14} showNotes={true} />
      </div>
    );
  };

  const _OrderPItem = (data) => {
    return (
      <div key={data.id} className={'align-middle w100'}>
        <div style={styles.item_qty}>x {data.quantity}</div>
        <div style={styles.item_divider} />
        <div className={'align-middle'} style={styles.item_infoview}>
          <div style={styles.item_title}>{data.title}</div>
          <div style={styles.item_price}>{parseInt(data.price)} L</div>
        </div>
      </div>
    );
  };

  const _renderOrderDetail = () => {
    return (
      <div className={'align-col-start section-view'}>
        <div className={'align-middle'} style={{ width: '100%', marginBottom: 16 }}>
          {order.vendor != null && (
            <div className={'align-row-start flex_1'}>
              <RoundIconBtn
                style={{ ...styles.LogoView }}
                icon={
                  <img
                    style={styles.Logo}
                    src={Config.IMG_BASE_URL + order.vendor.logo_thumbnail_path}
                  />
                }
                onClick={() => {}}
              />
              <div style={styles.LogoText}>{order.vendor.title}</div>
            </div>
          )}
        </div>
        <div className={'align-col-middle order-info-view'}>
          {order.products != null && order.products.map((item) => _OrderPItem(item))}
          {order.order_note != null && order.order_note !== '' && (
            <div className={'align-middle order-note-view'}>
              <div className={'order-info-text'}>
                <div style={styles.item_title}>{t('order_details.order_notes')}:</div>
                {order.order_note}
              </div>
            </div>
          )}
          {order.total_price != null && (
            <div
              className={
                order.order_note == null || order.order_note === ''
                  ? 'w100 flex_between order-note-view'
                  : 'w100 flex_between'
              }>
              <div className={'subject-title'}>{t('cart.order_total')}</div>
              <div className={'order-total'}>{parseInt(order.total_price)} L</div>
            </div>
          )}
          {order.splits != null && order.splits.length > 0 && (
            <div className={'order-info-text'}>
              {t('split.bill_split_among')} {order.splits.length} {t('cart.people')}
            </div>
          )}
          {order.sub_total != null && (
            <div className={'w100 flex_between'} style={{ marginTop: 3 }}>
              <div className={'order-info-text'}>{t('cart.subtotal')}</div>
              <div className={'order-info-text'}>{parseInt(order.sub_total)} L</div>
            </div>
          )}
          {order.cashback != null && parseInt(order.cashback) >= 0 && (
            <div className={'w100 flex_between'} style={{ marginTop: 3 }}>
              <div className={'order-info-text'}>{t('filter.cashback')}</div>
              <div className={'order-info-text'}>
                {parseInt(order.cashback) > 0 ? `-${parseInt(order.cashback)}` : `0`} L
              </div>
            </div>
          )}
          {order.total_price != null && getDiscountAmount(order) >= 0 && (
            <div className={'w100 flex_between'} style={{ marginTop: 3 }}>
              <div className={'order-info-text'}>{t('filter.discount')}</div>
              <div className={'order-info-text'}>
                {parseInt(getDiscountAmount(order)) > 0
                  ? `-${parseInt(getDiscountAmount(order))}`
                  : `0`}{' '}
                L
              </div>
            </div>
          )}
          {order.order_type === OrderType_Delivery &&
            order.small_order_fee != null &&
            parseInt(order.small_order_fee) > 0 && (
              <div className={'w100 flex_between'}>
                <div className={'align-middle'}>
                  <div className={'order-info-text pr-10'}>{t('cart.small_order_fee')}</div>
                  <SmallOrderFeeTooltip
                    delivery_minimum_order_price={order.delivery_minimum_order_price}
                    small_order_fee={parseInt(order.small_order_fee)}
                  />
                </div>

                <div className={'order-info-text'}>{parseInt(order.small_order_fee) + ' L'}</div>
              </div>
            )}
          {order.delivery_fee != null && parseInt(order.delivery_fee) >= 0 && (
            <div className={'w100 flex_between'} style={{ marginTop: 3 }}>
              <div className={'order-info-text'}>{t('checkout.delivery_fee')}</div>
              <div className={'order-info-text'}>{parseInt(order.delivery_fee)} L</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (isLoaded === false) {
      return (
        <div style={{ width: '100%', marginTop: 20 }}>
          <ContentLoader
            speed={2}
            width={window.innerWidth}
            height={window.innerHeight - 150}
            viewBox={`0 0 ${window.innerWidth} ${window.innerHeight - 120}`}
            backgroundColor={Theme.colors.gray8}
            foregroundColor={Theme.colors.gray8}>
            <rect x="20" y="0" rx="0" ry="0" width={window.innerWidth - 40} height="200" />
            <rect x="20" y="240" rx="0" ry="0" width={window.innerWidth - 40} height="60" />
            <rect x="20" y="340" rx="0" ry="0" width={window.innerWidth - 40} height="250" />
          </ContentLoader>
        </div>
      );
    }
    return (
      <div className={'scrollview'}>
        <div style={{ flex: 1, width: '100%', paddingHorizontal: 20 }}>
          <div className={'align-col-middle section-view'} style={{ paddingVertical: 10 }}>
            {order.id != null && <OrderStepper order={order} onTrackOrder={() => {}} />}
            {order.status === 'canceled' && (
              <div className={'order-cancelled'}>{t('order_summary.order_cancelled')}</div>
            )}
          </div>
          {order.order_type === OrderType_Delivery && _renderAddress()}
          {_renderOrderDetail()}
          {isPastOrder(order) && order.order_review && (
            <OrderReviewItem
              vendor_rating={
                order.order_review.vendor_review != null
                  ? order.order_review.vendor_review.rating
                  : 0
              }
              product_rating={
                order.order_review.product_review != null
                  ? order.order_review.product_review.rating
                  : 0
              }
              rider_rating={
                order.order_review.rider_review != null ? order.order_review.rider_review.rating : 0
              }
            />
          )}
          <div style={{ height: 20 }} />
        </div>
        {isPastOrder(order) && !order.order_review && (
          <div style={{ width: '100%', paddingHorizontal: 20, paddingBottom: 10 }}>
            <MainBtn
              title={t('order_summary.review_order')}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/order-review/${order_id}`);
              }}
            />
          </div>
        )}
        {isPastOrder(order) && (
          <div className={'align-col-start'} style={styles.bottom}>
            <div
              onClick={(e) => {
                e.preventDefault();
                reorder();
              }}
              className={'btn-style'}>
              <div style={styles.backTxt}>{t('order_summary.order_again')}</div>
            </div>
          </div>
        )}
        {isNew && order.status === 'new' && (
          <div style={{ width: '100%', paddingHorizontal: 20, paddingBottom: 10 }}>
            <MainBtn
              title={t('order_summary.my_orders')}
              onClick={() => {
                navigate(ROUTES_NAMES.orders, { replace: true });
              }}
            />
          </div>
        )}
        {isNew && order.status === 'new' && (
          <div className={'align-col-start'} style={styles.bottom}>
            <div
              onClick={() => {
                navigate(ROUTES_NAMES.home, { replace: true });
              }}
              className={'btn-style'}>
              <div style={styles.backTxt}>{t('order_summary.go_back_home')}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={'order-summ-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        left={isNew && order.status === 'new' ? <div /> : null}
        title={t('order_summary.title')}
      />
      {renderBody()}
    </div>
  );
};

const styles = {
  divider: { width: '100%', height: 1, backgroundColor: Theme.colors.gray9 },
  LogoText: {
    color: Theme.colors.text,
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    marginLeft: 10
  },
  LogoView: {
    width: 34,
    height: 34,
    borderRadius: 8,
    backgroundColor: Theme.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  Logo: { width: 28, height: 28 },
  bottom: {
    width: '100%',
    height: 75,
    paddingHorizontal: 20,
    paddingTop: 20,
    backgroundColor: Theme.colors.white
  },
  backTxt: { fontSize: 16, fontFamily: Theme.fonts.semiBold, color: Theme.colors.text },

  item_divider: { width: 1, height: 35, backgroundColor: Theme.colors.gray6 },
  item_qty: { width: 35, fontSize: 16, color: Theme.colors.red1, fontFamily: Theme.fonts.semiBold },
  item_title: {
    flex: 1,
    marginTop: 8,
    fontSize: 16,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.medium
  },
  item_price: {
    marginTop: 8,
    marginBottom: 12,
    fontSize: 16,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.medium
  },
  item_infoview: { flex: 1, alignItems: 'stretch', marginLeft: 12 }
};

OrderSumm.propTypes = {
  cartItems: PropTypes.array,
  reOrder: PropTypes.func
};
const mapStateToProps = ({ shop }) => ({
  cartItems: shop.items
});

export default connect(mapStateToProps, { reOrder })(OrderSumm);
