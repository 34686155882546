import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Theme } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { RoundIconBtn } from '../../../components/Buttons';
import Header from '../../../components/Header';
import BlogItem from '../../../components/Profile/BlogItem';
import NoBlogList from '../../../components/Empty/NoBlogList';
import './index.css';
import { SearchInput } from '../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import { Filter } from '@styled-icons/bootstrap';
import { setProfileBlogFilter, setProfileBlogs } from '../../../store/actions/app';
import apiFactory from '../../../services/apiFactory';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { ROUTES_NAMES } from '../../../constants';

const IS_LOADING = 'isLoading';
const IS_REFRESHING = 'isRefreshing';
const IS_LOADING_NEXT = 'isLoadingNext';

const Blog = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const _isMounted = useRef(true);
  const [state, setState] = useState({
    blog: [],
    page: 0,
    totalPages: 0,
    show_loading: null
  });

  useEffect(() => {
    getBlog();
    getCategories();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getBlog(IS_REFRESHING);
  }, [props.profile_blog_filter.searchTerm, props.profile_blog_filter.category_id]);

  const getBlog = async (propToLoad = IS_LOADING) => {
    const { page } = state;
    const { searchTerm, category_id } = props.profile_blog_filter;

    const params = [
      `title=${searchTerm}`,
      `page=${page}`,
      `category_id=${category_id ? category_id : ''}`
    ];
    setState({ ...state, [propToLoad]: true, show_loading: true });
    apiFactory.get(`blogs?${params.join('&')}`).then(
      ({ data }) => {
        const blog = data['blogs'];
        let blogItems = state.blog;
        if ([IS_LOADING, IS_LOADING_NEXT].indexOf(propToLoad) > -1) {
          blogItems = [...blogItems, ...blog['data']];
        } else {
          blogItems = blog['data'];
        }
        if (_isMounted.current === true) {
          setState({
            ...state,
            blog: blogItems,
            page: blog['current_page'],
            totalPages: blog['last_page'],
            [propToLoad]: false,
            show_loading: false
          });
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current === true) {
          setState({
            ...state,
            [propToLoad]: false,
            show_loading: false
          });
          console.error(t('alerts.error'), message);
        }
      }
    );
  };

  const getCategories = () => {
    apiFactory.get('blogs/categories').then(({ data }) => {
      props.setProfileBlogs(data.categories);
    });
  };

  const loadNextPage = async () => {
    const { page, totalPages } = state;
    if (!state[IS_LOADING_NEXT] && page < totalPages) {
      setState({
        ...state,
        page: page + 1
      });
      getBlog(IS_LOADING_NEXT);
    }
  };

  return (
    <div
      className={'align-col-start flex_1 blog-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        title={t('account.blog_menu')}
      />
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('search.search_keywords')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45, marginRight: 12 }}
          value={props.profile_blog_filter.searchTerm}
          onChange={(text) => {
            props.setProfileBlogFilter({
              ...props.profile_blog_filter,
              searchTerm: text
            });
          }}
        />
        <RoundIconBtn
          style={{ width: 45, height: 45 }}
          icon={<Filter size={26} color={Theme.colors.cyan2} />}
          onClick={() => {
            navigate(ROUTES_NAMES.blogFilter);
          }}
        />
      </div>
      {state.show_loading === false && state.blog.length === 0 ? (
        <NoBlogList />
      ) : (
        <div className={'scrollview'}>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadNextPage}
            hasMore={state.page < state.totalPages}
            loader={
              <div className="align-col-middle mt12 mb20" key={0}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
            useWindow={false}>
            {state.blog.map((item) => (
              <BlogItem key={item.id} data={item} />
            ))}
            <div style={{ height: 20 }} />
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

Blog.propTypes = {
  profile_blog_filter: PropTypes.shape({
    searchTerm: PropTypes.string,
    category_id: PropTypes.string
  }),
  setProfileBlogs: PropTypes.func,
  setProfileBlogFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  user: app.user,
  profile_blog_filter: app.profile_blog_filter
});

export default connect(mapStateToProps, { setProfileBlogFilter, setProfileBlogs })(Blog);
