import React, { useState, useRef, useMemo, useCallback } from 'react';
import { GiftedChat } from '../../../components/Messages/react-web-gifted-chat';
import { connect } from 'react-redux';
import { convertTimestamp2Date } from '../../../utils/common';
import {
  channel_collection,
  getChannelData,
  sendMessage,
  uploadImage,
  seenUnreadCntChannel,
  deleteGroupChannel,
  exitGroupChannel
} from '../../../services/chat';
import { goActiveScreenFromPush } from '../../../store/actions/app';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// import { Theme } from '../../../assets';
import EmojiPickerModal from '../../../components/Modals/EmojiPicker';
import ImgGalleryModal from '../../../components/Modals/ImgGalleryModal';
import LocationMsgOptionsModal from '../../../components/Modals/LocationMsgOptions';
// import AudioInputView from '../components/AudioInputView';
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import MessagesHeader from '../../../components/Messages/MessagesHeader';
import {
  renderInputToolbar,
  renderComposer,
  renderSend
} from '../../../components/Messages/InputToolbar';
import { renderBubble, renderMessage } from '../../../components/Messages/MessageContainer';
import PropTypes from 'prop-types';
// import {
//   checkLocationPermission,
//   requestLocationPermission
// } from '../../../common/services/location';
import './index.css';
import { ROUTES_NAMES } from '../../../constants';
import { confirmAlert } from 'react-confirm-alert';
import { checkLocationPermission, getCurrentLocation } from '../../../services/location';
import LoadingModal from '../../../components/Modals/LoadingModal';
import ImageCapture from 'react-image-data-capture';
import AudioInputView from '../../../components/Messages/AudioInput';

const PerPage = 12;
const MessagesScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { channel_id } = useParams();
  const isFromPush = false;

  const file_input = useRef(null);
  const config = useMemo(() => ({ video: true }), []);
  const [isCameraOn, setIsCameraOn] = useState(false);

  const [imageUploading, setImageUploading] = useState(false);

  const [prevLoading, setPrevLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [isMuted, SetMuteGroup] = useState(false);
  const [isDeleteGroupModal, ShowDeleteGroupModal] = useState(false);
  const [isExitGroupModal, ShowExitGroupModal] = useState(false);
  const [isShareLocModal, ShowShareModal] = useState(false);
  const [showEmoji, setShowEMoji] = useState(false);
  const [isGalleryModal, ShowGalleryModal] = useState(false);
  const [modal_imgs, setModalImages] = useState([]);

  const [channelData, setChannelData] = useState(null);

  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);
  const [quote_msg, setQuoteMsg] = useState(null);
  const [images, setImages] = useState(null);
  const [isRecording, setRecording] = useState(false);

  const msgs_unlistener = useRef(null);
  const systemMsg = {
    _id: 1,
    text: '',
    createdAt: new Date(),
    system: true
  };

  React.useEffect(() => {}, []);

  React.useEffect(() => {
    if (msgs_unlistener.current != null) {
      msgs_unlistener.current();
    }
    loadChannelData().then();
    const messages_coll = channel_collection
      .doc(channel_id)
      .collection('messages')
      .limit(PerPage)
      .orderBy('created_time', 'desc');
    msgs_unlistener.current = messages_coll.onSnapshot((querySnapshot) => {
      let msgs = [];
      querySnapshot.docs.forEach((doc) => {
        if (doc.exists) {
          msgs.push({ ...doc.data(), createdAt: convertTimestamp2Date(doc.data().createdAt) });
        }
      });
      setMessages(msgs);
      if (msgs.length >= PerPage) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    });

    return () => {
      if (msgs_unlistener.current != null) {
        msgs_unlistener.current();
      }
      props.goActiveScreenFromPush({
        isChatVisible: false
      });
    };
  }, [channel_id, isFromPush]);

  const loadChannelData = async () => {
    let channel = await getChannelData(channel_id);
    setChannelData(channel);

    await seenUnreadCntChannel(channel, props.user.id);
  };

  const loadPrevMessage = () => {
    if (prevLoading || hasMore === false || messages.length === 0) {
      return;
    }
    let start = messages[messages.length - 1].created_time;
    if (start == null) {
      return;
    }
    const messages_coll = channel_collection
      .doc(channel_id)
      .collection('messages')
      .orderBy('created_time', 'desc')
      .limit(PerPage)
      .startAfter(start);

    setPrevLoading(true);
    messages_coll
      .get()
      .then((snaps) => {
        let msgs = [];
        snaps.docs.forEach((doc) => {
          if (doc.exists) {
            msgs.push({ ...doc.data(), createdAt: convertTimestamp2Date(doc.data().createdAt) });
          }
        });
        if (msgs.length > 0) {
          let tmpMsgs = messages.slice(0, messages.length);
          msgs.map((msg) => {
            tmpMsgs.push(msg);
          });
          setMessages(tmpMsgs);
        }
        setPrevLoading(false);
        if (msgs.length > 0) {
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch(() => {
        setPrevLoading(false);
        setHasMore(false);
      });
  };

  const onSend = async (newMessages = []) => {
    if (quote_msg != null) {
      newMessages.map((msg, index) => {
        newMessages[index].reply = quote_msg;
      });
      setQuoteMsg(null);
    }
    if (images != null && images.length > 0) {
      setImageUploading(true);
      let imageUrls = [];
      for (var i = 0; i < images.length; i++) {
        if (images[i] != null && images[i] != null) {
          try {
            let res = await uploadImage(images[i].split(',')[1]);
            if (res != null && res.data != null && res.data.success === true) {
              imageUrls.push(res.data.url);
            }
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }
      }
      setImages(null);

      if (imageUrls.length === 0) {
        return;
      } else {
        newMessages.map((msg, index) => {
          newMessages[index].images = imageUrls;
        });
      }
    }

    for (let i = 0; i < newMessages.length; i++) {
      await sendMessage(channelData.id, props.user.id, newMessages[i]);
    }
    setImageUploading(false);
  };

  const addCurrentLocation = async () => {
    ShowShareModal(false);
    try {
      let hasPermission = await checkLocationPermission();
      if (hasPermission) {
        const location = await getCurrentLocation();
        if (location) {
          let newMsg = {
            user: {
              _id: props.user.id,
              full_name: props.user.full_name,
              photo: props.user.photo,
              phone: props.user.phone,
              email: props.user.email
            },
            map: {
              coords: {
                latitude: location.latitude,
                longitude: location.longitude
              },
              type: 0 // 0 : my location, 1 : a location
            }
          };
          onSend([newMsg]);
        }
      } else {
        confirmAlert({
          title: t('attention'),
          message: t('locationUnavailable'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    } catch (error) {
      confirmAlert({
        title: t('attention'),
        message: t('locationUnavailable'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onSendAudio = async (currentTime, fileSize, base64) => {
    try {
      let res = await uploadImage(base64);
      if (res != null && res.data != null && res.data.success == true) {
        let newMsg = {
          user: {
            _id: props.user.id,
            full_name: props.user.full_name,
            photo: props.user.photo,
            phone: props.user.phone,
            email: props.user.email
          },
          audio: {
            url: res.data.url,
            duration: currentTime,
            fileSize: fileSize,
            playing: false,
            position: 0
          }
        };
        onSend([newMsg]);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    setRecording(false);
  };

  const goFindLocation = () => {
    ShowShareModal(false);
    navigate(`/pickup-location/${channelData.id}`);
  };

  const onPressLocation = () => {
    ShowShareModal(true);
  };

  const onSelectEmoji = (emojiObj) => {
    setShowEMoji(false);
    setText((text) => text.concat(emojiObj.emoji));
  };

  const onPressEmoji = () => {
    // Keyboard.dismiss();
    setTimeout(() => {
      setShowEMoji(true);
    }, 100);
  };

  const onImageUpload = () => {
    if (file_input.current != null) {
      file_input.current.click();
    }
  };

  const onImageCapture = () => {
    setIsCameraOn(true);
  };

  const onRecord = () => {
    setRecording(true);
  };

  const onCapture = (imageData) => {
    if (imageData == null) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(imageData.blob);
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages([reader.result]);
      }
    };

    setIsCameraOn(false);
  };

  const onError = useCallback(() => {
    confirmAlert({
      title: '',
      message: t('account_details.no_camera'),
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: t('Ok'),
          className: 'error-ok-btn',
          onClick: () => {
            setIsCameraOn(false);
            // navigate(-1);
          }
        }
      ]
    });
  }, []);

  const onHandleGalleryImgs = ({ target }) => {
    let tmp_images = [];
    const reader = new FileReader();

    function readFile(index) {
      if (index >= target.files.length) {
        setImages(tmp_images);
        return;
      }
      const file = target.files[index];
      reader.onload = function (e) {
        // get file content
        tmp_images.push(e.target.result);
        // do sth with bin
        readFile(index + 1);
      };
      reader.readAsDataURL(file);
    }

    readFile(0);
  };

  const onDeleteGroup = async () => {
    let ret = await deleteGroupChannel(channelData.id);
    ShowDeleteGroupModal(true);
    if (ret === true) {
      navigate(-1);
    } else {
      confirmAlert({
        title: t('alerts.error'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  const onExitGroup = async () => {
    if (channelData.users.length > 1) {
      let ret = await exitGroupChannel(channelData, props.user.id);
      ShowExitGroupModal(false);
      if (ret === true) {
        let channel = await getChannelData(channelData.id);
        setChannelData(channel);
      } else {
        confirmAlert({
          title: t('alerts.error'),
          message: t('checkout.something_is_wrong'),
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    } else {
      ShowExitGroupModal(false);
      await onDeleteGroup();
    }
  };

  const onCancelQuote = () => {
    setQuoteMsg(null);
  };

  const onRemoveImage = (index) => {
    let tmp_imgs = images.slice(0, images.length);
    tmp_imgs.splice(index, 1);
    setImages(tmp_imgs.length === 0 ? null : tmp_imgs);
  };

  const onLongPressMessage = (currentMessage) => {
    if (currentMessage && currentMessage.text) {
      setQuoteMsg(currentMessage);
    }
  };

  const onPressMsg = (currentMessage) => {
    // Keyboard.dismiss();
    if (
      currentMessage &&
      currentMessage.map &&
      currentMessage.map.coords &&
      currentMessage.map.coords.latitude &&
      currentMessage.map.coords.longitude
    ) {
      navigate(
        `${ROUTES_NAMES.locationMsg}?lat=${currentMessage.map.coords.latitude}&lng=${currentMessage.map.coords.longitude}`
      );
    }
  };

  const onShowGalleryMsgs = (images) => {
    if (images.length > 0) {
      setModalImages(images);
      ShowGalleryModal(true);
    }
  };

  const renderEmptyInputToolbar = () => (
    <div className={'no-members-txt'}>{t('social.chat.no_longer_member')}</div>
  );

  const renderBottomInputbar = (giftchat_props) => {
    if (channelData != null && channelData.users.findIndex((i) => i === props.user.id) === -1) {
      return renderEmptyInputToolbar();
    } else if (isRecording) {
      return (
        <AudioInputView
          onRemove={() => {
            setRecording(false);
          }}
          onSend={onSendAudio}
        />
      );
    }
    return renderInputToolbar(giftchat_props, quote_msg, images, onCancelQuote, onRemoveImage);
  };

  const isCloseToTop = (event) => {
    return event.target.scrollTop === 0;
  };

  const isCloseToBottom = (event) => {
    const node = event.target;
    return node.scrollHeight - node.scrollTop > node.clientHeight - 20;
  };

  const onGoBack = async () => {
    if (channelData != null) {
      await seenUnreadCntChannel(channelData, props.user.id);
    }
    navigate(-1);
  };

  return (
    <div className={'messages-screen'}>
      <GiftedChat
        messages={messages.length === 0 ? [systemMsg] : messages}
        text={text}
        onInputTextChanged={(_text) => {
          setText(_text);
        }}
        onSend={onSend}
        user={{
          _id: props.user.id,
          full_name: props.user.full_name,
          photo: props.user.photo,
          phone: props.user.phone,
          email: props.user.email
        }}
        minInputToolbarHeight={100}
        alwaysShowSend={true}
        showUserAvatar={false}
        renderUsernameOnMessage={true}
        textInputAutoFocus={false}
        renderLoading={() => (
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              paddingVertical: 12
            }}>
            <CircularProgress size={50} />
          </div>
        )}
        listViewProps={{
          ListFooterComponent: () => {
            return (
              <div className={'align-col-middle'}>
                <div style={{ height: 100, backgroundColor: '#fff' }} />
                {prevLoading && (
                  <div
                    style={{
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      paddingVertical: 12
                    }}>
                    <CircularProgress size={20} />
                  </div>
                )}
              </div>
            );
          },
          onScroll: (event) => {
            // eslint-disable-next-line no-empty
            if (isCloseToTop(event)) {
            }
            if (isCloseToBottom(event)) {
              loadPrevMessage();
            }
          },
          keyboardShouldPersistTaps: 'handled'
        }}
        renderInputToolbar={renderBottomInputbar}
        renderSend={(props) =>
          renderSend(
            props,
            (text != null && text.length > 0) || (images != null && images.length > 0),
            onRecord
          )
        }
        renderComposer={(props) =>
          renderComposer(props, onPressEmoji, onPressLocation, onImageUpload, onImageCapture)
        }
        renderMessage={renderMessage}
        renderBubble={(props) =>
          renderBubble(
            props,
            channelData != null && channelData.channel_type !== 'single',
            onLongPressMessage,
            onPressMsg,
            onShowGalleryMsgs
          )
        }
        renderAvatar={null}
        // alignTop
        // scrollToBottom={260}
        bottomOffset={0}
        renderSystemMessage={() => null}
        // renderAvatarOnTop
        // renderActions={renderActions}
        // renderMessageImage
        // renderCustomView={renderCustomView}
        // isCustomViewBottom
        renderFooter={() => (
          <div>
            <div />
          </div>
        )}
        loadEarlier={true}
        renderLoadEarlier={() => (
          <div className={'align-col-middle'}>
            <div style={{ height: 100, backgroundColor: '#fff' }} />
            {prevLoading && (
              <div
                style={{ justifyContent: 'flex-end', alignItems: 'center', paddingVertical: 12 }}>
                <CircularProgress size={20} />
              </div>
            )}
          </div>
        )}
        parsePatterns={(linkStyle) => [
          {
            pattern: /#(\w+)/,
            style: linkStyle,
            onPress: () => {}
          }
        ]}
      />
      <MessagesHeader
        style={{ position: 'absolute', top: -15, left: 0 }}
        isMuted={isMuted}
        channel_id={channelData == null ? null : channelData.id}
        data={channelData}
        user_id={props.user.id}
        onBack={() => {
          onGoBack();
        }}
        onCall={() => {}}
        onDelete={() => {
          ShowDeleteGroupModal(true);
        }}
        onExit={() => {
          ShowExitGroupModal(true);
        }}
        onMute={() => {
          SetMuteGroup(!isMuted);
        }}
        onGallery={() => {
          navigate(-1);
        }}
        onPressName={() => {
          if (channelData == null) {
            return;
          }
          let user_id = props.user.id;
          if (channelData.channel_type === 'single') {
            if (user_id === channelData.creator.id) {
              navigate(`/snapfooder/${channelData.partner.id}`);
            } else if (user_id === channelData.partner.id) {
              navigate(`/snapfooder/${channelData.creator.id}`);
            }
          } else {
            // group
          }
        }}
      />

      <LocationMsgOptionsModal
        isOpen={isShareLocModal}
        onCurrentLoc={addCurrentLocation}
        onPickMap={goFindLocation}
        onClose={() => ShowShareModal(false)}
      />
      <ConfirmModal
        showModal={isDeleteGroupModal}
        title={t('group_related.confirm_del_group')}
        yes={t('group_related.confirm_del_group_yes')}
        no={t('group_related.confirm_del_group_no')}
        onYes={onDeleteGroup}
        onClose={() => ShowDeleteGroupModal(false)}
      />
      <ConfirmModal
        showModal={isExitGroupModal}
        title={t('group_related.confirm_exit_group')}
        yes={t('group_related.confirm_exit_group_yes')}
        no={t('group_related.confirm_exit_group_no')}
        onYes={onExitGroup}
        onClose={() => ShowExitGroupModal(false)}
      />
      <EmojiPickerModal
        isOpen={showEmoji}
        onClose={() => {
          setShowEMoji(false);
        }}
        onPickEmoji={onSelectEmoji}
      />
      <ImgGalleryModal
        images={modal_imgs}
        showModal={isGalleryModal}
        onClose={() => ShowGalleryModal(false)}
      />
      {isCameraOn && (
        <div className="align-col-middle camera-view">
          <ImageCapture
            onCapture={onCapture}
            onError={onError}
            width={window.innerWidth}
            userMediaConfig={config}
          />
        </div>
      )}
      <input
        accept="image/jpeg/png"
        ref={file_input}
        hidden={true}
        type="file"
        multiple={true}
        onChange={onHandleGalleryImgs}
      />
      <LoadingModal showModal={imageUploading} />
    </div>
  );
};

MessagesScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    full_name: PropTypes.string,
    photo: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  style: PropTypes.object,
  goActiveScreenFromPush: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  coordinates: app.coordinates,
  user: app.user
});

export default connect(mapStateToProps, {
  goActiveScreenFromPush
})(MessagesScreen);
