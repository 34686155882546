const ROUTES_NAMES = {
  splash: '/',
  onBoarding: '/onboard',
  login: '/login',
  welcome: '/welcome',
  phoneVerification: '/phone-verification',
  editPhone: '/edit-phone',
  setupLocation: '/setup-location',
  almostDone: '/almost-done',
  home: '/home',
  homeFilters: '/home/filter',
  search: '/search',
  chat: '/chat',
  messages: '/messages/:channel_id',
  locationMsg: '/location-msg',
  pickupLocation: '/pickup-location/:channel_id',
  orders: '/orders',
  ordersFilter: '/orders-filter',
  reviewOrder: '/order-review/:order_id',
  profile: '/profile',
  editProfile: '/profile/edit',
  vendor: '/vendor/:vendor_id/:order_method',
  favs: '/favorites',
  addresses: '/addresses',
  addAddress: '/add-address',
  findAddressOnMap: '/address-on-map',
  cashback: '/cashback',
  inviteAndEarn: '/invite-and-earn',
  rewardsHistory: '/rewards-history',
  referralsHistory: '/referrals-history',
  referralsInvitationDetails: '/referrals-invite/:invitation_id',
  paymentMethods: '/payment-method',
  addCard: '/new-card',
  promotions: '/promotions-menu',
  blog: '/blog',
  blogDetails: '/blog/:blog_id',
  blogFilter: '/blog-filter',
  settings: '/settings',
  changePass: '/change-password',
  orderSummary: '/order/:order_id',
  myFriends: '/my-friends',
  invitations: '/invitations',
  snapfoodMap: '/snapfood-map',
  snapfooders: '/snapfooders',
  snapfooder: '/snapfooder/:snapfooder_id',
  newChat: '/new-chat',
  newGroup: '/new-group',
  createGroup: '/create-group',
  vendorOnMap: '/vendor-on-map',
  foodDetails: '/food/:vendor_id/:food_id',
  pastOrders: '/past-order/:vendor_id',
  cart: '/cart',
  cartPayment: '/cart-payment',
  splitFriends: '/split-friends',
  splitOrder: '/split-order',
  pickUserRewards: '/rewards-pickuser/:type'
};

export default ROUTES_NAMES;
