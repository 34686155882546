import React from 'react';
import moment from 'moment';
import { isEmpty, getImageFullURL } from '../../utils/common';
import { findZodiacSign } from '../../utils/common';
import { Theme } from '../../assets';
// svgs
import Img_user from '../../assets/images/user-default.png';
import Svg_edit from '../../assets/svgs/profile/btn_edit.svg';
import Svg_birthday from '../../assets/svgs/profile/ic_birthday.svg';
import PropTypes from 'prop-types';

const ProfileAvatarView = ({ photo, full_name, birthdate, city, country, onEdit }) => {
  return (
    <div className={'align-col-middle'} style={styles.container}>
      <div className={'align-row-start'} style={{ width: '100%', justifyContent: 'flex-end' }}>
        <div onClick={onEdit}>
          <img src={Svg_edit} className={'btn-style'} />
        </div>
      </div>
      <div className={'align-col-middle'} style={styles.avatarView}>
        <div className={'align-col-middle'} style={styles.photoView}>
          <img
            src={isEmpty(photo) || photo === 'x' ? Img_user : getImageFullURL(photo)}
            style={styles.avatarImg}
          />
        </div>
        <div className={'align-middle'} style={{ marginTop: 12 }}>
          <div style={styles.name}>{full_name}</div>
          {birthdate != null && <img src={findZodiacSign(birthdate)} className={'zodiac'} />}
        </div>
        {birthdate != null && (
          <div className={'align-middle'}>
            <img src={Svg_birthday} />
            <div style={{ paddingBottom: 6, marginLeft: 6, ...styles.infoTxt }}>
              {moment(birthdate).format('D MMM, YYYY')} ({moment().diff(moment(birthdate), 'years')}
              y)
            </div>
          </div>
        )}
        <div style={styles.infoTxt}>
          {city}, {country}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: { flex: 1, width: '100%', paddingHorizontal: 20 },
  avatarView: { marginTop: 16 },
  photoView: {
    height: 100,
    width: 100,
    borderWidth: 1,
    borderColor: Theme.colors.gray9,
    borderRadius: 15,
    backgroundColor: '#E8D7D0'
  },
  avatarImg: { width: 100, height: 100, borderRadius: 6, objectFit: 'cover' },
  name: {
    marginRight: 4,
    paddingBottom: 4,
    fontSize: 18,
    fontFamily: Theme.fonts.bold,
    color: Theme.colors.text
  },
  infoTxt: {
    marginTop: 6,
    fontSize: 14,
    fontFamily: Theme.fonts.semiBold,
    color: Theme.colors.gray7
  }
};

ProfileAvatarView.propTypes = {
  id: PropTypes.number,
  photo: PropTypes.string,
  full_name: PropTypes.string,
  birthdate: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  onEdit: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.photo === nextProps.photo &&
    prevProps.full_name === nextProps.full_name &&
    prevProps.birthdate === nextProps.birthdate &&
    prevProps.city === nextProps.city
  );
}

export default React.memo(ProfileAvatarView, arePropsEqual);
