import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CommonTabs } from '../../../components/TabSelector';
import Header from '../../../components/Header';
import PromotionItem from '../../../components/Vendor/PromotionItem';
import NoPromoList from '../../../components/Empty/NoPromoList';
import apiFactory from '../../../services/apiFactory';
import './index.css';

const Promotions = () => {
  const _isMounted = useRef(true);

  const { t } = useTranslation();

  const [opType, setOpType] = useState('current');
  const [currents, setCurrents] = useState([]);
  const [pasts, setPasts] = useState([]);

  const [currentsLoading, setCurrentsLoading] = useState(null);
  const [pastsLoading, setPastsLoading] = useState(null);

  useEffect(() => {
    getActivePromotions();
    getPromotions();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const getActivePromotions = () => {
    setCurrentsLoading(true);
    apiFactory
      .get('/promotions/active')
      .then(({ data }) => {
        if (_isMounted.current === true) {
          setCurrents(data.promotions || []);
          setCurrentsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setCurrentsLoading(false);
        }
      });
  };

  const getPromotions = () => {
    setPastsLoading(true);
    apiFactory
      .get('/promotions/used')
      .then(({ data }) => {
        if (_isMounted.current === true) {
          const promotions = data.data;
          setPasts(promotions || []);
          setPastsLoading(false);
        }
      })
      .catch(() => {
        if (_isMounted.current === true) {
          setPastsLoading(false);
        }
      });
  };

  return (
    <div className={'flex_1 fav-screen'}>
      <Header
        style={{
          height: 65,
          display: 'flex',
          alignItems: 'flex-end',
          paddingLeft: 20,
          paddingRight: 20
        }}
        title={t('account.promotions_menu')}
      />
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['current', 'past']}
            item={opType}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) => setOpType(item)}
          />
        </div>
      </div>
      <div className={'flex_1 scrollview'}>
        <div className={opType === 'current' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {currentsLoading === false && currents.length === 0 ? (
            <NoPromoList />
          ) : (
            currents.map((item) => (
              <PromotionItem key={item.id} id={item.id} data={item} onSelect={() => {}} />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
        <div className={opType === 'past' ? 'show' : 'hidden'}>
          <div style={{ height: 20 }} />
          {pastsLoading === false && pasts.length === 0 ? (
            <NoPromoList />
          ) : (
            pasts.map((item) => (
              <PromotionItem key={item.id} id={item.id} data={item} onSelect={() => {}} />
            ))
          )}
          <div style={{ height: 40 }} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates
});

export default connect(mapStateToProps, {})(Promotions);
