import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchInput } from '../../../../components/Inputs';
import { Search } from '@styled-icons/evil';
import UserItem from '../../../../components/Chats/UserItem';
import NoFriendList from '../../../../components/Empty/NoFriendList';
import './index.css';
import { Theme } from '../../../../assets';
import Header from '../../../../components/Header';
import PropTypes from 'prop-types';
import { setPickedUserIdForRewards } from '../../../../store/actions/app';

const PickUserInvite = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { type } = useParams();

  const [loading, setLoading] = useState(null);
  const [friends, setFriends] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    getAllFriends();
  }, []);

  const getAllFriends = () => {
    setLoading(true);
    let tmp = [];
    for (let i = 1; i < 12; i++) {
      tmp.push({
        id: i.toString(),
        photo: '',
        full_name: 'Kathryn Miller',
        birthdate: '2021-11-11'
      });
    }
    setFriends(tmp);
    setLoading(false);
  };

  return (
    <div
      className={'align-col-start flex_1 pick-user-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      <Header
        style={{ marginTop: 0, paddingLeft: 20, paddingRight: 20 }}
        title={
          type === 'friends' ? t('refer_rewards.invite_friend') : t('refer_rewards.invite_user')
        }
      />
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('social.search.friends')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45 }}
          value={searchTerm}
          onChange={(text) => {
            setSearchTerm(text);
          }}
        />
      </div>
      <div className={'scrollview mt20'}>
        {loading === false && friends.length === 0 ? (
          type === 'friends' ? (
            <NoFriendList title={t('social.no_friends')} desc={t('social.no_friends_desc')} />
          ) : (
            <NoFriendList
              title={t('social.no_snapfooders')}
              desc={t('social.no_snapfooders_desc')}
            />
          )
        ) : (
          friends.map((item) => (
            <UserItem
              key={item.id}
              item={item}
              id={item.id}
              onClick={() => {
                props.setPickedUserIdForRewards(item.full_name);
                navigate(-1);
              }}
            />
          ))
        )}
        <div style={{ height: 20 }} />
      </div>
    </div>
  );
};

PickUserInvite.propTypes = {
  setPickedUserIdForRewards: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter,
  default_orders_tab: app.default_orders_tab
});

export default connect(mapStateToProps, { setPickedUserIdForRewards })(PickUserInvite);
