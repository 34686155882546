import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SnapfooderAvatar from '../../../components/Chats/SnapfooderAvatar';
import SuggestedUserItem from '../../../components/Chats/SuggestedUser';
import { MainBtn } from '../../../components/Buttons';
import './index.css';
import { Theme } from '../../../assets';
import MapView from '../../../components/Chats/MapView';
import Header from '../../../components/Header';
import { TIRANA_CITY_LOCATION } from '../../../constants/config';
import { useParams } from 'react-router-dom';
import { ApiFactory } from '../../../services';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import ContentLoader from 'react-content-loader';

const Snapfooder = (props) => {
  const _isMounted = useRef(true);
  const { t } = useTranslation();
  const { snapfooder_id } = useParams();

  const [state, setState] = useState({
    loading: null,
    user: {}
  });
  const [isFriend, setIsFriend] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    if (snapfooder_id != null) {
      getSnapfoodDetail(snapfooder_id, true);
      checkFriend(snapfooder_id);
    }
    return () => {
      _isMounted.current = false;
    };
  }, [snapfooder_id]);

  const getSnapfoodDetail = (user_id, initLoading = false) => {
    if (initLoading == true) {
      setState({ ...state, loading: true });
    }
    ApiFactory.get(`users/snapfooders/${user_id}`).then(
      ({ data }) => {
        const res_snapfooder = data['snapfooder'];
        if (_isMounted.current == true) {
          setState({
            ...state,
            user: res_snapfooder,
            loading: false
          });
        }
      },
      () => {
        if (_isMounted.current == true) {
          setState({ ...state, loading: false });
        }
      }
    );
  };

  const checkFriend = (snapfooder_id) => {
    ApiFactory.post(`users/friends/check`, {
      user_id: props.user.id,
      friend_id: snapfooder_id
    }).then(
      ({ data }) => {
        if (_isMounted.current == true) {
          setIsFriend(data.success == true);
        }
      },
      () => {}
    );
  };

  const onSendInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/update`, {
      user_id: props.user.id,
      friend_id: state.user.id,
      status: 'invited'
    }).then(
      () => {
        getSnapfoodDetail(state.user.id);
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onCancelInvitation = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        getSnapfoodDetail(state.user.id);
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  const onRemoveFriend = async () => {
    if (state.user == null) {
      return;
    }
    setBtnLoading(true);
    ApiFactory.post(`users/friends/remove`, {
      user_id: props.user.id,
      friend_id: state.user.id
    }).then(
      () => {
        checkFriend(state.user.id);
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
      },
      (error) => {
        const message = error.message || t('generic_error');
        if (_isMounted.current == true) {
          setBtnLoading(false);
        }
        confirmAlert({
          title: t('alerts.error'),
          message: message,
          closeOnEscape: true,
          closeOnClickOutside: true,
          buttons: [
            {
              label: t('Ok'),
              className: 'error-ok-btn',
              onClick: () => {}
            }
          ]
        });
      }
    );
  };

  return (
    <div
      className={'align-col-start flex_1 snapfooder-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      <Header title={''} />
      {state.loading ? (
        <div style={{ width: '100%', marginTop: 20 }}>
          <ContentLoader
            speed={2}
            width={window.innerWidth}
            height={window.innerHeight - 150}
            viewBox={`0 0 ${window.innerWidth} ${window.innerHeight - 120}`}
            backgroundColor={Theme.colors.gray8}
            foregroundColor={Theme.colors.gray8}>
            <rect x="20" y="0" rx="0" ry="0" width={window.innerWidth - 40} height="60" />
            <rect x="20" y="80" rx="0" ry="0" width={window.innerWidth - 40} height="320" />
            <rect x="20" y="420" rx="0" ry="0" width={window.innerWidth - 40} height="150" />
          </ContentLoader>
        </div>
      ) : (
        <div className={'scrollview'}>
          <SnapfooderAvatar
            full_name={state.user.full_name}
            photo={state.user.photo}
            birthdate={state.user.birthdate}
            country={state.user.country}
          />
          <div style={{ marginTop: 28, marginBottom: 20 }}>
            <MapView
              lat={parseFloat(state.user.latitude) || TIRANA_CITY_LOCATION.latitude}
              lng={parseFloat(state.user.longitude) || TIRANA_CITY_LOCATION.longitude}
            />
          </div>
          {state.user.suggested_users != null && state.user.suggested_users.length > 0 && (
            <div className={'align-col-start suggested-hlist'}>
              <div className={'divider'} />
              <div className={'subject-title'}>{t('chat.suggested_users')}</div>
              <div className={'align-row-start suggested-list'}>
                {state.user.suggested_users.map((item) => (
                  <SuggestedUserItem
                    key={item.id}
                    id={item.id}
                    full_name={item.full_name}
                    photo={item.photo}
                    onViewProfile={() => {
                      getSnapfoodDetail(item.id);
                      checkFriend(item.id);
                    }}
                  />
                ))}
              </div>
              <div className={'scrollview_hider'} />
            </div>
          )}
          <div style={{ width: '100%', paddingHorizontal: 20, marginTop: 20, marginBottom: 40 }}>
            {isFriend ? (
              <div className={'remove-friend'} onClick={onRemoveFriend}>
                {t('friend_related.remove_friend')}
              </div>
            ) : (
              <MainBtn
                loading={btnLoading}
                className={state.user.invite_status == 'invited' ? 'gray7_bg' : 'cyan2_bg'}
                title={
                  state.user.invite_status == 'invited'
                    ? t('friend_related.cancel_invitation')
                    : t('friend_related.add_friend')
                }
                onClick={
                  state.user.invite_status == 'invited' ? onCancelInvitation : onSendInvitation
                }
              />
            )}
          </div>
          <div style={{ height: 20 }} />
        </div>
      )}
    </div>
  );
};

Snapfooder.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number
  })
};

const mapStateToProps = ({ app }) => ({
  user: app.user
});

export default connect(mapStateToProps, {})(Snapfooder);
