import React from 'react';
import Logo from '../../../components/Logo';
import './index.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const Splash = () => {
  return (
    <div className={'splash-screen'}>
      <Logo className={'logo'} />
      <p>
        <span>Save</span> time, <span>Eat</span> good.
      </p>
    </div>
  );
};

Splash.propTypes = {
  isLoggedIn: PropTypes.bool,
  hasLocation: PropTypes.bool,
  seenOnboard: PropTypes.bool,
  hasVerifiedPhone: PropTypes.bool
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  hasLocation: app.hasLocation,
  seenOnboard: app.seenOnboard,
  hasVerifiedPhone: app.hasVerifiedPhone
});

export default connect(mapStateToProps, {})(Splash);
