import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const DotBorderButton = ({ onPress, text, style, disabled }) => {
  return (
    <Button
      className={`app-btn dot-border-btn`}
      disabled={disabled}
      style={style}
      onClick={onPress}>
      {text}
    </Button>
  );
};

DotBorderButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onPress: PropTypes.func,
  disabled: PropTypes.bool
};

export default DotBorderButton;
