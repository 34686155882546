import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationMarker from '../../../components/Markers/LocationMarker';
import { Config } from '../../../constants';
import { LocationInput } from '../../../components/Inputs';
import { MainBtn } from '../../../components/Buttons';
import { getAddressByCoordinates } from '../../../services/location';
import './index.css';
import { addDefaultAddress, getAddresses, setAddress } from '../../../store/actions/app';
import { updateProfileDetails } from '../../../store/actions/auth';
import PropTypes from 'prop-types';
import { TIRANA_CITY_LOCATION } from '../../../constants/config';
import querystring from 'query-string';

const SetupLocation = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const location = useLocation();
  const parsed = querystring.parse(location.search);

  const isFromHome = parsed.from_home === 'true';
  const initLat = parsed.lat ? parseFloat(parsed.lat) : Config.TIRANA_CITY_LOCATION.latitude;
  const initLong = parsed.lng ? parseFloat(parsed.lng) : Config.TIRANA_CITY_LOCATION.longitude;

  const [loading, setLoading] = useState(false);
  const [curLoc, setCurLoc] = useState({
    latitude: initLat,
    longitude: initLong,
    street: '',
    building: '',
    country: '',
    city: '',
    formatted_address: ''
  });

  const [formatted_address, setFormattedAddress] = useState('');

  const curLocData = useRef(curLoc);

  useEffect(() => {
    _getFormattedAddress(curLoc.latitude, curLoc.longitude);
  }, [curLoc.latitude, curLoc.longitude]);

  const _getFormattedAddress = async (latitude, longitude) => {
    const address = await getAddressByCoordinates({
      latitude,
      longitude
    });
    if (address) {
      setFormattedAddress(address.formatted_address || '');
    }
  };

  const _changeLocData = (data) => {
    curLocData.current = data;
    setCurLoc(data);
  };

  const _changeMarkerPosition = async (latitude, longitude) => {
    try {
      const address = await getAddressByCoordinates({
        latitude: latitude,
        longitude: longitude
      });
      if (address) {
        _changeLocData(address);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const _saveLocation = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (props.isLoggedIn) {
        await props.updateProfileDetails({
          latitude: curLoc.latitude,
          longitude: curLoc.longitude
        });

        let address_data = {
          lat: curLoc.latitude,
          lng: curLoc.longitude,
          country: curLoc.country || TIRANA_CITY_LOCATION.country,
          city: curLoc.city || TIRANA_CITY_LOCATION.city,
          street: curLoc.street || TIRANA_CITY_LOCATION.street
        };
        await props.addDefaultAddress(address_data);
        await props.getAddresses();
      }

      setLoading(false);
      await props.setAddress({
        coordinates: {
          latitude: curLoc.latitude,
          longitude: curLoc.longitude
        },
        address: {
          country: curLoc.country,
          city: curLoc.city,
          street: curLoc.street
        }
      });

      if (isFromHome === true) {
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);
      console.warn('on setup location', error);
      if (isFromHome === true) {
        navigate(-1);
      } else {
        setDefaultAddress();
      }
    }
  };

  const setDefaultAddress = async () => {
    try {
      if (props.isLoggedIn) {
        await props.updateProfileDetails({
          latitude: TIRANA_CITY_LOCATION.latitude,
          longitude: TIRANA_CITY_LOCATION.longitude
        });

        let address_data = {
          lat: TIRANA_CITY_LOCATION.latitude,
          lng: TIRANA_CITY_LOCATION.longitude,
          country: TIRANA_CITY_LOCATION.country,
          city: TIRANA_CITY_LOCATION.city,
          street: TIRANA_CITY_LOCATION.street
        };
        await props.addDefaultAddress(address_data);
        await props.getAddresses();
      }
    } catch (error) {
      console.warn('setDefaultAddress', error);
    }

    await props.setAddress({
      coordinates: {
        latitude: TIRANA_CITY_LOCATION.latitude,
        longitude: TIRANA_CITY_LOCATION.longitude
      },
      address: {
        country: TIRANA_CITY_LOCATION.country,
        city: TIRANA_CITY_LOCATION.city,
        street: TIRANA_CITY_LOCATION.street
      }
    });
  };

  return (
    <div className={'location-setup-screen'}>
      <div className={'map-view'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY, libraries: ['places', 'geometry'] }}
          center={[curLoc.latitude, curLoc.longitude]}
          defaultZoom={12}
          options={{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: Config.SocialMapScreenStyles
          }}
          onClick={(e) => {
            _changeMarkerPosition(e.lat, e.lng);
          }}>
          <LocationMarker lat={curLoc.latitude} lng={curLoc.longitude} />
        </GoogleMapReact>
      </div>
      <div className={'w100 align-col-middle search-bar'}>
        <div className={'w100'}>
          <div className={'w100 search-view'}>
            <LocationInput
              defaultInput={formatted_address}
              placeholder={t('search_location.search_location')}
              onChange={(loc) => {
                _changeLocData(loc);
              }}
            />
            <p style={{ fontSize: 15 }}>{t('search_location.selected_location')}</p>
            <h4>{formatted_address}</h4>
          </div>
          <MainBtn
            disabled={loading}
            loading={loading}
            title={t('search_location.save_location')}
            onClick={_saveLocation}
          />
        </div>
      </div>
      {isFromHome === true && (
        <div
          className={'skip-btn'}
          onClick={() => {
            navigate(-1);
          }}>
          {t('skip')}
        </div>
      )}
    </div>
  );
};

SetupLocation.propTypes = {
  isLoggedIn: PropTypes.bool,
  setAddress: PropTypes.func,
  addDefaultAddress: PropTypes.func,
  getAddresses: PropTypes.func,
  updateProfileDetails: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    hasLocation: app.hasLocation,
    coordinates: app.coordinates,
    isLoggedIn: app.isLoggedIn
  };
}

export default connect(mapStateToProps, {
  setAddress,
  addDefaultAddress,
  getAddresses,
  updateProfileDetails
})(SetupLocation);
