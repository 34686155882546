import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AppText extends PureComponent {
  render() {
    const { style } = this.props;
    return <div style={style}>{this.props.children}</div>;
  }
}

AppText.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any
};

export default AppText;
