import { ACTION_TYPES } from '../../constants';
import apiFactory from '../../services/apiFactory';
import { setLanguage } from '../../localisations';
import { Storage } from '../../services';

export const toggleSideBar = (payload) => {
  return { type: ACTION_TYPES.APP_TOGGLE_SIDE_BAR, payload: payload };
};

export const setTmpFood = (payload) => {
  return { type: ACTION_TYPES.SET_FOOD, payload: payload };
};

export const setUserData = (payload) => {
  return { type: ACTION_TYPES.APP_SET_USER_DATA, payload: payload };
};

export const setTmpLocationPicked = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_ADDR_PICKED, payload: payload };
};

export const setTmpPassChanged = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_PASS_CHANGED, payload: payload };
};

export const setAllChatChannels = (payload) => {
  return { type: ACTION_TYPES.APP_SET_CHAT_CHANNELS, payload: payload };
};

export const setTmpSelectedForGroupChat = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_SET_SELECTED_FOR_GROUP_CHAT, payload: payload };
};

export const goActiveScreenFromPush = (value) => async (dispatch) => {
  await dispatch({
    type: ACTION_TYPES.APP_SET_ACTIVE_SCREEN_FROM_PUSH,
    payload: value
  });
};

export const setActiveSnapfoodMarker = (payload) => {
  return { type: ACTION_TYPES.APP_SET_ACTIVE_MARKER, payload: payload };
};

export const setProfileBlogFilter = (payload) => {
  return { type: ACTION_TYPES.APP_SET_PROFILE_BLOG_FILTER, payload: payload };
};

export const setProfileBlogs = (payload) => {
  return { type: ACTION_TYPES.APP_SET_BLOG_CATEGORIES, payload: payload };
};

export const setTmpSnapfoodMapData = (payload) => {
  return { type: ACTION_TYPES.APP_TMP_SNAPFOOD_MAP_DATA, payload: payload };
};

export const setHomeVendorFilter = (payload) => async (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      await dispatch({
        type: ACTION_TYPES.APP_SET_VENDOR_FILTER,
        payload: payload
      });
      resolve();
    } catch (e) {
      reject(e);
    }
  });
};

export const setHomeVendorSort = (payload) => {
  return { type: ACTION_TYPES.APP_SET_VENDOR_SORT, payload: payload };
};

export const setHomeOrdersFilter = (payload) => {
  return { type: ACTION_TYPES.APP_SET_ORDERS_FILTER, payload: payload };
};

export const setPickedUserIdForRewards = (payload) => {
  return { type: ACTION_TYPES.APP_SET_REWARDS_PICKED_USER, payload: payload };
};

export const setAddress =
  ({ coordinates, address }) =>
  async (dispatch) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        Storage.setLastCoordinates(coordinates);
        await dispatch({
          type: ACTION_TYPES.APP_APPLY_LOCATION,
          payload: {
            coordinates,
            address
          }
        });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

export const getAddresses = () => (dispatch) => {
  return new Promise((resolve) => {
    apiFactory.get('addresses').then(
      ({ data }) => {
        dispatch({
          type: ACTION_TYPES.APP_SET_ADDRESSES,
          payload: data.addresses || []
        });
        resolve(data.addresses);
      },
      () => resolve([])
    );
  });
};

export const setLocallyAddresses = (addresses) => (dispatch) => {
  return new Promise((resolve) => {
    dispatch({
      type: ACTION_TYPES.APP_SET_ADDRESSES,
      payload: addresses || []
    });
    resolve(addresses);
  });
};

export const saveAddress = (address) => {
  return new Promise((resolve, reject) => {
    if (address.id) {
      apiFactory.put(`addresses/${address.id}`, address).then(() => {
        resolve();
      }, reject);
    } else {
      apiFactory.post('addresses', address).then(async () => {
        resolve();
      }, reject);
    }
  });
};

export const addDefaultAddress = (address) => () => {
  return new Promise((resolve, reject) => {
    apiFactory.post('addresses/default', address).then(async () => {
      resolve();
    }, reject);
  });
};

export const deleteAddress = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    apiFactory.delete(`addresses/${id}`).then(() => {
      dispatch({
        type: ACTION_TYPES.APP_DELETED_ADDRESS,
        payload: id
      });
      resolve();
    }, reject);
  });
};

export const setAppLang = (language) => (dispatch) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    setLanguage(language);
    await dispatch({ type: ACTION_TYPES.APP_SET_LANGE, payload: language });
    resolve();
  });
};

export const getFriends = (status, searchTerm, filter_ids, online_payment) => {
  return new Promise((resolve) => {
    apiFactory
      .post('users/friends', {
        status: status,
        name: searchTerm == '' ? null : searchTerm,
        filter_ids: filter_ids,
        online_payment: online_payment
      })
      .then(
        ({ data }) => {
          resolve(data.friends);
        },
        () => resolve([])
      );
  });
};
