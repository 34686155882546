import React from 'react';
import { getImageFullURL } from '../../../utils/common';
import AppBadge from '../../AppBadge';
import { RoundIconBtn } from '../../Buttons';
import { MapMarkerAlt } from '@styled-icons/fa-solid';
import { Wallet } from '@styled-icons/entypo';
import PropTypes from 'prop-types';
import Switch from '../Switch';
import { Theme } from '../../../assets';
import './index.css';

// const MIN_W = 386
const HomeHeader = ({
  curTab,
  isLoggedIn,
  coordinates,
  cashback_amount,
  photo,
  onLocationSetup,
  onGoWallet,
  onGoProfile,
  onTabChange
}) => {
  const styles = {
    header: {
      height: 50,
      width: '100%',
      marginTop: 15,
      display: 'flex'
    },
    profileBtn: { marginLeft: 10 }
  };
  return (
    <div className="flex_between" style={styles.header}>
      <Switch
        items={['vendors', 'grocery']}
        cur_item={curTab}
        onSelect={(item) => {
          onTabChange(item);
        }}
      />
      <div className="align-middle">
        <RoundIconBtn
          style={{ width: 40, height: 40 }}
          icon={<MapMarkerAlt size={19} color={Theme.colors.cyan2} />}
          onClick={() => {
            onLocationSetup(coordinates);
          }}
        />
        {isLoggedIn && (
          <div
            className={'home-header-hide-btn'}
            style={{
              width: 40,
              height: 40,
              marginLeft: 8,
              position: 'relative',
              justifyContent: 'flex-end'
            }}>
            <RoundIconBtn
              style={{ width: 40, height: 40 }}
              icon={<Wallet color={Theme.colors.cyan2} size={22} />}
              onClick={() => {
                onGoWallet();
              }}
            />
            <AppBadge value={cashback_amount || 0} />
          </div>
        )}
        {isLoggedIn && (
          <div className="align-col-middle" style={styles.profileBtn} onClick={() => onGoProfile()}>
            <img
              src={getImageFullURL(photo)}
              style={{
                borderRadius: 20,
                width: 38,
                height: 38,
                resizeMode: 'contain'
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// function arePropsEqual(prevProps, nextProps) {
//   if (
//     prevProps.isLoggedIn !== nextProps.isLoggedIn ||
//     prevProps.photo !== nextProps.photo ||
//     prevProps.curTab !== nextProps.curTab ||
//     prevProps.coordinates.latitude !== nextProps.coordinates.latitude ||
//     prevProps.coordinates.longitude !== nextProps.coordinates.longitude
//   ) {
//     return false;
//   }
//   return true;
// }

HomeHeader.propTypes = {
  curTab: PropTypes.string,
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  isLoggedIn: PropTypes.bool,
  cashback_amount: PropTypes.number,
  photo: PropTypes.string,
  onLocationSetup: PropTypes.func,
  onGoWallet: PropTypes.func,
  onGoProfile: PropTypes.func,
  onTabChange: PropTypes.func
};

export default HomeHeader;
