import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItem from '../../../components/Order/OrderItem';
import './index.css';
import { RoundIconBtn } from '../../../components/Buttons';
import { ChevronLeft } from '@styled-icons/material';
import { Theme } from '../../../assets';
import { Config } from '../../../constants';
import { getPastOrders } from '../../../store/actions/orders';
import PropTypes from 'prop-types';

const PastOrders = (props) => {
  const _isMounted = useRef(true);
  const navigate = useNavigate();

  const { vendor_id } = useParams();

  const [orders, setOrders] = useState([]);
  // const [isLoading, setLoading] = useState(null);

  useEffect(() => {
    loadPastOrders();
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const loadPastOrders = () => {
    getPastOrders(vendor_id)
      .then((data) => {
        setOrders(data);
      })
      .catch((error) => {
        console.log('getPastOrders', error);
      });
  };

  return (
    <div className={'flex_1  past-order-screen'}>
      <div className={'align-middle ph-20 header'}>
        <RoundIconBtn
          style={{
            ...styles.headerBtn,
            borderWidth: 0
          }}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className={'align-row-start'}>
          <RoundIconBtn
            style={styles.topLogoView}
            icon={
              <img
                src={Config.IMG_BASE_URL + props.vendorData.logo_thumbnail_path}
                style={styles.topLogo}
              />
            }
            onClick={() => {}}
          />
          <div className={'vendor-title'}>{props.vendorData.title}</div>
        </div>
        <div style={{ flex: 1 }} />
      </div>
      <div className={'flex_1 scrollview'}>
        {orders.map((item) => (
          <OrderItem key={item.id} data={item} order_id={item.id} order_status={item.status} />
        ))}
        <div style={{ height: 40 }} />
      </div>
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogoView: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white },
  topLogo: { width: 30, height: 30, borderRadius: 8 }
};

const mapStateToProps = ({ app, shop }) => ({
  isLoggedIn: app.isLoggedIn,
  vendorData: shop.vendorData
});

PastOrders.propTypes = {
  vendorData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string
  })
};

export default connect(mapStateToProps, {})(PastOrders);
