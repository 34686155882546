import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RadioBtn } from '../../Buttons';
import './index.css';

const AddressItem = ({
  favourite,
  data,
  phone,
  editable,
  showNotes,
  outOfDeliveryArea,
  style,
  onClick,
  onEdit
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={'address-item'}
      style={style}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) {
          onClick();
        }
      }}>
      {editable !== false && (
        <div className={'flex_between title-view'}>
          <div className={'align-middle'}>
            <div className={'address-type'}>{t(data.address_type)}</div>
            {favourite === 1 && <div className={'primary-text'}>{t('primary')}</div>}
          </div>

          <div className={'align-middle'}>
            <p
              className={'edit-link'}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onEdit();
              }}>
              {t('edit')}
            </p>
            {onClick && (
              <div className={'radio'}>
                <RadioBtn checked={favourite === 1} onClick={onClick} />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={'info'}>
        {/*<h3>{data.full_name}</h3>*/}
        <p>{phone}</p>
        <p>
          {data.street} {data.city}, {data.country}
        </p>
        {outOfDeliveryArea === true && (
          <div className={'out_delivery_area_txt'}>{t('cart.out_of_range_address')}</div>
        )}
        {showNotes === true && data.notes != null && data.notes !== '' && <p>{data.notes}</p>}
      </div>
    </div>
  );
};

AddressItem.propTypes = {
  id: PropTypes.number,
  favourite: PropTypes.number,
  data: PropTypes.shape({
    address_type: PropTypes.string,
    favourite: PropTypes.number,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    notes: PropTypes.string
  }),
  phone: PropTypes.string,
  editable: PropTypes.bool,
  showNotes: PropTypes.bool,
  outOfDeliveryArea: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onEdit: PropTypes.func
};
export default AddressItem;
