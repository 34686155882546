import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'query-string';
import { RoundIconBtn } from '../../../components/Buttons';
import './index.css';
import { Theme } from '../../../assets';
import { ChevronLeft } from '@styled-icons/material';
import GoogleMapReact from 'google-map-react';
import { Config } from '../../../constants';
import Svg_maker from '../../../assets/svgs/markers/ic_locpin.svg';

const LocationMsgScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const parsed = querystring.parse(location.search);

  useEffect(() => {}, []);

  return (
    <div className={'align-col-start flex_1 location-msg-screen bg-white'}>
      <div className={'vendor-map-view flex_1 align-col-middle'}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: Config.GOOGLE_MAP_API_KEY }}
          defaultCenter={[
            parseFloat(parsed.lat) || Config.TIRANA_CITY_LOCATION.latitude,
            parseFloat(parsed.lng) || Config.TIRANA_CITY_LOCATION.longitude
          ]}
          defaultZoom={12}
          options={{
            styles: Config.SocialMapScreenStyles
          }}>
          {parsed.lat && parsed.lng && (
            <img src={Svg_maker} lat={parseFloat(parsed.lat)} lng={parseFloat(parsed.lng)} />
          )}
        </GoogleMapReact>
      </div>
      <div className={'align-middle ph-20 header'}>
        <RoundIconBtn
          style={styles.headerBtn}
          icon={<ChevronLeft size={22} color={Theme.colors.text} />}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div style={{ flex: 1 }} />
      </div>
    </div>
  );
};

const styles = {
  headerBtn: { width: 33, height: 33, borderRadius: 8, backgroundColor: Theme.colors.white }
};

export default LocationMsgScreen;
