import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './AuthTab.css';

const items = ['login', 'register'];
const AuthTab = ({ curitem, onSelect }) => {
  const { t } = useTranslation();

  const [curTtem, setCurItem] = useState(curitem == null ? items[0] : curitem);
  useEffect(() => {
    setCurItem(curitem == null ? items[0] : curitem);
  }, [curitem]);

  return (
    <div className={'align-middle auth-tab'}>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setCurItem(item);
            onSelect(item);
          }}
          className={['align-middle btn', item === curTtem ? 'btn-active' : 'btn-inactive'].join(
            ' '
          )}>
          <div className={['btn-txt', item === curTtem ? 'active_txt' : 'inactive_txt'].join(' ')}>
            {t(item)}
          </div>
        </div>
      ))}
    </div>
  );
};

AuthTab.propTypes = {
  curitem: PropTypes.string,
  onSelect: PropTypes.func
};

export default React.memo(AuthTab);
