import React from 'react';
import { Theme } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getImageFullURL } from '../../../utils/common';
import DropdownMenus from '../../Dropdowns';
import { ChevronLeft } from '@styled-icons/material';
import PropTypes from 'prop-types';
// svgs
import Svg_more from '../../../assets/svgs/chats/btn_more.svg';
import Svg_delete from '../../../assets/svgs/chats/delete.svg';
import Svg_mute from '../../../assets/svgs/chats/mute.svg';
import Svg_exit from '../../../assets/svgs/chats/exit.svg';
import Svg_muted from '../../../assets/svgs/chats/muted.svg';
import './index.css';

const MessagesHeader = ({
  data,
  user_id,
  style,
  isMuted,
  onPressName,
  onBack,
  onDelete,
  onExit,
  onMute
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getMembers = () => {
    if (data == null) {
      return '';
    }
    if (data.members == null) {
      return '';
    }
    let member_names = '';
    var remaining_cnt = 0;
    let other_members = data.members.filter((i) => i.id !== user_id);
    other_members.map((item, index) => {
      if (index < 2) {
        member_names = member_names + item.full_name + ', ';
      } else {
        remaining_cnt = remaining_cnt + 1;
      }
    });

    if (remaining_cnt > 0) {
      member_names = member_names + '+' + remaining_cnt;
    }
    return member_names;
  };

  const getPhoto = () => {
    if (data == null) {
      return getImageFullURL('default');
    }
    if (data.channel_type === 'single') {
      if (user_id === data.creator.id) {
        return getImageFullURL(data.partner.photo);
      } else if (user_id === data.partner.id) {
        return getImageFullURL(data.creator.photo);
      }
    } else {
      return getImageFullURL(data.photo);
    }
    return getImageFullURL('default');
  };

  const getName = () => {
    if (data == null) {
      return '';
    }
    if (data.channel_type === 'single') {
      if (user_id === data.creator.id) {
        return data.partner.full_name;
      } else if (user_id === data.partner.id) {
        return data.creator.full_name;
      }
    } else {
      return data.full_name;
    }
    return '';
  };

  const getDesc = () => {
    if (data == null) {
      return '';
    }
    if (data.channel_type === 'single') {
      return 'Online';
    } else {
      // group chat
      return getMembers();
    }
  };

  const canDeleteGroup = () => {
    if (data == null) {
      return false;
    }
    if (data.channel_type !== 'single' && data.admin != null && data.admin.id === user_id) {
      return true;
    }
    return false;
  };

  const canExitGroup = () => {
    if (data == null) {
      return false;
    }
    if (
      data.channel_type !== 'single' &&
      data.users != null &&
      data.users.findIndex((i) => i === user_id) >= 0
    ) {
      return true;
    }
    return false;
  };

  const canMute = () => {
    if (data == null) {
      return false;
    }
    if (data.channel_type === 'single') {
      return true;
    } else if (
      data.channel_type !== 'single' &&
      data.users !== null &&
      data.users.findIndex((i) => i === user_id) >= 0
    ) {
      return true;
    }
    return false;
  };

  const getMenusItems = () => {
    let items = [];
    if (canDeleteGroup()) {
      items.push(
        <div className={'align-middle popup-btn'} onClick={onDelete}>
          <img src={Svg_delete} />
          <div className={'messages-header-popup-btn-text'}>{t('social.chat.delete_group')}</div>
        </div>
      );
    }
    if (canMute()) {
      items.push(
        <div className={'align-middle popup-btn'} onClick={onMute}>
          <img src={Svg_mute} />
          <div className={'messages-header-popup-btn-text'}>
            {data.channel_type !== 'single' ? t('social.chat.mute_group') : t('social.chat.mute')}
          </div>
        </div>
      );
    }
    if (canExitGroup()) {
      items.push(
        <div className={'align-middle popup-btn'} style={{ borderBottomWidth: 0 }} onClick={onExit}>
          <img src={Svg_exit} />
          <div className={'messages-header-popup-btn-text'}>{t('social.chat.exit_group')}</div>
        </div>
      );
    }
    return items;
  };

  return (
    <div className={'align-middle messages-header'} style={style}>
      <div
        className={'btn-style'}
        style={{ paddingHorizontal: 5 }}
        onClick={
          onBack
            ? onBack
            : () => {
                navigate(-1);
              }
        }>
        <ChevronLeft size={28} color={Theme.colors.text} />
      </div>
      <div
        className={'align-middle'}
        style={{ marginLeft: 15 }}
        onClick={onPressName ? onPressName : () => {}}>
        <img className={'avatar'} src={getPhoto()} />
        <div className={'align-col-middle'} style={{ alignItems: 'flex-start' }}>
          <div className={'align-middle'}>
            <div className={'name'}>{getName()}</div>
            {isMuted && <img src={Svg_muted} />}
          </div>
          <div className={'desc'}>{getDesc()}</div>
        </div>
      </div>
      <div style={{ flex: 1 }} />
      <DropdownMenus
        onClick={() => {}}
        button={<img src={Svg_more} />}
        menuItems={getMenusItems()}
      />
    </div>
  );
};

MessagesHeader.propTypes = {
  user_id: PropTypes.number,
  isMuted: PropTypes.bool,
  data: PropTypes.shape({
    channel_type: PropTypes.string,
    members: PropTypes.array,
    users: PropTypes.array,
    creator: PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
      photo: PropTypes.string
    }),
    partner: PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string,
      photo: PropTypes.string
    }),
    admin: PropTypes.shape({
      id: PropTypes.number
    }),
    full_name: PropTypes.string,
    photo: PropTypes.string
  }),
  style: PropTypes.object,
  onPressName: PropTypes.func,
  onBack: PropTypes.func,
  onDelete: PropTypes.func,
  onExit: PropTypes.func,
  onMute: PropTypes.func
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.isMuted === nextProps.isMuted &&
    prevProps.user_id === nextProps.user_id &&
    prevProps.channel_id === nextProps.channel_id
  );
}

export default React.memo(MessagesHeader, arePropsEqual);
