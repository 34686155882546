import React from 'react';
import { IconButton } from '@mui/material';
import Counter from '../../Common/Counter';
import { Theme } from '../../../assets';
import Svg_delete from '../../../assets/svgs/cart/ic_delete.svg';
import './index.css';
import PropTypes from 'prop-types';

const CartItem = ({ data, onPlus, onMinus, onDelete, style }) => {
  return (
    <div className={'align-middle cart-item'} style={style}>
      <div className={'align-col-middle info-view'}>
        <h2>{data.title}</h2>
        <p>{parseInt(data.price)} L</p>
      </div>
      <IconButton
        style={{ padding: 0, marginRight: 15 }}
        onClick={onDelete ? () => onDelete(data.id) : () => {}}>
        <img src={Svg_delete} />
      </IconButton>
      <div className={'divider'} />
      <Counter
        value={data.quantity}
        onPlus={() => onPlus(data.id)}
        onMinus={() => {
          if (data.quantity > 1) {
            onMinus(data.id);
          } else {
            onDelete(data.id);
          }
        }}
        btnSize={18}
        value_style={{ fontSize: 14, paddingBottom: 1 }}
        style={{
          backgroundColor: Theme.colors.gray8,
          width: 90,
          height: 40,
          marginLeft: 6
        }}
      />
    </div>
  );
};

CartItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    price: PropTypes.number,
    quantity: PropTypes.number,
    title: PropTypes.string
  }),
  style: PropTypes.object,
  onPlus: PropTypes.func,
  onMinus: PropTypes.func,
  onDelete: PropTypes.func
};

export default React.memo(CartItem);
