import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Theme } from '../../../assets';
import { AuthInput } from '../../../components/Inputs';
import { AuthBtn } from '../../../components/Buttons';
import AuthLayout from '../auth-layout';
import { updateProfileDetails } from '../../../store/actions/auth';
import PropTypes from 'prop-types';
import { validatePhoneNumber } from '../../../utils/common';
import './index.css';
import { ChevronLeft } from '@styled-icons/material';
import { confirmAlert } from 'react-confirm-alert';

const EditPhoneScreen = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [state, setState] = useState({
    loading: false,
    phone: ''
  });

  const update = async () => {
    if (props.user == null) {
      return;
    }
    const user = { ...props.user };
    user.phone = state.phone;
    const isValidPhone = validatePhoneNumber(user.phone);
    if (!isValidPhone) {
      return confirmAlert({
        title: t('attention'),
        message: t('wrong_phone_format'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }

    try {
      setState({ ...state, loading: true });
      user.photo = null;
      await props.updateProfileDetails(user);
      setState({ ...state, loading: false });
      navigate(-1);
    } catch (error) {
      setState({ ...state, loading: false });
      return confirmAlert({
        title: t('attention'),
        message: t('checkout.something_is_wrong'),
        closeOnEscape: true,
        closeOnClickOutside: true,
        buttons: [
          {
            label: t('Ok'),
            className: 'error-ok-btn',
            onClick: () => {}
          }
        ]
      });
    }
  };

  return (
    <div className={'edit-phone-screen'}>
      <AuthLayout>
        <div className={'scrollview'}>
          <div style={styles.title}>
            {props.user.phone ? t('edit_phone.header1') : t('edit_phone.header1_new_user')}
          </div>
          <div style={styles.descTxt}>
            {props.user.phone ? t('edit_phone.text') : t('edit_phone.text_new_user')}
          </div>
          <AuthInput
            placeholder={t('edit_phone.placeholder')}
            onChange={(phone) => setState({ ...state, phone })}
            text={state.phone}
            style={{ marginBottom: 20, marginTop: 30 }}
          />
          <AuthBtn
            title={props.user.phone ? t('edit_phone.button') : t('edit_phone.button_new_user')}
            style={{ width: '100%', marginTop: 50 }}
            isLoading={state.loading}
            onClick={update}
          />
        </div>
      </AuthLayout>
      {props.user.phone != null && (
        <div
          style={styles.backBtn}
          onClick={() => {
            navigate(-1);
          }}>
          <ChevronLeft size={28} color={Theme.colors.text} />
        </div>
      )}
    </div>
  );
};

const styles = {
  title: {
    textAlign: 'center',
    fontSize: 18,
    color: Theme.colors.text,
    fontFamily: Theme.fonts.bold
  },
  descTxt: {
    fontFamily: Theme.fonts.medium,
    color: Theme.colors.text,
    fontSize: 16,
    textAlign: 'center',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 18
  },
  backBtn: {
    cursor: 'pointer',
    position: 'absolute',
    left: 20,
    top: 30,
    paddingBottom: 12,
    paddingTop: 12,
    paddingRight: 20
  }
};

EditPhoneScreen.propTypes = {
  user: PropTypes.shape({
    phone: PropTypes.string
  }),
  updateProfileDetails: PropTypes.func
};

function mapStateToProps({ app }) {
  return {
    user: app.user
  };
}

export default connect(mapStateToProps, {
  updateProfileDetails
})(EditPhoneScreen);
