import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTextWidth } from '@tag0/use-text-width';
import { useTranslation } from 'react-i18next';
import Svg_vendor from '../../assets/svgs/markers/snapfooder_vendor.svg';
import { Config } from '../../constants';
import './index.css';
import { Theme } from '../../assets';
import { setActiveSnapfoodMarker } from '../../store/actions/app';
import { connect } from 'react-redux';

const VendorMarker = ({ vendor_id, vendor, activeMarker, setActiveSnapfoodMarker, onGoVendor }) => {
  const { t } = useTranslation();
  const [isPopup, ShowPopup] = useState(false);

  const width = useTextWidth({ text: vendor.title, font: '18px Yellix-Bold' });

  useEffect(() => {
    if (activeMarker.id == vendor_id && activeMarker.type == 'vendor-marker') {
      ShowPopup(true);
    } else {
      ShowPopup(false);
    }
  }, [activeMarker.id, activeMarker.type]);

  return (
    <div className={'map-marker vendor-marker'}>
      <div className={'marker-body'}>
        <div className={['popup', !isPopup && 'hidden'].join(' ')} onClick={onGoVendor}>
          <div className={'align-middle vendor-info'}>
            <img src={Config.IMG_BASE_URL + vendor.logo_thumbnail_path} alt={''} />
            <p style={{ width: isNaN(width) ? 60 : width + 4 }}>{vendor.title}</p>
          </div>
          <div
            className={'delivery_range_indicator'}
            style={{ color: vendor.in_deliver_range ? Theme.colors.cyan2 : Theme.colors.gray7 }}>
            {vendor.in_deliver_range
              ? t('vendor_item.in_delivery_range')
              : t('vendor_item.out_delivery_range')}
          </div>
          <div className={'anchor'} />
        </div>
        <img
          src={Svg_vendor}
          onClick={(e) => {
            e.preventDefault();
            if (isPopup) {
              setActiveSnapfoodMarker(null);
            } else {
              setActiveSnapfoodMarker({
                type: 'vendor-marker',
                id: vendor_id
              });
            }
          }}
        />
      </div>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.vendor_id === nextProps.vendor_id &&
    prevProps.lat === nextProps.lat &&
    prevProps.lng === nextProps.lng &&
    prevProps.activeMarker.id === nextProps.activeMarker.id &&
    prevProps.activeMarker.type === nextProps.activeMarker.type
  );
}

VendorMarker.propTypes = {
  vendor_id: PropTypes.number,
  infoType: PropTypes.string,
  vendor: PropTypes.shape({
    title: PropTypes.string,
    logo_thumbnail_path: PropTypes.string,
    rating_interval: PropTypes.string,
    is_new: PropTypes.bool,
    isFav: PropTypes.number,
    in_deliver_range: PropTypes.bool
  }),
  activeMarker: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number
  }),
  setActiveSnapfoodMarker: PropTypes.func,
  onGoVendor: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  activeMarker: app.activeMarker
});

export default connect(mapStateToProps, {
  setActiveSnapfoodMarker
})(React.memo(VendorMarker, arePropsEqual));
