import React from 'react';
import { ChevronRight } from '@styled-icons/evaicons-solid';
import { Theme } from '../../../assets';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.css';
import PropTypes from 'prop-types';
import { PrintLog } from '../../../utils/common';

const PastOrderView = ({ vendor_id }) => {
  PrintLog('PastOrderView');
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        navigate(`/past-order/${vendor_id}`);
      }}
      className={'flex_between past-order-link'}>
      <p>{t('vendor_profile.my_past_orders')}</p>
      <ChevronRight size={22} color={Theme.colors.text} />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.vendor_id === nextProps.vendor_id;
}

PastOrderView.propTypes = {
  vendor_id: PropTypes.number
};

export default React.memo(PastOrderView, arePropsEqual);
