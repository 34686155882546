import React from 'react';
import { Theme } from '../../../assets';
import PropTypes from 'prop-types';

const ReviewTagItem = ({ onSelect, name, isChecked }) => {
  return (
    <div
      onClick={() => onSelect(name)}
      className={'align-col-middle '}
      style={{
        ...styles.container,
        backgroundColor: isChecked ? '#23CBD826' : Theme.colors.white
      }}>
      <div
        style={{ ...styles.txt, fontFamily: isChecked ? Theme.fonts.bold : Theme.fonts.semiBold }}>
        {name}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: 32,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 7,
    borderWidth: 1,
    borderColor: Theme.colors.cyan2,
    borderStyleL: 'solid',
    backgroundColor: Theme.colors.white,
    marginRight: 6,
    marginBottom: 6
  },
  txt: { fontSize: 15, color: Theme.colors.cyan2 }
};

ReviewTagItem.propTypes = {
  name: PropTypes.string,
  isChecked: PropTypes.bool,
  onSelect: PropTypes.func
};
export default React.memo(ReviewTagItem);
