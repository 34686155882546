import React from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './index.css';
import Svg_curloc from '../../../assets/svgs/chats/current_location.svg';
import Svg_map from '../../../assets/svgs/chats/map.svg';

const LocationMsgOptionsModal = ({ isOpen, onClose, onCurrentLoc, onPickMap }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      className={'align-col-middle w100 modal location-msg-option-modal'}>
      <div className={'content'}>
        <h1>{t('chat.share_location')}</h1>
        <div
          onClick={onCurrentLoc}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <img src={Svg_curloc} />
          <div className={'btn-text'}>{t('chat.current_location')}</div>
        </div>
        <div className={'divider'} />
        <div
          onClick={onPickMap}
          className={'align-middle'}
          style={{ width: '100%', height: 50, cursor: 'pointer' }}>
          <img src={Svg_map} />
          <div className={'btn-text'}>{t('chat.find_location')}</div>
        </div>
      </div>
    </Dialog>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.isOpen === nextProps.isOpen;
}
LocationMsgOptionsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCurrentLoc: PropTypes.func,
  onPickMap: PropTypes.func
};

export default React.memo(LocationMsgOptionsModal, arePropsEqual);
