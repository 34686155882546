import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { StarFill } from '@styled-icons/bootstrap';
import { Theme } from '../../../assets';
import { Rating } from '@mui/material';
import ReviewTagItem from './TagItem';
import { CommentInput } from '../../Inputs';

const ReviewInput = ({ data, changeReview }) => {
  const [reviewData, setReviewData] = useState({
    rating: 0,
    options: [],
    comment: ''
  });

  const isCheckedOption = (option) => {
    let found_index = reviewData.options.findIndex((i) => i === option);
    if (found_index === -1) {
      return false;
    }
    return true;
  };

  return (
    <div className={'align-col-middle order-review-input'}>
      <div className={'align-middle w100 mb20'}>
        <img src={data.icon} />
        <div
          className={'align-col-middle flex_1'}
          style={{ alignItems: 'flex-start', marginLeft: 12 }}>
          <h3>{data.question}</h3>
          <Rating
            value={reviewData.rating}
            icon={<StarFill color={Theme.colors.red1} size={18} style={{ marginRight: 4 }} />}
            emptyIcon={<StarFill color={Theme.colors.gray7} size={18} style={{ marginRight: 4 }} />}
            onChange={(event, newValue) => {
              event.preventDefault();
              setReviewData({
                ...reviewData,
                rating: newValue
              });
              changeReview({
                ...reviewData,
                rating: newValue
              });
            }}
          />
        </div>
      </div>
      <div className={'align-row-start flex-wrap mb12'}>
        {data.options.map((item, index) => (
          <ReviewTagItem
            key={index}
            onSelect={(name) => {
              let tmp = reviewData.options.slice(0, reviewData.options.length);
              let found_index = tmp.findIndex((i) => i === name);
              if (found_index === -1) {
                tmp.push(name);
              } else {
                tmp.splice(found_index, 1);
              }
              setReviewData({
                ...reviewData,
                options: tmp
              });
              changeReview({
                ...reviewData,
                options: tmp
              });
            }}
            name={item}
            isChecked={isCheckedOption(item)}
          />
        ))}
      </div>
      <CommentInput
        placeholder={data.placeholder}
        text={reviewData.comment}
        onChange={(comment) => {
          setReviewData({
            ...reviewData,
            comment
          });
          changeReview({
            ...reviewData,
            comment
          });
        }}
      />
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.type === nextProps.type;
}

ReviewInput.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.element,
    question: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string
  }),
  changeReview: PropTypes.func
};
export default React.memo(ReviewInput, arePropsEqual);
