import React from 'react';
import { Message } from '../react-web-gifted-chat';
import MessageBubble from './MessageBubble';

export const renderMessage = (props) => (
  <Message
    {...props}
    containerStyle={{
      left: { backgroundColor: '#fff' },
      right: { backgroundColor: '#fff' }
    }}
    textStyle={{ fontSize: 13, textAlign: 'center' }}
  />
);

export const renderBubble = (props, isGroup, onLongPress, onPressMsg, onShowGalleryMsgs) => {
  return (
    <MessageBubble
      {...props}
      isGroup={isGroup}
      // renderTime={() => <Text>Time</Text>}
      // renderTicks={() => <Text>Ticks</Text>}
      containerStyle={{
        left: { paddingLeft: 12, marginTop: 8 },
        right: { paddingRight: 12, marginTop: 8 }
      }}
      bottomContainerStyle={{
        left: { display: 'none' },
        right: { display: 'none' }
      }}
      tickStyle={{}}
      // usernameStyle={{ color: 'tomato', fontWeight: '100' }}
      containerToNextStyle={{
        left: {},
        right: {}
      }}
      containerToPreviousStyle={{
        left: {},
        right: {}
      }}
      onPressMsg={onPressMsg}
      onLongPress={onLongPress}
      onShowGalleryMsgs={onShowGalleryMsgs}
    />
  );
};
