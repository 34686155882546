import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { SearchInput } from '../../components/Inputs';
import { Search } from '@styled-icons/evil';
import { Filter } from '@styled-icons/bootstrap';
import { RoundIconBtn } from '../../components/Buttons';
import OrderItem from '../../components/Order/OrderItem';
import NoOrders from '../../components/Empty/NoOrders';
import { getOrders } from '../../store/actions/orders';
import { setHomeOrdersFilter } from '../../store/actions/app';
import './index.css';
import { Theme } from '../../assets';
import { CommonTabs } from '../../components/TabSelector';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { ROUTES_NAMES } from '../../constants';

const PerPage = 10;

const Orders = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [curLoading, setCurLoading] = useState(null);
  const [pastLoading, setPastLoading] = useState(null);
  const [orders, setOrders] = useState([]);
  const [pastOrders, setPastOrders] = useState([]);

  const [curPageTab1, setCurPageTab1] = useState(1);
  const [totalPagesTab1, setTotalPagesTab1] = useState(1);
  const [curPageTab2, setCurPageTab2] = useState(1);
  const [totalPagesTab2, setTotalPagesTab2] = useState(1);

  useEffect(() => {
    loadOrders(1, PerPage, true);
  }, [
    props.home_orders_filter.discount,
    props.home_orders_filter.cashback,
    props.home_orders_filter.promotion,
    props.home_orders_filter.split,
    props.home_orders_filter.searchTerm,
    props.home_orders_filter.orders_tab
  ]);

  const getFilers = () => {
    const { discount, cashback, promotion, split, searchTerm, orders_tab } =
      props.home_orders_filter;
    let filters = [];
    if (discount) {
      filters.push('discount=1');
    }
    if (cashback) {
      filters.push('cashback=1');
    }
    if (promotion) {
      filters.push('promotion=1');
    }
    if (split) {
      filters.push('split=1');
    }
    if (searchTerm !== '') {
      filters.push('searchTerm=' + searchTerm);
    }
    if (orders_tab === 'current') {
      filters.push('status=current');
    }
    if (orders_tab === 'past') {
      filters.push('status=past');
    }
    return filters;
  };

  const loadOrders = (page, perPage, forceLoading = false) => {
    const { orders_tab } = props.home_orders_filter;

    if ((curLoading || pastLoading) && forceLoading === false) {
      return;
    }
    if (orders_tab === 'current') {
      setCurLoading(true);
    } else {
      setPastLoading(true);
    }

    let filterKeys = getFilers();

    getOrders(page, perPage, filterKeys)
      .then((orderData) => {
        if (page > 1) {
          if (orders_tab === 'current') {
            const currentOrderIds = orders.map((x) => x.id);
            const newOrders = orderData.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
            setOrders([...orders, ...newOrders]);
            setCurPageTab1(orderData['current_page']);
            setTotalPagesTab1(orderData['last_page']);
          } else {
            const currentOrderIds = pastOrders.map((x) => x.id);
            const newOrders = orderData.data.filter((x) => currentOrderIds.indexOf(x.id) === -1);
            setPastOrders([...pastOrders, ...newOrders]);
            setCurPageTab2(orderData['current_page']);
            setTotalPagesTab2(orderData['last_page']);
          }
        } else {
          if (orders_tab === 'current') {
            setOrders(orderData.data);
            setCurPageTab1(orderData['current_page']);
            setTotalPagesTab1(orderData['last_page']);
          } else {
            setPastOrders(orderData.data);
            setCurPageTab2(orderData['current_page']);
            setTotalPagesTab2(orderData['last_page']);
          }
        }
        if (orders_tab === 'current') {
          setCurLoading(false);
        } else {
          setPastLoading(false);
        }
      })
      .catch(() => {
        if (orders_tab === 'current') {
          setCurLoading(false);
        } else {
          setPastLoading(false);
        }
      });
  };

  const loadNextPage = async (isCurrent = false) => {
    if (isCurrent) {
      loadOrders(curPageTab1 + 1, PerPage, true);
    } else {
      loadOrders(curPageTab2 + 1, PerPage, true);
    }
  };

  const _renderSearchView = () => {
    return (
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('search.search_vendors_on_search')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45, marginRight: 12 }}
          value={props.home_orders_filter.searchTerm}
          onChange={(text) => {
            props.setHomeOrdersFilter({
              ...props.home_orders_filter,
              searchTerm: text
            });
          }}
        />
        <RoundIconBtn
          style={{ width: 45, height: 45 }}
          icon={<Filter size={26} color={Theme.colors.cyan2} />}
          onClick={() => {
            navigate(ROUTES_NAMES.ordersFilter);
          }}
        />
      </div>
    );
  };

  const renderCurrentOrders = () => {
    return (
      <div className={'scrollview'}>
        {curLoading === false && orders.length === 0 ? (
          <NoOrders isCurrent={true} style={{ marginTop: 80 }} />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadNextPage(true)}
            hasMore={curPageTab1 < totalPagesTab1}
            loader={
              <div className="align-col-middle mt12 mb20" key={0}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
            useWindow={false}>
            {orders.map((item) => (
              <OrderItem key={item.id} data={item} order_id={item.id} order_status={item.status} />
            ))}
            <div style={{ height: 20 }} />
          </InfiniteScroll>
        )}
      </div>
    );
  };

  const renderPastOrders = () => {
    return (
      <div className={'scrollview'}>
        {pastLoading === false && pastOrders.length === 0 ? (
          <NoOrders isCurrent={false} style={{ marginTop: 80 }} />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadNextPage(false)}
            hasMore={curPageTab2 < totalPagesTab2}
            loader={
              <div className="align-col-middle mt12 mb20" key={0}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
            useWindow={false}>
            {pastOrders.map((item) => (
              <OrderItem key={item.id} data={item} order_id={item.id} order_status={item.status} />
            ))}
            <div style={{ height: 20 }} />
          </InfiniteScroll>
        )}
      </div>
    );
  };

  return (
    <div
      className={'align-col-start flex_1 orders-screen'}
      style={{ backgroundColor: Theme.colors.white }}>
      {_renderSearchView()}
      <div style={{ width: '100%', paddingLeft: 20, paddingRight: 20 }}>
        <div className={'align-middle operation-tab'}>
          <CommonTabs
            items={['current', 'past']}
            item={props.home_orders_filter.orders_tab}
            style={{ width: '100%', paddingLeft: 0, paddingRight: 0 }}
            onChange={(item) =>
              props.setHomeOrdersFilter({
                ...props.home_orders_filter,
                orders_tab: item
              })
            }
          />
        </div>
      </div>
      {props.home_orders_filter.orders_tab === 'current'
        ? renderCurrentOrders()
        : renderPastOrders()}
    </div>
  );
};

Orders.propTypes = {
  home_orders_filter: PropTypes.shape({
    orders_tab: PropTypes.string,
    searchTerm: PropTypes.string,
    discount: PropTypes.bool,
    cashback: PropTypes.bool,
    promotion: PropTypes.bool,
    split: PropTypes.bool
  }),
  setHomeOrdersFilter: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  home_orders_filter: app.home_orders_filter
});

export default connect(mapStateToProps, { setHomeOrdersFilter })(Orders);
