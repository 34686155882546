import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// svgs
import Home_active from '../../../assets/svgs/home_tabs/home_active.svg';
import Home_inactive from '../../../assets/svgs/home_tabs/home_inactive.svg';
import Search_active from '../../../assets/svgs/home_tabs/search_active.svg';
import Search_inactive from '../../../assets/svgs/home_tabs/search_inactive.svg';
import Chat_active from '../../../assets/svgs/home_tabs/chat_active.svg';
import Chat_inactive from '../../../assets/svgs/home_tabs/chat_inactive.svg';
import Orders_active from '../../../assets/svgs/home_tabs/orders_active.svg';
import Orders_inactive from '../../../assets/svgs/home_tabs/orders_inactive.svg';
import Profile_active from '../../../assets/svgs/home_tabs/profile_active.svg';
import Profile_inactive from '../../../assets/svgs/home_tabs/profile_inactive.svg';
import { ROUTES_NAMES } from '../../../constants';
import './index.css';
import PropTypes from 'prop-types';

const HomeTabs = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const tabs = [
    {
      label: t('home'),
      route: ROUTES_NAMES.home,
      activeIcon: Home_active,
      inactiveIcon: Home_inactive
    },
    {
      label: t('bottom-search'),
      route: ROUTES_NAMES.search,
      activeIcon: Search_active,
      inactiveIcon: Search_inactive
    },
    {
      label: t('bottom-chat'),
      route: ROUTES_NAMES.chat,
      activeIcon: Chat_active,
      inactiveIcon: Chat_inactive
    },
    {
      label: t('bottom-orders'),
      route: ROUTES_NAMES.orders,
      activeIcon: Orders_active,
      inactiveIcon: Orders_inactive
    },
    {
      label: t('bottom-profile'),
      route: ROUTES_NAMES.profile,
      activeIcon: Profile_active,
      inactiveIcon: Profile_inactive
    }
  ];

  return (
    <div className={'home-tabs'}>
      {tabs.map((tab) => (
        <div
          key={tab.label}
          className={'btn'}
          onClick={(e) => {
            e.preventDefault();
            if (tab.label === t('bottom-chat') && props.isLoggedIn !== true) {
              navigate(ROUTES_NAMES.welcome);
            } else if (tab.label === t('bottom-orders') && props.isLoggedIn !== true) {
              navigate(ROUTES_NAMES.welcome);
            } else if (tab.label === t('bottom-profile') && props.isLoggedIn !== true) {
              navigate(ROUTES_NAMES.welcome);
            } else if (location.pathname === tab.route && tab.label === t('home')) {
              const homeScroller = document.getElementById('home-scroller');
              if (homeScroller != null) {
                homeScroller.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
              }
            } else {
              navigate(tab.route);
            }
          }}>
          <img src={location.pathname === tab.route ? tab.activeIcon : tab.inactiveIcon} />
          <p className={location.pathname === tab.route ? 'active' : 'inactive'}>{tab.label}</p>
        </div>
      ))}
    </div>
  );
};

HomeTabs.propTypes = {
  isLoggedIn: PropTypes.bool
};

const mapStateToProps = ({ app }) => ({
  isLoggedIn: app.isLoggedIn,
  language: app.language
});

export default connect(mapStateToProps, {})(HomeTabs);
