import React from 'react';
import PropTypes from 'prop-types';
import LogoImg from '../../assets/images/logo.png';

const Logo = ({ className }) => {
  return (
    <img src={LogoImg} className={className} style={{ cursor: 'pointer' }} onClick={() => {}} />
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.className === nextProps.className;
}

Logo.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string
};
export default React.memo(Logo, arePropsEqual);
