import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import './index.css';

const InviteEarnTooltip = ({ reward_user, text, style }) => {
  // const { t } = useTranslation();
  return (
    <div className={'invite_earn_tooltip'} style={style}>
      <a data-tip data-for="InviteEarnTooltip">
        {text}
      </a>
      <ReactTooltip
        id="InviteEarnTooltip"
        className={'tooltip'}
        aria-haspopup="true"
        backgroundColor="#FFFFFF">
        <div className={'w100'}>
          <h3>How can invitations be used?</h3>
          <div className={'align-middle w100 row-item'}>
            <div className={'indicator'} />
            <p>
              if you order using this code at 18:00-20.00, {reward_user} will earn 2% of order total
              as reward.
            </p>
          </div>
          <div className={'align-middle w100 row-item'}>
            <div className={'indicator'} />
            <p>
              if you order using this code at other hours, {reward_user} will earn 1% of order total
              as reward.
            </p>
          </div>
          <div className={'dismiss-btn'}>Dismiss</div>
        </div>
      </ReactTooltip>
    </div>
  );
};

function arePropsEqual(prevProps, nextProps) {
  return prevProps.text === nextProps.text && prevProps.reward_user === nextProps.reward_user;
}

InviteEarnTooltip.propTypes = {
  reward_user: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object
};

export default React.memo(InviteEarnTooltip, arePropsEqual);
