import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Theme from '../../assets/theme';
import { setVendorCart } from '../../store/actions/shop';
import PopularItem from '../../components/Search/PopularItem';
import StartItem from '../../components/Search/StartItem';
import VendorItem from '../../components/Vendor/VendorItem';
import FoodItem from '../../components/Vendor/FoodItem';
import { SearchInput } from '../../components/Inputs';
import { RoundIconBtn } from '../../components/Buttons';
import SearchFilterModal from '../../components/Modals/SearchFilter';
import NoSearchRestaurants from '../../components/Empty/NoSearchRestaurants';
import { Filter } from '@styled-icons/bootstrap';
import './index.css';
import { Search } from '@styled-icons/evil';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ApiFactory } from '../../services';
import { Storage } from '../../services';

const SearchScreen = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [tab, setTab] = useState('vendor');
  const [recents, setRecents] = useState([]);
  const [text, setText] = useState('');
  const [isFilterModal, showFilterModal] = useState(false);
  const [filters, setFilters] = useState([
    {
      title: t('search.recommended'),
      check: false,
      keyFilter: 'recommended'
    },
    {
      title: t('search.free_delivery'),
      check: false,
      keyFilter: 'free_delivery'
    },
    {
      title: t('search.fastest'),
      check: false,
      keyFilter: 'fastest'
    },
    {
      title: t('search.distance'),
      check: false,
      keyFilter: 'distance'
    }
  ]);

  const [itemsState, setItemsState] = useState({
    loading: null,
    foodItems: []
  });
  const [restaurantsState, setRestaurantsState] = useState({
    loading: null,
    restaurants: []
  });
  const [popularSearchesState, setPopularSearchesState] = useState({
    loading: null,
    popularSearches: []
  });

  useEffect(() => {
    getPopularSearch();
    getRecents();
  }, []);

  const getRestaurants = (searchTerm, _filters) => {
    let { latitude, longitude } = props.coordinates;

    let arr = [];
    _filters.map((item) => {
      if (item.check === true) {
        arr.push(item);
      }
    });

    if (arr.length === 0) {
      ApiFactory.get(`vendors?lat=${latitude}&lng=${longitude}&name=${searchTerm}`)
        .then(({ data }) => {
          setRestaurantsState({
            ...restaurantsState,
            loading: false,
            restaurants: data.vendors.data
          });
        })
        .catch(() => {});
    } else if (arr.length === 1) {
      ApiFactory.get(
        `vendors?lat=${latitude}&lng=${longitude}&name=${searchTerm}&${arr[0].keyFilter}=1`
      )
        .then(({ data }) => {
          setRestaurantsState({
            ...restaurantsState,
            loading: false,
            restaurants: data.vendors.data
          });
        })
        .catch(() => {});
    } else {
      ApiFactory.get(
        `vendors?lat=${latitude}&lng=${longitude}&name=${searchTerm}&${arr[0].keyFilter}=1&${arr[1].keyFilter}=1`
      )
        .then(({ data }) => {
          setRestaurantsState({
            ...restaurantsState,
            loading: false,
            restaurants: data.vendors.data
          });
        })
        .catch(() => {});
    }

    showFilterModal(false);
  };

  const getItems = (text) => {
    let { latitude, longitude } = props.coordinates;
    setItemsState({ ...itemsState, loading: true });
    ApiFactory.get(`products?lat=${latitude}&lng=${longitude}&title=${text}`)
      .then(({ data }) => {
        setItemsState({ ...itemsState, loading: false, foodItems: data.products.data });
      })
      .catch(() => {
        setItemsState({ ...itemsState, loading: false });
      });
  };

  const getPopularSearch = () => {
    setPopularSearchesState({ ...popularSearchesState, loading: true });
    ApiFactory.get('search/suggestions')
      .then(({ data }) => {
        setPopularSearchesState({
          ...popularSearchesState,
          loading: false,
          popularSearches: data['suggestions']
        });
      })
      .catch(() => {
        setPopularSearchesState({ ...popularSearchesState, loading: false });
      });
  };

  const getRecents = () => {
    setRecents(Storage.getFilterRecent());
  };

  const clearAllRecents = () => {
    Storage.removeFilterRecent();
    setRecents([]);
  };

  const removeRecentItem = (text) => {
    try {
      if (text) {
        let filtered = recents.filter(function (el) {
          return el != text;
        });
        Storage.setFilterRecent(filtered);
        setRecents(filtered);
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const search = (text) => {
    setText(text);
    if (text.length > 2) {
      getRestaurants(text, filters);
      getItems(text);
    } else {
      setRestaurantsState({ ...restaurantsState, restaurants: [] });
    }
  };

  const showSimilar = async (restaurant) => {
    goToRestaurantDetails(restaurant);
    let text = restaurant.title;
    if (text) {
      let tmp_recents = recents.slice(0, recents.length);
      tmp_recents = tmp_recents.filter((i) => i !== text);
      tmp_recents.push(text);
      if (tmp_recents.length >= 8) {
        let newrecents = tmp_recents.slice(1);
        setRecents(newrecents);
        Storage.setFilterRecent(newrecents);
      } else {
        Storage.setFilterRecent(tmp_recents);
      }
    }
  };

  const goToRestaurantDetails = (vendorData) => {
    props.setVendorCart(vendorData);
    navigate(`/vendor/${vendorData.id}/${vendorData.order_method}`);
  };

  const onFavChange = (data) => {
    let found_index = restaurantsState.restaurants.findIndex((i) => i.id == data.id);

    if (found_index != -1) {
      let tmp = restaurantsState.restaurants.slice(0, restaurantsState.restaurants.length);
      tmp[found_index].isFav = data.isFav;
      setRestaurantsState({ ...restaurantsState, restaurants: tmp });
    }
  };

  const onProductFavChange = (data) => {
    let found_index = itemsState.foodItems.findIndex((i) => i.id == data.id);

    if (found_index != -1) {
      let tmp = itemsState.foodItems.slice(0, itemsState.foodItems.length);
      tmp[found_index].isFav = data.isFav;
      setItemsState({ foodItems: tmp });
    }
  };

  return (
    <div className={'search-screen'}>
      <div className={'align-middle searchview'}>
        <SearchInput
          placeholder={t('search.search_vendors_on_search')}
          icon={<Search size={20} color={Theme.colors.gray5} />}
          style={{ flex: 1, height: 45 }}
          value={text}
          onChange={search}
        />
        {text.length > 2 &&
          (restaurantsState.restaurants.length > 0 || itemsState.foodItems.length > 0) && (
            <RoundIconBtn
              style={{ marginLeft: 12, width: 45, height: 45 }}
              icon={<Filter size={26} color={Theme.colors.cyan2} />}
              onClick={() => {
                showFilterModal(true);
              }}
            />
          )}
      </div>
      <div className={'scrollview'}>
        {text.length > 2 && (
          <div
            className={'row_center_start'}
            style={{ height: 37, display: 'inline-flex', flexWrap: 'wrap' }}>
            <div className="col_center" onClick={() => setTab('vendor')}>
              <p
                style={{
                  color: tab === 'vendor' ? Theme.colors.cyan2 : '#B5B5B5',
                  fontSize: 16,
                  fontFamily: Theme.fonts.medium
                }}>
                {t('search_details.restaurants_tab')}
              </p>
            </div>
            <div className={'col_center'} style={{ marginLeft: 20 }} onClick={() => setTab('item')}>
              <p
                style={{
                  color: tab === 'item' ? Theme.colors.cyan2 : '#B5B5B5',
                  fontSize: 16,
                  fontFamily: Theme.fonts.medium
                }}>
                {t('search_details.items_tab')}
              </p>
            </div>
          </div>
        )}
        {text.length < 3 ? (
          <div style={{ paddingHorizontal: 20 }}>
            <div className={'row_center'}>
              <p className={'subject-title'}>{t('search_details.recents')}</p>
              <div>
                <p
                  className={'clear-all-btn'}
                  onClick={() => {
                    clearAllRecents();
                  }}>
                  {t('search_details.clear_all')}
                </p>
              </div>
            </div>
            {recents.reverse().map((item, index) => (
              <StartItem
                key={index}
                title={item}
                onPress={() => search(item)}
                onRemove={(text) => removeRecentItem(text)}
              />
            ))}
            <p className={'subject-title'}>{t('search_details.popular')}</p>
            <div
              className={'align-middle row_center_start flex-wrap'}
              style={{ justifyContent: 'flex-start' }}>
              {popularSearchesState.popularSearches.map((item, index) => (
                <PopularItem key={index} title={item} onPress={() => search(item)} />
              ))}
            </div>
          </div>
        ) : (
          <div style={{ paddingHorizontal: 20, paddingTop: 20 }}>
            {tab == 'vendor' ? (
              <div>
                {restaurantsState.restaurants.length > 0
                  ? restaurantsState.restaurants.reverse().map((vendor, index) => (
                      <VendorItem
                        key={index}
                        id={vendor.id}
                        data={vendor}
                        vendor_id={vendor.id}
                        isFav={vendor.isFav}
                        isOpen={vendor.is_open}
                        style={{ width: '100%', marginBottom: 12 }}
                        onFavChange={onFavChange}
                        onClick={() => {
                          showSimilar(vendor);
                        }}
                      />
                    ))
                  : restaurantsState.loading == false && (
                      <NoSearchRestaurants searchTerm={text} style={{ marginTop: 150 }} />
                    )}
              </div>
            ) : (
              <div className={'flex_1 align-col-middle'}>
                {itemsState.foodItems.length > 0
                  ? itemsState.foodItems.map((item, index) => (
                      <FoodItem
                        key={index}
                        data={item}
                        id={item.id}
                        isFav={item.isFav}
                        onClick={() => {
                          goToRestaurantDetails(item.vendor);
                        }}
                        onFavChange={onProductFavChange}
                      />
                    ))
                  : itemsState.loading == false && (
                      <NoSearchRestaurants searchTerm={text} style={{ marginTop: 150 }} />
                    )}
              </div>
            )}
          </div>
        )}
      </div>
      <SearchFilterModal
        isOpen={isFilterModal}
        items={filters}
        onClose={() => showFilterModal(false)}
        onFilterChange={(filterItems) => {
          setFilters(filterItems);
        }}
        onApplyFilter={() => {
          getRestaurants(text, filters);
          showFilterModal(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ app }) => ({
  user: app.user || {},
  isLoggedIn: app.isLoggedIn,
  coordinates: app.coordinates
});

SearchScreen.propTypes = {
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number
  }),
  setVendorCart: PropTypes.func
};
export default connect(mapStateToProps, {
  setVendorCart
})(SearchScreen);
